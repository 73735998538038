import React from "react";
import { Route, Routes } from "react-router-dom";
import AddProduct from "../../components/admin/addProduct/AddProduct";
import Home from "../../components/admin/home/Home";
import Reviews from "../../components/admin/reviews/Reviews";
import Navbar from "../../components/admin/navbar/Navbar";
import OrderDetails from "../../components/admin/orderDetails/OrderDetails";
import Orders from "../../components/admin/orders/Orders";
import ViewProducts from "../../components/admin/viewProducts/ViewProducts";

import styles from "./Admin.module.scss";
import CreateCouponCode from "../../components/admin/createCouponCode/CreateCouponCode";
import ManageCouponCode from "../../components/admin/manageCouponCodes/ManageCouponCode";
import Tagline from "../../components/admin/tagline/Tagline";
import EditProduct from "../../components/admin/editProduct/EditProduct";
import SoldOut from "../../components/admin/soldOut/SoldOut";
import StockRequests from "../../components/admin/stockRequests/StockRequests";
import Returns from "../../components/admin/returns/Returns";
import AdminTeamKit from "../../components/admin/teamkit/AdminTeamKit";
import ManageTopCategories from "../../components/admin/manageTopCategories/manageTopCategories";
import ManageVideoReviews from "../../components/admin/manageVideoReviews/ManageVideoReviews";
import JerseyCategory from "../../components/admin/manageTopCategories/jerseyCategory/JerseyCategory";
import ManageCuratedJersey from "../../components/admin/manageCuratedJersey/ManageCuratedJersey";

const Admin = () => {
  return (
    <div className={styles.admin}>
      <div className={styles.navbar}>
        <Navbar />
      </div>
      <div className={styles.content}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="all-products" element={<ViewProducts />} />
          <Route path="admin-teamkit" element={<AdminTeamKit />} />
          <Route path="manage-curated-jerseys" element={<ManageCuratedJersey />} />

          <Route path="add-product/" element={<AddProduct />} />
          <Route path="edit-product/:id" element={<EditProduct />} />
          <Route path="orders" element={<Orders />} />
          <Route path="order-details/:id" element={<OrderDetails />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="create-coupon-code" element={<CreateCouponCode />} />
          <Route path="manage-coupon-code" element={<ManageCouponCode />} />
          <Route path="manage-tagline" element={<Tagline />} />
          <Route
            path="manage-featured-categories"
            element={<ManageTopCategories />}
          />
          <Route path="manage-jersey" element={<JerseyCategory />} />
          <Route path="manage-video-reviews" element={<ManageVideoReviews />} />

          {/* <Route path="stock-requests" element={<StockRequests />} /> */}
          <Route path="sold-out" element={<SoldOut />} />
          <Route path="stock-requests" element={<StockRequests />} />
          <Route path="returns" element={<Returns />} />
        </Routes>
      </div>
    </div>
  );
};

export default Admin;

import React, { useEffect, useState } from "react";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import styles from "./OrderDetails.module.scss";
import spinnerImg from "../../../assets/spinner.jpg";
import { Link, useParams } from "react-router-dom";
import ChangeOrderStatus from "../changeOrderStatus/ChangeOrderStatus";

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const { id } = useParams();
  const { document } = useFetchDocument("orders", id);

  useEffect(() => {
    setOrder(document);
  }, [document]);

  // Calculate total discount and discount percentage
  let totalDiscount = 0;
  let discountPercentage = 0;
  if (order !== null) {
    totalDiscount = order.cartItems.reduce((total, cart) => {
      const price = parseFloat(cart.price) +  parseFloat(cart.knockingServicePrice);
      const tax = (order.shippingAddress.state==="New Jersey"?cart.tax:0);
      const withTax = price + (price * tax) / 100;
      const totalItemPrice = withTax * cart.cartQuantity;
      return total + totalItemPrice;
    }, 0) - order.orderAmount;

    if (totalDiscount > 0) {
      discountPercentage = (totalDiscount / (totalDiscount + order.orderAmount)) * 100;
    }
  }

  return (
    <>
      <div className={styles.table}>
        <h2>Order Details</h2>
        <div>
          <Link to="/admin/orders">&larr; Back To Orders</Link>
        </div>
        <br />
        {order === null ? (
          <img src={spinnerImg} alt="Loading..." style={{ width: "50px" }} />
        ) : (
          <>
            <p style={{ fontSize: 15 }}>
              <b>Order ID</b> {order.id}
            </p>
            <p style={{ fontSize: 15 }}>
              <b>Amount Paid</b> ${parseFloat(order.orderAmount).toFixed(2)} ({order.paymentMethod})
            </p>
            <p style={{ fontSize: 15 }}>
              <b>Actual Amount: </b>${" "}
              {order.cartItems
                .reduce((total, cart) => {
                  const price = parseFloat(cart.price) + parseFloat(cart.knockingServicePrice);
                  const tax = (order.shippingAddress.state==="New Jersey"?cart.tax:0);
                  const withTax = price + (price * tax) / 100;
                  const totalItemPrice = withTax * cart.cartQuantity;
                  return total + totalItemPrice;
                }, 0)
                .toFixed(2)}{" "}
              {/* Ensure the total is formatted with 2 decimal places */}
            </p>
            <p style={{ fontSize: 15 }}>
              <b>Order Status</b> {order.orderStatus}
            </p>
            <p style={{ fontSize: 15 }}>
              <b>Shipping Address</b>
              <br />
              Customer name: {order.shippingAddress.name}
              <br />
              Customer phone no: {order.shippingAddress.phone}
              <br />
              Customer email ID: {order.userEmail}
              <br />
              Address: {order.shippingAddress.line1},
              {order.shippingAddress.line2}, {order.shippingAddress.city}
              <br />
              State: {order.shippingAddress.state}
              <br />
              Country: {order.shippingAddress.country}
            </p>
            <br />
            <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Product</th>
                  <th>Selected Size</th>
                  <th>Price</th>
                 <th>Tax</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {order.cartItems.map((cart, index) => {
                  const {
                    id,
                    name,
                    price,
                    tax,
                    imageURLs,
                    cartQuantity,
                    selectedSize,
                    sport,
                    category,
                    knockingService,
                    knockingServicePrice
                  } = cart;
                  const realprice = parseFloat(price+knockingServicePrice);
                  const realtax = (order.shippingAddress.state==="New Jersey"?tax:0);
                  const withTax = (realprice + (realprice * realtax) / 100).toFixed(2);
                  const total = (withTax * cartQuantity).toFixed(2);
                  return (
                    <tr key={id}>
                      <td>
                        <b>{index + 1}</b>
                      </td>
                      <td>
                        <p>
                          <b>{name} - {(cart.sport==="Cricket" && cart.category==="Bats")?knockingService:"" }</b>
                        </p>
                        <img
                          src={imageURLs[0]}
                          alt={name}
                          style={{ width: "100px" }}
                        />
                      </td>
                      <td>{selectedSize}</td>
                      <td>{realprice}</td>
                     <td>{realtax}</td>
                      <td>{withTax}</td>
                      <td>{cartQuantity}</td>
                      <td>{total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <p style={{ fontSize: 20 }}>
              <b>Discount Provided: </b>
              {totalDiscount > 0.00 ? `$${totalDiscount.toFixed(2)}` : "N/A"} ({discountPercentage.toFixed(2)}%)
            </p>
          </>
        )}
        <ChangeOrderStatus order={order} id={id} />
      </div>
    </>
  );
};

export default OrderDetails;

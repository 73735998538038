// ReviewProducts.jsx
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { selectUserID, selectUserName } from "../../redux/slice/authSlice";
import Card from "../card/Card";
import {
  addDoc,
  collection,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db, storage } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import useFetchDocument from "../../customHooks/useFetchDocument";
import spinnerImg from "../../assets/spinner.jpg";
import Loader from "../loader/Loader";
import ReactStars from "react-stars";
import styles from "./ReviewProducts.module.scss";

const REVIEW_COLLECTION = "reviews";

const ReviewProducts = () => {
  const navigate = useNavigate();
  const [rate, setRate] = useState(0);
  const [review, setReview] = useState("");
  const [product, setProduct] = useState(null);
  const [imageURLs, setImageURLs] = useState([null, null]);
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [uploadProgress2, setUploadProgress2] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const { id } = useParams();
  const { document } = useFetchDocument("products", id);
  const userID = useSelector(selectUserID);
  const userName = useSelector(selectUserName);

  useEffect(() => {
    setProduct(document);
  }, [document]);

  const handleImageChange = async (e, index) => {
    const file = e.target.files[0];

    const storageRef = ref(storage, `reviews/${Date.now()}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setIsUploadInProgress(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (index === 0) {
          setUploadProgress1(progress);
        } else if (index === 1) {
          setUploadProgress2(progress);
        }
      },
      (error) => {
        toast.error(error.message);
        setIsUploadInProgress(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          setImageURLs((prevURLs) => {
            const newURLs = [...prevURLs];
            newURLs[index] = downloadURL;
            return newURLs;
          });

          toast.success("Image uploaded successfully.");
        } catch (error) {
          toast.error(error.message);
        } finally {
          setIsUploadInProgress(false);
        }
      }
    );
  };

  const removeImage = (index) => {
    setImageURLs((prevURLs) => {
      const newURLs = [...prevURLs];
      newURLs[index] = null;
      return newURLs;
    });
  };

  const submitReview = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    if (!product) {
      toast.error("Product not found. Please try again later.");
      setShowLoader(false);
      return;
    }

    if (rate === 0) {
      toast.error("Please provide a rating before submitting.");
      setShowLoader(false);
      return;
    }

    const today = new Date();
    const date = today.toDateString();

    const cleanedImageURLs = imageURLs.filter((url) => url !== null);

    try {
      const reviewQuerySnapshot = await getDocs(
        query(
          collection(db, REVIEW_COLLECTION),
          where("productID", "==", id),
          where("userID", "==", userID)
        )
      );

      const editedTag = reviewQuerySnapshot.docs.length > 0 ? "(edited) " : "";

      const reviewConfig = {
        userID,
        userName,
        productID: id,
        rate,
        review: editedTag + review,
        reviewDate: date,
        createdAt: Timestamp.now().toDate(),
        productName: product.name,
        hasReply: false,
        imageURLs: cleanedImageURLs,
      };

      if (reviewQuerySnapshot.docs.length > 0) {
        const existingReview = reviewQuerySnapshot.docs[0];
        await updateDoc(doc(db, REVIEW_COLLECTION, existingReview.id), {
          ...reviewConfig,
          replies: null,
        });
        toast.success("Review updated successfully");
      } else {
        await addDoc(collection(db, REVIEW_COLLECTION), reviewConfig);
        toast.success("Review submitted successfully");
      }

      setRate(0);
      setReview("");
      setImageURLs([null, null]);
      setUploadProgress1(0);
      setUploadProgress2(0);
      setIsUploadInProgress(false);
      navigate("/");
      setShowLoader(false);
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error(error.message);
      setShowLoader(false);
    }
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className={styles.reviewContainer}>
      <h2 className={styles.reviewHeading}>Review the Product</h2>
      {product ? (
        <div className={styles.productDetails}>
          <p>
            <b>Product name:</b> {product.name}
          </p>
          <img
            src={product.imageURLs[0]}
            alt={product.name}
            className={styles.productImage}
          />
        </div>
      ) : (
        <img src={spinnerImg} alt="Loading..." className={styles.spinner} />
      )}
      <Card cardClass={styles.card}>
        <form onSubmit={(e) => submitReview(e)}>
          <label className={styles.label}>Rating:</label>
          <div className={styles.stars}>
            <ReactStars
              size={40}
              value={rate}
              onChange={(rate) => {
                setRate(rate);
              }}
            />
          </div>
          <label className={styles.label}>Review</label>
          <textarea
            value={review}
            required
            onChange={(e) => setReview(e.target.value)}
            className={styles.textarea}
            cols="30"
            rows="10"
          ></textarea>

          <div className={styles.imageUploadContainer}>
            <div className={styles.imageUpload}>
              <label htmlFor="image1" className={styles.customFileLabel}>
                {imageURLs[0] ? "Change Image 1" : "Upload Image 1"}
              </label>
              <input
                type="file"
                id="image1"
                accept="image/*"
                onChange={(e) => handleImageChange(e, 0)}
                style={{ display: "none" }}
              />
              {imageURLs[0] && (
                <div className={styles.imagePreview}>
                  <img src={imageURLs[0]} alt="Image Preview" width={200} />
                  <button
                    type="button"
                    onClick={() => removeImage(0)}
                    className={styles.removeImageButton}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              {uploadProgress1 > 0 && (
                <div className={styles.progressBarContainer}>
                  <progress
                    value={uploadProgress1}
                    max="100"
                    className={styles.progressBar}
                  />
                  <span className={styles.progressPercentage}>
                    {Math.round(uploadProgress1)}%
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={styles.imageUploadContainer}>
            <div className={styles.imageUpload}>
              <label htmlFor="image2" className={styles.customFileLabel}>
                {imageURLs[1] ? "Change Image 2" : "Upload Image 2"}
              </label>
              <input
                type="file"
                id="image2"
                accept="image/*"
                onChange={(e) => handleImageChange(e, 1)}
                style={{ display: "none" }}
              />
              {imageURLs[1] && (
                <div className={styles.imagePreview}>
                  <img src={imageURLs[1]} alt="Image Preview" width={200} />
                  <button
                    type="button"
                    onClick={() => removeImage(1)}
                    className={styles.removeImageButton}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              {uploadProgress2 > 0 && (
                <div className={styles.progressBarContainer}>
                  <progress
                    value={uploadProgress2}
                    max="100"
                    className={styles.progressBar}
                  />
                  <span className={styles.progressPercentage}>
                    {Math.round(uploadProgress2)}%
                  </span>
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            className={`${styles.btn} ${styles.btnPrimary}`}
            disabled={isUploadInProgress}
          >
            Submit Review
          </button>
        </form>
      </Card>
    </div>
  );
};

export default ReviewProducts;

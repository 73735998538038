import React, { useState } from "react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./EmblaCarousel.module.css"; // Import CSS module
import { InstagramEmbed } from "react-social-media-embed";
import { useEffect } from "react";
import {
  FaChevronCircleRight,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

const EmblaCarousel = (props) => {
  const { slides, options } = props;
  const slidesArray = [...slides];
  console.log(slidesArray);
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);
  const isMobile = window.innerWidth <= 800;

  const [slideID, setSlideID] = useState(0);

  const prev = () => {
    if (slideID === 0) {
      setSlideID(slidesArray.length - 1); // If at the beginning, go to the last slide
    } else {
      setSlideID(slideID - 1);
    }
  };

  const next = () => {
    if (slideID === slidesArray.length - 1) {
      setSlideID(0); // If at the end, go to the first slide
    } else {
      setSlideID(slideID + 1);
    }
  };
  let slideToShow = slidesArray[slideID];
  useEffect(() => {
    slideToShow = slidesArray[slideID];
  }, [slidesArray, slideID]);
  return (
    <>
      {isMobile ? (
        <>
          {slideToShow && (
            <div className={styles.mobileslider}>
              <div style={{marginLeft:"-20px"}}>
                <button onClick={prev} className={styles.sliderbutton}>
                  <FaChevronLeft />
                </button>
                <button onClick={next} className={styles.sliderbutton}>
                  <FaChevronRight />
                </button>
              </div>
              <div className="embla__slide" key={slideToShow.id}>
                <div key={slideToShow.id} className={styles.reel}>
                  <InstagramEmbed url={slideToShow.src} width={328} />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <section className={styles.embla}>
          {" "}
          <div className={styles.embla__buttons}>
            {/* Use styles from CSS module */}

            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />

            <div className={styles.embla__viewport} ref={emblaRef}>
              {/* Use styles from CSS module */}
              <div className={styles.embla__container}>
                {" "}
                {/* Use styles from CSS module */}
                {slides.map((reel) => (
                  <div className="embla__slide" key={reel.id}>
                    <div key={reel.id} className={styles.reel}>
                      <InstagramEmbed url={reel.src} width={328} />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>
          {/* {!isMobile && ( */}
          <div className={styles.embla__controls}>
            {" "}
            {/* Use styles from CSS module */}{" "}
            {/* Use styles from CSS module */}
            <div className={styles.embla__dots}>
              {" "}
              {/* Use styles from CSS module */}
              {!isMobile && (
                <>
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      onClick={() => onDotButtonClick(index)}
                      className={`${styles.embla__dot} ${
                        index === selectedIndex
                          ? styles["embla__dot--selected"]
                          : ""
                      }`}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          {/* )} */}
        </section>
      )}
    </>
  );
};

export default EmblaCarousel;

import React, { useEffect, useState } from 'react';
import { getDocs, collection} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage,auth } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styles from './TeamKitForm.module.scss';
import {toast} from 'react-toastify'
import { CirclePicker } from 'react-color';
import Loader from '../../components/loader/Loader';


const TeamKitForm = () => {
  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState({});
  const [showLoader,setShowLoader] = useState(false)
  const [name, setName] = useState('');
  const [hoveredColor, setHoveredColor] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [color, setColor] = useState('#ffffff');
  const [selectedColor, setSelectedColor] = useState('');
 // Default color
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showColorPickers, setShowColorPickers] = useState({});

  const [colorChoices, setColorChoices] = useState({});
  const [radioImageOptions, setRadioImageOptions] = useState({});
  // Add state variables to manage visibility of radio image options
const [showRadioImageOptions, setShowRadioImageOptions] = useState({});

// Function to toggle visibility of radio image options
const toggleRadioImageOptions = (sectionId, index) => {
  setShowRadioImageOptions(prevState => ({
    ...prevState,
    [`${sectionId}_${index}`]: !prevState[`${sectionId}_${index}`]
  }));
};
  // Function to handle color change
  const handleColorChange = (sectionId, fieldIndex, color) => {
    setColorChoices(prevState => ({
      ...prevState,
      [`${sectionId}_${fieldIndex}`]: color,
    }));
    console.log(colorChoices)
  };


  // State for managing predefined colors
  const colours = {
    "Dark Orange": '#ea4606',
    "White": '#ffffff',
    "Half White": '#d4d4d4',
    "Light Grey": '#9e9dad',
    "Grey": '#505050',
    "Anthracite": '#373a36',
    "Black": '#000000',
    "Beige": '#ebddbe',
    "Brown": '#845b35',
    "Reel Gold": '#665000',
    "Olive": '#af9841',
    "Mustard": '#d7cc75',
    "Pale Yellow": '#ffffa7',
    "Yellow": '#fff22d',
    "Gold": '#ffbb00',
    "Orange": '#ff6700',
    "Teal": '#006050',
    "Green": '#033c20',
    "Neon": '#99ff32',
    "Light Green": '#33aa33',
    "Cardinal": '#6b2c3c',
    "Maroon": '#6c1f29',
    "Scarlet": '#b52130',
    "Dark Red": '#bb0000',
    "Red": '#ef3838',
    "Pink": '#ec268f',
    "Baby Pink": '#ff96be',
    "Magenta": '#ff0045',
    "Violet": '#2d003c',
    "Purple": '#542d7e',
    "Light Blue": '#c2def4',
    "Rich Royal": '#1568be',
    "Sky Blue": '#3399ff',
    "Seize": '#23bfc8',
    "Reflex Blue": '#4343ff',
    "Royal Blue": '#0f41b2',
    "Catalina Blue": '#040e5f',
    "Navy": '#22293c',
  };
  
  
  const getColorDisplayText = (color) => {
    const colorName = Object.keys(colours).find((key) => colours[key].toLowerCase() === color.toLowerCase());
    return colorName ? `${colorName} (${colours[colorName]})` : color;
};

const getColorDisplayTextValue = (colorChoices, sectionId, index) => {
  const selectedColor = colorChoices[`${sectionId}_${index}`];
  if (selectedColor) {
    const colorName = Object.keys(colours).find((key) => colours[key].toLowerCase() === selectedColor.toLowerCase());
    return colorName ? `${colorName} (${selectedColor})` : selectedColor;
  } else {
    return 'Not chosen';
  }
};

const navigate = useNavigate()
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        // If user is not logged in, redirect to login page
        navigate('/login');
      }
    });


    fetchSections();
    setShowLoader(false)
  }, []);

  

  const fetchSections = async () => {
    try {
      setShowLoader(true)
      const querySnapshot = await getDocs(collection(db, 'teamkitsections'));
      const fetchedSections = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSections(fetchedSections);
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      toast.error('Error fetching form Information');
    }
  };

  const handleSectionToggle = (sectionId) => {
    setSelectedSections(prevState => ({
      ...prevState,
      [sectionId]: !prevState[sectionId]
    }));
  };

  const getHighQualityDownloadURL = async (file) => {
    try {
      const imgRef = ref(storage, `images/${file.name}`);
      const highQualityDownloadURL = await getDownloadURL(imgRef);
      return highQualityDownloadURL;
    } catch (error) {
      console.error('Error getting high-quality download URL:', error);
      throw error;
    }
  };
  
  const downloadImage = async (url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to download image');
        }
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error('Error downloading image:', error);
        return null;
    }
};

// Function to add image to PDF document
const addImageToPDF = async (doc, imageUrl, xPos, yPos, width, height) => {
    try {
        const base64Image = await convertToBase64(imageUrl);
        doc.addImage(base64Image, 'JPEG', xPos, yPos, width, height);
    } catch (error) {
        console.error('Error adding image to PDF:', error);
    }
};

// Function to convert image to base64 string
const convertToBase64 = async (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/jpeg');
            resolve(dataURL);
        };
        img.onerror = reject;
        img.src = imageUrl;
    });
}

function getDataUri(url, cb) {
  var image = new Image();
  image.setAttribute('crossOrigin', 'anonymous'); // Getting images from external domain

  image.onload = function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight; 

      // Next three lines for white background in case PNG has a transparent background
      var ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff';  // Set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(this, 0, 0);

      cb(canvas.toDataURL('image/jpeg'));
  };

  image.src = url;
}
const handleSubmit = async (e) => {
  setShowLoader(true)
  e.preventDefault();

  const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
  const isEmailValid = validateEmail(email);
  if (!isPhoneNumberValid || !isEmailValid) {
      toast.error("Enter all information correctly");
      return;
  }

  const doc = new jsPDF();

  doc.text('Custom Teamkit Order Enquiry', 70, 10);
  doc.text(`Name: ${name}`, 10, 20);
  doc.text(`Phone Number: ${phoneNumber}`, 10, 30);
  doc.text(`Email: ${email}`, 10, 40);
  doc.text('Selected Products:', 10, 50);

  let yPos = 60;
  let currentPage = 1;

  for (const [sectionId, isSelected] of Object.entries(selectedSections)) {
      if (isSelected) {
          const section = sections.find(section => section.id === sectionId);
          if (section) {
              doc.text('', 10, yPos); // Insert line break before section name
              yPos += 5; // Adjust spacing after line break
              doc.setFont('helvetica', 'bold'); // Set font to bold
              doc.text(section.name, 10, yPos); // Section name as bold text
              doc.setFont('helvetica', 'normal'); // Reset font
              yPos += 10;

              for (const [index, field] of section.fields.entries()) {
                  const fieldValue = document.getElementById(`field_${sectionId}_${index}`)?.value || '';
                  
                 
                 
                 
                  if (field.type === 'radio-image') {
                      const selectedOption = radioImageOptions[`${sectionId}_${index}`];
                      if (selectedOption) {
                          try {
                              yPos += 10; // Add space before the label
                              doc.text(`${field.label}:`, 20, yPos);
                              yPos += 5; // Move yPos down slightly to create space between label and image

                              const response = await fetch(selectedOption);
                              if (!response.ok) {
                                  throw new Error('Network response was not ok');
                              }
                              const blob = await response.blob();
                              const imageUrl = URL.createObjectURL(blob);

                              const imgWidth = 50; // Adjust as needed
                              const imgHeight = 50; // Adjust as needed
                              if (yPos + imgHeight + 10 > doc.internal.pageSize.getHeight()) {
                                  doc.addPage(); // Start a new page
                                  currentPage++;
                                  yPos = 10; // Reset yPos for the new page
                              }
                              doc.addImage(imageUrl, 'JPEG', 20, yPos, imgWidth, imgHeight);
                              yPos += imgHeight + 10; // Adjust vertical spacing as needed
                          } catch (error) {
                              console.error('Error fetching image:', error);
                          }
                      }
                  } 
                  else if (field.type === 'description') {

                    yPos += 10; // Add space before the label
                    doc.text(`${field.label}:`, 20, yPos);
                    yPos += 20; // Move yPos down slightly to create space between label and image
                    // Split the description into multiple lines based on a predefined width
                    const descriptionLines = doc.splitTextToSize(fieldValue, 180); // Adjust the width as needed
                
                    // Add each line to the PDF
                    descriptionLines.forEach((line, lineIndex) => {
                        doc.text(line, 20, yPos + lineIndex * 10); // Adjust spacing as needed
                    });
                
                    // Update yPos based on the number of lines added
                    yPos += descriptionLines.length * 10 + 5; // Adjust spacing as needed
                }
                  
                  
                  else if (field.type === 'file' || field.type === 'multiple-files') {
                      const inputField = document.getElementById(`field_${sectionId}_${index}`);
                      if (inputField?.files.length > 0) {
                          const files = Array.from(inputField.files);
                          for (const [fileIndex, file] of files.entries()) {
                              const imgUrl = await uploadImageToStorage(file);

                              // Calculate the height required for this image and link
                              const totalHeight = 80; // Height of image and link
                              if (yPos + totalHeight > doc.internal.pageSize.getHeight()) {
                                  doc.addPage(); // Start a new page
                                  currentPage++;
                                  yPos = 10; // Reset yPos for the new page
                              }

                              // Display field name above the image
                              doc.text(`${field.label}:`, 20, yPos);
                              yPos += 10; // Adjust spacing after field name

                              // Display image with size 50x50 pixels
                              const imgData = await getImageDataUrl(file); // Get image data URL
                              doc.addImage(imgData, 'JPEG', 20, yPos, 50, 50); // Adjust size as needed

                              // Display hyperlink text below the image
                              doc.setTextColor(0, 0, 255); // Set text color to blue
                              doc.textWithLink('Click to view original Image', 20, yPos + 60, { url: imgUrl }); // Adjust position as needed
                              doc.setTextColor(0); // Reset text color

                              yPos += 80; // Adjust spacing between image and hyperlink
                          }
                      }
                  } else {
                      if (field.type === 'color') {
                          // Get the selected color and its hex code
                          const selectedColor = colorChoices[`${sectionId}_${index}`];
                          let colorName = '';
                          let hexCode = '';

                          if (selectedColor) {
                              colorName = Object.keys(colours).find(key => colours[key].toLowerCase() === selectedColor.toLowerCase());
                              hexCode = selectedColor;
                          } else {
                              colorName = 'Not chosen';
                              hexCode = '';
                          }

                          // Draw a colored square to represent the selected color
                          if (selectedColor) {
                              doc.setFillColor(selectedColor);
                              doc.rect(20, yPos, 10, 10, 'F'); // Adjust position as needed
                          }

                          // Display color name and hex code
                          doc.setTextColor(0); // Set text color to black
                          doc.text(`${field.label}: ${colorName} (${hexCode})`, 40, yPos + 8); // Adjust position as needed

                          yPos += 15; // Adjust spacing after color representation
                      } else {
                          doc.text('', 10, yPos); // Insert line break before field name
                          yPos += 5; // Adjust spacing after line break
                          doc.text(`${field.label}: ${fieldValue}`, 20, yPos); // Display field label and value
                          yPos += 10; // Adjust spacing after field
                      }
                  }

                  // Check if content exceeds page height and start a new page if needed
                  if (yPos + 10 > doc.internal.pageSize.getHeight()) {
                      doc.addPage(); // Start a new page
                      currentPage++;
                      yPos = 10; // Reset yPos for the new page
                  }
              }
          }
      }
  }

  // Save the PDF and upload to Firebase Storage
  const pdfBlob = doc.output('blob');
  const pdfRef = ref(storage, `pdfs/form_data_${name + "-" + email}.pdf`);
  try {
      await uploadBytes(pdfRef, pdfBlob);
      const downloadURL = await getDownloadURL(pdfRef);
      setShowLoader(false)
      console.log('PDF uploaded successfully! Download URL:', downloadURL);
  } catch (error) {
      console.error('Error uploading PDF:', error);
  }
};



 // Function to convert image file to data URL
  const getImageDataUrl = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsDataURL(file);
    });
  };

  const toggleColorPicker = (sectionId, index) => {
    setShowColorPickers(prevState => ({
      ...prevState,
      [`${sectionId}_${index}`]: !prevState[`${sectionId}_${index}`]
    }));
  };

  
  const validatePhoneNumber = (number) => {
    if (!/^\d{10}$/.test(number)) {
      setPhoneNumberError('Please enter a 10-digit phone number.');
      return false;
    }
    setPhoneNumberError('');
    return true;
  };

  const validateEmail = (email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address.');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value;
    setPhoneNumber(number);
    validatePhoneNumber(number);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    validateEmail(email);
  };

  const uploadImageToStorage = async (blob) => {
    try {
      
      const imgRef = ref(storage, `images/${Date.now()}.jpg`);
      await uploadBytes(imgRef, blob);
      const downloadURL = await getDownloadURL(imgRef);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

 

  // Function to handle clicking on a predefined color
  const handleColorClick = (selectedColor) => {
    setColor(selectedColor); // Set the selected color
    handleColorChange(selectedColor); // Populate the text field with the name of the color
  };
  const handleCloseColorPicker = (sectionId, index) => {
    setShowColorPickers(prevState => ({
      ...prevState,
      [`${sectionId}_${index}`]: false
    }));
  };

  return (
    <>
    {showLoader && <Loader />}
    <div className={styles.formcontainer}>
      <h2>Team Kit Enquiry Form</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles['input-container']} >
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className={`${styles['input-container']} ${phoneNumberError && styles.error}`}>
  <label htmlFor="phoneNumber">Phone Number (10 Digits):</label>
  <input
    type="text"
    id="phoneNumber"
    value={phoneNumber}
    onChange={handlePhoneNumberChange}
  />
  {phoneNumberError && <div className={styles['error-message']}>{phoneNumberError}</div>}
</div>
<div className={`${styles['input-container']} ${emailError && styles.error}`}>
  <label htmlFor="email">Email Address:</label>
  <input
    type="email"
    id="email"
    value={email}
    onChange={handleEmailChange}
  />
  {emailError && <div className={styles['error-message']}>{emailError}</div>}
</div>
        <h3>Select Required Accessories:</h3>
        {sections.map(section => (
          section.enabled && (
            <div key={section.id} className={styles['section-card']}>
              <input
                type="checkbox"
                id={`section_${section.id}`}
                checked={selectedSections[section.id]}
                onChange={() => handleSectionToggle(section.id)}
                className={styles['section-checkbox']}
              />
              <label htmlFor={`section_${section.id}`} className={styles['section-name']}>{section.name}</label>
              <div className={`${styles['form-group']} ${selectedSections[section.id] ? styles['active'] : ''}`}>
                {section.fields && section.fields.map((field, index) => (
                  <div key={index}>
                    <label htmlFor={`field_${section.id}_${index}`}><br></br>{field.label}</label>
                    {field.type === 'dropdown' && (
                      <select id={`field_${section.id}_${index}`}>
                        {field.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>{option}</option>
                        ))}
                      </select>
                    )}
                  {field.type === 'text' && <input type="text" id={`field_${section.id}_${index}`} required={selectedSections[section.id] && field.required} />}
                  {field.type === 'description' && <textarea rows="5" cols="33" id={`field_${section.id}_${index}`} required={selectedSections[section.id] && field.required} />}
                  {field.type === 'radio-image' && (
  <div className={styles.radioImageContainer}>
    {/* Map over radio image options */}
    {field.options.map((option, optionIndex) => (
      <div key={optionIndex} className={styles.radioImageLabel}>
        <input
          type="radio"
          id={`field_${section.id}_${index}_${optionIndex}`}
          name={`field_${section.id}_${index}`}
          value={option}
          required={selectedSections[section.id] && field.required}
          checked={radioImageOptions[`${section.id}_${index}`] === option}
          onChange={() => {
            console.log("onchange event")
            console.log(option); // Log the option to verify its value
            setRadioImageOptions(prevState => ({
              ...prevState,
              [`${section.id}_${index}`]: option
            }));
            console.log("radio change"); // Log a message to verify that the onChange event is triggered
            console.log(radioImageOptions); // Log the updated state to verify state changes
          }}
          className={styles.radioImageInput}
        />
        {/* Apply custom styles for radio images */}
        <label htmlFor={`field_${section.id}_${index}_${optionIndex}`}>
          <img src={option} alt={option} className={styles.radioImage} />
        </label>
      </div>
    ))}
  </div>
)}



                  
  {field.type === 'number' && (
    <input 
        type="text" 
        id={`field_${section.id}_${index}`} 
        required={selectedSections[section.id] && field.required} 
        onInput={(e) => {
            // Allow only numbers and prevent any other characters
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }} 
    />
)}
{field.type === 'color' && (
  <div className={styles.colorPickerContainer}>
    <input
      type="text"
      id="color"
      value={getColorDisplayTextValue(colorChoices, section.id, index) !== "Not chosen" ?getColorDisplayTextValue(colorChoices, section.id, index):""}
      onClick={()=>{toggleColorPicker(section.id, index)}}
      placeholder='Click to pick a colour'
      required={selectedSections[section.id] && field.required}
  
    />
    <br /><br />
    <p>{showColorPickers[`${section.id}_${index}`] ? "Pick a color:" : ""}</p>
    <br />
    {showColorPickers[`${section.id}_${index}`] && (
      <div className={styles['color-picker-container']}>
      <CirclePicker
  style={{
    outline: '1px solid #ced2d9', // doesn't work
    border: '1px solid #ced2d9', // doesn't work
  }}
  colors={Object.values(colours)}
  onChange={color => handleColorChange(section.id, index, color.hex)}
  onClose={() => handleCloseColorPicker(section.id, index)}
>
  {Object.values(colours).map((color, index) => (
    <div
      key={index}
      style={{
        backgroundColor: color,
        border: color === selectedColor ? '2px solid black' : 'none', // Highlight the selected color
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        margin: '5px',
      }}
    />
  ))}
</CirclePicker>

      </div>
    )}
  </div>
)}
{field.type === 'file' && <input type="file" id={`field_${section.id}_${index}`} accept="image/jpeg" required={selectedSections[section.id] && field.required} />}
{field.type === 'multiple-files' && (
    <input type="file" id={`field_${section.id}_${index}`} multiple accept="image/jpeg" max={7} required={selectedSections[section.id] && field.required} />
)}
                  </div>
                ))}
              </div>
            </div>
          )
        ))}
        <button type="submit" className={styles['submit-button']}>Submit</button>
      </form>
    </div>
    </>
   
  );
};

export default TeamKitForm;
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Pages
import { Home, Contact, Login, Register, Reset, Admin } from "./pages";
// Components
import { Header, Footer } from "./components";
import AdminOnlyRoute from "./components/adminOnlyRoute/AdminOnlyRoute";
import ProductDetails from "./components/product/productDetails/ProductDetails";
import Cart from "./pages/cart/Cart";
import CheckoutDetails from "./components/checkout/checkoutDetails/CheckoutDetails";
import Checkout from "./pages/checkout/Checkout";
import CheckoutSuccess from "./components/checkout/checkoutSuccess/CheckoutSuccess";
import OrderHistory from "./pages/orderHistory/OrderHistory";
import OrderDetails from "./pages/orderDetails/OrderDetails";
import ReviewProducts from "./components/reviewProducts/ReviewProducts";
import TeamKitForm from "./pages/teamKitForm/TeamKitForm";
import NotFound from "./pages/notFound/NotFound";
import Wishlist from "./pages/wishlist/Wishlist";
import { useSelector } from "react-redux";

import {
  selectIsLoggedIn,
  selectEmail,
  selectUserName,
  selectUserID,
} from "./redux/slice/authSlice";
import PrivacyPolicy from "./components/privacyPolicy/privacyPolicy/PrivacyPolicy";
import Terms from "./components/privacyPolicy/terms/Terms";
import ReturnPolicy from "./components/returnPolicy/ReturnPolicy";
import SportProducts from "./components/sports/SportsProducts";
import ReturnHistory from "./pages/returnHistory/ReturnHistory";
import HomePage from "./pages/homePage/HomePage";
import AllProducts from "./components/product/allProducts/AllProducts";
import ThankYouPage from "./pages/thankYouPage/ThankYouPage";
import Profile from "./pages/profile/Profile";
import About from "./pages/about/About";
import Customizer from "./pages/customizer/Customizer";

function App() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <div
      id="app-container"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {" "}
      <BrowserRouter>
        <Header />
        <ToastContainer />

        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/home" element={<HomePage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/customer-teamkit-form" element={<TeamKitForm />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/sport/:sportsname" element={<SportProducts />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/register"
            element={isLoggedIn ? <Navigate to="/" /> : <Register />}
          />
          <Route path="/reset" element={<Reset />} />

          <Route
            path="/admin/*"
            element={
              <AdminOnlyRoute>
                <Admin />
              </AdminOnlyRoute>
            }
          />

          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about" element={<About />} />
          <Route path="/customizer" element={<Customizer />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/checkout-details" element={<CheckoutDetails />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/return-history" element={<ReturnHistory />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />
          <Route path="/review-product/:id" element={<ReviewProducts />} />
          <Route path="/all-products" element={<AllProducts />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import styles from "./manageTopCategories.module.scss";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase/config";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

const ManageTopCategories = () => {
  const { data: prods, isLoading } = useFetchCollection("products");

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [categoryImages, setCategoryImages] = useState({});
  const [topCategories, setTopCategories] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);

  useEffect(() => {
    setIsSaveEnabled(selectedCategories.length > 0);
  }, [selectedCategories]);

  useEffect(() => {
    if (prods) {
      const categories = getAllCategories(prods);
      setAllCategories(categories);
      fetchTopCategories();
    }
  }, [prods]);

  const fetchTopCategories = async () => {
    try {
      const topCategoriesSnapshot = await getDocs(
        collection(db, "topcategories")
      );
      const topCategoriesData = [];
      topCategoriesSnapshot.forEach((doc) => {
        topCategoriesData.push({ id: doc.id, ...doc.data() });
      });
      setTopCategories(topCategoriesData);
    } catch (error) {
      console.error("Error fetching top categories:", error.message);
      toast.error("Error fetching top categories");
    }
  };

    const getAllCategories = (products) => {
      const uniqueCategories = new Set();
      products.forEach((product) => {
        if (product.category) {
          uniqueCategories.add(product.category);
        }
      });
      // Filter out the "jersey" category
      return Array.from(uniqueCategories).filter(
        (category) => category.toLowerCase() !== "jersey"
      );
    };

  const toggleCategorySelection = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleImageChange = (category, files) => {
    if (files) {
      setCategoryImages({
        ...categoryImages,
        [category]: [...(categoryImages[category] || []), ...files],
      });
    }
  };

  const saveChanges = async () => {
    try {
      const uploadedImages = await Promise.all(
        selectedCategories.map(async (category) => {
          const files = categoryImages[category];
          if (files) {
            const imageUrls = await Promise.all(files.map(uploadImage));
            return { category, imageUrls };
          } else {
            return { category, imageUrls: [] };
          }
        })
      );

      await Promise.all(
        uploadedImages.map(async (data) => {
          await addDoc(collection(db, "topcategories"), data);
        })
      );

      toast.success("Top categories added successfully");
      fetchTopCategories(); // Refresh the list of top categories
      // Remove added categories from allCategories
      setAllCategories(
        allCategories.filter(
          (category) => !selectedCategories.includes(category)
        )
      );
      setSelectedCategories([]); // Clear selected categories
      setCategoryImages({}); // Clear category images
    } catch (error) {
      console.error("Error adding top categories:", error.message);
      toast.error("Error adding top categories");
    }
  };

  const uploadImage = async (file) => {
    const storageRef = ref(storage, `eshop/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const deleteTopCategory = async (id, category) => {
    try {
      await deleteDoc(doc(db, "topcategories", id));
      toast.success("Top category deleted successfully");
      window.location.reload()
      fetchTopCategories(); // Refresh the list of top categories

      // Update allCategories immediately after deletion
      setAllCategories([...allCategories, category]);
    } catch (error) {
      console.error("Error deleting top category:", error.message);
      toast.error("Error deleting top category");
    }
  };

  const deleteImage = async (categoryId, imageUrl) => {
    try {
      const categoryDoc = topCategories.find((cat) => cat.id === categoryId);
      if (categoryDoc) {
        const updatedImageUrls = categoryDoc.imageUrls.filter(
          (url) => url !== imageUrl
        );
        await updateDoc(doc(db, "topcategories", categoryDoc.id), {
          imageUrls: updatedImageUrls,
        });

        // Delete image from storage
        const storageRef = ref(storage, imageUrl);
        await deleteObject(storageRef);

        toast.success("Image deleted successfully");
        fetchTopCategories(); // Refresh the list of top categories
      } else {
        toast.error("Category not found");
      }
    } catch (error) {
      console.error("Error deleting image:", error.message);
      toast.error("Error deleting image");
    }
  };

  const editTopCategoryImage = async (category, files) => {
    try {
      setIsUpdatingImage(true); // Show loader
      const imageUrls = await Promise.all(files.map(uploadImage));
      const categoryDoc = topCategories.find(
        (cat) => cat.category === category
      );
      if (categoryDoc) {
        await updateDoc(doc(db, "topcategories", categoryDoc.id), {
          imageUrls: [...categoryDoc.imageUrls, ...imageUrls],
        });
        toast.success("Top category images updated successfully");
        fetchTopCategories(); // Refresh the list of top categories
      } else {
        toast.error("Category not found");
      }
    } catch (error) {
      console.error("Error updating top category images:", error.message);
      toast.error("Error updating top category images");
    } finally {
      setIsUpdatingImage(false); // Hide loader
    }
  };

  return (
    <div className={styles.manageTopCategories}>
      <h2>Manage Top Categories</h2>

      <div className={styles.topCategories}>
        <h3>Current Top Categories:</h3>
        <ul>
          {topCategories
            .filter((category) => category.category.toLowerCase() !== "design your jersey")
            .map((category) => (
              <li key={category.id}>
                <div className={styles.categoryInfo}>
                  <span>{category.category}</span>
                  <div className={styles.categoryActions}>
                    <button
                      onClick={() =>
                        deleteTopCategory(category.id, category.category)
                      }
                    >
                      Delete Category
                    </button>
                  </div>
                </div>
                <div className={styles.categoryImages}>
                  {category.imageUrls.map((url, index) => (
                    <div key={index} className={styles.categoryImage}>
                      <img src={url} alt={category.category} />
                      <button onClick={() => deleteImage(category.id, url)}>
                        Delete Image
                      </button>
                    </div>
                  ))}
                  <input
                    type="file"
                    multiple
                    onChange={(e) =>
                      editTopCategoryImage(
                        category.category,
                        Array.from(e.target.files)
                      )
                    }
                  />
                </div>
              </li>
            ))}
        </ul>
      </div>

      <div className={styles.categorySelection}>
        <h3>Select Categories:</h3>
        <ul>
          {allCategories.map(
            (category) =>
              !topCategories.some(
                (topCategory) => topCategory.category === category
              ) && (
                <li key={category}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => toggleCategorySelection(category)}
                    />
                    {category}
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(e) =>
                      handleImageChange(category, Array.from(e.target.files))
                    }
                  />
                </li>
              )
          )}
        </ul>
      </div>

      <button onClick={saveChanges} disabled={!isSaveEnabled}>
        {isUpdatingImage ? (
          <div
            className="loader"
            style={{ display: "inline-block", marginRight: "5px" }}
          >
            Updating image...
          </div>
        ) : null}
        Save Changes
      </button>
    </div>
  );
};

export default ManageTopCategories;

// Import necessary Firebase and React dependencies
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase/config";
import styles from "./ViewProducts.module.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Loader from "../../loader/Loader";
import Notiflix from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProducts,
  STORE_PRODUCTS,
} from "../../../redux/slice/productSlice";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import {
  FILTER_BY_SEARCH,
  FILTER_BY_SEARCHPRODUCTS,
  selectFilteredProducts,
} from "../../../redux/slice/filterSlice";
import Search from "../../search/Search";
import Pagination from "../../pagination/Pagination";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const ViewProducts = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);
  const [wishlistCount, setWishlistCount] = useState([]);
  // Fetch products from Firebase
  const { data, isLoading } = useFetchCollection("products");

  // Redux state and dispatch
  const products = useSelector(selectProducts);
  const filteredProducts = useSelector(selectFilteredProducts);
  const dispatch = useDispatch();
  const fetchWishlistCounts = async () => {
    const counts = await Promise.all(
      filteredProducts.map((product) => getWishlistCount(product.id))
    );
    setWishlistCount(counts);
  };

  useEffect(() => {
    // Fetch wishlist counts when filteredProducts changes
    fetchWishlistCounts();
  }, [filteredProducts]);
  // Effect to store fetched products in Redux state
  useEffect(() => {
    dispatch(
      STORE_PRODUCTS({
        products: data,
      })
    );
  }, [dispatch, data]);

  // Effect to filter products based on search term
  useEffect(() => {
    dispatch(FILTER_BY_SEARCHPRODUCTS({ products, search }));
  }, [dispatch, products, search]);

  // Function to handle delete confirmation
  const confirmDelete = (id, imageURLs) => {
    Notiflix.Confirm.show(
      "Delete Product!!!",
      "You are about to delete this product",
      "Delete",
      "Cancel",
      function okCb() {
        deleteProduct(id, imageURLs);
      },
      function cancelCb() {},
      {
        width: "320px",
        borderRadius: "3px",
        titleColor: "orangered",
        okButtonBackground: "orangered",
        cssAnimationStyle: "zoom",
      }
    );
  };
  const getWishlistCount = async (productId) => {
    try {
      const wishlistedDoc = await doc(db, "wishlisted", productId);
      const wishlistedSnapshot = await getDoc(wishlistedDoc);

      if (wishlistedSnapshot.exists()) {
        const wishlistedData = wishlistedSnapshot.data();
        return wishlistedData.inWishlist ? wishlistedData.inWishlist.length : 0;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Error fetching wishlist count:", error);
      return 0;
    }
  };
  // Function to delete a product
  const deleteProduct = async (id, imageURLs) => {
    try {
      await deleteDoc(doc(db, "products", id));

      // Delete associated images from storage
      imageURLs.forEach(async (url) => {
        const storageRef = ref(storage, url);
        await deleteObject(storageRef);
      });

      toast.success("Product deleted successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Function to toggle the enabled status of a product
  const toggleEnabledStatus = async (id, currentEnabledStatus) => {
    try {
      await updateDoc(doc(db, "products", id), {
        enabled: !currentEnabledStatus,
      });
      toast.success("Enabled status updated successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.table}>
        <h2>All Products</h2>

        <div className={styles.search}>
          <p>
            <b>{filteredProducts.length}</b> products found
          </p>
          <Search value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>

        {filteredProducts.length === 0 ? (
          <p>No product found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Image</th>
                <th>Name</th>
                <th>Category</th>
                <th>Sport</th>
                <th>Stock Count</th>
                <th>Tax</th>
                <th>Selling Price</th>
                <th>Actual Price</th>
                <th>Wishlisted</th>
                <th>Enabled Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts
                .slice(
                  (currentPage - 1) * productsPerPage,
                  currentPage * productsPerPage
                )
                .map((product, index) => {
                    const wishlistlen = wishlistCount[index];
                  return (
                    <tr key={product.id}>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          src={product.imageURLs[0]} // Using the first image URL
                          alt={product.name}
                          style={{ width: "100px" }}
                        />
                      </td>
                      <td>{product.name}</td>
                      <td>{product.category}</td>
                      <td>{product.sport}</td>
                      <td>
                        {/* Check if sizeInfo is defined before mapping over it */}
                        {product.sizeInfo &&
                          product.sizeInfo.map((size, sizeIndex) => (
                            <div key={sizeIndex}>
                              <p>
                                <b>
                                  <strong>{`Size ${size.size}:`}</strong>{" "}
                                  {size.stockCount !== undefined
                                    ? `${size.stockCount} units`
                                    : "N/A"}
                                </b>
                              </p>
                            </div>
                          ))}
                      </td>
                      <td>{`${product.tax}%`}</td>
                      <td>{`$${product.price}`}</td>
                      <td>{`$${product.actualPrice}`}</td>
                      <td>{wishlistlen}</td>
                      <td>
                        {/* Toggle button for enabled status */}
                        <Toggle
                          checked={product.enabled}
                          onChange={() =>
                            toggleEnabledStatus(product.id, product.enabled)
                          }
                        />
                      </td>
                      <td className={styles.icons}>
                        <Link to={`/admin/edit-product/${product.id}`}>
                          <FaEdit size={20} color="green" />
                        </Link>
                        &nbsp;
                        <FaTrashAlt
                          size={18}
                          color="red"
                          onClick={() =>
                            confirmDelete(product.id, product.imageURLs)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          productsPerPage={productsPerPage}
          totalProducts={filteredProducts.length}
        />
      </div>
    </>
  );
};

export default ViewProducts;
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyCUQh40CIhcWjJ8XE4T0KBg1x5pthyenbw",
  authDomain: "idkman2-6afeb.firebaseapp.com",
  databaseURL: "https://idkman2-6afeb-default-rtdb.firebaseio.com",
  projectId: "idkman2-6afeb",
  storageBucket: "idkman2-6afeb.appspot.com",
  messagingSenderId: "422246359678",
  appId: "1:422246359678:web:e514befd3ac53949b72e2f",
  measurementId: "G-2W4TJGK5BE"
};
 



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;

import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { auth, db } from "../../firebase/config.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Loader from "../../components/loader/Loader.js";
import { useDispatch, useSelector } from "react-redux";
import {
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  selectCartItems,
  selectCartTotalAmountWithTaxes,
} from "../../redux/slice/cartSlice";
import {
  SAVE_BILLING_ADDRESS,
  SAVE_SHIPPING_ADDRESS,
} from "../../redux/slice/checkoutSlice.js";
import { useNavigate } from "react-router-dom";
import { selectEmail, selectUserID } from "../../redux/slice/authSlice";
import styles from "./Checkout.module.scss";
import {
  selectBillingAddress,
  selectShippingAddress,
} from "../../redux/slice/checkoutSlice";
import { toast } from "react-toastify";
import CheckoutForm from "../../components/checkout/checkoutForm/CheckoutForm";
import CheckoutDetails from "../../components/checkout/checkoutDetails/CheckoutDetails";
import axios from "axios";
import Card from "../../components/card/Card";
import CheckoutSummary from "../../components/checkout/checkoutSummary/CheckoutSummary";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const initialAddressState = {
  name: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "US",
  phone: "",
};
const Checkout = () => {
  const [message, setMessage] = useState("Initializing checkout...");
  const [clientSecret, setClientSecret] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [proceed, setProceed] = useState(false);
  const cartItems = useSelector(selectCartItems);
  const totalAmountWithTaxes = useSelector(selectCartTotalAmountWithTaxes);
  const customerEmail = useSelector(selectEmail);
  const shippingAdd = useSelector(selectShippingAddress);
  const [shippingAddress, setShippingAddress] = useState({
    ...initialAddressState,
  });
  const [billingAddress, setBillingAddress] = useState({
    ...initialAddressState,
  });
  const temp = false;
  const [copyAddress, setCopyAddress] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [usStates, setUsStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateBilling, setSelectedStateBilling] = useState("");
  const [shippingErrors, setShippingErrors] = useState({});
  const [billingErrors, setBillingErrors] = useState({});
  const [postalCodeError, setPostalCodeError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId2 = useSelector(selectUserID);
  const [couponApply, setCouponApply] = useState(false);
  const [showSavedAddressButton, setShowSavedAddressButton] = useState(false);
  const failed = false;
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const user = await auth.currentUser;
        if (user) {
          const uid = user.uid;
          localStorage.setItem("userId", uid); // Store user ID in localStorage
          setUserId(uid);
        } else {
        }
      } catch (error) {
        console.error("Error fetching current user:", error);
      }
    };

    const storedUserId = localStorage.getItem("userId"); // Retrieve user ID from localStorage
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      fetchUserId();
    }
  }, []);

  useEffect(() => {
    // Clear discountedAmount from localStorage on component mount
    localStorage.removeItem("discountedAmount");
    localStorage.removeItem("discountedAmount2");
    localStorage.removeItem("couponcode");
  }, []);
  const status = localStorage.getItem("status");
  const shipaddress = localStorage.getItem("shipaddress");
  const billaddress = localStorage.getItem("billaddress");

  const failshipping = JSON.parse(shipaddress);
  const failbilling = JSON.parse(billaddress);
  useEffect(() => {
    if (status === "true") {
      setShippingAddress(failshipping);
      setBillingAddress(failbilling);
      console.log(failshipping);
      handleSubmitFail();
    }
  }, [status, failbilling, failshipping]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Perform the initial copy when the component mounts
    if (copyAddress) {
      setBillingAddress({ ...shippingAddress });
    }
  }, [copyAddress, shippingAddress]);
  useEffect(() => {
    const statesData = [
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" },
    ];

    setUsStates(statesData);
  }, []);

  const isValidPhoneNumber = (phoneNumber) => {
    // Use a regular expression to define the valid format for phone numbers
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber.replace(/[-()\s]/g, ""));
  };
  const [saveShippingAddress, setSaveShippingAddress] = useState(false);

  const handleSaveShippingAddress = async () => {
    if (!saveShippingAddress) return; // Don't proceed if checkbox is not checked

    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid); // Reference to user's document
        const userDocSnap = await getDoc(userDocRef); // Get user's document snapshot
        if (userDocSnap.exists()) {
          // If user document exists, update it with shipping address without overwriting existing data
          await updateDoc(userDocRef, {
            shippingAddress: {
              ...userDocSnap.data().shippingAddress, // Preserve existing shipping address data
              ...shippingAddress, // Add new shipping address
            },
          });
          console.log("Shipping address saved to user document.");
        }
      }
    } catch (error) {
      console.error("Error saving shipping address to user document:", error);
    }
  };
  const [savedShippingAddress, setSavedShippingAddress] = useState(null);
  // Update this function to handle the checkbox state
  const handleCheckboxChange = () => {
    setSaveShippingAddress(!saveShippingAddress); // Toggle checkbox state
  };

  const fetchSavedShippingAddress = async () => {
    try {
      const user = auth.currentUser; // Get current user
      if (user) {
        const userDocRef = doc(db, "users", user.uid); // Reference to user's document
        const userDocSnap = await getDoc(userDocRef); // Get user's document snapshot
        if (userDocSnap.exists()) {
          // If user document exists and has shippingAddress field, set it to state
          const userData = userDocSnap.data();
          if (userData.shippingAddress) {
            setSavedShippingAddress(userData.shippingAddress);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching saved shipping address:", error);
    }
  };

  // Fetch saved shipping address on component mount
  useEffect(() => {
    fetchSavedShippingAddress();
  }, []);
  useEffect(() => {
    if (savedShippingAddress !== null) {
      setShowSavedAddressButton(true); // Show the button if savedShippingAddress exists
    }
  }, [savedShippingAddress]);
  const handleShipping = async (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const isValidPhoneNumber = /^\d{10}$/.test(value.replace(/[-()\s]/g, ""));

      setShippingErrors({
        ...shippingErrors,
        phone: isValidPhoneNumber
          ? ""
          : "Invalid phone number format(10 digit)",
      });
    }
    // Special handling for state
    if (name === "state") {
      setShippingAddress({
        ...shippingAddress,
        state: value,
      });
    } else {
      // For postal_code, handle separately to preserve the last digit
      if (name === "postal_code") {
        const truncatedValue = value.slice(0, 5);

        // Update state with the truncated postal code
        setShippingAddress({
          ...shippingAddress,
          [name]: truncatedValue,
        });

        // Validate and fetch state information based on the truncated postal code
        if (isValidPostalCode(truncatedValue)) {
          try {
            const response = await axios.get(
              `https://api.zippopotam.us/us/${truncatedValue}`
            );
            const stateCode = response.data.places[0]?.state || "";
            setSelectedState(stateCode);

            // Update state with the full postal code after the asynchronous fetch
            setShippingAddress({
              ...shippingAddress,
              postal_code: value,
              state: stateCode,
            });
          } catch (error) {
            console.error("Error fetching state from postal code:", error);
            setSelectedState("");
            setPostalCodeError("This Postal Code doesn't exist");
          }
        }
      } else {
        // For other fields, handle normally
        setShippingAddress({
          ...shippingAddress,
          [name]: value,
        });
      }

      // Copy to billing if needed
      if (copyAddress) {
        setBillingAddress({
          ...billingAddress,
          [name]: value,
        });
      }
    }
  };

  const handleBilling = async (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const isValidPhoneNumber = /^\d{10}$/.test(value.replace(/[-()\s]/g, ""));

      setBillingErrors({
        ...billingErrors,
        phone: isValidPhoneNumber
          ? ""
          : "Invalid phone number format(10 digit)",
      });
    }
    if (name === "state") {
      setBillingAddress({
        ...billingAddress,
        state: value,
      });
    } else {
      // For postal_code, handle separately to preserve the last digit
      if (name === "postal_code") {
        const truncatedValue = value.slice(0, 5);

        // Update state with the truncated postal code
        setBillingAddress({
          ...billingAddress,
          [name]: truncatedValue,
        });

        // Validate and fetch state information based on the truncated postal code
        if (isValidPostalCode(truncatedValue)) {
          try {
            const response = await axios.get(
              `https://api.zippopotam.us/us/${truncatedValue}`
            );
            const stateCode = response.data.places[0]?.state || "";
            setSelectedStateBilling(stateCode);

            // Update state with the full postal code after the asynchronous fetch
            setBillingAddress({
              ...billingAddress,
              postal_code: value,
              state: stateCode,
            });
          } catch (error) {
            console.error("Error fetching state from postal code:", error);
            setSelectedStateBilling("");
            setPostalCodeError("This Postal Code doesn't exist");
          }
        }
      } else {
        // For other fields, handle normally
        setBillingAddress({
          ...billingAddress,
          [name]: value,
        });
      }
    }
  };

  const handleCopyShippingToBilling = () => {
    setCopyAddress(!copyAddress);

    if (!copyAddress) {
      setBillingAddress({ ...shippingAddress });
    }
  };

  const handleCountryChange = (val) => {
    setShippingAddress({
      ...shippingAddress,
      country: val,
    });

    if (val === "US") {
      setSelectedState("CA");
    } else {
      setSelectedState("");
    }
  };

  const isValidPostalCode = (postalCode) => {
    // Use a regular expression to define the valid format for postal codes
    const postalCodeRegex = /^\d{5}$/;
    return postalCodeRegex.test(postalCode);
  };
  const handlePostalCodeChangefail = (e) => {
    const postalCode = e;

    // Clear previous postal code error
    setPostalCodeError("");

    // Validate the postal code
    if (!isValidPostalCode(postalCode)) {
      setPostalCodeError(
        "Invalid postal code format. Please enter a valid postal code."
      );
      setSelectedState("");
    } else {
      // Fetch state information based on the valid postal code
      axios
        .get(`https://api.zippopotam.us/us/${postalCode}`)
        .then((response) => {
          const stateCode = response.data.places[0]?.state || "";
          setSelectedState(stateCode);
        })
        .catch((error) => {
          console.error("Error fetching state from postal code:", error);
          setSelectedState("");
          setPostalCodeError("This Postal Code doesn't exist");
        });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handlePostalCodeChange = (e) => {
    const postalCode = e.target.value;

    // Clear previous postal code error
    setPostalCodeError("");

    // Validate the postal code
    if (!isValidPostalCode(postalCode)) {
      setPostalCodeError(
        "Invalid postal code format. Please enter a valid postal code."
      );
      setSelectedState("");
    } else {
      // Fetch state information based on the valid postal code
      axios
        .get(`https://api.zippopotam.us/us/${postalCode}`)
        .then((response) => {
          const stateCode = response.data.places[0]?.state || "";
          setSelectedState(stateCode);
          localStorage.setItem("selectedState", stateCode)
        })
        .catch((error) => {
          console.error("Error fetching state from postal code:", error);
          setSelectedState("");
          setPostalCodeError("This Postal Code doesn't exist");
        });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handlePostalCodeChangeBillingfail = (e) => {
    const postalCode = e;

    // Clear previous postal code error
    setPostalCodeError("");

    // Validate the postal code
    if (!isValidPostalCode(postalCode)) {
      setPostalCodeError(
        "Invalid postal code format. Please enter a valid postal code."
      );
      setSelectedStateBilling("");
    } else {
      // Fetch state information based on the valid postal code
      axios
        .get(`https://api.zippopotam.us/us/${postalCode}`)
        .then((response) => {
          const stateCode = response.data.places[0]?.state || "";
          setSelectedStateBilling(stateCode);
        })
        .catch((error) => {
          console.error("Error fetching state from postal code:", error);
          setSelectedStateBilling("");
          setPostalCodeError("This Postal Code doesn't exist");
        });
    }
  };
  const handlePostalCodeChangeBilling = (e) => {
    const postalCode = e.target.value;

    // Clear previous postal code error
    setPostalCodeError("");

    // Validate the postal code
    if (!isValidPostalCode(postalCode)) {
      setPostalCodeError(
        "Invalid postal code format. Please enter a valid postal code."
      );
      setSelectedStateBilling("");
    } else {
      // Fetch state information based on the valid postal code
      axios
        .get(`https://api.zippopotam.us/us/${postalCode}`)
        .then((response) => {
          const stateCode = response.data.places[0]?.state || "";
          setSelectedStateBilling(stateCode);
        })
        .catch((error) => {
          console.error("Error fetching state from postal code:", error);
          setSelectedStateBilling("");
          setPostalCodeError("This Postal Code doesn't exist");
        });
    }
  };
  const discountedAmount2 = localStorage.getItem("discountedAmount2");

  const isCouponCodeDisabled = !!discountedAmount2;
  const handleCoupon = async () => {
    setLoading(true); // Set loading to true before API call

    await applyCoupon();

    // Check if discountedAmount2 is present in localStorage
    const discountedAmount2 = localStorage.getItem("discountedAmount2");
    if (discountedAmount2) {
      setCouponApply(true);
    }

    // Set loading back to false after the API call and check for discountedAmount2
    setLoading(false);
  };
  const handleSubmitFail = async () => {
    console.log("submit", shippingAddress);

    // Check if all required fields are filled in
    if (
      !shippingAddress.name ||
      !shippingAddress.line1 ||
      !shippingAddress.city ||
      !shippingAddress.postal_code ||
      !shippingAddress.phone ||
      (!copyAddress &&
        (!billingAddress.name ||
          !billingAddress.line1 ||
          !billingAddress.city ||
          !billingAddress.postal_code ||
          !billingAddress.phone))
    ) {
      return; // Return early to prevent further execution
    }

    dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));
    dispatch(SAVE_BILLING_ADDRESS(billingAddress));
    localStorage.setItem("status", false);
    localStorage.setItem("shipaddress", JSON.stringify(shippingAddress));
    localStorage.setItem(
      "billaddress",
      JSON.stringify(billingAddress.name ? billingAddress : shippingAddress)
    );
    setProceed(true);
    // Continue with the rest of your handleSubmit logic
    // ...
  };
  const handleSubmit = async () => {
    console.log("submit", couponCode);
    setTimeout(() => {
      console.log(shippingAddress);
    }, 1000);
    if (saveShippingAddress) {
      await handleSaveShippingAddress(shippingAddress);
    }
    // Check if all required fields are filled in
    if (
      !shippingAddress.name ||
      !shippingAddress.line1 ||
      !shippingAddress.city ||
      !shippingAddress.postal_code ||
      !shippingAddress.phone ||
      (!copyAddress &&
        (!billingAddress.name ||
          !billingAddress.line1 ||
          !billingAddress.city ||
          !billingAddress.postal_code ||
          !billingAddress.phone))
    ) {
      // If any required field is missing, display an error message or handle it as needed
      toast.error("Please fill in all required fields.");
      return; // Return early to prevent further execution
    }

    dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));
    dispatch(SAVE_BILLING_ADDRESS(billingAddress));
    localStorage.setItem("status", false);
    localStorage.setItem("shipaddress", JSON.stringify(shippingAddress));
    localStorage.setItem("billaddress", JSON.stringify(billingAddress));
    setProceed(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // Continue with the rest of your handleSubmit logic
    // ...
  };
  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL());
    dispatch(CALCULATE_TOTAL_QUANTITY());
  }, [dispatch, cartItems]);

  useEffect(() => {
    // Clear localStorage on page exit
    const handleBeforeUnload = () => {
      localStorage.removeItem("discountedAmount");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const description = `UltimateSportStore payment: email: ${customerEmail}, Amount: ${totalAmountWithTaxes}`;

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };
  const areRequiredFieldsFilled = () => {
    return (
      shippingAddress.name &&
      shippingAddress.line1 &&
      shippingAddress.city &&
      shippingAddress.postal_code &&
      shippingAddress.state &&
      isValidPhoneNumber(shippingAddress.phone) &&
      billingAddress.name &&
      billingAddress.line1 &&
      billingAddress.city &&
      billingAddress.postal_code &&
      billingAddress.state &&
      isValidPhoneNumber(billingAddress.phone)
    );
  };

  const [applyButtonClicked, setApplyButtonClicked] = useState(false);
  const handleApplyButtonClick = async (e) => {
    e.preventDefault();
    localStorage.setItem("selectedState",selectedState);
    
    if ("scrollBehavior" in document.documentElement.style) {
      document.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      // Fallback for browsers that don't support smooth scrolling
      window.scrollTo(0, 0);
    }
    if (!areRequiredFieldsFilled()) {
      toast.error("Please recheck all required fields.");
      return;
    }

    setApplyButtonClicked(true);

    try {
      await handleSubmit();
     
      
      // await applyCoupon();
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error(error.message);
    }
  };

  // const [couponCodeInput, setCouponCodeInput] = useState("");
  const handleUseSavedAddress = async(e) => {
    e.preventDefault();
    // Set the saved shipping address to your shippingAddress state
    if (savedShippingAddress) {
      
      try {
        
        setSelectedState(savedShippingAddress.state);

        // Update state with the full postal code after the asynchronous fetch
        setShippingAddress({
          ...shippingAddress,
          postal_code: savedShippingAddress.postal_code,
          state: savedShippingAddress.state,
        });
      } catch (error) {
        console.error("Error fetching state from postal code:", error);
        setSelectedState("");
        setPostalCodeError("This Postal Code doesn't exist");
      }
      setShippingAddress(savedShippingAddress);
    }
  };
  const applyCoupon = async () => {
    console.log(cartItems);
    console.log(userId2);
    console.log(couponCode);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/validate-coupon-code`,
        // `http://127.0.0.1:4242/validate-coupon-code`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: cartItems,
            userId: userId2,
            couponCode: couponCode,
            selectedState: selectedState
          }),
        }
      );
  
      console.log("Server Response:", response);
  
      if (!response.ok) {
        const errorData = await response.json();
        console.log("Error Data:", errorData);
        if (
          errorData.details ===
          "Please add items worth at least $300 to apply the coupon."
        ) {
          toast.error(errorData.details);
        } else {
          toast.error("User already used coupon or Invalid Coupon");
        }
  
        setCouponCode("");
        return false;
      }
  
      const couponValidationResult = await response.json();
      console.log("Coupon Validation Result:", couponValidationResult);
  
      // Set discountedAmount2 in localStorage
      localStorage.setItem(
        "discountedAmount2",
        couponValidationResult.totalAmount
      );
      console.log("discountedAmount", couponValidationResult.totalAmount)
      localStorage.setItem("couponcode", couponCode);
  
      console.log("discountedAmount2 set in localStorage");
  
      return true;
    } catch (error) {
      console.error("Error applying coupon:", error);
      // Handle the error, show a message to the user, etc.
      return false;
    }
  };
  
  // Check if couponCode is not empty
  const isApplyButtonDisabled =
    couponCode.trim() === "" || !areRequiredFieldsFilled();
  return isLoading ? (
    <Loader />
  ) : (
    <>
      {!proceed && (
        <>
          <section>
            <div className={`container ${styles.checkout}`}>
              <h2>Checkout Details</h2>
              <h2 style={{ display: "none" }}>
                Welcome to UltimateCricketStore, your premier destination for
                all your cricket needs in USA!: 🏏 Specialized Bats,👟 Top-notch
                Shoes,🛡️Accessories
              </h2>

              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formSection}>
                  <Card cardClass={styles.card}>
                    <h3>Shipping Address</h3>
                    {!isLoading && showSavedAddressButton && (
                      <div>
                        <button
                          onClick={handleUseSavedAddress}
                          style={{
                            padding: "8px 16px",
                            marginRight: "30px",
                            marginBottom: "30px",
                            marginLeft: "110px",
                            backgroundColor: "#4caf50",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          }}
                        >
                          Use Saved Shipping Address
                        </button>
                      </div>
                    )}
                    <label>
                      Recipient Name<span className={styles.required}>*</span>
                    </label>

                    <input
                      type="text"
                      required
                      name="name"
                      value={shippingAddress.name}
                      onChange={(e) => handleShipping(e)}
                      className={`input ${
                        applyButtonClicked && !shippingAddress.name
                          ? styles.invalidInput
                          : ""
                      }`}
                    />
                    {shippingErrors.name && (
                      <span className={styles.error}>
                        {shippingErrors.name}
                      </span>
                    )}
                    <label>
                      Address line 1<span className={styles.required}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="line1"
                      value={shippingAddress.line1}
                      onChange={(e) => handleShipping(e)}
                      className={`input ${
                        applyButtonClicked && !shippingAddress.line1
                          ? styles.invalidInput
                          : ""
                      }`}
                    />
                    <label>Address line 2</label>
                    <input
                      type="text"
                      name="line2"
                      value={shippingAddress.line2}
                      onChange={(e) => handleShipping(e)}
                    />
                    <label>
                      City<span className={styles.required}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="city"
                      value={shippingAddress.city}
                      onChange={(e) => handleShipping(e)}
                      className={`input ${
                        applyButtonClicked && !shippingAddress.city
                          ? styles.invalidInput
                          : ""
                      }`}
                    />
                    <label>
                      Postal code<span className={styles.required}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="postal_code"
                      value={shippingAddress.postal_code}
                      onChange={(e) => {
                        // Limit input to max 5 characters
                        handleShipping({
                          target: {
                            name: "postal_code",
                            value: e.target.value.slice(0, 5),
                          },
                        });
                        handlePostalCodeChange(e);
                      }}
                      maxLength={5}
                      className={`input ${
                        applyButtonClicked && !shippingAddress.postal_code
                          ? styles.invalidInput
                          : ""
                      }`}
                    />
                    {postalCodeError && (
                      <span className={styles.error}>{postalCodeError}</span>
                    )}
                    <label>
                      State<span className={styles.required}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="state"
                      disabled
                      value={
                        shippingAddress.state
                          ? shippingAddress.state
                          : selectedState
                      }
                      onChange={(e) => handleShipping(e)}
                      className={`input ${
                        applyButtonClicked && !selectedState
                          ? styles.invalidInput
                          : ""
                      }`}
                    />
                    {/* <CountryDropdown
                className={styles.select}
                valueType="short"
                value={shippingAddress.country}
                onChange={(val) => handleCountryChange(val)}
              /> */}
                    <label>
                      Country<span className={styles.required}>*</span>
                    </label>
                    <input
                      type="text"
                      disabled
                      name="country"
                      value="US"
                      onChange={(e) => handleShipping(e)}
                    />
                    <label>
                      Phone<span className={styles.required}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="phone"
                      value={shippingAddress.phone}
                      onChange={(e) => handleShipping(e)}
                      className={`input ${
                        (applyButtonClicked && !shippingAddress.phone) ||
                        (shippingErrors.phone &&
                          !isValidPhoneNumber(shippingAddress.phone))
                          ? styles.invalidInput
                          : ""
                      }`}
                    />
                    {shippingErrors.phone && (
                      <span className={styles.error}>
                        {shippingErrors.phone}
                      </span>
                    )}
                  </Card>
                  <div className={styles.checkbox}>
                    <label>
                      <input
                        type="checkbox"
                        checked={copyAddress}
                        onChange={handleCopyShippingToBilling}
                      />
                      <>‎ ‎ </>Copy Shipping to Billing
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <label>
                      <input
                        type="checkbox"
                        checked={saveShippingAddress}
                        onChange={handleCheckboxChange}
                      />
                      <>‎ ‎ </>Save Shipping Address to My Account
                    </label>
                  </div>
                </div>
                {!copyAddress && (
                  <div className={styles.formSection1}>
                    <Card cardClass={styles.card}>
                      <h3>Billing Address</h3>
                      <label>
                        Recipient Name<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="name"
                        value={billingAddress.name}
                        onChange={(e) => handleBilling(e)}
                        className={`input ${
                          applyButtonClicked && !billingAddress.name
                            ? styles.invalidInput
                            : ""
                        }`}
                      />
                      <label>
                        Address line 1<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="line1"
                        value={billingAddress.line1}
                        onChange={(e) => handleBilling(e)}
                        className={`input ${
                          applyButtonClicked && !billingAddress.line1
                            ? styles.invalidInput
                            : ""
                        }`}
                      />
                      <label>Address line 2</label>
                      <input
                        type="text"
                        name="line2"
                        value={billingAddress.line2}
                        onChange={(e) => handleBilling(e)}
                      />
                      <label>
                        City<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="city"
                        value={billingAddress.city}
                        onChange={(e) => handleBilling(e)}
                        className={`input ${
                          applyButtonClicked && !billingAddress.city
                            ? styles.invalidInput
                            : ""
                        }`}
                      />
                      <label>
                        Postal code<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="postal_code"
                        value={billingAddress.postal_code}
                        onChange={(e) => {
                          // Limit input to max 5 characters
                          handleBilling({
                            target: {
                              name: "postal_code",
                              value: e.target.value.slice(0, 5),
                            },
                          });
                          handlePostalCodeChangeBilling(e);
                        }}
                        className={`input ${
                          applyButtonClicked && !billingAddress.postal_code
                            ? styles.invalidInput
                            : ""
                        }`}
                        maxLength={5}
                      />
                      {postalCodeError && (
                        <span className={styles.error}>{postalCodeError}</span>
                      )}
                      <label>
                        State<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="state"
                        disabled
                        value={
                          billingAddress.state
                            ? billingAddress.state
                            : selectedStateBilling
                        }
                        onChange={(e) => handleBilling(e)}
                        className={`input ${
                          applyButtonClicked && !selectedStateBilling
                            ? styles.invalidInput
                            : ""
                        }`}
                      />

                      <label>
                        Country<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        name="country"
                        value="US"
                        onChange={(e) => handleBilling(e)}
                      />
                      <label>
                        Phone<span className={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="phone"
                        value={billingAddress.phone}
                        className={`input ${
                          (applyButtonClicked && !billingAddress.phone) ||
                          (billingErrors.phone &&
                            !isValidPhoneNumber(billingAddress.phone))
                            ? styles.invalidInput
                            : ""
                        }`}
                        onChange={(e) => handleBilling(e)}
                      />
                      {billingErrors.phone && (
                        <span className={styles.error}>
                          {billingErrors.phone}
                        </span>
                      )}
                    </Card>
                  </div>
                )}
                <div className={styles.formSection}>
                  <Card cardClass={styles.card}>
                    <CheckoutSummary selectedState={selectedState}/>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <label
                        htmlFor="couponCode"
                        style={{ marginRight: "5px" }}
                      >
                        Coupon Code:
                      </label>
                      <>
                        <div
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="text"
                            id="couponCode"
                            value={couponCode}
                            onChange={handleCouponCodeChange}
                            style={{
                              padding: "8px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginRight: "10px",
                            }}
                            disabled={isCouponCodeDisabled}
                          />
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleCoupon();
                              // createPaymentIntent();
                            }}
                            style={{
                              padding: "8px 16px",
                              backgroundColor: "#4caf50",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              cursor:
                                isApplyButtonDisabled || isCouponCodeDisabled
                                  ? "not-allowed"
                                  : "pointer",
                              transition: "background-color 0.3s",
                              opacity:
                                isApplyButtonDisabled || isCouponCodeDisabled
                                  ? 0.5
                                  : 1,
                            }}
                            disabled={
                              isApplyButtonDisabled || isCouponCodeDisabled
                            }
                          >
                            {loading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Apply"
                            )}
                          </button>
                          {/* <p style={{color:"red"}}> Coupon has been applied</p> */}
                        </div>
                      </>
                      {/* {clientSecret && <h3>{message}</h3>} */}
                    </div>
                  </Card>
                  {cartItems.length !== 0 && (
                    <button
                      onClick={(e) => {
                        handleApplyButtonClick(e);
                        scrollToTop();
                      }}
                      className={styles.btncheck}
                      style={{
                        padding: "8px 16px",
                        margin: "30px",
                        marginLeft: "140px",
                        backgroundColor: "#4caf50",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                    >
                      Proceed to Checkout
                    </button>
                  )}
                </div>
              </form>
            </div>
          </section>
          <section></section>
        </>
      )}
      {proceed && (
        <CheckoutForm description={description} couponCode={couponCode}  />
      )}
    </>
  );
};

export default Checkout;

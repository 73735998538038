import React from "react";
import styles from "./ReturnPolicy.module.scss";

const ReturnPolicy = () => {
  return (
    <div className={styles.returnPolicy}>
    

    <h1>Welcome to Ultimate Sport Store</h1>
    <p>Thank you for choosing Ultimate Sport Store for your sporting needs. We appreciate your trust in our products and services. We want to inform you of the recent changes to our Return Policy, aimed at ensuring your satisfaction.</p>

    <h2>Return Policy:</h2>

    <p><strong>Return Period:</strong> Any unopened or unused product can be returned for a full refund or store credit within 7 days of purchase. The option to Return on our website will be available for 7 days from the day of delivery.</p>

    <p><strong>Eligibility for Return:</strong> To qualify for a return, the item must be unopened or unused, and in the same condition as received.</p>

    <h2>Return Process:</h2>

    <p>We will review your return request and notify you via phone or email to discuss your return. All returns need to be approved by our Customer Service. Returns received without approval will not be issued a refund or credit.</p>

    <p>We kindly request that customers take on the responsibility of returning the product. The return package must include the original product packaging, all accessories, and a copy of the purchase receipt.</p>

    <h2>Deduction of Shipping Cost:</h2>

    <p>To initiate the refund process, a deduction equivalent to our original shipping cost will be applied to the refund amount. Please be aware that the shipping cost to return the product is the responsibility of the customer. However, if the return is due to a product defect or error on our part, the shipping cost will be covered by us.</p>

    <h2>Returns Procedure:</h2>

    <ol>
        <li>Login into your account.</li>
        <li>View the order using the "My Orders".</li>
        <li>Click the order Return Item(s) button.</li>
    </ol>

    <p>We will review your return request and notify you via phone or email to discuss your return. All returns need to be approved by our Customer Service. Returns received without approval will not be issued a refund or credit.</p>

    <h2>Exceptions:</h2>

    <p>Products bought together for sizing or to avail free shipping are not eligible for a refund. Used or knocked bats will not be applicable for return unless approved by management.</p>

    <h2>Return Address:</h2>

    <p>Ultimate Sport Store</p>
    <p>68 Boston Street,</p> 
   <p>Newark, NJ 07103</p> 
    <p>(M) +1 (973) 687-7960
      </p>

    <p>Mention your Order Number, Phone number, Full name for easy tracking.</p>

    <p><strong>Note:</strong> Please dispatch the return product within 7 days of raising the return request. Failure to do so may make the refund ineligible.</p>

    <p>For any questions or concerns regarding the return process, please contact our customer service at <a href="mailto:support@ultimatesportstore.com">support@ultimatesportstore.com</a>. We aim to make your shopping experience smooth and enjoyable.</p>

    <h2>Special Note for "SALE" Items:</h2>

    <p>Please be aware that items purchased during any "SALE" are not eligible for returns, refunds, or exchanges unless we shipped you the wrong product or a faulty item, which was our mistake. If you need to return a product you ordered, kindly initiate the return within a week of receiving it.</p>

    <h2>Our Commitment to You:</h2>

    <p>If we make any errors in your order, rest assured that we will correct them promptly.</p>

    <p>In case your shipping encounters damage or is missed by UPS, USPS, or FedEx, we kindly request you to follow these steps:</p>

    <ol>
        <li>File a claim with your local delivery office.</li>
        <li>For items missed by the delivery services, check with neighbors, family members, and local delivery staff before registering a complaint.</li>
        <li>If the items are still missing, register a police complaint and, with the FIR, file an official complaint with the service provider.</li>
    </ol>

    <p>After completing these steps, please reach out to us via phone or email with the necessary proof. We are committed to working with you to address any items that were missed or damaged during the delivery process.</p>

    <p>We understand that situations may arise, and we are here to assist you every step of the way. Your satisfaction is paramount, and we appreciate your cooperation in adhering to these guidelines.</p>

    <h2>Special Note for Discounted Products:</h2>

    <p>Kindly note that items purchased through a discount or promotion may not be eligible for a refund. We want to ensure you are aware of this aspect when making your purchase decisions. However, if you encounter any issues with a discounted product due to an error on our part, please reach out to us, and we will address it promptly.</p>

    </div>
  );
};

export default ReturnPolicy;

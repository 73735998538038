import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  minPrice: null,
  maxPrice: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    STORE_PRODUCTS(state, action) {
      state.products = action.payload.products;
    },
    GET_PRICE_RANGE(state, action) {
      const { products } = action.payload;

      if (products.length === 0) {
        state.minPrice = null;
        state.maxPrice = null;
        return;
      }

      const prices = products.map((product) => product.price);
      const validPrices = prices.filter(
        (price) => typeof price === "number" && !isNaN(price)
      );

      if (validPrices.length === 0) {
        state.minPrice = null;
        state.maxPrice = null;
        return;
      }

      state.minPrice = Math.min(...validPrices);
      state.maxPrice = Math.max(...validPrices);
    },
  },
});

export const { STORE_PRODUCTS, GET_PRICE_RANGE } = productSlice.actions;

export const selectProducts = (state) => state.product.products;
export const selectMinPrice = (state) => state.product.minPrice;
export const selectMaxPrice = (state) => state.product.maxPrice;

export default productSlice.reducer;

// ImageWithTextOverlay.js

import React from "react";
import styles from "./BannerPage.module.scss"; // Import the SCSS module

const BannerPage = () => {
  return (
    <section
      className={`${styles.imageWithTextOverlay} ${styles.imageWithTextOverlayWide} ${styles.imageWithTextOverlayHeightMedium} ${styles.imageWithTextOverlayTextPositionBottomLeft}`}
    >
      <div className={styles.imageWithTextOverlayContainer}>
        <div className={styles.imageWithTextOverlayImageWrapper}>
          <noscript data-rimg-noscript="">
            <img
              src="https://wallpapercave.com/wp/wp12324049.jpg"
              alt="shop NOW"
              data-rimg="noscript"
              className={styles.imageWithTextOverlayImage}
            />
          </noscript>
          <img
            src="https://wallpapercave.com/wp/wp12324049.jpg"
            alt="shop NOW"
            data-rimg="loaded"
            data-rimg-scale="1"
            data-rimg-template="https://wallpapercave.com/wp/wp12324049.jpg"
            data-rimg-max="2590x3270"
            data-rimg-crop="center"
            srcSet="https://wallpapercave.com/wp/wp12324049.jpg"
            className={styles.imageWithTextOverlayImage}
            data-rimg-template-svg="data:image/svg+xml;utf8,<svg%20xmlns='http://www.w3.org/2000/svg'%20width='1500'%20height='1894'></svg>"
          />
        </div>
        <div
          className={styles.imageWithTextOverlayContent}
          style={{
            backgroundColor: "rgba(48, 48, 48, 0.25)",
            color: "#ffffff",
          }}
        >
          <div className={styles.imageWithTextOverlayContentWrapper}>
            <h2 className={styles.imageWithTextOverlayHeading}>shop NOW</h2>
            <div className={styles.imageWithTextOverlayText}>
              <p>BEST QUALITY CRICKET EQUIPMENT</p>
            </div>
            <a
              className={`${styles.imageWithTextOverlayButton} ${styles.imageWithTextOverlayButtonStylePrimary}`}
              href="/sport/Cricket?category=All"
            >
              EXPLORE NOW
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerPage;

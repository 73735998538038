import React, { useState, useEffect } from "react";
import styles from "./TopCategories.module.scss";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
import Loader from "../../loader/Loader";

const TopCategories = () => {
  const [showLoader,setShowLoader] = useState(false)
  const [categories, setCategories] = useState([]);
  const [imageIndexes, setImageIndexes] = useState({});

  useEffect(() => {

    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(
          collection(db, "topcategories")
        );
        const categoriesData = [];
        await categoriesSnapshot.forEach((doc) => {
          categoriesData.push({ id: doc.id, ...doc.data() });
        });

        // Move "jersey" category to the start of the categories array
        const jerseyCategory = categoriesData.find(
          (category) => category.id === "jersey"
        );
        const otherCategories = categoriesData.filter(
          (category) => category.id !== "jersey"
        );
        if (jerseyCategory) {
          setCategories([jerseyCategory, ...otherCategories]);
        } else {
          setCategories(otherCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error.message);
      }
    };
     setShowLoader(true)
  fetchCategories();
    setShowLoader(false)
  }, []);


 
  useEffect(() => {
    const intervals = categories.map((category) => {
      if (category.imageUrls && category.imageUrls.length > 1) {
        const intervalId = setInterval(() => {
          setImageIndexes((prevIndexes) => {
            const nextIndex = (prevIndexes[category.id] || 0) + 1;
            return {
              ...prevIndexes,
              [category.id]: nextIndex % category.imageUrls.length,
            };
          });
        }, 500);
        return { id: category.id, intervalId };
      }
      return null;
    });

    return () => {
      intervals.forEach((interval) => {
        if (interval && interval.intervalId) {
          clearInterval(interval.intervalId);
        }
      });
    };
  }, [categories]);

  const handleCategoryClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const getCategoryRoute = (category) => {
    if (category === "Design your Jersey") {
      return `/customizer`;
    } else {
      return `/sport/Cricket?category=${encodeURIComponent(category)}`;
    }
  };

  return (
  
    <div className={styles.catlist}>
        {showLoader &&(<Loader />)}
      <div className={styles.topSection}>
        <div className={styles.toptext}>
          <h4> FEATURED</h4>
        </div>
      </div>

      <div className={styles.topCategories}>

        {categories.map((category) => (
          <div key={category.id} className={styles.categoryItem}>
            <Link
              to={getCategoryRoute(category.category)}
              onClick={handleCategoryClick}
            >
              {category.imageUrls && category.imageUrls.length > 0 ? (
                <img
                  src={category.imageUrls[imageIndexes[category.id] || 0]}
                  alt={category.category}
                  className={styles.categoryImage}
                />
              ) : (
                <div className={styles.noImagePlaceholder}>No Image Available</div>
              )}
              <p className={styles.categoryTitle}>
                {category.category.toUpperCase()}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopCategories;

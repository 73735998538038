import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import styles from "./Tagline.module.scss";
import { FaEdit } from "react-icons/fa";
import Loader from "../../loader/Loader";
import Modal from "react-modal";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import styled from "styled-components";
import { getDocs, collection, doc, updateDoc } from "firebase/firestore";

Modal.setAppElement("#root");

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  height: auto;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Add a shadow */
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

const StyledButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
`;

const CancelButton = styled(StyledButton)`
  background-color: #f44336;
`;

const Tagline = () => {
  const [tagline, setTagline] = useState({ text: "", status: false, id: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchTagline = async () => {
      try {
        const taglinesCollection = collection(db, "taglines");
        const taglinesSnapshot = await getDocs(taglinesCollection);

        if (taglinesSnapshot.docs.length > 0) {
          const taglineDocument = taglinesSnapshot.docs[0];
          const taglineData = taglineDocument.data();
          setTagline({ ...taglineData, id: taglineDocument.id });
        } else {
          // If no tagline is found, set default values
          setTagline({ text: "", status: false, id: "" });
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching tagline:", error.message);
        toast.error("Error fetching tagline");
        setIsLoading(false);
      }
    };

    fetchTagline();
  }, []);


  const toggleTaglineStatus = async () => {
    try {
      // Fetch the current tagline document
      const taglinesCollection = collection(db, "taglines");
      const taglineDocument = doc(db, "taglines", tagline.id);

      // Toggle the status
      const updatedStatus = !tagline.status;
      await updateDoc(taglineDocument, { status: updatedStatus });

      // Update the local state
      setTagline({ ...tagline, status: updatedStatus });

      toast.success("Tagline status updated successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const openEditModal = () => {
    setEditMode(true);
  };

  const closeEditModal = () => {
    setEditMode(false);
  };

  const updateTaglineText = async (newTagline) => {
    try {
      // Fetch the current tagline document
      const taglinesCollection = collection(db, "taglines");
      const taglineDocument = doc(db, "taglines", tagline.id);

      // Update the tagline text
      await updateDoc(taglineDocument, { text: newTagline });

      // Update the local state
      setTagline({ ...tagline, text: newTagline });

      toast.success("Tagline updated successfully.");
      closeEditModal();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.taglineContainer}>
        <h2>Manage Tagline</h2>
        <p>Current Tagline: {tagline.text}</p>
        <Toggle
          checked={tagline.status}
          icons={false}
          onChange={toggleTaglineStatus}
        />
        <FaEdit size={20} color="green" onClick={openEditModal} />

        {/* Edit Tagline Modal */}
        <StyledModal
          isOpen={editMode}
          onRequestClose={closeEditModal}
          contentLabel="Edit Tagline Modal"
        >
          <ModalContent>
            <h2>Edit Tagline</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const newTagline = e.target.newTagline.value;
                updateTaglineText(newTagline);
              }}
            >
              <StyledInput
                type="text"
                name="newTagline"
                placeholder="Enter new tagline"
                defaultValue={tagline.text}
                required
              />
              <div>
                <StyledButton type="submit">Update Tagline</StyledButton>
                <CancelButton onClick={closeEditModal}>Cancel</CancelButton>
              </div>
            </form>
          </ModalContent>
        </StyledModal>
      </div>
    </>
  );
};

export default Tagline;

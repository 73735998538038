import React, { useState, useEffect } from "react";
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { toast } from "react-toastify";
import styles from "./ManageVideoReviews.module.scss";
const ManageVideoReviews = () => {
  const [videoReviews, setVideoReviews] = useState([]);
  const [newVideoReviewSrc, setNewVideoReviewSrc] = useState("");

  useEffect(() => {
    fetchVideoReviews();
  }, []);

  const fetchVideoReviews = async () => {
    try {
      const videoReviewsSnapshot = await getDocs(
        collection(db, "videoreviews")
      );
      const videoReviewsData = videoReviewsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideoReviews(videoReviewsData);
    } catch (error) {
      console.error("Error fetching video reviews:", error.message);
      toast.error("Error fetching video reviews");
    }
  };

  const addVideoReview = async () => {
    if (!newVideoReviewSrc.trim()) {
      alert("Please enter a video review source.");
      return;
    }

    try {
      await addDoc(collection(db, "videoreviews"), {
        src: newVideoReviewSrc.trim(),
      });
      toast.success("Video review added successfully!");
      fetchVideoReviews(); // Refresh the list of video reviews
      setNewVideoReviewSrc(""); // Clear input field
    } catch (error) {
      console.error("Error adding video review:", error.message);
      toast.error("Error adding video review");
    }
  };

  const deleteVideoReview = async (id) => {
    try {
      await deleteDoc(doc(db, "videoreviews", id));
      toast.success("Video review deleted successfully");
      fetchVideoReviews(); // Refresh the list of video reviews
    } catch (error) {
      console.error("Error deleting video review:", error.message);
      toast.error("Error deleting video review");
    }
  };

  return (
    <div className={styles.videoReviewsContainer}>
      <h2>Manage Video Reviews</h2>
      <div>
        <h3>Add New Video Review</h3>
        <input
          type="text"
          value={newVideoReviewSrc}
          onChange={(e) => setNewVideoReviewSrc(e.target.value)}
        />
        <button onClick={addVideoReview}>Add</button>
      </div>
      <div>
        <h3>Existing Video Reviews</h3>
        <ul>
          {videoReviews.map((videoReview) => (
            <li key={videoReview.id} className={styles.videoReviewItem}>
              <a
                href={videoReview.src}
                target="_blank"
                className={styles.videoReviewLink}
                rel="noopener noreferrer"
              >
                {videoReview.src} {/* Display the source as a link */}
              </a>
              <button
                className={styles.deleteButton}
                onClick={() => deleteVideoReview(videoReview.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ManageVideoReviews;

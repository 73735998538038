import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import Loader from "../../loader/Loader";
import styles from "./Reviews.module.scss";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { Navigate } from "react-router-dom";



const Reviews = () => {
  const navigate = useNavigate("");
  const { data, isLoading } = useFetchCollection("reviews");

  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, "reviews")), (snapshot) => {
      const updatedReviews = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      // Process the updatedReviews as needed (e.g., update the state or UI)
    });

    return () => {
      // Cleanup the listener when the component is unmounted
      unsubscribe();
    };
  }, []);

  const handleClick = (id)=>{
    navigate("/product-details/"+`${id}`)
  }

  return (
    <>
      <div className={styles.reviews}>
        <h2>Product Reviews</h2>
        <>
          {isLoading && <Loader />}
          <div className={styles.table}>
            {data.length === 0 ? (
              <p>No reviews found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Review Date</th>
                    <th>User</th>
                    <th>Product</th>
                    <th>Rating</th>
                    <th>Review</th>
                  </tr>
                </thead>
                <tbody >
                  {data.map((review, index) => {
                    const {
                      id,
                      userName,
                      productName,
                      rate,
                      review: userReview,
                      productID,
                      hasReply,
                      reviewDate
                    } = review;
                    return (
                      <tr key={id} style={{ fontWeight: hasReply ? "normal" : "bold" }} onClick={()=>{handleClick(productID)}}>
                        <td>{index + 1}</td>
                        <td>{reviewDate}</td>
                        <td>{userName}</td>
                        <td>
                          {/* Link to the product page */}
                          <Link to={`/product-details/${productID}`}>{productName}</Link>
                        </td>
                        <td>{rate}/5</td>
                        <td>
                          <Link to={`/product-details/${productID}`} style={{ fontWeight: hasReply ? "normal" : "bold" }}>
                            {userReview}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Reviews;

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  wishlistItems: [],
  wishlistTotalQuantity: 0,
  wishlistTotalAmount: 0,
  wishlistTotalAmountWithTaxes: 0,
  previousURL: "",
};

const loadWishlistFromLocalStorage = () => {
  const storedWishlistItems = localStorage.getItem("wishlistItems");
  return storedWishlistItems ? JSON.parse(storedWishlistItems) : [];
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    ...initialState,
    wishlistItems: loadWishlistFromLocalStorage(),
  },
  reducers: {
    ADD_TO_WISHLIST(state, action) {
      const productIndex = state.wishlistItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (productIndex >= 0) {
        state.wishlistItems[productIndex].wishlistQuantity += 1;
        toast.info(`${action.payload.name} increased by one`, {
          position: "top-left",
        });
        
      } else {
        const tempProduct = { ...action.payload, wishlistQuantity: 1 };
        state.wishlistItems.push(tempProduct);
        toast.success(`${action.payload.name} added to wishlist`, {
          position: "top-left",
        });
      }
      localStorage.setItem(
        "wishlistItems",
        JSON.stringify(state.wishlistItems)
      );
    },
    DECREASE_WISHLIST(state, action) {
      const productIndex = state.wishlistItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (state.wishlistItems[productIndex].wishlistQuantity > 1) {
        state.wishlistItems[productIndex].wishlistQuantity -= 1;
        toast.info(`${action.payload.name} decreased by one`, {
          position: "top-left",
        });
      } else if (state.wishlistItems[productIndex].wishlistQuantity === 1) {
        const newWishlistItem = state.wishlistItems.filter(
          (item) => item.id !== action.payload.id
        );
        state.wishlistItems = newWishlistItem;
        toast.success(`${action.payload.name} removed from wishlist`, {
          position: "top-left",
        });
      }
      localStorage.setItem(
        "wishlistItems",
        JSON.stringify(state.wishlistItems)
      );
    },
    REMOVE_FROM_WISHLIST(state, action) {
      const newWishlistItem = state.wishlistItems.filter(
        (item) => item.id !== action.payload.id
      );

      state.wishlistItems = newWishlistItem;
      toast.success(`${action.payload.name} removed from wishlist`, {
        position: "top-left",
      });

      localStorage.setItem(
        "wishlistItems",
        JSON.stringify(state.wishlistItems)
      );

      

    },
    CLEAR_WISHLIST(state, action) {
      state.wishlistItems = [];
      toast.info(`Wishlist cleared`, {
        position: "top-left",
      });

      localStorage.setItem(
        "wishlistItems",
        JSON.stringify(state.wishlistItems)
      );
    },
    CALCULATE_SUBTOTAL(state, action) {
      const array = [];
      state.wishlistItems.forEach((item) => {
        const { price, wishlistQuantity, tax } = item;
        const wishlistItemAmount = price * wishlistQuantity;
        const wishlistItemAmountWithTaxes =
          (price + (price * tax) / 100) * wishlistQuantity;
        array.push({ wishlistItemAmount, wishlistItemAmountWithTaxes });
      });

      const totalAmount = array.reduce((a, b) => a + b.wishlistItemAmount, 0);
      const totalAmountWithTaxes = array.reduce(
        (a, b) => a + b.wishlistItemAmountWithTaxes,
        0
      );

      state.wishlistTotalAmount = totalAmount;
      state.wishlistTotalAmountWithTaxes = totalAmountWithTaxes;
    },
    CALCULATE_WISHLIST_QUANTITY(state, action) {
      const array = [];
      state.wishlistItems.forEach((item) => {
        const { wishlistQuantity } = item;
        array.push(wishlistQuantity);
      });
      const totalQuantity = array.reduce((a, b) => a + b, 0);
      state.wishlistTotalQuantity = totalQuantity;
    },
    SAVE_URL(state, action) {
      state.previousURL = action.payload;
    },
  },
});

export const {
  ADD_TO_WISHLIST,
  DECREASE_WISHLIST,
  REMOVE_FROM_WISHLIST,
  CLEAR_WISHLIST,
  CALCULATE_SUBTOTAL,
  CALCULATE_WISHLIST_QUANTITY,
  SAVE_URL,
} = wishlistSlice.actions;

export const selectWishlistItems = (state) => state.wishlist.wishlistItems;
export const selectWishlistTotalQuantity = (state) => state.wishlist.wishlistTotalQuantity;
export const selectWishlistTotalAmount = (state) => state.wishlist.wishlistTotalAmount;
export const selectWishlistTotalAmountWithTaxes = (state) =>
  state.wishlist.wishlistTotalAmountWithTaxes;
export const selectPreviousURL = (state) => state.wishlist.previousURL;

export default wishlistSlice.reducer;

import React from "react";
import styles from "./About.module.scss";

const About = () => {
  return (
    <div className={styles.about}>
      <h2>About Us</h2>
      <p>
        At Ultimate Sport Store, we're committed to enhancing your sporting
        experience by offering a diverse range of top-tier gear. Whether you're
        seeking high-quality cricket accessories or cutting-edge performance
        footwear, our carefully curated selection guarantees superior play. Rely
        on us for unmatched quality and expertise, propelling you towards
        triumph on the field. Embrace excellence, choose Ultimate Sport Store as
        your trusted companion for victory in every game.
      </p>
      <p>
        With a deep understanding of the needs of athletes at every level, we
        strive to provide the latest innovations and technologies in sports
        equipment and apparel. Our team consists of passionate sports
        enthusiasts who are dedicated to helping you achieve your goals and
        perform at your best.
      </p>
      <p>
        Beyond offering premium products, we aim to foster a community of
        like-minded individuals who share a love for sports and competition.
        Through our events, workshops, and partnerships, we create opportunities
        for athletes to connect, learn, and grow together.
      </p>
      <p>
        Whether you're a seasoned professional or just starting your sporting
        journey, Ultimate Sport Store is here to support you every step of the
        way. Join us in pursuing excellence and elevating your game to new
        heights.
      </p>
    </div>
  );
};

export default About;

import React, { useRef, useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { useGLTF, OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import state from "../State";
import Loader from "../../loader/Loader";
import { useThree } from "@react-three/fiber";

const Pant = ({ logo, rotateEnabled, viewMode }) => {
  const snap = useSnapshot(state);
  const { nodes } = useGLTF("/pant.glb");
  const meshRef = useRef();
  const [shirtTexture, setShirtTexture] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const designs = [
    {
      id: 1,
      colors: 1,
      colorNames: ["Line Color"],
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4535" height="4535" viewBox="0 0 4535 4535"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><path id="Waistrband" fill="${snap.colorPantWaistOpt}" d="M725.784,1007.794V880.419h2820.035v127.375H725.784z" style="fill: ${snap.colorPantWaistOpt};"></path>

<path id="Base" fill="${snap.colorPantBaseOpt}" d="M452.331,4461.614l-16.706-174.907l-16.216-168.56l-16.465-168.529l-16.851-168.485
			l-17.353-168.426l-17.993-168.358l-15.623-140.221l-3.134-28.046l-16.367-140.128l-3.284-28.027l-3.372-28.001l-3.404-28.01
			l-3.455-28.001l-3.505-27.99l-3.558-27.985l-3.635-27.967l-3.703-27.964l-3.793-27.944l-3.883-27.932l-3.978-27.916l-4.091-27.899
			l-4.203-27.881l-4.332-27.857l-4.479-27.826l-4.62-27.806l-4.783-27.776l-4.962-27.737l-5.143-27.705l-5.338-27.66l-5.55-27.619
			l-5.774-27.566l-6.006-27.52l-6.253-27.458l-6.52-27.389l-6.791-27.323l-7.071-27.249l-7.381-27.16l-7.687-27.069l-8.01-26.976
			l-8.325-26.882l-8.616-26.798l-8.886-26.712l-9.118-26.65l-9.32-26.584l-9.495-26.528l-9.659-26.477l-22.336-60.434l34.733-7.742
			l26.567-6.645l26.019-7.965l25.274-9.806l24.295-11.844l22.998-14.061l21.372-16.325l19.356-18.56l16.864-20.741l13.901-22.816
			l10.955-24.508l8.399-25.653l6.293-26.406l4.61-26.873l3.37-27.167l2.423-27.374l1.927-27.523l1.725-27.559l1.594-27.581
			l1.462-27.589l1.351-27.604l1.241-27.604l1.146-27.612l1.061-27.619l0.983-27.626l0.916-27.627l0.861-27.637l0.816-27.638
			l0.774-27.639l0.749-27.644l1.65-62.571l907.197,59.203l-4.387,63.517l-3.942,56.512l-1.996,28.253l-2.018,28.254l-2.046,28.25
			l-2.073,28.254l-2.115,28.25l-2.15,28.249l-2.195,28.245l-2.247,28.244l-2.295,28.237l-2.354,28.24l-2.419,28.233l-2.487,28.224
			l-2.564,28.227l-2.636,28.213l-2.714,28.213l-2.804,28.202l-2.891,28.193l-2.986,28.186l-3.083,28.178l-3.186,28.165
			l-3.291,28.156l-3.404,28.141l-3.519,28.132l-3.636,28.114l-3.764,28.104l-3.89,28.084l-4.032,28.065l-3.99,28.026l-3.738,28.016
			l-3.494,28.039l-3.328,28.085l-3.194,28.104l-3.1,28.127l-3.06,28.142l-21.164,197.103l-2.959,28.149l-20.17,197.193
			l-2.679,28.146l-15.748,169.178l-2.599,28.191l-18.32,225.744l-16.58,225.903l-14.016,197.727l-1.977,28.247l-1.94,28.244
			l-13.188,197.788l-1.818,28.248l-12.366,197.844l-1.723,28.255l-12.356,204.459H452.331z M2525.542,4461.505l-47.677-548.365
			l-12.169-140.479l-17.835-206.636l-11.23-130.527l-13.743-160.416l-20.707-242.928l-3.269-38.054l-2.444-28.092l-2.471-28.083
			l-2.508-28.08l-2.542-28.076l-2.598-28.068l-2.647-28.062l-2.716-28.056l-2.769-28.05l-2.851-28.032l-2.923-28.032l-3.015-28.016
			l-3.099-28.01l-3.195-27.994l-3.303-27.981l-3.406-27.968l-3.516-27.955l-3.644-27.93l-3.764-27.921l-3.892-27.894l-4.031-27.878
			l-4.176-27.854l-4.316-27.833l-4.47-27.801l-4.638-27.776l-4.796-27.747l-4.968-27.715l-5.122-27.694l-5.268-27.67l-5.381-27.652
			l-5.496-27.631l-5.565-27.627l-5.63-27.616l-7.324-35.644l-5.152-25.003l29.726-13.887l22.965-13.398l19.666-17.176l15.615-21.092
			l12.253-23.635l9.549-25.116l7.268-26.01l5.283-26.595l3.708-26.993l2.605-27.234l1.832-27.382l1.34-27.499l1.232-27.543
			l1.04-27.535l0.957-27.564l0.859-27.562l0.784-27.572l0.711-27.572l0.645-27.578l0.59-27.583l0.544-27.583l0.503-27.586
			l0.471-27.586l0.449-27.596l0.97-62.092l775.02,1.2l-4.832,92.023l-1.942,36.472l-1.072,19.97l-1.534,28.223l-1.557,28.224
			l-1.582,28.223l-1.615,28.219l-1.647,28.22l-1.683,28.221l-1.729,28.22l-1.772,28.214l-1.824,28.211l-1.874,28.211l-1.937,28.208
			l-1.992,28.204l-2.061,28.202l-2.123,28.19l-2.2,28.196l-2.275,28.186l-2.35,28.181l-2.436,28.173l-2.525,28.171l-2.618,28.164
			l-2.715,28.15l-2.804,28.144l-2.854,28.129l-2.819,28.109l-2.706,28.102l-2.566,28.105l-2.484,28.132l-2.415,28.137l-2.386,28.154
			l-2.97,36.371l-1.616,19.934l-2.208,28.157l-2.182,28.17l-2.144,28.166l-2.114,28.172l-2.094,28.178l-2.071,28.178l-2.054,28.182
			l-2.032,28.18l-2.008,28.184l-1.991,28.185l-1.959,28.187l-1.936,28.184l-1.904,28.19l-1.872,28.187l-1.845,28.196l-1.81,28.19
			l-1.772,28.193l-1.736,28.199l-1.696,28.198l-1.657,28.201l-1.615,28.201l-1.568,28.207l-1.528,28.207l-1.481,28.209
			l-1.414,28.209l-10.025,225.815l-8.968,225.867l-38.286,910.23H2525.542z M1505.784,4461.473l-38.285-910.214l-8.971-225.919
			l-10.025-225.818l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2
			l-1.737-28.195l-1.772-28.196l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.186l-1.959-28.185l-1.987-28.182
			l-2.009-28.184l-2.035-28.179l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168
			l-3.776-46.342l-0.816-9.967l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106
			l-2.804-28.133l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196
			l-2.059-28.203l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222
			l-1.616-28.219l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042
			l0.963,62.098l0.448,27.591l0.473,27.593l0.504,27.588l0.543,27.584l0.589,27.586l0.644,27.581l0.717,27.572l0.779,27.576
			l0.86,27.565l0.956,27.563l1.044,27.554l1.236,27.528l1.333,27.472l1.831,27.388l2.607,27.223l3.71,26.988l5.288,26.602
			l7.266,26.011l9.542,25.115l12.252,23.633l15.616,21.092l19.666,17.18l22.969,13.401l29.725,13.887l-6.808,33.032l-5.673,27.623
			l-5.629,27.621l-5.566,27.629l-5.499,27.643l-5.376,27.653l-5.269,27.676l-5.127,27.692l-4.968,27.719l-4.794,27.748
			l-4.637,27.778l-4.465,27.798l-4.32,27.831l-4.176,27.854l-4.031,27.873l-3.892,27.897l-3.764,27.918l-3.643,27.933l-3.514,27.949
			l-3.407,27.967l-3.303,27.979l-3.189,27.993l-3.104,28.009l-3.009,28.014l-2.922,28.026l-2.855,28.042l-2.769,28.041
			l-2.714,28.057l-2.643,28.06l-2.598,28.069l-2.546,28.072l-2.507,28.08l-2.472,28.082l-2.443,28.086l-2.416,28.092l-6.341,74.327
			l-17.621,206.657l-12.041,140.488l-17.642,204.856l-26.036,300.884l-44.532,512.098H1505.784z M3499.119,4461.432l-12.355-204.458
			l-1.723-28.264l-12.367-197.846l-1.817-28.248l-13.187-197.787l-1.94-28.24l-1.976-28.245l-14.019-197.734l-16.578-225.94
			l-18.327-225.767l-2.587-28.129l-15.754-169.18l-2.685-28.179l-20.165-197.165l-2.961-28.146l-21.162-197.094l-3.056-28.146
			l-3.106-28.135l-3.193-28.123l-3.325-28.089l-3.5-28.06l-3.738-28.013l-3.979-27.975l-4.024-28.027l-3.896-28.086l-3.759-28.101
			l-3.642-28.116l-3.519-28.133l-3.404-28.146l-3.292-28.154l-3.187-28.165l-3.081-28.18l-2.987-28.185l-2.892-28.196l-2.804-28.206
			l-2.715-28.211l-2.636-28.219l-2.566-28.22l-2.485-28.232l-2.419-28.234l-2.355-28.236l-2.295-28.242l-2.246-28.242l-2.195-28.253
			l-2.151-28.246l-2.109-28.254l-2.076-28.252l-2.048-28.25l-2.019-28.257l-1.995-28.259l-1.978-28.251l-6.353-91.773
			l869.018-56.722l38.18-2.492l1.65,62.578l0.748,27.651l0.776,27.643l0.815,27.64l0.862,27.633l0.915,27.636l0.983,27.625
			l1.061,27.621l1.146,27.62l1.241,27.607l1.351,27.604l1.463,27.596l1.596,27.578l1.726,27.577l1.922,27.463l2.426,27.387
			l3.369,27.164l4.609,26.872l6.302,26.406l8.393,25.654l10.953,24.503l13.9,22.814l16.863,20.746l19.361,18.564l21.371,16.32
			l22.994,14.062l24.301,11.847l25.263,9.803l26.031,7.968l26.583,6.645l34.711,7.743l-22.338,60.459l-9.67,26.492l-9.494,26.528
			l-9.324,26.592l-9.119,26.652l-8.883,26.725l-8.621,26.801l-8.327,26.888l-8.008,26.979l-7.688,27.07l-7.379,27.161l-7.067,27.241
			l-6.79,27.321l-6.519,27.388l-6.251,27.451l-6.004,27.511l-5.775,27.569l-5.549,27.613l-5.337,27.66l-5.143,27.701l-4.961,27.737
			l-4.781,27.768l-4.618,27.804l-4.476,27.828l-4.329,27.854l-4.204,27.877l-4.095,27.895l-3.979,27.911l-3.883,27.933
			l-3.792,27.945l-3.702,27.956l-3.636,27.973l-3.558,27.98l-3.504,27.99l-3.454,27.999l-3.403,28.004l-3.374,28.011
			l-19.646,168.173l-18.762,168.263l-17.99,168.351l-17.354,168.423l-16.846,168.477l-16.469,168.524l-16.217,168.556l-16.703,174.9
			H3499.119z" style="fill: ${snap.colorPantBaseOpt};"></path>

<path id="C1" d="M1497.87,3551.259l38.285,910.214h-30.372l-38.285-910.214l-8.971-225.919l-10.025-225.818l-1.414-28.188
			l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2l-1.737-28.195l-1.772-28.196l-1.808-28.193
			l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.186l-1.959-28.185l-1.987-28.182l-2.009-28.184l-2.035-28.179l-2.053-28.183
			l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168l-3.776-46.342l-0.816-9.967l-2.383-28.137
			l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106l-2.804-28.133l-2.713-28.153l-2.62-28.157
			l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203l-1.993-28.207
			l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219l-1.578-28.226
			l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042l0.001,0.046l-744.654,1.156
			l4.83,92.025l1.501,28.219l1.515,28.228l1.534,28.223l1.561,28.221l1.578,28.226l1.616,28.219l1.646,28.222l1.683,28.219
			l1.729,28.22l1.774,28.214l1.824,28.217l1.879,28.211l1.933,28.204l1.993,28.207l2.059,28.203l2.129,28.196l2.196,28.195
			l2.273,28.188l2.352,28.182l2.438,28.176l2.527,28.172l2.62,28.157l2.713,28.153l2.804,28.133l2.847,28.106l2.818,28.093
			l2.704,28.097l2.57,28.12l2.487,28.131l2.413,28.15l2.383,28.137l0.816,9.967l3.776,46.342l2.204,28.168l2.181,28.167l2.148,28.17
			l1.494,19.944l2.71,36.409l2.073,28.179l2.053,28.183l2.035,28.179l2.009,28.184l1.987,28.182l1.959,28.185l1.935,28.186
			l1.905,28.187l1.872,28.191l1.846,28.19l1.808,28.193l1.772,28.196l1.737,28.195l1.695,28.2l1.659,28.199l1.615,28.202
			l1.568,28.206l1.528,28.206l1.481,28.203l1.414,28.188l10.025,225.818L1497.87,3551.259z M1538.899,3325.34l-10.025-225.818
			l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2l-1.737-28.195l-1.772-28.196
			l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.186l-1.959-28.185l-1.987-28.182l-2.009-28.184l-2.035-28.179
			l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168l-3.776-46.342l-0.816-9.967
			l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106l-2.804-28.133l-2.713-28.153
			l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203l-1.993-28.207
			l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219l-1.578-28.226
			l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l744.654-1.156l-0.001-0.046l-28.038,0.042l-746.986,1.159
			l4.83,92.025l1.501,28.219l1.515,28.228l1.534,28.223l1.561,28.221l1.578,28.226l1.616,28.219l1.646,28.222l1.683,28.219
			l1.729,28.22l1.774,28.214l1.824,28.217l1.879,28.211l1.933,28.204l1.993,28.207l2.059,28.203l2.129,28.196l2.196,28.195
			l2.273,28.188l2.352,28.182l2.438,28.176l2.527,28.172l2.62,28.157l2.713,28.153l2.804,28.133l2.847,28.106l2.818,28.093
			l2.704,28.097l2.57,28.12l2.487,28.131l2.413,28.15l2.383,28.137l0.816,9.967l3.776,46.342l2.204,28.168l2.181,28.167l2.148,28.17
			l1.494,19.944l2.71,36.409l2.073,28.179l2.053,28.183l2.035,28.179l2.009,28.184l1.987,28.182l1.959,28.185l1.935,28.186
			l1.905,28.187l1.872,28.191l1.846,28.19l1.808,28.193l1.772,28.196l1.737,28.195l1.695,28.2l1.659,28.199l1.615,28.202
			l1.568,28.206l1.528,28.206l1.481,28.203l1.414,28.188l10.025,225.818l8.971,225.919l38.285,910.214h30.372l-38.285-910.214
			L1538.899,3325.34z" style="fill: ${snap.designColorPant1Opt};"></path>

<path id="C2" d="M3019.26,3325.373l10.025-225.818l1.414-28.188l1.481-28.203l1.528-28.206l1.568-28.206l1.615-28.202
			l1.659-28.199l1.695-28.2l1.737-28.195l1.772-28.196l1.808-28.193l1.846-28.19l1.872-28.191l1.905-28.187l1.935-28.186
			l1.959-28.185l1.987-28.182l2.009-28.184l2.035-28.179l2.053-28.183l2.073-28.179l2.71-36.409l1.494-19.944l2.148-28.17
			l2.181-28.167l2.204-28.168l3.776-46.342l0.816-9.967l2.383-28.137l2.413-28.15l2.487-28.131l2.57-28.12l2.704-28.097
			l2.818-28.093l2.847-28.106l2.804-28.133l2.713-28.153l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182l2.273-28.188
			l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.207l1.933-28.204l1.879-28.211l1.824-28.217l1.774-28.214l1.729-28.22
			l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219l4.83-92.025
			l-744.654-1.156l0.001-0.046l28.038,0.042l746.986,1.159l-4.83,92.025l-1.501,28.219l-1.515,28.228l-1.534,28.223l-1.561,28.221
			l-1.578,28.226l-1.616,28.219l-1.646,28.222l-1.683,28.219l-1.729,28.22l-1.774,28.214l-1.824,28.217l-1.879,28.211l-1.933,28.204
			l-1.993,28.207l-2.059,28.203l-2.129,28.196l-2.196,28.195l-2.273,28.188l-2.352,28.182l-2.438,28.176l-2.527,28.172l-2.62,28.157
			l-2.713,28.153l-2.804,28.133l-2.847,28.106l-2.818,28.093l-2.704,28.097l-2.57,28.12l-2.487,28.131l-2.413,28.15l-2.383,28.137
			l-0.816,9.967l-3.776,46.342l-2.204,28.168l-2.181,28.167l-2.148,28.17l-1.494,19.944l-2.71,36.409l-2.073,28.179l-2.053,28.183
			l-2.035,28.179l-2.009,28.184l-1.987,28.182l-1.959,28.185l-1.935,28.186l-1.905,28.187l-1.872,28.191l-1.846,28.19l-1.808,28.193
			l-1.772,28.196l-1.737,28.195l-1.695,28.2l-1.659,28.199l-1.615,28.202l-1.568,28.206l-1.528,28.206l-1.481,28.203l-1.414,28.188
			l-10.025,225.818l-8.971,225.919l-38.285,910.214h-30.372l38.285-910.214L3019.26,3325.373z M2960.289,3551.292l-38.285,910.214
			h30.372l38.285-910.214l8.971-225.919l10.025-225.818l1.414-28.188l1.481-28.203l1.528-28.206l1.568-28.206l1.615-28.202
			l1.659-28.199l1.695-28.2l1.737-28.195l1.772-28.196l1.808-28.193l1.846-28.19l1.872-28.191l1.905-28.187l1.935-28.186
			l1.959-28.185l1.987-28.182l2.009-28.184l2.035-28.179l2.053-28.183l2.073-28.179l2.71-36.409l1.494-19.944l2.148-28.17
			l2.181-28.167l2.204-28.168l3.776-46.342l0.816-9.967l2.383-28.137l2.413-28.15l2.487-28.131l2.57-28.12l2.704-28.097
			l2.818-28.093l2.847-28.106l2.804-28.133l2.713-28.153l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182l2.273-28.188
			l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.207l1.933-28.204l1.879-28.211l1.824-28.217l1.774-28.214l1.729-28.22
			l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219l4.83-92.025
			l-746.986-1.159l-28.038-0.042l-0.001,0.046l744.654,1.156l-4.83,92.025l-1.501,28.219l-1.515,28.228l-1.534,28.223l-1.561,28.221
			l-1.578,28.226l-1.616,28.219l-1.646,28.222l-1.683,28.219l-1.729,28.22l-1.774,28.214l-1.824,28.217l-1.879,28.211l-1.933,28.204
			l-1.993,28.207l-2.059,28.203l-2.129,28.196l-2.196,28.195l-2.273,28.188l-2.352,28.182l-2.438,28.176l-2.527,28.172l-2.62,28.157
			l-2.713,28.153l-2.804,28.133l-2.847,28.106l-2.818,28.093l-2.704,28.097l-2.57,28.12l-2.487,28.131l-2.413,28.15l-2.383,28.137
			l-0.816,9.967l-3.776,46.342l-2.204,28.168l-2.181,28.167l-2.148,28.17l-1.494,19.944l-2.71,36.409l-2.073,28.179l-2.053,28.183
			l-2.035,28.179l-2.009,28.184l-1.987,28.182l-1.959,28.185l-1.935,28.186l-1.905,28.187l-1.872,28.191l-1.846,28.19l-1.808,28.193
			l-1.772,28.196l-1.737,28.195l-1.695,28.2l-1.659,28.199l-1.615,28.202l-1.568,28.206l-1.528,28.206l-1.481,28.203l-1.414,28.188
			l-10.025,225.818L2960.289,3551.292z" style="fill:${snap.designColorPant1Opt};"></path><g class="field-id-78990038 field-type-custom" field-key="brand"><path id="brand" fill="${snap.colorPantBaseOpt}" d="M1648.177,2113.365h-18.905l-10.291-9.306l9.947,1.311l-3.429-23.588l15.155-19.978    L1648.177,2113.365z M1623.965,2077.717l-27.036,35.648h-17.562l0.012-0.016h-13.669l-12.413-18.604h-8.283l-7.145,18.604h-18.176    l16.188-42.103l0.039-0.105c1.049-3.381,0.794-6.534-0.755-9.371c-0.422-0.773-0.924-1.483-1.476-2.138l-20.661,53.718h-18.18    l16.194-42.13l0.036-0.109c1.045-3.381,0.792-6.532-0.755-9.363c-1.166-2.13-2.922-3.795-4.719-5.061h24.828    c-0.001-0.001-0.002-0.002-0.004-0.002h53.55c5.113,0,8.711,1.299,10.998,3.958c2.209,2.582,3.286,6.518,3.286,12.036    c0,10.182-3.742,22.067-14.284,22.067h-10.814l9.509,14.257l28.327-37.367c2.031-2.669,2.766-5.466,2.18-8.314    c-0.556-2.711-2.247-4.977-3.939-6.649h30.689L1623.965,2077.717z M1575.685,2082.627c4.03,0,6.157-3.964,6.762-7.356    c0.378-2.16,0.094-3.759-0.855-4.891c-0.877-1.044-2.243-1.571-4.058-1.571h-22.523l-5.324,13.818H1575.685z"></path></g><g class="field-id-78989831 field-type-editor" field-key="editor"><image placement-key="add-club-logo" name="Upload your Club Logo" placed-text="Placed Logos" x="2700" y="2100" width="150" height="150" view="Front"></image></g></g></svg>`,
    },
    {
      id: 2,
      colors: 1,
      colorNames: ["Line Color"],
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4535" height="4535" viewBox="0 0 4535 4535"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><path id="Waistrband" fill="${snap.colorPantWaistOpt}" d="M725.784,1007.794V880.419h2820.035v127.375H725.784z" style="fill: ${snap.colorPantWaistOpt};"></path>

<path id="Base" fill="${snap.colorPantBaseOpt}" d="M452.331,4461.614l-16.706-174.907l-16.216-168.56l-16.465-168.529l-16.851-168.485
			l-17.353-168.426l-17.993-168.358l-15.623-140.221l-3.134-28.046l-16.367-140.128l-3.284-28.027l-3.372-28.001l-3.404-28.01
			l-3.455-28.001l-3.505-27.99l-3.558-27.985l-3.635-27.967l-3.703-27.964l-3.793-27.944l-3.883-27.932l-3.978-27.916l-4.091-27.899
			l-4.203-27.881l-4.332-27.857l-4.479-27.826l-4.62-27.806l-4.783-27.776l-4.962-27.737l-5.143-27.705l-5.338-27.66l-5.55-27.619
			l-5.774-27.566l-6.006-27.52l-6.253-27.458l-6.52-27.389l-6.791-27.323l-7.071-27.249l-7.381-27.16l-7.687-27.069l-8.01-26.976
			l-8.325-26.882l-8.616-26.798l-8.886-26.712l-9.118-26.65l-9.32-26.584l-9.495-26.528l-9.659-26.477l-22.336-60.434l34.733-7.742
			l26.567-6.645l26.019-7.965l25.274-9.806l24.295-11.844l22.998-14.061l21.372-16.325l19.356-18.56l16.864-20.741l13.901-22.816
			l10.955-24.508l8.399-25.653l6.293-26.406l4.61-26.873l3.37-27.167l2.423-27.374l1.927-27.523l1.725-27.559l1.594-27.581
			l1.462-27.589l1.351-27.604l1.241-27.604l1.146-27.612l1.061-27.619l0.983-27.626l0.916-27.627l0.861-27.637l0.816-27.638
			l0.774-27.639l0.749-27.644l1.65-62.571l907.197,59.203l-4.387,63.517l-3.942,56.512l-1.996,28.253l-2.018,28.254l-2.046,28.25
			l-2.073,28.254l-2.115,28.25l-2.15,28.249l-2.195,28.245l-2.247,28.244l-2.295,28.237l-2.354,28.24l-2.419,28.233l-2.487,28.224
			l-2.564,28.227l-2.636,28.213l-2.714,28.213l-2.804,28.202l-2.891,28.193l-2.986,28.186l-3.083,28.178l-3.186,28.165
			l-3.291,28.156l-3.404,28.141l-3.519,28.132l-3.636,28.114l-3.764,28.104l-3.89,28.084l-4.032,28.065l-3.99,28.026l-3.738,28.016
			l-3.494,28.039l-3.328,28.085l-3.194,28.104l-3.1,28.127l-3.06,28.142l-21.164,197.103l-2.959,28.149l-20.17,197.193
			l-2.679,28.146l-15.748,169.178l-2.599,28.191l-18.32,225.744l-16.58,225.903l-14.016,197.727l-1.977,28.247l-1.94,28.244
			l-13.188,197.788l-1.818,28.248l-12.366,197.844l-1.723,28.255l-12.356,204.459H452.331z M2525.542,4461.505l-47.677-548.365
			l-12.169-140.479l-17.835-206.636l-11.23-130.527l-13.743-160.416l-20.707-242.928l-3.269-38.054l-2.444-28.092l-2.471-28.083
			l-2.508-28.08l-2.542-28.076l-2.598-28.068l-2.647-28.062l-2.716-28.056l-2.769-28.05l-2.851-28.032l-2.923-28.032l-3.015-28.016
			l-3.099-28.01l-3.195-27.994l-3.303-27.981l-3.406-27.968l-3.516-27.955l-3.644-27.93l-3.764-27.921l-3.892-27.894l-4.031-27.878
			l-4.176-27.854l-4.316-27.833l-4.47-27.801l-4.638-27.776l-4.796-27.747l-4.968-27.715l-5.122-27.694l-5.268-27.67l-5.381-27.652
			l-5.496-27.631l-5.565-27.627l-5.63-27.616l-7.324-35.644l-5.152-25.003l29.726-13.887l22.965-13.398l19.666-17.176l15.615-21.092
			l12.253-23.635l9.549-25.116l7.268-26.01l5.283-26.595l3.708-26.993l2.605-27.234l1.832-27.382l1.34-27.499l1.232-27.543
			l1.04-27.535l0.957-27.564l0.859-27.562l0.784-27.572l0.711-27.572l0.645-27.578l0.59-27.583l0.544-27.583l0.503-27.586
			l0.471-27.586l0.449-27.596l0.97-62.092l775.02,1.2l-4.832,92.023l-1.942,36.472l-1.072,19.97l-1.534,28.223l-1.557,28.224
			l-1.582,28.223l-1.615,28.219l-1.647,28.22l-1.683,28.221l-1.729,28.22l-1.772,28.214l-1.824,28.211l-1.874,28.211l-1.937,28.208
			l-1.992,28.204l-2.061,28.202l-2.123,28.19l-2.2,28.196l-2.275,28.186l-2.35,28.181l-2.436,28.173l-2.525,28.171l-2.618,28.164
			l-2.715,28.15l-2.804,28.144l-2.854,28.129l-2.819,28.109l-2.706,28.102l-2.566,28.105l-2.484,28.132l-2.415,28.137l-2.386,28.154
			l-2.97,36.371l-1.616,19.934l-2.208,28.157l-2.182,28.17l-2.144,28.166l-2.114,28.172l-2.094,28.178l-2.071,28.178l-2.054,28.182
			l-2.032,28.18l-2.008,28.184l-1.991,28.185l-1.959,28.187l-1.936,28.184l-1.904,28.19l-1.872,28.187l-1.845,28.196l-1.81,28.19
			l-1.772,28.193l-1.736,28.199l-1.696,28.198l-1.657,28.201l-1.615,28.201l-1.568,28.207l-1.528,28.207l-1.481,28.209
			l-1.414,28.209l-10.025,225.815l-8.968,225.867l-38.286,910.23H2525.542z M1505.784,4461.473l-38.285-910.214l-8.971-225.919
			l-10.025-225.818l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2
			l-1.737-28.195l-1.772-28.196l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.186l-1.959-28.185l-1.987-28.182
			l-2.009-28.184l-2.035-28.179l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168
			l-3.776-46.342l-0.816-9.967l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106
			l-2.804-28.133l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196
			l-2.059-28.203l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222
			l-1.616-28.219l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042
			l0.963,62.098l0.448,27.591l0.473,27.593l0.504,27.588l0.543,27.584l0.589,27.586l0.644,27.581l0.717,27.572l0.779,27.576
			l0.86,27.565l0.956,27.563l1.044,27.554l1.236,27.528l1.333,27.472l1.831,27.388l2.607,27.223l3.71,26.988l5.288,26.602
			l7.266,26.011l9.542,25.115l12.252,23.633l15.616,21.092l19.666,17.18l22.969,13.401l29.725,13.887l-6.808,33.032l-5.673,27.623
			l-5.629,27.621l-5.566,27.629l-5.499,27.643l-5.376,27.653l-5.269,27.676l-5.127,27.692l-4.968,27.719l-4.794,27.748
			l-4.637,27.778l-4.465,27.798l-4.32,27.831l-4.176,27.854l-4.031,27.873l-3.892,27.897l-3.764,27.918l-3.643,27.933l-3.514,27.949
			l-3.407,27.967l-3.303,27.979l-3.189,27.993l-3.104,28.009l-3.009,28.014l-2.922,28.026l-2.855,28.042l-2.769,28.041
			l-2.714,28.057l-2.643,28.06l-2.598,28.069l-2.546,28.072l-2.507,28.08l-2.472,28.082l-2.443,28.086l-2.416,28.092l-6.341,74.327
			l-17.621,206.657l-12.041,140.488l-17.642,204.856l-26.036,300.884l-44.532,512.098H1505.784z M3499.119,4461.432l-12.355-204.458
			l-1.723-28.264l-12.367-197.846l-1.817-28.248l-13.187-197.787l-1.94-28.24l-1.976-28.245l-14.019-197.734l-16.578-225.94
			l-18.327-225.767l-2.587-28.129l-15.754-169.18l-2.685-28.179l-20.165-197.165l-2.961-28.146l-21.162-197.094l-3.056-28.146
			l-3.106-28.135l-3.193-28.123l-3.325-28.089l-3.5-28.06l-3.738-28.013l-3.979-27.975l-4.024-28.027l-3.896-28.086l-3.759-28.101
			l-3.642-28.116l-3.519-28.133l-3.404-28.146l-3.292-28.154l-3.187-28.165l-3.081-28.18l-2.987-28.185l-2.892-28.196l-2.804-28.206
			l-2.715-28.211l-2.636-28.219l-2.566-28.22l-2.485-28.232l-2.419-28.234l-2.355-28.236l-2.295-28.242l-2.246-28.242l-2.195-28.253
			l-2.151-28.246l-2.109-28.254l-2.076-28.252l-2.048-28.25l-2.019-28.257l-1.995-28.259l-1.978-28.251l-6.353-91.773
			l869.018-56.722l38.18-2.492l1.65,62.578l0.748,27.651l0.776,27.643l0.815,27.64l0.862,27.633l0.915,27.636l0.983,27.625
			l1.061,27.621l1.146,27.62l1.241,27.607l1.351,27.604l1.463,27.596l1.596,27.578l1.726,27.577l1.922,27.463l2.426,27.387
			l3.369,27.164l4.609,26.872l6.302,26.406l8.393,25.654l10.953,24.503l13.9,22.814l16.863,20.746l19.361,18.564l21.371,16.32
			l22.994,14.062l24.301,11.847l25.263,9.803l26.031,7.968l26.583,6.645l34.711,7.743l-22.338,60.459l-9.67,26.492l-9.494,26.528
			l-9.324,26.592l-9.119,26.652l-8.883,26.725l-8.621,26.801l-8.327,26.888l-8.008,26.979l-7.688,27.07l-7.379,27.161l-7.067,27.241
			l-6.79,27.321l-6.519,27.388l-6.251,27.451l-6.004,27.511l-5.775,27.569l-5.549,27.613l-5.337,27.66l-5.143,27.701l-4.961,27.737
			l-4.781,27.768l-4.618,27.804l-4.476,27.828l-4.329,27.854l-4.204,27.877l-4.095,27.895l-3.979,27.911l-3.883,27.933
			l-3.792,27.945l-3.702,27.956l-3.636,27.973l-3.558,27.98l-3.504,27.99l-3.454,27.999l-3.403,28.004l-3.374,28.011
			l-19.646,168.173l-18.762,168.263l-17.99,168.351l-17.354,168.423l-16.846,168.477l-16.469,168.524l-16.217,168.556l-16.703,174.9
			H3499.119z" style="fill: ${snap.colorPantBaseOpt}"></path>

<path id="C1" d="M1485.87,3551.259l38.285,910.214h-30.372l-38.285-910.214l-8.971-225.919l-10.025-225.818l-1.414-28.188
			l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2l-1.737-28.195l-1.772-28.196l-1.808-28.193
			l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.186l-1.959-28.185l-1.987-28.182l-2.009-28.184l-2.035-28.179l-2.053-28.183
			l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168l-3.776-46.342l-0.816-9.967l-2.383-28.137
			l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106l-2.804-28.133l-2.713-28.153l-2.62-28.157
			l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203l-1.993-28.207
			l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219l-1.578-28.226
			l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042l0.001,0.046l-744.654,1.156
			l4.83,92.025l1.501,28.219l1.515,28.228l1.534,28.223l1.561,28.221l1.578,28.226l1.616,28.219l1.646,28.222l1.683,28.219
			l1.729,28.22l1.774,28.214l1.824,28.217l1.879,28.211l1.933,28.204l1.993,28.207l2.059,28.203l2.129,28.196l2.196,28.195
			l2.273,28.188l2.352,28.182l2.438,28.176l2.527,28.172l2.62,28.157l2.713,28.153l2.804,28.133l2.847,28.106l2.818,28.093
			l2.704,28.097l2.57,28.12l2.487,28.131l2.413,28.15l2.383,28.137l0.816,9.967l3.776,46.342l2.204,28.168l2.181,28.167l2.148,28.17
			l1.494,19.944l2.71,36.409l2.073,28.179l2.053,28.183l2.035,28.179l2.009,28.184l1.987,28.182l1.959,28.185l1.935,28.186
			l1.905,28.187l1.872,28.191l1.846,28.19l1.808,28.193l1.772,28.196l1.737,28.195l1.695,28.2l1.659,28.199l1.615,28.202
			l1.568,28.206l1.528,28.206l1.481,28.203l1.414,28.188l10.025,225.818L1485.87,3551.259z M3026.687,3325.34l10.025-225.818
			l1.414-28.188l1.481-28.203l1.528-28.206l1.568-28.206l1.615-28.202l1.659-28.199l1.695-28.2l1.737-28.195l1.772-28.196
			l1.808-28.193l1.846-28.19l1.872-28.191l1.905-28.187l1.935-28.186l1.959-28.185l1.987-28.182l2.009-28.184l2.035-28.179
			l2.053-28.183l2.073-28.179l2.71-36.409l1.494-19.944l2.148-28.17l2.181-28.167l2.204-28.168l3.776-46.342l0.816-9.967
			l2.383-28.137l2.413-28.15l2.487-28.131l2.57-28.12l2.704-28.097l2.818-28.093l2.847-28.106l2.804-28.133l2.713-28.153
			l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182l2.273-28.188l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.207
			l1.933-28.204l1.879-28.211l1.824-28.217l1.774-28.214l1.729-28.22l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226
			l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219l4.83-92.025l-744.654-1.156l0.001-0.046l28.038,0.042l746.986,1.159
			l-4.83,92.025l-1.501,28.219l-1.515,28.228l-1.534,28.223l-1.561,28.221l-1.578,28.226l-1.616,28.219l-1.646,28.222l-1.683,28.219
			l-1.729,28.22l-1.774,28.214l-1.824,28.217l-1.879,28.211l-1.933,28.204l-1.993,28.207l-2.059,28.203l-2.129,28.196l-2.196,28.195
			l-2.273,28.188l-2.352,28.182l-2.438,28.176l-2.527,28.172l-2.62,28.157l-2.713,28.153l-2.804,28.133l-2.847,28.106l-2.818,28.093
			l-2.704,28.097l-2.57,28.12l-2.487,28.131l-2.413,28.15l-2.383,28.137l-0.816,9.967l-3.776,46.342l-2.204,28.168l-2.181,28.167
			l-2.148,28.17l-1.494,19.944l-2.71,36.409l-2.073,28.179l-2.053,28.183l-2.035,28.179l-2.009,28.184l-1.987,28.182l-1.959,28.185
			l-1.935,28.186l-1.905,28.187l-1.872,28.191l-1.846,28.19l-1.808,28.193l-1.772,28.196l-1.737,28.195l-1.695,28.2l-1.659,28.199
			l-1.615,28.202l-1.568,28.206l-1.528,28.206l-1.481,28.203l-1.414,28.188l-10.025,225.818l-8.971,225.919l-38.285,910.214h-30.372
			l38.285-910.214L3026.687,3325.34z" style="fill:${snap.designColorPant1Opt};"></path><g class="field-id-78990038 field-type-custom" field-key="brand"></g></g></svg>`,
    },
    {
      id: 3,
      colors: 3,
      colorNames: ["Design Color 1", "Design Color 2", "Design Color 3"],
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4535" height="4535" viewBox="0 0 4535 4535"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><g id="IRA305"> 
		<path id="Waistrband" fill="${snap.colorPantWaistOpt}" d="M725.784,1007.794V880.419h2820.035v127.375H725.784z" style="fill:${snap.colorPantWaistOpt}"></path>
		<path id="Base" fill="${snap.colorPantBaseOpt}" d="M452.331,4461.614l-16.706-174.907l-16.216-168.56l-16.465-168.529l-16.851-168.485
			l-17.353-168.426l-17.993-168.358l-15.623-140.221l-3.134-28.046l-16.367-140.128l-3.284-28.027l-3.372-28.001l-3.404-28.01
			l-3.455-28.001l-3.505-27.99l-3.558-27.985l-3.635-27.967l-3.703-27.964l-3.793-27.944l-3.883-27.932l-3.978-27.916l-4.091-27.899
			l-4.203-27.881l-4.332-27.857l-4.479-27.826l-4.62-27.806l-4.783-27.776l-4.962-27.737l-5.143-27.705l-5.338-27.66l-5.55-27.619
			l-5.774-27.566l-6.006-27.52l-6.253-27.458l-6.52-27.389l-6.791-27.323l-7.071-27.249l-7.381-27.16l-7.687-27.069l-8.01-26.976
			l-8.325-26.882l-8.616-26.798l-8.886-26.712l-9.118-26.65l-9.32-26.584l-9.495-26.528l-9.659-26.477l-22.336-60.434l34.733-7.742
			l26.567-6.646l26.019-7.965l25.274-9.806l24.295-11.844l22.997-14.061l21.372-16.325l19.356-18.56l16.864-20.741l13.901-22.816
			l10.955-24.508l8.399-25.653l6.293-26.406l4.61-26.873l3.37-27.167l2.423-27.373l1.927-27.523l1.725-27.559l1.594-27.58
			l1.462-27.589l1.351-27.604l1.241-27.603l1.146-27.612l1.061-27.619l0.983-27.626l0.916-27.628l0.861-27.637l0.816-27.638
			l0.774-27.639l0.749-27.644l1.65-62.571l907.197,59.203l-4.387,63.517l-3.942,56.512l-1.996,28.253l-2.018,28.254l-2.046,28.25
			l-2.073,28.254l-2.115,28.25l-2.15,28.249l-2.195,28.245l-2.247,28.244l-2.295,28.237l-2.354,28.24l-2.419,28.233l-2.487,28.224
			l-2.564,28.227l-2.636,28.213l-2.714,28.213l-2.804,28.202l-2.891,28.193l-2.986,28.185l-3.083,28.178l-3.186,28.165
			l-3.291,28.156l-3.404,28.141l-3.519,28.132l-3.636,28.114l-3.764,28.104l-3.89,28.084l-4.032,28.065l-3.99,28.026l-3.738,28.016
			l-3.494,28.039l-3.328,28.085l-3.194,28.104l-3.1,28.127l-3.06,28.142l-21.164,197.103l-2.959,28.149l-20.17,197.193
			l-2.679,28.146l-15.748,169.178l-2.599,28.191l-18.32,225.744l-16.58,225.904l-14.016,197.726l-1.977,28.247l-1.94,28.244
			l-13.188,197.788l-1.818,28.248l-12.366,197.844l-1.723,28.255l-12.356,204.459H452.331z M2525.542,4461.505l-47.677-548.365
			l-12.169-140.479l-17.835-206.636l-11.23-130.527l-13.743-160.416l-20.707-242.928l-3.269-38.054l-2.444-28.092l-2.471-28.083
			l-2.508-28.08l-2.542-28.076l-2.598-28.068l-2.647-28.062l-2.716-28.056l-2.769-28.05l-2.851-28.032l-2.923-28.032l-3.015-28.016
			l-3.099-28.01l-3.195-27.994l-3.303-27.981l-3.406-27.968l-3.516-27.955l-3.644-27.93l-3.764-27.921l-3.892-27.894l-4.031-27.878
			l-4.176-27.854l-4.316-27.833l-4.47-27.801l-4.638-27.776l-4.796-27.747l-4.968-27.715l-5.122-27.694l-5.268-27.67l-5.381-27.652
			l-5.496-27.631l-5.565-27.627l-5.63-27.616l-7.324-35.644l-5.152-25.003l29.726-13.887l22.965-13.398l19.666-17.176l15.615-21.092
			l12.253-23.635l9.549-25.116l7.268-26.01l5.283-26.595l3.708-26.993l2.605-27.234l1.832-27.382l1.34-27.499l1.232-27.543
			l1.04-27.535l0.957-27.564l0.859-27.562l0.784-27.572l0.711-27.572l0.645-27.578l0.59-27.583l0.544-27.583l0.503-27.586
			l0.471-27.586l0.449-27.596l0.97-62.092l775.02,1.2l-4.832,92.023l-1.942,36.472l-1.072,19.97l-1.534,28.223l-1.557,28.224
			l-1.582,28.223l-1.615,28.219l-1.647,28.22l-1.683,28.221l-1.729,28.22l-1.772,28.214l-1.824,28.211l-1.874,28.211l-1.937,28.208
			l-1.992,28.204l-2.061,28.202l-2.123,28.19l-2.2,28.196l-2.275,28.186l-2.35,28.181l-2.436,28.173l-2.525,28.171l-2.618,28.164
			l-2.715,28.15l-2.804,28.144l-2.854,28.129l-2.819,28.109l-2.706,28.102l-2.566,28.105l-2.484,28.132l-2.415,28.137l-2.386,28.154
			l-2.97,36.371l-1.616,19.934l-2.208,28.157l-2.182,28.17l-2.144,28.166l-2.114,28.172l-2.094,28.178l-2.071,28.178l-2.054,28.182
			l-2.032,28.18l-2.008,28.184l-1.991,28.185l-1.959,28.187l-1.936,28.184l-1.904,28.19l-1.872,28.187l-1.845,28.196l-1.81,28.19
			l-1.772,28.193l-1.736,28.199l-1.696,28.198l-1.657,28.201l-1.615,28.201l-1.568,28.207l-1.528,28.207l-1.481,28.209
			l-1.414,28.209l-10.025,225.815l-8.968,225.867l-38.286,910.23H2525.542z M1505.784,4461.473l-38.285-910.214l-8.971-225.919
			l-10.025-225.818l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2
			l-1.737-28.196l-1.772-28.196l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.185l-1.959-28.185l-1.987-28.181
			l-2.009-28.184l-2.035-28.179l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168
			l-3.776-46.342l-0.816-9.967l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106
			l-2.804-28.133l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196
			l-2.059-28.203l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222
			l-1.616-28.219l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042
			l0.963,62.098l0.448,27.591l0.473,27.593l0.504,27.588l0.543,27.584l0.589,27.586l0.644,27.581l0.717,27.572l0.779,27.576
			l0.86,27.565l0.956,27.563l1.044,27.554l1.236,27.528l1.333,27.472l1.831,27.388l2.607,27.223l3.71,26.988l5.288,26.602
			l7.266,26.011l9.542,25.115l12.252,23.633l15.616,21.092l19.666,17.18l22.969,13.401l29.725,13.887l-6.808,33.032l-5.673,27.623
			l-5.629,27.621l-5.566,27.629l-5.499,27.643l-5.376,27.653l-5.269,27.676l-5.127,27.692l-4.968,27.719l-4.794,27.748
			l-4.637,27.778l-4.465,27.798l-4.32,27.831l-4.176,27.854l-4.031,27.873l-3.892,27.896l-3.764,27.918l-3.643,27.933l-3.514,27.949
			l-3.407,27.967l-3.303,27.979l-3.189,27.993l-3.104,28.009l-3.009,28.013l-2.922,28.026l-2.855,28.042l-2.769,28.041
			l-2.714,28.057l-2.643,28.059l-2.598,28.07l-2.546,28.072l-2.507,28.08l-2.472,28.082l-2.443,28.086l-2.416,28.092l-6.341,74.327
			l-17.621,206.657l-12.041,140.488l-17.642,204.856l-26.036,300.884l-44.532,512.098H1505.784z M3499.119,4461.432l-12.355-204.458
			l-1.723-28.264l-12.367-197.846l-1.817-28.248l-13.187-197.787l-1.94-28.24l-1.976-28.245l-14.019-197.735l-16.578-225.94
			l-18.327-225.767l-2.587-28.129l-15.754-169.18l-2.685-28.179l-20.165-197.165l-2.961-28.146l-21.162-197.094l-3.056-28.146
			l-3.106-28.135l-3.193-28.123l-3.325-28.089l-3.5-28.06l-3.738-28.013l-3.979-27.975l-4.024-28.027l-3.896-28.086l-3.759-28.101
			l-3.642-28.116l-3.519-28.133l-3.404-28.146l-3.292-28.154l-3.187-28.165l-3.081-28.18l-2.987-28.185l-2.892-28.196l-2.804-28.206
			l-2.715-28.212l-2.636-28.219l-2.566-28.22l-2.485-28.232l-2.419-28.234l-2.355-28.236l-2.295-28.242l-2.246-28.242l-2.195-28.253
			l-2.151-28.246l-2.109-28.254l-2.076-28.252l-2.048-28.25l-2.019-28.257l-1.995-28.259l-1.978-28.251l-6.353-91.773
			l869.018-56.722l38.18-2.492l1.65,62.578l0.748,27.651l0.776,27.642l0.815,27.64l0.862,27.633l0.915,27.636l0.983,27.625
			l1.061,27.621l1.146,27.62l1.241,27.607l1.351,27.604l1.463,27.596l1.596,27.578l1.726,27.577l1.922,27.463l2.426,27.387
			l3.369,27.164l4.609,26.872l6.302,26.406l8.393,25.654l10.953,24.503l13.9,22.814l16.863,20.746l19.361,18.564l21.371,16.32
			l22.994,14.062l24.301,11.847l25.263,9.803l26.031,7.968l26.583,6.645l34.711,7.743l-22.338,60.459l-9.67,26.492l-9.494,26.528
			l-9.324,26.592l-9.119,26.652l-8.883,26.725l-8.621,26.801l-8.327,26.888l-8.008,26.979l-7.688,27.07l-7.379,27.161l-7.067,27.241
			l-6.79,27.321l-6.519,27.388l-6.251,27.451l-6.004,27.511l-5.775,27.569l-5.549,27.613l-5.337,27.66l-5.143,27.701l-4.961,27.737
			l-4.781,27.768l-4.618,27.804l-4.476,27.828l-4.329,27.854l-4.204,27.877l-4.095,27.895l-3.979,27.911l-3.883,27.933
			l-3.792,27.945l-3.702,27.956l-3.636,27.973l-3.558,27.98l-3.504,27.99l-3.454,27.999l-3.403,28.004l-3.374,28.011
			l-19.646,168.173l-18.762,168.263l-17.99,168.351l-17.354,168.423l-16.846,168.477l-16.469,168.523l-16.217,168.556l-16.703,174.9
			H3499.119z" style="fill: ${snap.colorPantBaseOpt}"></path>
		<path id="C3" fill="#F15A24" d="M1746.66,4461.473h-240.876l-38.285-910.214l-6.26-157.654l244.406,71.956L1746.66,4461.473z
			 M2801.842,3465.451l-41.021,996.055h241.554l38.286-910.23l6.272-157.982L2801.842,3465.451z" style="fill: ${snap.designColorPant2Opt};"></path>
		<path id="C2" fill="${snap.designColorPant1Opt}" d="M1472.075,3660.059l-0.889-21.143l237.746-93.553l0.871,21.15L1472.075,3660.059z
			 M1711.805,3615.135l-0.868-21.086l-237.804,91.149l0.887,21.08L1711.805,3615.135z M1717.796,3760.604l-0.861-20.901
			l-237.984,83.824l0.879,20.894L1717.796,3760.604z M1707.797,3517.828l-0.874-21.216l-237.688,95.939l0.892,21.209
			L1707.797,3517.828z M1715.801,3712.181l-0.863-20.962l-237.923,86.285l0.881,20.955L1715.801,3712.181z M1713.804,3663.69
			l-0.866-21.023l-237.863,88.726l0.884,21.016L1713.804,3663.69z M1719.787,3808.96l-0.858-20.842l-238.046,81.344l0.876,20.836
			L1719.787,3808.96z M1744.575,4410.845l-238.934,47.23l0.143,3.398h85.807l153.817-30.405L1744.575,4410.845z M1508.157,3407.419
			l-29.651-8.73l-16.77,7.437l0.854,21.5L1508.157,3407.419z M1573.985,3426.799l-29.944-8.816l-80.45,34.883l0.851,21.43
			L1573.985,3426.799z M1641.469,3446.667l-30.249-8.906l-145.776,61.762l0.848,21.362L1641.469,3446.667z M1468.177,3567.377
			l237.613-98.299l-0.145-3.517l-25.514-7.511l-212.837,88.049l0.205,5.16L1468.177,3567.377z M1735.615,4193.283l-0.841-20.424
			l-238.573,60.778l0.859,20.42L1735.615,4193.283z M1741.5,4336.188l-0.836-20.296l-238.787,52.717l0.854,20.292L1741.5,4336.188z
			 M1739.542,4288.63l-0.838-20.338l-238.715,55.426l0.855,20.334L1739.542,4288.63z M1737.58,4240.994l-0.839-20.379
			l-238.644,58.113l0.857,20.375L1737.58,4240.994z M1746.525,4458.201l-17.595,3.272h17.73L1746.525,4458.201z M1733.647,4145.497
			l-0.843-20.47l-238.504,63.422l0.861,20.466L1733.647,4145.497z M1743.456,4383.667l-0.834-20.259l-238.86,49.985l0.852,20.255
			L1743.456,4383.667z M1723.762,3905.468l-0.854-20.729l-238.172,76.325l0.872,20.723L1723.762,3905.468z M1721.776,3857.249
			l-0.856-20.785l-238.109,78.844l0.874,20.779L1721.776,3857.249z M1731.676,4097.636l-0.845-20.518l-238.436,66.044l0.863,20.513
			L1731.676,4097.636z M1725.745,3953.617l-0.851-20.674l-238.237,73.785l0.869,20.669L1725.745,3953.617z M1729.702,4049.702
			l-0.847-20.569l-238.369,68.646l0.865,20.563L1729.702,4049.702z M1727.725,4001.695l-0.849-20.62l-238.302,71.226l0.867,20.615
			L1727.725,4001.695z M2782.594,3932.832l-0.852,20.674l238.888,73.988l0.869-20.669L2782.594,3932.832z M2772.713,4172.749
			l-0.841,20.424l239.227,60.945l0.859-20.42L2772.713,4172.749z M2776.656,4077.007l-0.845,20.518l239.089,66.225l0.863-20.513
			L2776.656,4077.007z M2780.611,3980.965l-0.849,20.62l238.954,71.42l0.867-20.615L2780.611,3980.965z M2778.632,4029.023
			l-0.847,20.569l239.021,68.833l0.865-20.563L2778.632,4029.023z M2774.683,4124.916l-0.843,20.471l239.157,63.596l0.861-20.466
			L2774.683,4124.916z M2760.961,4458.09l-0.141,3.415h18.502L2760.961,4458.09z M2762.912,4410.734l-0.833,20.223l154.538,30.548
			h85.758l0.143-3.408L2762.912,4410.734z M2764.865,4363.298l-0.834,20.258l239.515,50.122l0.852-20.255L2764.865,4363.298z
			 M2766.822,4315.78l-0.836,20.297l239.441,52.861l0.854-20.293L2766.822,4315.78z M2770.746,4220.505l-0.839,20.38l239.297,58.272
			l0.857-20.375L2770.746,4220.505z M2768.782,4268.182l-0.838,20.338l239.369,55.578l0.855-20.334L2768.782,4268.182z
			 M2790.552,3739.593l-0.861,20.901l238.635,84.053l0.879-20.894L2790.552,3739.593z M2801.842,3465.451l-0.145,3.517
			l238.28,98.575l0.684-16.267l0.199-5.011l-213.504-88.326L2801.842,3465.451z M2799.689,3517.717l238.337,96.201l0.892-21.208
			l-238.355-96.208L2799.689,3517.717z M2866.018,3446.557l175.843,74.501l0.848-21.363l-146.441-62.045L2866.018,3446.557z
			 M2784.579,3884.629l-0.854,20.728l238.823,76.534l0.872-20.723L2784.579,3884.629z M2999.329,3407.309l46.234,20.502l0.854-21.5
			l-17.436-7.732L2999.329,3407.309z M2797.685,3566.403l238.395,93.808l0.889-21.143l-238.413-93.815L2797.685,3566.403z
			 M2933.502,3426.689l110.209,47.786l0.851-21.431l-81.115-35.171L2933.502,3426.689z M2786.567,3836.354l-0.856,20.785
			l238.76,79.06l0.874-20.779L2786.567,3836.354z M2788.558,3788.008l-0.858,20.842l238.697,81.566l0.876-20.836L2788.558,3788.008z
			 M2793.682,3663.58l238.513,88.969l0.884-21.016l-238.531-88.975L2793.682,3663.58z M2791.685,3712.07l238.573,86.521
			l0.882-20.956l-238.592-86.527L2791.685,3712.07z M2795.682,3615.024l238.453,91.398l0.887-21.08l-238.472-91.405
			L2795.682,3615.024z" style="fill: ${snap.designColorPant1Opt}"></path>
		<path id="C1" d="M1485.87,3551.259l38.285,910.214h-30.372l-38.285-910.214l-8.971-225.919l-10.025-225.818l-1.414-28.188
			l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2l-1.737-28.196l-1.772-28.196l-1.808-28.193
			l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.185l-1.959-28.185l-1.987-28.181l-2.009-28.184l-2.035-28.179l-2.053-28.183
			l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168l-3.776-46.342l-0.816-9.967l-2.383-28.137
			l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106l-2.804-28.133l-2.713-28.153l-2.62-28.157
			l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203l-1.993-28.207
			l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219l-1.578-28.226
			l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042l0.001,0.046l-744.654,1.156
			l4.83,92.025l1.501,28.219l1.515,28.228l1.534,28.223l1.561,28.221l1.578,28.226l1.616,28.219l1.646,28.222l1.683,28.219
			l1.729,28.22l1.774,28.214l1.824,28.217l1.879,28.211l1.933,28.204l1.993,28.207l2.059,28.203l2.129,28.196l2.196,28.195
			l2.273,28.188l2.352,28.182l2.438,28.176l2.527,28.172l2.62,28.157l2.713,28.153l2.804,28.133l2.847,28.106l2.818,28.093
			l2.704,28.097l2.57,28.12l2.487,28.131l2.413,28.15l2.383,28.137l0.816,9.967l3.776,46.342l2.204,28.168l2.181,28.167l2.148,28.17
			l1.494,19.944l2.71,36.409l2.073,28.179l2.053,28.183l2.035,28.179l2.009,28.184l1.987,28.181l1.959,28.185l1.935,28.185
			l1.905,28.187l1.872,28.191l1.846,28.19l1.808,28.193l1.772,28.196l1.737,28.196l1.695,28.2l1.659,28.199l1.615,28.202
			l1.568,28.206l1.528,28.206l1.481,28.203l1.414,28.188l10.025,225.818L1485.87,3551.259z M3026.687,3325.34l10.025-225.818
			l1.414-28.188l1.481-28.203l1.528-28.206l1.568-28.206l1.615-28.202l1.659-28.199l1.695-28.2l1.737-28.196l1.772-28.196
			l1.808-28.193l1.846-28.19l1.872-28.191l1.905-28.187l1.935-28.185l1.959-28.185l1.987-28.181l2.009-28.184l2.035-28.179
			l2.053-28.183l2.073-28.179l2.71-36.409l1.494-19.944l2.148-28.17l2.181-28.167l2.204-28.168l3.776-46.342l0.816-9.967
			l2.383-28.137l2.413-28.15l2.487-28.131l2.57-28.12l2.704-28.097l2.818-28.093l2.847-28.106l2.804-28.133l2.713-28.153
			l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182l2.273-28.188l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.207
			l1.933-28.204l1.879-28.211l1.824-28.217l1.774-28.214l1.729-28.22l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226
			l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219l4.83-92.025l-744.654-1.156l0.001-0.046l28.038,0.042l746.986,1.159
			l-4.83,92.025l-1.501,28.219l-1.515,28.228l-1.534,28.223l-1.561,28.221l-1.578,28.226l-1.616,28.219l-1.646,28.222l-1.683,28.219
			l-1.729,28.22l-1.774,28.214l-1.824,28.217l-1.879,28.211l-1.933,28.204l-1.993,28.207l-2.059,28.203l-2.129,28.196l-2.196,28.195
			l-2.273,28.188l-2.352,28.182l-2.438,28.176l-2.527,28.172l-2.62,28.157l-2.713,28.153l-2.804,28.133l-2.847,28.106l-2.818,28.093
			l-2.704,28.097l-2.57,28.12l-2.487,28.131l-2.413,28.15l-2.383,28.137l-0.816,9.967l-3.776,46.342l-2.204,28.168l-2.181,28.167
			l-2.148,28.17l-1.494,19.944l-2.71,36.409l-2.073,28.179l-2.053,28.183l-2.035,28.179l-2.009,28.184l-1.987,28.181l-1.959,28.185
			l-1.935,28.185l-1.905,28.187l-1.872,28.191l-1.846,28.19l-1.808,28.193l-1.772,28.196l-1.737,28.196l-1.695,28.2l-1.659,28.199
			l-1.615,28.202l-1.568,28.206l-1.528,28.206l-1.481,28.203l-1.414,28.188l-10.025,225.818l-8.971,225.919l-38.285,910.214h-30.372
			l38.285-910.214L3026.687,3325.34z" style="fill:${snap.designColorPant3Opt}"></path>
	</g><g class="field-id-78990038 field-type-custom" field-key="brand"></g></g></svg>`,
    },
    {
      id: 4,
      colors: 1,
      colorNames: ["Line Color"],
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4535" height="4535" viewBox="0 0 4535 4535"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><g id="_x30_01"> 
		<path id="Waistrband" fill="${snap.colorPantWaistOpt}" d="M725.784,1007.794V880.419h2820.035v127.375H725.784z" style="fill: ${snap.colorPantWaistOpt}"></path>
		<path id="Base" fill="${snap.colorPantBaseOpt}" d="M452.331,4461.614l-16.706-174.907l-16.216-168.56l-16.465-168.529l-16.851-168.485
			l-17.353-168.426l-17.993-168.358l-15.623-140.221l-3.134-28.046l-16.367-140.128l-3.284-28.027l-3.372-28.001l-3.404-28.01
			l-3.455-28.001l-3.505-27.99l-3.558-27.985l-3.635-27.967l-3.703-27.964l-3.793-27.944l-3.883-27.932l-3.978-27.916l-4.091-27.899
			l-4.203-27.881l-4.332-27.857l-4.479-27.826l-4.62-27.806l-4.783-27.776l-4.962-27.737l-5.143-27.705l-5.338-27.66l-5.55-27.619
			l-5.774-27.566l-6.006-27.52l-6.253-27.458l-6.52-27.389l-6.791-27.323l-7.071-27.249l-7.381-27.16l-7.687-27.069l-8.01-26.976
			l-8.325-26.882l-8.616-26.798l-8.886-26.712l-9.118-26.65l-9.32-26.584l-9.495-26.528l-9.659-26.477l-22.336-60.434l34.733-7.742
			l26.567-6.646l26.019-7.965l25.274-9.806l24.295-11.844l22.997-14.061l21.372-16.325l19.356-18.56l16.864-20.741l13.901-22.816
			l10.955-24.508l8.399-25.653l6.293-26.406l4.61-26.873l3.37-27.167l2.423-27.373l1.927-27.523l1.725-27.559l1.594-27.58
			l1.462-27.589l1.351-27.604l1.241-27.603l1.146-27.612l1.061-27.619l0.983-27.626l0.916-27.628l0.861-27.637l0.816-27.638
			l0.774-27.639l0.749-27.644l1.65-62.571l907.197,59.203l-4.387,63.517l-3.942,56.512l-1.996,28.253l-2.018,28.254l-2.046,28.25
			l-2.073,28.254l-2.115,28.25l-2.15,28.249l-2.195,28.245l-2.247,28.244l-2.295,28.237l-2.354,28.24l-2.419,28.233l-2.487,28.224
			l-2.564,28.227l-2.636,28.213l-2.714,28.213l-2.804,28.202l-2.891,28.193l-2.986,28.185l-3.083,28.178l-3.186,28.165
			l-3.291,28.156l-3.404,28.141l-3.519,28.132l-3.636,28.114l-3.764,28.104l-3.89,28.084l-4.032,28.065l-3.99,28.026l-3.738,28.016
			l-3.494,28.039l-3.328,28.085l-3.194,28.104l-3.1,28.127l-3.06,28.142l-21.164,197.103l-2.959,28.149l-20.17,197.193
			l-2.679,28.146l-15.748,169.178l-2.599,28.191l-18.32,225.744l-16.58,225.904l-14.016,197.726l-1.977,28.247l-1.94,28.244
			l-13.188,197.788l-1.818,28.248l-12.366,197.844l-1.723,28.255l-12.356,204.459H452.331z M2525.542,4461.505l-47.677-548.365
			l-12.169-140.479l-17.835-206.636l-11.23-130.527l-13.743-160.416l-20.707-242.928l-3.269-38.054l-2.444-28.092l-2.471-28.083
			l-2.508-28.08l-2.542-28.076l-2.598-28.068l-2.647-28.062l-2.716-28.056l-2.769-28.05l-2.851-28.032l-2.923-28.032l-3.015-28.016
			l-3.099-28.01l-3.195-27.994l-3.303-27.981l-3.406-27.968l-3.516-27.955l-3.644-27.93l-3.764-27.921l-3.892-27.894l-4.031-27.878
			l-4.176-27.854l-4.316-27.833l-4.47-27.801l-4.638-27.776l-4.796-27.747l-4.968-27.715l-5.122-27.694l-5.268-27.67l-5.381-27.652
			l-5.496-27.631l-5.565-27.627l-5.63-27.616l-7.324-35.644l-5.152-25.003l29.726-13.887l22.965-13.398l19.666-17.176l15.615-21.092
			l12.253-23.635l9.549-25.116l7.268-26.01l5.283-26.595l3.708-26.993l2.605-27.234l1.832-27.382l1.34-27.499l1.232-27.543
			l1.04-27.535l0.957-27.564l0.859-27.562l0.784-27.572l0.711-27.572l0.645-27.578l0.59-27.583l0.544-27.583l0.503-27.586
			l0.471-27.586l0.449-27.596l0.97-62.092l775.02,1.2l-4.832,92.023l-1.942,36.472l-1.072,19.97l-1.534,28.223l-1.557,28.224
			l-1.582,28.223l-1.615,28.219l-1.647,28.22l-1.683,28.221l-1.729,28.22l-1.772,28.214l-1.824,28.211l-1.874,28.211l-1.937,28.208
			l-1.992,28.204l-2.061,28.202l-2.123,28.19l-2.2,28.196l-2.275,28.186l-2.35,28.181l-2.436,28.173l-2.525,28.171l-2.618,28.164
			l-2.715,28.15l-2.804,28.144l-2.854,28.129l-2.819,28.109l-2.706,28.102l-2.566,28.105l-2.484,28.132l-2.415,28.137l-2.386,28.154
			l-2.97,36.371l-1.616,19.934l-2.208,28.157l-2.182,28.17l-2.144,28.166l-2.114,28.172l-2.094,28.178l-2.071,28.178l-2.054,28.182
			l-2.032,28.18l-2.008,28.184l-1.991,28.185l-1.959,28.187l-1.936,28.184l-1.904,28.19l-1.872,28.187l-1.845,28.196l-1.81,28.19
			l-1.772,28.193l-1.736,28.199l-1.696,28.198l-1.657,28.201l-1.615,28.201l-1.568,28.207l-1.528,28.207l-1.481,28.209
			l-1.414,28.209l-10.025,225.815l-8.968,225.867l-38.286,910.23H2525.542z M1505.784,4461.473l-38.285-910.214l-8.971-225.919
			l-10.025-225.818l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2
			l-1.737-28.196l-1.772-28.196l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.185l-1.959-28.185l-1.987-28.181
			l-2.009-28.184l-2.035-28.179l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168
			l-3.776-46.342l-0.816-9.967l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106
			l-2.804-28.133l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196
			l-2.059-28.203l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222
			l-1.616-28.219l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042
			l0.963,62.098l0.448,27.591l0.473,27.593l0.504,27.588l0.543,27.584l0.589,27.586l0.644,27.581l0.717,27.572l0.779,27.576
			l0.86,27.565l0.956,27.563l1.044,27.554l1.236,27.528l1.333,27.472l1.831,27.388l2.607,27.223l3.71,26.988l5.288,26.602
			l7.266,26.011l9.542,25.115l12.252,23.633l15.616,21.092l19.666,17.18l22.969,13.401l29.725,13.887l-6.808,33.032l-5.673,27.623
			l-5.629,27.621l-5.566,27.629l-5.499,27.643l-5.376,27.653l-5.269,27.676l-5.127,27.692l-4.968,27.719l-4.794,27.748
			l-4.637,27.778l-4.465,27.798l-4.32,27.831l-4.176,27.854l-4.031,27.873l-3.892,27.896l-3.764,27.918l-3.643,27.933l-3.514,27.949
			l-3.407,27.967l-3.303,27.979l-3.189,27.993l-3.104,28.009l-3.009,28.013l-2.922,28.026l-2.855,28.042l-2.769,28.041
			l-2.714,28.057l-2.643,28.059l-2.598,28.07l-2.546,28.072l-2.507,28.08l-2.472,28.082l-2.443,28.086l-2.416,28.092l-6.341,74.327
			l-17.621,206.657l-12.041,140.488l-17.642,204.856l-26.036,300.884l-44.532,512.098H1505.784z M3499.119,4461.432l-12.355-204.458
			l-1.723-28.264l-12.367-197.846l-1.817-28.248l-13.187-197.787l-1.94-28.24l-1.976-28.245l-14.019-197.735l-16.578-225.94
			l-18.327-225.767l-2.587-28.129l-15.754-169.18l-2.685-28.179l-20.165-197.165l-2.961-28.146l-21.162-197.094l-3.056-28.146
			l-3.106-28.135l-3.193-28.123l-3.325-28.089l-3.5-28.06l-3.738-28.013l-3.979-27.975l-4.024-28.027l-3.896-28.086l-3.759-28.101
			l-3.642-28.116l-3.519-28.133l-3.404-28.146l-3.292-28.154l-3.187-28.165l-3.081-28.18l-2.987-28.185l-2.892-28.196l-2.804-28.206
			l-2.715-28.212l-2.636-28.219l-2.566-28.22l-2.485-28.232l-2.419-28.234l-2.355-28.236l-2.295-28.242l-2.246-28.242l-2.195-28.253
			l-2.151-28.246l-2.109-28.254l-2.076-28.252l-2.048-28.25l-2.019-28.257l-1.995-28.259l-1.978-28.251l-6.353-91.773
			l869.018-56.722l38.18-2.492l1.65,62.578l0.748,27.651l0.776,27.642l0.815,27.64l0.862,27.633l0.915,27.636l0.983,27.625
			l1.061,27.621l1.146,27.62l1.241,27.607l1.351,27.604l1.463,27.596l1.596,27.578l1.726,27.577l1.922,27.463l2.426,27.387
			l3.369,27.164l4.609,26.872l6.302,26.406l8.393,25.654l10.953,24.503l13.9,22.814l16.863,20.746l19.361,18.564l21.371,16.32
			l22.994,14.062l24.301,11.847l25.263,9.803l26.031,7.968l26.583,6.645l34.711,7.743l-22.338,60.459l-9.67,26.492l-9.494,26.528
			l-9.324,26.592l-9.119,26.652l-8.883,26.725l-8.621,26.801l-8.327,26.888l-8.008,26.979l-7.688,27.07l-7.379,27.161l-7.067,27.241
			l-6.79,27.321l-6.519,27.388l-6.251,27.451l-6.004,27.511l-5.775,27.569l-5.549,27.613l-5.337,27.66l-5.143,27.701l-4.961,27.737
			l-4.781,27.768l-4.618,27.804l-4.476,27.828l-4.329,27.854l-4.204,27.877l-4.095,27.895l-3.979,27.911l-3.883,27.933
			l-3.792,27.945l-3.702,27.956l-3.636,27.973l-3.558,27.98l-3.504,27.99l-3.454,27.999l-3.403,28.004l-3.374,28.011
			l-19.646,168.173l-18.762,168.263l-17.99,168.351l-17.354,168.423l-16.846,168.477l-16.469,168.523l-16.217,168.556l-16.703,174.9
			H3499.119z" style="fill: ${snap.colorPantBaseOpt}"></path>
		<path id="C1" fill="${snap.designColorPant1Opt}" d="M1326.295,1372.858l92.294-0.138l35.232,704.696l-73.751,74.833l-1.07-10.56l-2.804-28.133
			l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203
			l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219
			l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219L1326.295,1372.858z M3181.864,1372.89l-92.294-0.138
			l-35.232,704.696l73.751,74.833l1.07-10.56l2.804-28.133l2.713-28.153l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182
			l2.273-28.188l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.206l1.933-28.204l1.879-28.212l1.824-28.217l1.774-28.214
			l1.729-28.22l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219
			L3181.864,1372.89z M3002.375,4461.505l32.795-779.689l-83.83,65.41l-55.244,714.279H3002.375z M1612.063,4461.505
			l-55.244-714.279l-83.83-65.41l32.795,779.689H1612.063z" style="fill: ${snap.designColorPant1Opt}"></path>
	</g><g class="field-id-78990038 field-type-custom" field-key="brand"></g></g></svg>`,
    },
    {
      id: 5,
      colors: 2,
      colorNames: ["Design Color 1", "Design Color 2"],
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4535" height="4535" viewBox="0 0 4535 4535"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><g id="_x30_01"> 
		<path id="Waistrband" d="M725.784,1007.794V880.419h2820.035v127.375H725.784z" style="fill: ${snap.colorPantWaistOpt}"></path>
		<path id="Base" d="M452.331,4461.614l-16.706-174.907l-16.216-168.56l-16.465-168.529l-16.851-168.485l-17.353-168.426
			l-17.993-168.358l-15.623-140.221l-3.134-28.046l-16.367-140.128l-3.284-28.027l-3.372-28.001l-3.404-28.01l-3.455-28.001
			l-3.505-27.99l-3.558-27.985l-3.635-27.967l-3.703-27.964l-3.793-27.944l-3.883-27.932l-3.978-27.916l-4.091-27.899l-4.203-27.881
			l-4.332-27.857l-4.479-27.826l-4.62-27.806l-4.783-27.776l-4.962-27.737l-5.143-27.705l-5.338-27.66l-5.55-27.619l-5.774-27.566
			l-6.006-27.52l-6.253-27.458l-6.52-27.389l-6.791-27.323l-7.071-27.249l-7.381-27.16l-7.687-27.069l-8.01-26.976l-8.325-26.882
			l-8.616-26.798l-8.886-26.712l-9.118-26.65l-9.32-26.584l-9.495-26.528l-9.659-26.477l-22.336-60.434l34.733-7.742l26.567-6.646
			l26.019-7.965l25.274-9.806l24.295-11.844l22.997-14.061l21.372-16.325l19.356-18.56l16.864-20.741l13.901-22.816l10.955-24.508
			l8.399-25.653l6.293-26.406l4.61-26.873l3.37-27.167l2.423-27.373l1.927-27.523l1.725-27.559l1.594-27.58l1.462-27.589
			l1.351-27.604l1.241-27.603l1.146-27.612l1.061-27.619l0.983-27.626l0.916-27.628l0.861-27.637l0.816-27.638l0.774-27.639
			l0.749-27.644l1.65-62.571l907.197,59.203l-4.387,63.517l-3.942,56.512l-1.996,28.253l-2.018,28.254l-2.046,28.25l-2.073,28.254
			l-2.115,28.25l-2.15,28.249l-2.195,28.245l-2.247,28.244l-2.295,28.237l-2.354,28.24l-2.419,28.233l-2.487,28.224l-2.564,28.227
			l-2.636,28.213l-2.714,28.213l-2.804,28.202l-2.891,28.193l-2.986,28.185l-3.083,28.178l-3.186,28.165l-3.291,28.156
			l-3.404,28.141l-3.519,28.132l-3.636,28.114l-3.764,28.104l-3.89,28.084l-4.032,28.065l-3.99,28.026l-3.738,28.016l-3.494,28.039
			l-3.328,28.085l-3.194,28.104l-3.1,28.127l-3.06,28.142l-21.164,197.103l-2.959,28.149l-20.17,197.193l-2.679,28.146
			l-15.748,169.178l-2.599,28.191l-18.32,225.744l-16.58,225.904l-14.016,197.726l-1.977,28.247l-1.94,28.244l-13.188,197.788
			l-1.818,28.248l-12.366,197.844l-1.723,28.255l-12.356,204.459H452.331z M2525.542,4461.505l-47.677-548.365l-12.169-140.479
			l-17.835-206.636l-11.23-130.527l-13.743-160.416l-20.707-242.928l-3.269-38.054l-2.444-28.092l-2.471-28.083l-2.508-28.08
			l-2.542-28.076l-2.598-28.068l-2.647-28.062l-2.716-28.056l-2.769-28.05l-2.851-28.032l-2.923-28.032l-3.015-28.016l-3.099-28.01
			l-3.195-27.994l-3.303-27.981l-3.406-27.968l-3.516-27.955l-3.644-27.93l-3.764-27.921l-3.892-27.894l-4.031-27.878l-4.176-27.854
			l-4.316-27.833l-4.47-27.801l-4.638-27.776l-4.796-27.747l-4.968-27.715l-5.122-27.694l-5.268-27.67l-5.381-27.652l-5.496-27.631
			l-5.565-27.627l-5.63-27.616l-7.324-35.644l-5.152-25.003l29.726-13.887l22.965-13.398l19.666-17.176l15.615-21.092l12.253-23.635
			l9.549-25.116l7.268-26.01l5.283-26.595l3.708-26.993l2.605-27.234l1.832-27.382l1.34-27.499l1.232-27.543l1.04-27.535
			l0.957-27.564l0.859-27.562l0.784-27.572l0.711-27.572l0.645-27.578l0.59-27.583l0.544-27.583l0.503-27.586l0.471-27.586
			l0.449-27.596l0.97-62.092l775.02,1.2l-4.832,92.023l-1.942,36.472l-1.072,19.97l-1.534,28.223l-1.557,28.224l-1.582,28.223
			l-1.615,28.219l-1.647,28.22l-1.683,28.221l-1.729,28.22l-1.772,28.214l-1.824,28.211l-1.874,28.211l-1.937,28.208l-1.992,28.204
			l-2.061,28.202l-2.123,28.19l-2.2,28.196l-2.275,28.186l-2.35,28.181l-2.436,28.173l-2.525,28.171l-2.618,28.164l-2.715,28.15
			l-2.804,28.144l-2.854,28.129l-2.819,28.109l-2.706,28.102l-2.566,28.105l-2.484,28.132l-2.415,28.137l-2.386,28.154l-2.97,36.371
			l-1.616,19.934l-2.208,28.157l-2.182,28.17l-2.144,28.166l-2.114,28.172l-2.094,28.178l-2.071,28.178l-2.054,28.182l-2.032,28.18
			l-2.008,28.184l-1.991,28.185l-1.959,28.187l-1.936,28.184l-1.904,28.19l-1.872,28.187l-1.845,28.196l-1.81,28.19l-1.772,28.193
			l-1.736,28.199l-1.696,28.198l-1.657,28.201l-1.615,28.201l-1.568,28.207l-1.528,28.207l-1.481,28.209l-1.414,28.209
			l-10.025,225.815l-8.968,225.867l-38.286,910.23H2525.542z M1505.784,4461.473l-38.285-910.214l-8.971-225.919l-10.025-225.818
			l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2l-1.737-28.196l-1.772-28.196
			l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.185l-1.959-28.185l-1.987-28.181l-2.009-28.184l-2.035-28.179
			l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168l-3.776-46.342l-0.816-9.967
			l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106l-2.804-28.133l-2.713-28.153
			l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203l-1.993-28.207
			l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219l-1.578-28.226
			l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042l0.963,62.098l0.448,27.591
			l0.473,27.593l0.504,27.588l0.543,27.584l0.589,27.586l0.644,27.581l0.717,27.572l0.779,27.576l0.86,27.565l0.956,27.563
			l1.044,27.554l1.236,27.528l1.333,27.472l1.831,27.388l2.607,27.223l3.71,26.988l5.288,26.602l7.266,26.011l9.542,25.115
			l12.252,23.633l15.616,21.092l19.666,17.18l22.969,13.401l29.725,13.887l-6.808,33.032l-5.673,27.623l-5.629,27.621l-5.566,27.629
			l-5.499,27.643l-5.376,27.653l-5.269,27.676l-5.127,27.692l-4.968,27.719l-4.794,27.748l-4.637,27.778l-4.465,27.798l-4.32,27.831
			l-4.176,27.854l-4.031,27.873l-3.892,27.896l-3.764,27.918l-3.643,27.933l-3.514,27.949l-3.407,27.967l-3.303,27.979
			l-3.189,27.993l-3.104,28.009l-3.009,28.013l-2.922,28.026l-2.855,28.042l-2.769,28.041l-2.714,28.057l-2.643,28.059l-2.598,28.07
			l-2.546,28.072l-2.507,28.08l-2.472,28.082l-2.443,28.086l-2.416,28.092l-6.341,74.327l-17.621,206.657l-12.041,140.488
			l-17.642,204.856l-26.036,300.884l-44.532,512.098H1505.784z M3499.119,4461.432l-12.355-204.458l-1.723-28.264l-12.367-197.846
			l-1.817-28.248l-13.187-197.787l-1.94-28.24l-1.976-28.245l-14.019-197.735l-16.578-225.94l-18.327-225.767l-2.587-28.129
			l-15.754-169.18l-2.685-28.179l-20.165-197.165l-2.961-28.146l-21.162-197.094l-3.056-28.146l-3.106-28.135l-3.193-28.123
			l-3.325-28.089l-3.5-28.06l-3.738-28.013l-3.979-27.975l-4.024-28.027l-3.896-28.086l-3.759-28.101l-3.642-28.116l-3.519-28.133
			l-3.404-28.146l-3.292-28.154l-3.187-28.165l-3.081-28.18l-2.987-28.185l-2.892-28.196l-2.804-28.206l-2.715-28.212l-2.636-28.219
			l-2.566-28.22l-2.485-28.232l-2.419-28.234l-2.355-28.236l-2.295-28.242l-2.246-28.242l-2.195-28.253l-2.151-28.246l-2.109-28.254
			l-2.076-28.252l-2.048-28.25l-2.019-28.257l-1.995-28.259l-1.978-28.251l-6.353-91.773l869.018-56.722l38.18-2.492l1.65,62.578
			l0.748,27.651l0.776,27.642l0.815,27.64l0.862,27.633l0.915,27.636l0.983,27.625l1.061,27.621l1.146,27.62l1.241,27.607
			l1.351,27.604l1.463,27.596l1.596,27.578l1.726,27.577l1.922,27.463l2.426,27.387l3.369,27.164l4.609,26.872l6.302,26.406
			l8.393,25.654l10.953,24.503l13.9,22.814l16.863,20.746l19.361,18.564l21.371,16.32l22.994,14.062l24.301,11.847l25.263,9.803
			l26.031,7.968l26.583,6.645l34.711,7.743l-22.338,60.459l-9.67,26.492l-9.494,26.528l-9.324,26.592l-9.119,26.652l-8.883,26.725
			l-8.621,26.801l-8.327,26.888l-8.008,26.979l-7.688,27.07l-7.379,27.161l-7.067,27.241l-6.79,27.321l-6.519,27.388l-6.251,27.451
			l-6.004,27.511l-5.775,27.569l-5.549,27.613l-5.337,27.66l-5.143,27.701l-4.961,27.737l-4.781,27.768l-4.618,27.804l-4.476,27.828
			l-4.329,27.854l-4.204,27.877l-4.095,27.895l-3.979,27.911l-3.883,27.933l-3.792,27.945l-3.702,27.956l-3.636,27.973l-3.558,27.98
			l-3.504,27.99l-3.454,27.999l-3.403,28.004l-3.374,28.011l-19.646,168.173l-18.762,168.263l-17.99,168.351l-17.354,168.423
			l-16.846,168.477l-16.469,168.523l-16.217,168.556l-16.703,174.9H3499.119z" style="fill: ${snap.colorPantBaseOpt}"></path>
		<path id="C2_1_" d="M1485.87,3551.259l38.285,910.214h-30.372l-38.285-910.214l-8.971-225.919l-10.025-225.818l-1.414-28.188
			l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2l-1.737-28.196l-1.772-28.196l-1.808-28.193
			l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.185l-1.959-28.185l-1.987-28.181l-2.009-28.184l-2.035-28.179l-2.053-28.183
			l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168l-3.776-46.342l-0.816-9.967l-2.383-28.137
			l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106l-2.804-28.133l-2.713-28.153l-2.62-28.157
			l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203l-1.993-28.207
			l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219l-1.578-28.226
			l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042l0.001,0.046l-744.654,1.156
			l4.83,92.025l1.501,28.219l1.515,28.228l1.534,28.223l1.561,28.221l1.578,28.226l1.616,28.219l1.646,28.222l1.683,28.219
			l1.729,28.22l1.774,28.214l1.824,28.217l1.879,28.211l1.933,28.204l1.993,28.207l2.059,28.203l2.129,28.196l2.196,28.195
			l2.273,28.188l2.352,28.182l2.438,28.176l2.527,28.172l2.62,28.157l2.713,28.153l2.804,28.133l2.847,28.106l2.818,28.093
			l2.704,28.097l2.57,28.12l2.487,28.131l2.413,28.15l2.383,28.137l0.816,9.967l3.776,46.342l2.204,28.168l2.181,28.167l2.148,28.17
			l1.494,19.944l2.71,36.409l2.073,28.179l2.053,28.183l2.035,28.179l2.009,28.184l1.987,28.181l1.959,28.185l1.935,28.185
			l1.905,28.187l1.872,28.191l1.846,28.19l1.808,28.193l1.772,28.196l1.737,28.196l1.695,28.2l1.659,28.199l1.615,28.202
			l1.568,28.206l1.528,28.206l1.481,28.203l1.414,28.188l10.025,225.818L1485.87,3551.259z M3026.687,3325.34l10.025-225.818
			l1.414-28.188l1.481-28.203l1.528-28.206l1.568-28.206l1.615-28.202l1.659-28.199l1.695-28.2l1.737-28.196l1.772-28.196
			l1.808-28.193l1.846-28.19l1.872-28.191l1.905-28.187l1.935-28.185l1.959-28.185l1.987-28.181l2.009-28.184l2.035-28.179
			l2.053-28.183l2.073-28.179l2.71-36.409l1.494-19.944l2.148-28.17l2.181-28.167l2.204-28.168l3.776-46.342l0.816-9.967
			l2.383-28.137l2.413-28.15l2.487-28.131l2.57-28.12l2.704-28.097l2.818-28.093l2.847-28.106l2.804-28.133l2.713-28.153
			l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182l2.273-28.188l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.207
			l1.933-28.204l1.879-28.211l1.824-28.217l1.774-28.214l1.729-28.22l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226
			l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219l4.83-92.025l-744.654-1.156l0.001-0.046l28.038,0.042l746.986,1.159
			l-4.83,92.025l-1.501,28.219l-1.515,28.228l-1.534,28.223l-1.561,28.221l-1.578,28.226l-1.616,28.219l-1.646,28.222l-1.683,28.219
			l-1.729,28.22l-1.774,28.214l-1.824,28.217l-1.879,28.211l-1.933,28.204l-1.993,28.207l-2.059,28.203l-2.129,28.196l-2.196,28.195
			l-2.273,28.188l-2.352,28.182l-2.438,28.176l-2.527,28.172l-2.62,28.157l-2.713,28.153l-2.804,28.133l-2.847,28.106l-2.818,28.093
			l-2.704,28.097l-2.57,28.12l-2.487,28.131l-2.413,28.15l-2.383,28.137l-0.816,9.967l-3.776,46.342l-2.204,28.168l-2.181,28.167
			l-2.148,28.17l-1.494,19.944l-2.71,36.409l-2.073,28.179l-2.053,28.183l-2.035,28.179l-2.009,28.184l-1.987,28.181l-1.959,28.185
			l-1.935,28.185l-1.905,28.187l-1.872,28.191l-1.846,28.19l-1.808,28.193l-1.772,28.196l-1.737,28.196l-1.695,28.2l-1.659,28.199
			l-1.615,28.202l-1.568,28.206l-1.528,28.206l-1.481,28.203l-1.414,28.188l-10.025,225.818l-8.971,225.919l-38.285,910.214h-30.372
			l38.285-910.214L3026.687,3325.34z" style="fill: ${snap.designColorPant1Opt}"></path>
		<g id="C1" style="fill:  ${snap.designColorPant2Opt}">
			<g style="fill:  ${snap.designColorPant2Opt}">
				<path fill=" ${snap.designColorPant2Opt}" d="M3040.661,3551.276l-38.286,910.23H2892.76V3657.41l149.628-149.628L3040.661,3551.276z" style="fill: ${snap.designColorPant2Opt}"></path>
			</g>
			<path fill=" ${snap.designColorPant2Opt}" d="M1465.662,3507.782l149.628,149.628v804.095h-109.615l-38.286-910.23L1465.662,3507.782z" style="fill: ${snap.designColorPant2Opt}"></path>
		</g>
	</g><g class="field-id-78990038 field-type-custom" field-key="brand"></g></g></svg>`,
    },
    {
      id: 6,
      colors: 1,
      colorNames: ["Line Color"],
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4535" height="4535" viewBox="0 0 4535 4535"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><g id="_x30_01">
		<path id="Waistrband" fill=" ${snap.colorPantWaistOpt}" d="M725.784,1007.794V880.419h2820.035v127.375H725.784z" style="fill:${snap.colorPantWaistOpt}"></path>
		<path id="Base" fill="${snap.colorPantBaseOpt}" d="M452.331,4461.614l-16.706-174.907l-16.216-168.56l-16.465-168.529l-16.851-168.485
			l-17.353-168.426l-17.993-168.358l-15.623-140.221l-3.134-28.046l-16.367-140.128l-3.284-28.027l-3.372-28.001l-3.404-28.01
			l-3.455-28.001l-3.505-27.99l-3.558-27.985l-3.635-27.967l-3.703-27.964l-3.793-27.944l-3.883-27.932l-3.978-27.916l-4.091-27.899
			l-4.203-27.881l-4.332-27.857l-4.479-27.826l-4.62-27.806l-4.783-27.776l-4.962-27.737l-5.143-27.705l-5.338-27.66l-5.55-27.619
			l-5.774-27.566l-6.006-27.52l-6.253-27.458l-6.52-27.389l-6.791-27.323l-7.071-27.249l-7.381-27.16l-7.687-27.069l-8.01-26.976
			l-8.325-26.882l-8.616-26.798l-8.886-26.712l-9.118-26.65l-9.32-26.584l-9.495-26.528l-9.659-26.477l-22.336-60.434l34.733-7.742
			l26.567-6.646l26.019-7.965l25.274-9.806l24.295-11.844l22.997-14.061l21.372-16.325l19.356-18.56l16.864-20.741l13.901-22.816
			l10.955-24.508l8.399-25.653l6.293-26.406l4.61-26.873l3.37-27.167l2.423-27.373l1.927-27.523l1.725-27.559l1.594-27.58
			l1.462-27.589l1.351-27.604l1.241-27.603l1.146-27.612l1.061-27.619l0.983-27.626l0.916-27.628l0.861-27.637l0.816-27.638
			l0.774-27.639l0.749-27.644l1.65-62.571l907.197,59.203l-4.387,63.517l-3.942,56.512l-1.996,28.253l-2.018,28.254l-2.046,28.25
			l-2.073,28.254l-2.115,28.25l-2.15,28.249l-2.195,28.245l-2.247,28.244l-2.295,28.237l-2.354,28.24l-2.419,28.233l-2.487,28.224
			l-2.564,28.227l-2.636,28.213l-2.714,28.213l-2.804,28.202l-2.891,28.193l-2.986,28.185l-3.083,28.178l-3.186,28.165
			l-3.291,28.156l-3.404,28.141l-3.519,28.132l-3.636,28.114l-3.764,28.104l-3.89,28.084l-4.032,28.065l-3.99,28.026l-3.738,28.016
			l-3.494,28.039l-3.328,28.085l-3.194,28.104l-3.1,28.127l-3.06,28.142l-21.164,197.103l-2.959,28.149l-20.17,197.193
			l-2.679,28.146l-15.748,169.178l-2.599,28.191l-18.32,225.744l-16.58,225.904l-14.016,197.726l-1.977,28.247l-1.94,28.244
			l-13.188,197.788l-1.818,28.248l-12.366,197.844l-1.723,28.255l-12.356,204.459H452.331z M2525.542,4461.505l-47.677-548.365
			l-12.169-140.479l-17.835-206.636l-11.23-130.527l-13.743-160.416l-20.707-242.928l-3.269-38.054l-2.444-28.092l-2.471-28.083
			l-2.508-28.08l-2.542-28.076l-2.598-28.068l-2.647-28.062l-2.716-28.056l-2.769-28.05l-2.851-28.032l-2.923-28.032l-3.015-28.016
			l-3.099-28.01l-3.195-27.994l-3.303-27.981l-3.406-27.968l-3.516-27.955l-3.644-27.93l-3.764-27.921l-3.892-27.894l-4.031-27.878
			l-4.176-27.854l-4.316-27.833l-4.47-27.801l-4.638-27.776l-4.796-27.747l-4.968-27.715l-5.122-27.694l-5.268-27.67l-5.381-27.652
			l-5.496-27.631l-5.565-27.627l-5.63-27.616l-7.324-35.644l-5.152-25.003l29.726-13.887l22.965-13.398l19.666-17.176l15.615-21.092
			l12.253-23.635l9.549-25.116l7.268-26.01l5.283-26.595l3.708-26.993l2.605-27.234l1.832-27.382l1.34-27.499l1.232-27.543
			l1.04-27.535l0.957-27.564l0.859-27.562l0.784-27.572l0.711-27.572l0.645-27.578l0.59-27.583l0.544-27.583l0.503-27.586
			l0.471-27.586l0.449-27.596l0.97-62.092l775.02,1.2l-4.832,92.023l-1.942,36.472l-1.072,19.97l-1.534,28.223l-1.557,28.224
			l-1.582,28.223l-1.615,28.219l-1.647,28.22l-1.683,28.221l-1.729,28.22l-1.772,28.214l-1.824,28.211l-1.874,28.211l-1.937,28.208
			l-1.992,28.204l-2.061,28.202l-2.123,28.19l-2.2,28.196l-2.275,28.186l-2.35,28.181l-2.436,28.173l-2.525,28.171l-2.618,28.164
			l-2.715,28.15l-2.804,28.144l-2.854,28.129l-2.819,28.109l-2.706,28.102l-2.566,28.105l-2.484,28.132l-2.415,28.137l-2.386,28.154
			l-2.97,36.371l-1.616,19.934l-2.208,28.157l-2.182,28.17l-2.144,28.166l-2.114,28.172l-2.094,28.178l-2.071,28.178l-2.054,28.182
			l-2.032,28.18l-2.008,28.184l-1.991,28.185l-1.959,28.187l-1.936,28.184l-1.904,28.19l-1.872,28.187l-1.845,28.196l-1.81,28.19
			l-1.772,28.193l-1.736,28.199l-1.696,28.198l-1.657,28.201l-1.615,28.201l-1.568,28.207l-1.528,28.207l-1.481,28.209
			l-1.414,28.209l-10.025,225.815l-8.968,225.867l-38.286,910.23H2525.542z M1505.784,4461.473l-38.285-910.214l-8.971-225.919
			l-10.025-225.818l-1.414-28.188l-1.481-28.203l-1.528-28.206l-1.568-28.206l-1.615-28.202l-1.659-28.199l-1.695-28.2
			l-1.737-28.196l-1.772-28.196l-1.808-28.193l-1.846-28.19l-1.872-28.191l-1.905-28.187l-1.935-28.185l-1.959-28.185l-1.987-28.181
			l-2.009-28.184l-2.035-28.179l-2.053-28.183l-2.073-28.179l-2.71-36.409l-1.494-19.944l-2.148-28.17l-2.181-28.167l-2.204-28.168
			l-3.776-46.342l-0.816-9.967l-2.383-28.137l-2.413-28.15l-2.487-28.131l-2.57-28.12l-2.704-28.097l-2.818-28.093l-2.847-28.106
			l-2.804-28.133l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196
			l-2.059-28.203l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222
			l-1.616-28.219l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219l-4.83-92.025l746.986-1.159l28.038-0.042
			l0.963,62.098l0.448,27.591l0.473,27.593l0.504,27.588l0.543,27.584l0.589,27.586l0.644,27.581l0.717,27.572l0.779,27.576
			l0.86,27.565l0.956,27.563l1.044,27.554l1.236,27.528l1.333,27.472l1.831,27.388l2.607,27.223l3.71,26.988l5.288,26.602
			l7.266,26.011l9.542,25.115l12.252,23.633l15.616,21.092l19.666,17.18l22.969,13.401l29.725,13.887l-6.808,33.032l-5.673,27.623
			l-5.629,27.621l-5.566,27.629l-5.499,27.643l-5.376,27.653l-5.269,27.676l-5.127,27.692l-4.968,27.719l-4.794,27.748
			l-4.637,27.778l-4.465,27.798l-4.32,27.831l-4.176,27.854l-4.031,27.873l-3.892,27.896l-3.764,27.918l-3.643,27.933l-3.514,27.949
			l-3.407,27.967l-3.303,27.979l-3.189,27.993l-3.104,28.009l-3.009,28.013l-2.922,28.026l-2.855,28.042l-2.769,28.041
			l-2.714,28.057l-2.643,28.059l-2.598,28.07l-2.546,28.072l-2.507,28.08l-2.472,28.082l-2.443,28.086l-2.416,28.092l-6.341,74.327
			l-17.621,206.657l-12.041,140.488l-17.642,204.856l-26.036,300.884l-44.532,512.098H1505.784z M3499.119,4461.432l-12.355-204.458
			l-1.723-28.264l-12.367-197.846l-1.817-28.248l-13.187-197.787l-1.94-28.24l-1.976-28.245l-14.019-197.735l-16.578-225.94
			l-18.327-225.767l-2.587-28.129l-15.754-169.18l-2.685-28.179l-20.165-197.165l-2.961-28.146l-21.162-197.094l-3.056-28.146
			l-3.106-28.135l-3.193-28.123l-3.325-28.089l-3.5-28.06l-3.738-28.013l-3.979-27.975l-4.024-28.027l-3.896-28.086l-3.759-28.101
			l-3.642-28.116l-3.519-28.133l-3.404-28.146l-3.292-28.154l-3.187-28.165l-3.081-28.18l-2.987-28.185l-2.892-28.196l-2.804-28.206
			l-2.715-28.212l-2.636-28.219l-2.566-28.22l-2.485-28.232l-2.419-28.234l-2.355-28.236l-2.295-28.242l-2.246-28.242l-2.195-28.253
			l-2.151-28.246l-2.109-28.254l-2.076-28.252l-2.048-28.25l-2.019-28.257l-1.995-28.259l-1.978-28.251l-6.353-91.773
			l869.018-56.722l38.18-2.492l1.65,62.578l0.748,27.651l0.776,27.642l0.815,27.64l0.862,27.633l0.915,27.636l0.983,27.625
			l1.061,27.621l1.146,27.62l1.241,27.607l1.351,27.604l1.463,27.596l1.596,27.578l1.726,27.577l1.922,27.463l2.426,27.387
			l3.369,27.164l4.609,26.872l6.302,26.406l8.393,25.654l10.953,24.503l13.9,22.814l16.863,20.746l19.361,18.564l21.371,16.32
			l22.994,14.062l24.301,11.847l25.263,9.803l26.031,7.968l26.583,6.645l34.711,7.743l-22.338,60.459l-9.67,26.492l-9.494,26.528
			l-9.324,26.592l-9.119,26.652l-8.883,26.725l-8.621,26.801l-8.327,26.888l-8.008,26.979l-7.688,27.07l-7.379,27.161l-7.067,27.241
			l-6.79,27.321l-6.519,27.388l-6.251,27.451l-6.004,27.511l-5.775,27.569l-5.549,27.613l-5.337,27.66l-5.143,27.701l-4.961,27.737
			l-4.781,27.768l-4.618,27.804l-4.476,27.828l-4.329,27.854l-4.204,27.877l-4.095,27.895l-3.979,27.911l-3.883,27.933
			l-3.792,27.945l-3.702,27.956l-3.636,27.973l-3.558,27.98l-3.504,27.99l-3.454,27.999l-3.403,28.004l-3.374,28.011
			l-19.646,168.173l-18.762,168.263l-17.99,168.351l-17.354,168.423l-16.846,168.477l-16.469,168.523l-16.217,168.556l-16.703,174.9
			H3499.119z" style="fill:${snap.colorPantBaseOpt}"></path>
		<path id="C1_3_" fill="${snap.designColorPant1Opt}" d="M1326.295,1372.858l92.294-0.138l35.232,704.696l-73.751,74.833l-1.07-10.56l-2.804-28.133
			l-2.713-28.153l-2.62-28.157l-2.527-28.172l-2.438-28.176l-2.352-28.182l-2.273-28.188l-2.196-28.195l-2.129-28.196l-2.059-28.203
			l-1.993-28.207l-1.933-28.204l-1.879-28.211l-1.824-28.217l-1.774-28.214l-1.729-28.22l-1.683-28.219l-1.646-28.222l-1.616-28.219
			l-1.578-28.226l-1.561-28.221l-1.534-28.223l-1.515-28.228l-1.501-28.219L1326.295,1372.858z M3181.864,1372.89l-92.294-0.138
			l-35.232,704.696l73.751,74.833l1.07-10.56l2.804-28.133l2.713-28.153l2.62-28.157l2.527-28.172l2.438-28.176l2.352-28.182
			l2.273-28.188l2.196-28.195l2.129-28.196l2.059-28.203l1.993-28.206l1.933-28.204l1.879-28.212l1.824-28.217l1.774-28.214
			l1.729-28.22l1.683-28.219l1.646-28.222l1.616-28.219l1.578-28.226l1.561-28.221l1.534-28.223l1.515-28.228l1.501-28.219
			L3181.864,1372.89z M1239.468,1830.413l2.487-28.224l2.419-28.233l2.354-28.24l2.295-28.237l2.247-28.244l2.195-28.245
			l2.15-28.249l2.115-28.25l2.073-28.254l2.046-28.25l2.018-28.254l1.996-28.253l3.942-56.512l4.259-61.655l-89.461-5.838
			l-13.869,159.33l-2.43,28.154l-2.418,28.165l-2.399,28.163l-2.372,28.165l-2.355,28.172l-2.317,28.169l-2.287,28.177l-2.251,28.18
			l-2.206,28.187l-2.16,28.187l-2.111,28.197l-2.055,28.199l-2.001,28.199l-1.942,28.21l-1.876,28.215l-1.806,28.218l-1.739,28.227
			l-1.662,28.231l-1.583,28.235l-1.497,28.242l-1.408,28.25l-1.314,28.248l-1.221,28.257l-1.129,28.25l-0.406,10.606l81.345-62.278
			l1.26-9.741l0.124-0.991l-0.124-0.88l3.519-28.132l3.404-28.141l3.291-28.156l1.958-17.306l18.817-185.263L1239.468,1830.413z
			 M3269.305,1853.237l18.817,185.263l1.958,17.306l3.291,28.156l3.404,28.141l3.519,28.132l-0.124,0.88l0.124,0.991l1.26,9.741
			l81.345,62.278l-0.406-10.606l-1.129-28.25l-1.221-28.257l-1.314-28.248l-1.408-28.25l-1.497-28.242l-1.583-28.235l-1.662-28.231
			l-1.739-28.227l-1.806-28.218l-1.876-28.215l-1.942-28.21l-2.001-28.199l-2.055-28.199l-2.111-28.197l-2.16-28.187l-2.206-28.187
			l-2.251-28.18l-2.287-28.177l-2.317-28.169l-2.355-28.172l-2.372-28.165l-2.399-28.163l-2.418-28.165l-2.43-28.154l-13.869-159.33
			l-89.461,5.838l4.259,61.655l3.942,56.512l1.996,28.253l2.018,28.254l2.046,28.25l2.073,28.254l2.115,28.25l2.15,28.249
			l2.195,28.245l2.247,28.244l2.295,28.237l2.354,28.24l2.419,28.233l2.487,28.224L3269.305,1853.237z" style="fill:${snap.designColorPant1Opt}"></path>
	</g><g class="field-id-78990038 field-type-custom" field-key="brand"></g></g></svg>`,
    },
  ];
  const stateString = JSON.stringify(snap);
  const svgStr = designs.find((design) => design.id === snap.id);
  const svgString = svgStr.svg;
  state.designColorsPant = svgStr.colors;
  const svgDataURL = `data:image/svg+xml;base64,${btoa(svgString)}`;
  useEffect(() => {
    // Load SVG texture
    const shirtTextureLoader = new THREE.TextureLoader();
    shirtTextureLoader.load(svgDataURL, (texture) => {
      texture.flipY = false;
      setShirtTexture(texture);
    });
  }, [svgDataURL]);

  useEffect(() => {
    if (svgStr && svgStr.colorNames && svgStr.colorNames.length > 0) {
      svgStr.colorNames.forEach((colorName, index) => {
        state[`designColorPant${index + 1}Name`] = colorName;
      });
    }
  }, [svgStr]);

  // Define materials to apply texture
  const [logoTexture, setLogoTexture] = useState(null);

  useEffect(() => {
    const materialsToApplyTexture = [
      "fab2_FRONT_535500",
      "fab1_FRONT_3574",
      "fab1_BACK_3574",
    ];
    if (!shirtTexture) return;

    // Apply texture to materials
    Object.values(nodes).forEach((mesh) => {
      if (
        mesh.material &&
        materialsToApplyTexture.includes(mesh.material.name)
      ) {
        console.log(mesh);

        mesh.material.map = shirtTexture;

        mesh.material.needsUpdate = true; // Ensure material updates
        mesh.material.toneMapping = THREE.LinearToneMapping; // Better tone mapping for realistic lighting
        // Adjust material properties if needed
        // mesh.material.roughness = 0.5;
        mesh.material.metalness = 0.5;
        mesh.castShadow = true;
        mesh.receiveShadow = true;
      }
    });
    setLoading(false);
  }, [shirtTexture, nodes]);
  const controls = useRef();

  const { camera } = useThree();

  useEffect(() => {
    if (viewMode) {
      // Adjust camera position for back view
      camera.position.set(0, 0, 2); // Example position for the back view
      camera.lookAt(0, 0, 0); // Look at the center of the scene
      camera.position.applyQuaternion(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          Math.PI
        )
      );
    } else {
      // Adjust camera position for front view
      camera.position.set(0, 0, 2); // Example position for the front view
      camera.lookAt(0, 0, 1); // Look slightly ahead
    }
  }, [camera, viewMode]);

  return (
    <>
      <OrbitControls
        ref={controls}
        rotateSpeed={0.5}
        enablePan={false}
        autoRotate={rotateEnabled} // Enable auto rotation
        autoRotateSpeed={0.5} // Adjust auto rotation speed
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />
      <ambientLight intensity={0.5} />{" "}
      {/* Ambient light to provide general illumination */}
      <directionalLight position={[5, 5, 5]} intensity={0.8} castShadow />{" "}
      {/* Directional light to simulate sunlight */}
      <directionalLight position={[-5, -5, -5]} intensity={0.5} />{" "}
      {/* Additional light from behind */}
      <group key={stateString} ref={meshRef} scale={[0.75, 0.75, 0.75]}>
        {Object.values(nodes).map((mesh, index) => (
          <mesh
            key={index}
            geometry={mesh.geometry}
            material={mesh.material}
            visible={mesh.visible}
            dispose={null}
            castShadow
            receiveShadow
          />
        ))}
      </group>
    </>
  );
};

export default Pant;

// InstagramReels.js
import React, { useEffect, useState, useRef } from "react";
import { FaInstagram, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import styles from "./VideoReviews.module.scss";
import { InstagramEmbed } from "react-social-media-embed";
import { db } from "../../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import EmblaCarousel from "./EmblaCarousel";

const VideoReviews = () => {
  const [reelsData, setReelsData] = useState([]);

  useEffect(() => {
    // Fetch data from Firestore when the component mounts
    const fetchReelsData = async () => {
      try {
        const snapshot = await getDocs(collection(db, "videoreviews"));
        const reels = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReelsData(reels);
      } catch (error) {
        console.error("Error fetching reels data: ", error);
      }
    };

    fetchReelsData();
  }, []);

  const OPTIONS = { slidesToScroll: "auto", loop: true };
  return (
    <>
      <div className={styles.topSection}>
        <div className={styles.toptext}>
          <h4> LATEST REVIEWS</h4>
        </div>
        <div className={styles.viewAllLink}>
          <a
            target="blank"
            href="https://www.instagram.com/ultimate_sport_store"
          >
            View All
          </a>
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          {" "}
          <EmblaCarousel slides={reelsData} options={OPTIONS} />
        </div>
      </div>
    </>
  );
};

export default VideoReviews;

// PantForm.jsx
import React, { useEffect, useState } from 'react';
import { jsPDF } from "jspdf";

import { useNavigate } from 'react-router-dom';

import styles from "./CapForm.module.scss"
import Loader from '../../loader/Loader';
import { useSnapshot } from "valtio";
import {toast} from "react-toastify"
import state from "../State";
import { storage } from '../../../firebase/config';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage
import { teamKitMail } from '../../../emailjs/EmailTemplates';

const CapForm = () => {
    const navigate = useNavigate();
    const [teamName, setTeamName] = useState('');
  
    const [customerName, setCustomerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [showLoader,setShowLoader] = useState(false)
    const [frontImageUrl, setFrontImageUrl] = useState(null);
    const [backImageUrl, setBackImageUrl] = useState(null);
    const [logoLeftThigh, setLogoLeftThigh] = useState(null);
   
    const [memberDetailsFile, setMemberDetailsFile] = useState(null);
    const [backPrint,setBackPrint] = useState("")
    const [logoType,setLogoType] = useState("")
    const [logoLeftThighPreview, setLogoLeftThighPreview] = useState(null); // State for left Chest logo preview
   
    const [phoneNumberError, setPhoneNumberError] = useState(false); // State for phone number validation error
    const [emailError, setEmailError] = useState(false); // State for email validation error
    const snap = useSnapshot(state);
    useEffect(
      ()=>{
        setBackImageUrl(localStorage.getItem("DesignView_back"))
        setFrontImageUrl(localStorage.getItem("DesignView_front"))
      },[]
    )

    const handleFileChange = (event, setImage, setPreview) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(file); // Set file blob for form submission
                setPreview(reader.result); // Set preview image
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to handle phone number input change
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        if (!/^\d{10}$/.test(value)) {
            setPhoneNumberError(true);
        } else {
            setPhoneNumberError(false);
        }
    };

    // Function to handle email input change
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (!/^\S+@\S+\.\S+$/.test(value)) {
            setEmailError(true);
        }
        
        else {
            setEmailError(false);
        }
    };

    const handleDataURL = async (dataURL, filePath) => {
        const contentType = dataURL.split(';')[0].split(':')[1];
        const base64Data = dataURL.split(',')[1];
        const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
        const blob = new Blob([byteArray], { type: contentType });
        const storageRef = ref(storage, filePath);
        await uploadBytes(storageRef, blob);
        return getDownloadURL(storageRef);
    };
    
    
    
    
    // Function to handle File object
    const handleFileObject = async (file, filePath) => {
        const storageRef = ref(storage, filePath);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
    };
  


     

    
   

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLoader(true)
    
        // Validate form data
        if (!teamName || !customerName || !phoneNumber || !email || !frontImageUrl || !backImageUrl  ||  phoneNumberError || emailError) {
            alert('Please fill in all fields correctly');
            setShowLoader(false)
            return;
        }
    
       
    
        try {
            // Function to handle uploading various types of files
            const [frontImageURL, backImageURL, leftThighURL, memberDetailsURL] = await Promise.all([
                handleDataURL(frontImageUrl, `front_images/${frontImageUrl.name}${Date()}${teamName}`),
                handleDataURL(backImageUrl, `back_images/${backImageUrl.name}${Date()}${teamName}`),
                logoLeftThigh?handleFileObject(logoLeftThigh, `left_thigh_images/${logoLeftThigh.name}${Date()}${teamName}`):null,
               
                memberDetailsFile?handleFileObject(memberDetailsFile, `member_details_files/${memberDetailsFile.name}${Date()}${teamName}`):null,
            ]);
    
            // Prepare data for PDF
            const pdfData = {
                "Team Name": teamName,
               
                "Preview Image 1": backImageURL,
                "Preview Image 2": frontImageURL,
                "Cap Logo Image": leftThighURL,
                "Logo print type (front)":logoType,
                "Back Print":backPrint,
              
                "Member Details File": memberDetailsURL,
                "Cap Color": snap.colorCap,
                
            };
    
            
    
            // Create new jsPDF document
            const doc = new jsPDF();
            
            // Set default font size and spacing
            doc.setFontSize(12);
            const lineHeight = 8;
            let yPos = 20;
    
            // Add content to PDF
            for (const key in pdfData) {
                const value = pdfData[key];
                if (value && value!==null) {
                    // Add key-value pair to PDF
                    if(value.startsWith("https")){
                    doc.text(20, yPos, `${key}:`);
                    doc.setTextColor(0,0,139);
                    doc.textWithLink("Click here to see original File", 70, yPos, { url: value });
                    yPos += lineHeight;
                    doc.setTextColor(0,0,0);
                    }
                    else{
                        doc.text(20, yPos, `${key}: ${value}`);
                        yPos += lineHeight;

                    }
                 
                    
                    // Add image if URL key
                    if (key.includes("Logo") || key.includes("Image")) {
                        // Load image
                        const response = await fetch(value);
                        const blob = await response.blob();
                        const imageDataUrl = await new Promise((resolve) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                resolve(reader.result);
                            };
                            reader.readAsDataURL(blob);
                        });
                        
                        // Add image to PDF
                        doc.addImage(imageDataUrl, "JPEG", 20, yPos, 80, 50);
                        yPos += lineHeight + 50; // Adjust vertical position after image
                    }
                    
                    // Check if content exceeds page height
                    if (yPos >= doc.internal.pageSize.height - 20) {
                        // Add new page
                        doc.addPage();
                        // Reset yPos
                        yPos = 20;
                    }
                }
            }
    
            // Save or download the PDF
            const pdfBlob = doc.output("blob");
    const storageRef = ref(storage, `cap_${teamName}_${Date().toString()}.pdf`);
    await uploadBytes(storageRef, pdfBlob);
    const downloadURL = await getDownloadURL(storageRef);
    console.log("PDF download URL:", downloadURL);
  
    localStorage.removeItem("DesignView_back")
    localStorage.removeItem("DesignView_front")
    await teamKitMail({apparel_name:"Cap",team_name:teamName,cust_name:customerName,email_address:email,pdf_url:downloadURL,cust_phone:phoneNumber})
    navigate("/thank-you", { replace: true });
    setShowLoader(false)
        } catch (error) {
            console.error('Error:', error);
            setShowLoader(false)
            return
            // Handle error
        }
    };
    
    function backToSelection() {
      state.form = false;
      state.intro = false;
    }
      


    return (
      <div className={styles["pant-form"]}>
        {showLoader && <Loader />}
        <div className={styles.padiv}>
          <h4 className={styles.linkstyle} onClick={() => backToSelection()}>
            &larr; Back to Customizer
          </h4>
        </div>
        <h1>Just a little more information</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles["desktop-layout"]}>
            <div className={styles["column"]}>
              <label>
                Team Name:
                <input
                  type="text"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  required
                />
              </label>
              <label>
                Customer Name:
                <input
                  type="text"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  required
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                />
                {phoneNumberError && (
                  <p className={styles["error-message"]}>
                    Please enter a valid phone number (10 digits)
                  </p>
                )}
              </label>
              <label>
                Email:
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                {emailError && (
                  <p className={styles["error-message"]}>
                    Please enter a valid email address
                  </p>
                )}
              </label>
            </div>
            <div className={styles["column"]}>
              <label>
                Logo on Cap [front]:
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setLogoLeftThigh,
                      setLogoLeftThighPreview
                    )
                  }
                
                />
                {logoLeftThighPreview && (
                  <img
                    src={logoLeftThighPreview}
                    alt="Left Chest Logo Preview"
                    width={200}
                  />
                )}
                <br></br>
              </label>

              {logoLeftThigh && (
                <label>
                  Logo Print Type (Front):
                  <div className={styles["select-container"]}>
                    <select
                      value={logoType}
                      onChange={(e) => setLogoType(e.target.value)}
                      required
                    >
                      <option value="" disabled selected>
                        -- Choose a value --
                      </option>
                      <option value="Embroidered">Embroidered</option>
                      <option value="Rubber Printed">Rubber Printed</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </label>
              )}

              {/* Dropdown for Back Print */}
              <label>
                Back Print:
                <div className={styles["select-container"]}>
                  <select
                    value={backPrint}
                    onChange={(e) => setBackPrint(e.target.value)}
                    required
                  >
                    <option value="" disabled selected>
                      -- Choose a value --
                    </option>
                    <option value="Team Name">Team Name</option>
                    <option value="Player Name">Player Name</option>
                    <option value="Team & Player Name">
                      Team & Player Name
                    </option>
                    <option value="None">None</option>
                  </select>
                </div>
              </label>

              <label>
                Team Member Details File (Excel or PDF):
                <input
                  type="file"
                  accept=".xls,.xlsx,.pdf"
                  onChange={(e) => setMemberDetailsFile(e.target.files[0])}
              
                />
              </label>
            </div>
            <div className={styles["column"]}>
              <label>Cap Preview Images (Scroll):</label>
              <div className={styles["image-previews"]}>

              {backImageUrl && (
                  <img
                    src={backImageUrl}
                    alt="Back View"
                    className={`${styles["preview-image"]} `}
                  />
                )}
                {frontImageUrl && (
                  <img
                    src={frontImageUrl}
                    alt="Front View"
                    className={`${styles["preview-image"]} `}
                  />
                )}
               
              </div>
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    );
};

export default CapForm;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  returnAdminMail,
  returnCustomerMail,
} from "../../emailjs/EmailTemplates.js";
import Modal from "react-modal";
import Loader from "../../components/loader/Loader.js";
import ChangeOrderStatus from "../../components/admin/changeOrderStatus/ChangeOrderStatus";
import useFetchDocument from "../../customHooks/useFetchDocument";
import spinnerImg from "../../assets/spinner.jpg";
import styles from "./OrderDetails.module.scss";
import { selectUserID } from "../../redux/slice/authSlice";
import { useSelector } from "react-redux";

import {
  addDoc,
  collection,
  updateDoc,
  doc,
  serverTimestamp,
  onSnapshot,
  orderBy,
  getDocs,
  getDoc
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const OrderDetails = () => {

  const userID = useSelector(selectUserID);

  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [isReturnModalOpen, setReturnModalOpen] = useState(false);
  const [reasonForReturn, setReasonForReturn] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [returnQuantity, setReturnQuantity] = useState(1); // Default return quantity is 1
  const { id } = useParams();
  const { document } = useFetchDocument("orders", id);
  const [showLoader, setShowLoader] = useState(false);
  const [customReason, setCustomReason] = useState("");



  useEffect(() => {
    setOrder(document);
  }, [document]);
  const [returns, setReturns] = useState([]);

  useEffect(() => {
    // Fetch returns data from Firestore and subscribe to real-time updates
    const fetchReturns = async () => {
      try {
        const returnsCollection = await collection(db, "returns");

        // Initial fetch
        const returnsSnapshot = await getDocs(returnsCollection);
        const returnsData = returnsSnapshot.docs.map((doc) => doc.data());
        setReturns(returnsData);

        // Real-time updates
        const unsubscribe = onSnapshot(returnsCollection, (snapshot) => {
          const updatedReturns = snapshot.docs.map((doc) => doc.data());
          setReturns(updatedReturns);
        });

        return () => {
          // Unsubscribe from real-time updates when the component unmounts
          unsubscribe();
        };
      } catch (error) {
        console.error("Error fetching returns:", error);
      }
    };

    fetchReturns();
  }, []); // Fetch returns data on component mount
  // useEffect(() => {
  //   const unsubscribe = onSnapshot(doc(db, "orders", id), (snapshot) => {

  //     const orderData = snapshot.data();

  //     // Check if the userID in the order matches the current user's UID
  //     if (orderData.userID !== userID) {
  //       toast.error("Unauthorized access to order details.");
  //       navigate("/"); // Redirect the user to the homepage
  //       return;
  //     }

     

  //     setOrder(snapshot.data());
  //   });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, [id]);

  useEffect(() => {
    if (!userID) return; // Wait until userID is fetched

    const unsubscribe = onSnapshot(doc(db, "orders", id), (snapshot) => {
      const orderData = snapshot.data();

      // Check if the userID in the order matches the current user's UID
      if (orderData.userID !== userID) {
        toast.error("Unauthorized access to order details.");
        navigate("/"); // Redirect the user to the homepage
        return;
      }

      setOrder(orderData);
    });

    return () => {
      unsubscribe();
    };
  }, [id, userID, navigate]);
  

  const isReturnButtonValid = () => {
    if (order && order.orderStatus === "Delivered") {
      const editedAtTimestamp = new Date(order.editedAt).getTime();
      const currentTimestamp = new Date().getTime();
      const timeDifference = currentTimestamp - editedAtTimestamp;
      const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
      <p>
        <a
          href={order.trackingUrl}
          className="tracking-url-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Track Order (Click here)
        </a>
      </p>;
      return timeDifference <= sevenDaysInMilliseconds; // 7 days in milliseconds
    }
    return false;
  };

  const handleReturnButtonClick = (index) => {
    const orderDate = new Date(order.editedAt.toDate());
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - orderDate.getTime();
    const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;

    if (timeDifference <= sevenDaysInMilliseconds) {
      setSelectedProductIndex(index);
      setReturnModalOpen(true);
    } else {
      toast.error("Return Date Expired");
    }
  };

  const handleReturnSubmission = async () => {
    setShowLoader(true);
  
    try {
      if (!order) {
        console.error("Order details are not available yet.");
        toast.error("Order details are not available yet.");
        setShowLoader(false);
        return;
      }
  
      // Validate reasonForReturn or handle empty reason case
      if (!reasonForReturn && !customReason) {
        toast.error("Reason for return is not provided.");
        setShowLoader(false);
        return;
      }
  
      // Check if a product is selected for return
      if (selectedProductIndex !== null) {
        const selectedProduct = order.cartItems[selectedProductIndex];
  
        // Validate returnQuantity
        if (returnQuantity > selectedProduct.cartQuantity) {
          toast.error("Return quantity exceeds ordered quantity");
          setShowLoader(false);
          return;
        }
  
        // Create returnData for the selected product
        const returnData = {
          orderId: order.id,
          customerName: order.shippingAddress.name,
          customerEmail: order.userEmail,
          customerPhoneNumber: order.shippingAddress.phone,
          productId: selectedProduct.id,
          productName: selectedProduct.name,
          productSize: selectedProduct.selectedSize,
          quantity: returnQuantity,
          reasonForReturn: reasonForReturn === "Other" ? customReason : reasonForReturn,
          deliveryDate: order.editedAt,
          applyDate: serverTimestamp(),
          status: "Applied For Return",
        };
  
        // Check if "Other" is selected and custom reason is provided
        if (reasonForReturn === "Other" && !customReason) {
          toast.error("Custom reason is required for 'Other' option.");
          setShowLoader(false);
          return;
        }
  
        // Add returnData to the returns collection
        await addDoc(collection(db, "returns"), returnData);
  
        toast.success("Return request submitted successfully");
        setReturnModalOpen(false);
  
        await returnAdminMail({
          order_id: order.id,
          order_date: order.orderDate,
          customer_name: order.shippingAddress.name,
          return_reason: returnData.reasonForReturn,
        });

        await returnCustomerMail({
          to_name: order.shippingAddress.name,
          quantity: returnData.quantity,
          product_name: returnData.productName,
          order_amount: order.orderAmount,
          to_email: order.userEmail,
          order_id: order.id,
        });

        navigate("/return-history");
        setShowLoader(false);
  
      } else {
        toast.error("No product selected for return");
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error submitting return request:", error);
      toast.error("Error submitting return request");
      setReturnModalOpen(false);
      setShowLoader(false);
    }
  };
  

  const handleReturnQuantityChange = (event) => {
    const inputValue = parseInt(event.target.value);
    const maxQuantity =
      order && order.cartItems
        ? order.cartItems[selectedProductIndex].cartQuantity
        : 1;
    if (inputValue > maxQuantity) {
      setReturnQuantity(maxQuantity);
    } else {
      setReturnQuantity(inputValue);
    }
  };

  return showLoader ? (
    <Loader />
  ) : (
    <section className={styles.orderDetails}>
      
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.orderdetdiv}>
          <h2>Order Details</h2>
          <div>
            <Link to="/order-history" className={styles.linkstyle}>
              &larr; Back To Orders
            </Link>
          </div>
        </div>
        <br />
        {order === null ? (
          <div className={styles.spinnerContainer}>
            <img src={spinnerImg} alt="Loading..." className={styles.spinner} />
          </div>
        ) : (
          <>
            <div className={styles.orderInfo}>
              <p>
                <b>Order ID:</b> {order.id}
              </p>
              <p>
                <b>Amount Paid:</b> ${
                
                order.paymentMethod !== "Card"?
                Number(order.orderAmount).toFixed(2):(Number(order.orderAmount)).toFixed(2)}{" "}
                ({order.paymentMethod})
              </p>

              {/* <p> */}
                {/* <b>Total Amount: </b>$
                {order.cartItems
                  .reduce((total, cart) => {
                    const price = parseFloat(cart.price);
                    const knockingServicePrice = parseFloat(cart.knockingServicePrice ?cart.knockingServicePrice:0)
                    const tax = (order.shippingAddress.state === "New Jersey")?cart.tax:0;
                    const withTax = (price + knockingServicePrice  + (price * tax) / 100).toFixed(2);
                    const itemTotal = withTax * cart.cartQuantity;

                    return total + parseFloat(itemTotal); // Accumulate the total prices
                  }, 0)
                  .toFixed(2)}{" "} */}
                {/* Ensure the total is formatted with 2 decimal places */}
              {/* </p> */}

              <p>
                <b>Order Status:</b> {order.orderStatus}
              </p>
              <p>
                {console.log(order)}
                <a
                  href={order.trackingUrl}
                  className={styles.linkstyle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.trackingUrl && " Track Order (Click here)"}
                </a>
              </p>
            </div>
            {order.cartItems && order.cartItems.length > 0 ? (
              <div className={styles.tableWrapper}>
                <table className={styles.orderTable}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Product</th>
                      <th>Selected Size</th>
                      <th>Price</th>
                     
                      <th>Quantity</th>
                      <th>Tax</th>
                      <th>Total</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.cartItems.map((cart, index) => {
                      const price = parseFloat(cart.price);
                      const knockingServicePrice = parseFloat(cart.knockingServicePrice);
                      const knockingService = cart.knockingService;
                      const tax = (order.shippingAddress.state === "New Jersey"?cart.tax:0);
                      const withKnockingPrice = price + knockingServicePrice
                      const withTax = (withKnockingPrice + (withKnockingPrice * tax) / 100).toFixed(2);
                      const total = (withTax * cart.cartQuantity).toFixed(2);

                      return (
                        <tr key={cart.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className={styles.itemDetails}>
                              <Link
                                to={`/product-details/${cart.id}`}
                                target="_blank"
                              >
                                <img
                                  src={cart.imageURLs[0]}
                                  alt={cart.name}
                                  className={styles.prodImage}
                                />
                              </Link>
                              <div className={styles.itemInfo}>
                                <h1 className={styles.prodname}>{cart.name}</h1>
                          {(cart.sport === "Cricket" && cart.category ==="Bats")&&(
                            <b>{cart.knockingService}</b>
                          )}
                         <br></br><br></br>
                                <p>Product # {cart.id}</p>
                              </div>
                              {order.delivered && (
                                <Link to={`/review-product/${cart.id}`}>
                                  <button className="--btn --btn-primary">
                                    Review Product
                                  </button>
                                </Link>
                              )}

                              {(() => {
                                const matchingReturn = returns.find(
                                  (returnDoc) =>
                                    returnDoc.orderId === order.id &&
                                    returnDoc.productId === cart.id
                                );

                                if (
                                  !matchingReturn &&
                                  order.orderStatus === "Delivered"
                                ) {
                                  return (
                                    <>
                                      <button
                                        className="--btn --btn-primary"
                                        onClick={() => {
                                          handleReturnButtonClick(index);
                                        }}
                                      >
                                        Return Product
                                      </button>
                                    </>
                                  );
                                }
                              })()}
                            </div>
                          </td>
                          <td>
                            <span className={styles.price}>
                              <span className={styles.priceData}>
                                {cart.selectedSize}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className={styles.price}>
                              <span className={styles.priceSym}>$</span>
                              <span className={styles.priceData}>{price+knockingServicePrice}</span>
                            </span>
                          </td>
                         
                          <td>{cart.cartQuantity}</td>
                          <td>{order.shippingAddress.state==="New Jersey"?cart.tax:`N/A for ${order.shippingAddress.state} `}</td>
                          <td>
                            <span className={styles.price}>
                              <span className={styles.priceSym}>$</span>
                              <span className={styles.priceData}>{total}</span>
                            </span>
                          </td>
                          <td>
                            {(() => {
                              const matchingReturn = returns.find(
                                (returnDoc) =>
                                  returnDoc.orderId === order.id &&
                                  returnDoc.productId === cart.id
                              );

                              return matchingReturn
                                ? matchingReturn.status
                                : order.orderStatus;
                            })()}
                            <br></br>
                            <br></br>
                            <br></br>
                            <hr></hr>
                            <div>
                              {order.cartItems[index].status &&
                                (order.cartItems[index].status ===
                                  "Applied For Return" ||
                                  order.cartItems[index].status ===
                                    "applied for return") && (
                                  <Link
                                    to={"/return-history"}
                                    target="_blank"
                                    style={{ color: "orangered" }}
                                  >
                                    Check Return Status
                                  </Link>
                                )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No items in the order.</p>
            )}
          </>
        )}
      </div>
      <Modal
        isOpen={isReturnModalOpen}
        onRequestClose={() => setReturnModalOpen(false)}
        contentLabel="Return Product Modal"
        className={styles.customModal}
        overlayClassName={styles.customOverlay}
      >
        <div className={styles.modalContent}>
          <h3>Return Product</h3>
          <p>Provide a reason for return and select the return quantity:</p>
          <label>
            Reason for Return:
            <select
              required
              value={reasonForReturn}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setReasonForReturn(selectedValue);

                // Clear the customReason if the selected value is not "Other"
                if (selectedValue !== "Other") {
                  setCustomReason("");
                }
              }}
            >
              <option value="">Select a reason</option>
              <option value="Defective product">Defective product</option>
              <option value="wrong-size">Wrong size</option>
              <option value="Size or Fit Issues">Size or Fit Issues</option>
              <option value="Change of Mind">Change of Mind</option>
              <option value="Other">Other</option>
              {/* Add more predefined reasons as needed */}
            </select>
          </label>
          {
            reasonForReturn === "Other" ? (
              <>
                <p>Or write your custom reason:</p>
                <textarea
                  required
                  value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)}
                />
              </>
            ) : null /* Removed the unnecessary block */
          }
          <label>
            Return Quantity:
            <input
              type="number"
              value={returnQuantity}
              onInput={handleReturnQuantityChange}
              min="1"
            />
          </label>
          <p>
            By clicking on "Submit Return" you agree to our{" "}
            <Link to={"/return-policy"}>RETURN POLICY</Link>
          </p>
          <button onClick={handleReturnSubmission}>Submit Return</button>
          <button onClick={() => setReturnModalOpen(false)}>Cancel</button>
        </div>
      </Modal>
    </section>
  );
};

export default OrderDetails;

import React from "react";
import { Bar } from "react-chartjs-2";
import Card from "../card/Card";
import styles from "./StateOrderChart.module.scss";

const StateOrderChart = ({ orders }) => {
  const stateOrderData = orders.reduce((acc, order) => {
    const state = order.shippingAddress?.state;

    if (state) {
      acc[state] = (acc[state] || 0) + 1;
    }

    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(stateOrderData),
    datasets: [
      {
        label: "Number of Orders",
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.4)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: Object.values(stateOrderData),
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false, // Set to false
    aspectRatio: 2, // Adjust the aspect ratio as needed
    scales: {
      x: {
        title: {
          display: true,
          text: "States",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Orders",
        },
      },
    },
  };

  return (
    <Card className={styles.chartCard}>
      <h3>State-wise Order Count</h3>
      <div className={styles.chartContainer}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </Card>
  );
};

export default StateOrderChart;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  selectCartItems,
  selectCartTotalAmount,
  selectCartTotalAmountWithTaxes,
  selectCartTotalQuantity,
} from "../../../redux/slice/cartSlice";
import Card from "../../card/Card";
import styles from "./CheckoutSummaryDiscount.module.scss";

const CheckoutSummaryDiscount = () => {
  const cartItems = useSelector(selectCartItems);
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const cartTotalAmountWithTaxes = useSelector(selectCartTotalAmountWithTaxes);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  var discAmount = Number(localStorage.getItem("discountedAmount2"));
  const formattedDiscAmount = discAmount
    ? (parseFloat(discAmount) / 100).toFixed(2)
    : "0.00";

    const formattedDiscAmountNum = Number(formattedDiscAmount);
    const discountPercentage = ((cartTotalAmountWithTaxes - formattedDiscAmountNum) / cartTotalAmountWithTaxes) * 100;
    const showDiscount = formattedDiscAmountNum >= 0.01 && discountPercentage > 0.00;

  let selectedState = localStorage.getItem("selectedState");
  const totalValue = selectedState==="New Jersey"?cartTotalAmountWithTaxes:cartTotalAmount;
  useEffect(() => {
    selectedState = localStorage.getItem("selectedState");
  }, []);

  console.log(selectedState);

  return (
    <div>
      <h3>Checkout Summary</h3>
      <div>
        {cartItems.length === 0 ? (
          <>
            <p>No item in your cart.</p>
            <button className="--btn">
              <Link to="/">Back To Shop</Link>
            </button>
          </>
        ) : (
          <div>
            {cartItems.map((item, index) => {
              const {
                id,
                name,
                price,
                tax,
                cartQuantity,
                sport,
                category,
                knockingService,
                knockingServicePrice,
             
              } = item;
              const unitTotalPriceWithTax = price * (tax / 100) + price;
              const formattedUnitTotalPriceWithTax =
                typeof unitTotalPriceWithTax === "number"
                  ? unitTotalPriceWithTax.toFixed(2)
                  : "N/A";
              const amount = (
                ((price * tax) / 100) * cartQuantity +
                price * cartQuantity
              ).toFixed(2);
              return (
                <div key={id}>
                  <Card cardClass={styles.card}>
                    <h4>{name}</h4>
                    {sport === "Cricket" && category === "Bats" && (
                      <p>Knocking Service: {knockingService}</p>
                    )}
                    <p>Quantity: {cartQuantity}</p>
                    <p>Unit price: {price + knockingServicePrice}</p>
                    {selectedState === "New Jersey" ? (
                      <>
                         <p>Tax: {tax}% (${((tax/100)*price).toFixed(2)})</p>
                        <p>
                          Amount:{" "}
                          {(
                            (((price + knockingServicePrice) * tax) / 100) *
                              cartQuantity +
                            (price + knockingServicePrice) * cartQuantity
                          ).toFixed(2)}
                        </p>
                      </>
                    ) : (
                      <p>
                        Amount:{" "}
                        {(
                          (price + knockingServicePrice) *
                          cartQuantity
                        ).toFixed(2)}
                      </p>
                    )}
                  </Card>
                </div>
              );
            })}
            <div className={styles.text}>
              <h3>{`Subtotal (${cartTotalQuantity} items):`}</h3>
              <span className={styles.price}>
                <span className={styles.priceSym1}>$</span>
                {selectedState === "New Jersey" ? (
                  <span className={styles.priceData1}>
                 { cartTotalAmountWithTaxes.toFixed(2)}
                  </span>
                ) : (
                  <span className={styles.priceData1}>
                    {cartTotalAmount.toFixed(2)}
                  </span>
                )}
              </span>
            </div>
            {
  ((discountPercentage.toFixed(2)>0.00)&&(discountPercentage.toFixed(2)<100.00)) ? (
    <div className={styles.text}>
      <h3>{`New Discounted Price:`}</h3>
      <span className={styles.price}>
        <span className={styles.priceSym1}>$</span>
        <span className={styles.priceData1}>

          {formattedDiscAmountNum.toFixed(2)} (you saved {discountPercentage.toFixed(2)}%)
        </span>
      </span>
    </div>
  ) : (
    <></>
  )
}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSummaryDiscount;

import React, { useEffect, useState } from "react";
import Flickity from "flickity";
import "flickity/css/flickity.css";
import styles from "./Slider.module.scss";

const Slider = () => {
  const [bannerUrls, setBannerUrls] = useState({
    current: window.innerWidth <= 768 ? "mobile" : "desktop", // Initial state based on viewport width
    mobile: [
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2Fmobile%20teamkit%20final.gif?alt=media&token=1a6f519f-4cb5-4d7b-bcc7-ebc816d70f23",
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2Fmobile%20banner%202.png?alt=media&token=8ddf5a29-ef21-4b18-8117-421224296824",
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2FMOBILE%20BANNERS-2.png?alt=media&token=d0e4935f-3755-4c92-aad9-b9b3382994f1",

      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2FMOBILE%20BANNERS-3.png?alt=media&token=a569a6a8-c5fb-4e97-b0dd-7e4a78739851",
    ],
    desktop: [
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2Fdesktop%20teamkit%20final3.gif?alt=media&token=12b3f003-7ff4-49f5-816a-545b7cc07bc1",
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2Ffinal%20banner%20-1.png?alt=media&token=4b2b0345-3d0d-458c-b62d-2fb96c24a73b",
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2F2.png?alt=media&token=cd3bb322-6835-48ca-b208-a695e6274d93",
      "https://firebasestorage.googleapis.com/v0/b/idkman2-6afeb.appspot.com/o/eshop%2F3.png?alt=media&token=f0d3c6e8-2479-4fef-a7c6-6f5737453649",
    ],
  });

  useEffect(() => {
    const options = {
      accessibility: true,
      prevNextButtons: false,
      pageDots: true,
      setGallerySize: false,
      wrapAround: true, // Enable infinite scrolling
      autoPlay: 5000, // Adjust the interval (in milliseconds) for auto-scroll
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 60,
        y2: 45,
        x3: 15,
      },
    };

    const carousel = document.querySelector("[data-carousel]");
    const slides = document.getElementsByClassName(styles.carouselCell);
    const flkty = new Flickity(carousel, options);

    flkty.on("scroll", function () {
      flkty.slides.forEach(function (slide, i) {
        const image = slides[i];
        const x = (slide.target + flkty.x) * (-1 / 3);
        image.style.backgroundPosition = `${x}px`;
      });
    });

    flkty.on("change", function (index) {
      // Add custom transition duration for animation between first and last slide
      const isTransitioningToFirst =
        index === 0 && flkty.selectedIndex === flkty.slides.length - 1;
      const isTransitioningToLast =
        index === flkty.slides.length - 1 && flkty.selectedIndex === 0;

      if (isTransitioningToFirst || isTransitioningToLast) {
        const transitionDuration = "0.1s"; // Adjust the duration as needed
        carousel.style.transitionDuration = transitionDuration;
        setTimeout(() => {
          carousel.style.transitionDuration = "";
        }, parseFloat(transitionDuration) * 1000);
      }
    });

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBannerUrls({
          ...bannerUrls,
          current: "mobile",
        });
      } else {
        setBannerUrls({
          ...bannerUrls,
          current: "desktop",
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      flkty.destroy();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.heroSlider} data-carousel>
      {bannerUrls[bannerUrls.current].map((url, index) => (
        <div
          key={index}
          className={`${styles.carouselCell} ${styles.slide}`}
          style={{
            backgroundImage: `url(${url})`,
          }}
        >
          <div className={styles.inner}></div>
        </div>
      ))}
    </div>
  );
};

export default Slider;

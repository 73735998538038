import { Canvas } from "@react-three/fiber";
import { Center } from "@react-three/drei";
import Shirt from "./Shirt";
import Cap from "./Cap";
import CameraRig from "./CameraRig";
import { useSnapshot } from "valtio";
import state from "../State";
import styles from "./CanvasModel.module.scss";
import polo from "../assets/collarTypes/polo.jpg";
import mandarin from "../assets/collarTypes/mandarin.jpg";
import insert from "../assets/collarTypes/insert.jpg";
import rib from "../assets/collarTypes/rib.jpg";
import round from "../assets/collarTypes/round.jpg";
import zipup from "../assets/collarTypes/zipup.jpg";
import { useEffect, useState } from "react";
import Loader from "../../loader/Loader";
import download from "../assets/download.png";
import Pant from "./Pant";


const combinedColors = [
  { color: "#ea4606", name: "Dark Orange" },
  { color: "#ffffff", name: "White" },
  { color: "#d4d4d4", name: "Half White" },
  { color: "#9e9dad", name: "Light Grey" },
  { color: "#505050", name: "Grey" },
  { color: "#373a36", name: "Anthracite" },
  { color: "#000000", name: "Black" },
  { color: "#ebddbe", name: "Beige" },
  { color: "#845b35", name: "Brown" },
  { color: "#665000", name: "Reel Gold" },
  { color: "#af9841", name: "Olive" },
  { color: "#d7cc75", name: "Mustard" },
  { color: "#ffffa7", name: "Pale Yellow" },
  { color: "#fff22d", name: "Yellow" },
  { color: "#ffbb00", name: "Gold" },
  { color: "#ff6700", name: "Orange" },
  { color: "#006050", name: "Teal" },
  { color: "#033c20", name: "Green" },
  { color: "#99ff32", name: "Neon" },
  { color: "#33aa33", name: "Light Green" },
  { color: "#6b2c3c", name: "Cardinal" },
  { color: "#6c1f29", name: "Maroon" },
  { color: "#b52130", name: "Scarlet" },
  { color: "#bb0000", name: "Dark Red" },
  { color: "#ef3838", name: "Red" },
  { color: "#ec268f", name: "Pink" },
  { color: "#ff96be", name: "Baby Pink" },
  { color: "#ff0045", name: "Magenta" },
  { color: "#2d003c", name: "Violet" },
  { color: "#542d7e", name: "Purple" },
  { color: "#c2def4", name: "Light Blue" },
  { color: "#1568be", name: "Rich Royal" },
  { color: "#3399ff", name: "Sky Blue" },
  { color: "#23bfc8", name: "Seize" },
  { color: "#4343ff", name: "Reflex Blue" },
  { color: "#0f41b2", name: "Royal Blue" },
  { color: "#040e5f", name: "Catalina Blue" },
  { color: "#22293c", name: "Navy" },
];

const collarTypes = [
  { type: "polo", image: polo },
  { type: "zipUp", image: zipup },
  { type: "mandarin", image: mandarin },
  { type: "insert", image: insert },
  { type: "rib", image: rib },
  { type: "round", image: round },
];

const CanvasModel = () => {
  const snap = useSnapshot(state);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [rotateEnabled, setRotateEnabled] = useState(false);
  const [selectedColorCollar, setSelectedColorCollar] = useState("");
  const [selectedColorBody, setSelectedColorBody] = useState("");
  const [selectedColorPant, setSelectedColorPant] = useState("");
  const [selectedColorPantWaist, setSelectedColorPantWaist] = useState("");
  const [selectedColorCap, setSelectedColorCap] = useState("");
  const [selectedColorArm, setSelectedColorArm] = useState("");
  const [selectedColorArmHover, setSelectedColorArmHover] = useState(false);
  const [selectedColorBodyHover, setSelectedColorBodyHover] = useState(false);
  const [selectedColorPantHover, setSelectedColorPantHover] = useState(false);
  const [selectedColorPantWaistHover, setSelectedColorPantWaistHover] =
    useState(false);
  const [selectedColorCapHover, setSelectedColorCapHover] = useState(false);
  const [selectedColorCollarHover, setSelectedColorCollarHover] =
    useState(false);
  const [selectedColorPantDesign1, setSelectedColorPantDesign1] = useState("");
  const [selectedColorPantDesign2, setSelectedColorPantDesign2] = useState("");
  const [selectedColorPantDesign3, setSelectedColorPantDesign3] = useState("");
  const [selectedColorPantDesign4, setSelectedColorPantDesign4] = useState("");
  const [selectedColorPantDesign5, setSelectedColorPantDesign5] = useState("");
  const [selectedColorPantDesign6, setSelectedColorPantDesign6] = useState("");
  const [selectedColorDesign1, setSelectedColorDesign1] = useState("");
  const [selectedColorDesign2, setSelectedColorDesign2] = useState("");
  const [selectedColorDesign3, setSelectedColorDesign3] = useState("");
  const [selectedColorDesign4, setSelectedColorDesign4] = useState("");
  const [selectedColorDesign5, setSelectedColorDesign5] = useState("");
  const [selectedColorDesign6, setSelectedColorDesign6] = useState("");
  const [selectedColorDesign7, setSelectedColorDesign7] = useState("");
  const [selectedColorDesign8, setSelectedColorDesign8] = useState("");
  const [selectedColorDesign9, setSelectedColorDesign9] = useState("");
  const [selectedColorDesign10, setSelectedColorDesign10] = useState("");
  const [selectedColorPantDesignHover1, setSelectedColorPantDesignHover1] =
    useState("");
  const [selectedColorPantDesignHover2, setSelectedColorPantDesignHover2] =
    useState("");
  const [selectedColorPantDesignHover3, setSelectedColorPantDesignHover3] =
    useState("");
  const [selectedColorPantDesignHover4, setSelectedColorPantDesignHover4] =
    useState("");
  const [selectedColorPantDesignHover5, setSelectedColorPantDesignHover5] =
    useState("");
  const [selectedColorPantDesignHover6, setSelectedColorPantDesignHover6] =
    useState("");
  const [selectedColorDesignHover1, setSelectedColorDesignHover1] =
    useState("");
  const [selectedColorDesignHover2, setSelectedColorDesignHover2] =
    useState("");
  const [selectedColorDesignHover3, setSelectedColorDesignHover3] =
    useState("");
  const [selectedColorDesignHover4, setSelectedColorDesignHover4] =
    useState("");
  const [selectedColorDesignHover5, setSelectedColorDesignHover5] =
    useState("");
  const [selectedColorDesignHover6, setSelectedColorDesignHover6] =
    useState("");
  const [selectedColorDesignHover7, setSelectedColorDesignHover7] =
    useState("");
  const [selectedColorDesignHover8, setSelectedColorDesignHover8] =
    useState("");
  const [selectedColorDesignHover9, setSelectedColorDesignHover9] =
    useState("");
  const [selectedColorDesignHover10, setSelectedColorDesignHover10] =
    useState("");

  useEffect(() => {
    // Scroll to the top of the page when snap.intro changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [snap.intro]);

  const downloadCanvasToImage = () => {
    // Toggle to front view mode and save
    toggleViewMode("front");
    setTimeout(() => {
      downloadView("DesignView", "front");
      // Toggle to back view mode and save
      toggleViewMode("back");
      setTimeout(() => {
        downloadView("DesignView", "back");
        // Toggle back to the original view mode after saving both views
        toggleViewMode("front");
      }, 100); // Delay before saving the back view
    }, 100); // Delay before saving the front view
  };

  const handleDownload = (fileName) => {
    console.log("Downloading view mode:", viewMode); // Log the view mode before downloading
    // Download the view with the provided file name
    downloadView(fileName);
  };

  const downloadView = (fileName, viewMode) => {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      setTimeout(() => {
        // Delay the toggle to ensure the view switch completes
        const dataURL = canvas.toDataURL();
        // Save the image data in local storage
        localStorage.setItem(`${fileName}_${viewMode}`, dataURL);
      }, 100); // Adjust the delay time if necessary
    }
  };

  const handleClick = (presetColor, type, ind) => {
    const selectedColor = combinedColors.find(
      (color) => color.color === presetColor
    );

    if (type === "body") {
      state.colorBaseClick = presetColor;
      state.colorBaseOptClick = darkenColor(presetColor, 34);
      setSelectedColorBodyHover(true);
      setSelectedColorBody(selectedColor.name);
    }
    if (type === "cap") {
      state.colorCapOptClick = darkenColor(presetColor, 34);
      state.colorCapClick = presetColor;
      setSelectedColorCapHover(true);
      setSelectedColorCap(selectedColor.name);
    }
    if (type === "pant") {
      state.colorPantBaseClick = presetColor;
      state.colorPantBaseOptClick = darkenColor(presetColor, 34);
      setSelectedColorPantHover(true);
      setSelectedColorPant(selectedColor.name);
    }
    if (type === "waist") {
      state.colorPantWaistClick = presetColor;
      state.colorPantWaistOptClick = darkenColor(presetColor, 34);
      setSelectedColorPantWaistHover(true);
      setSelectedColorPantWaist(selectedColor.name);
    }
    if (type === "arm") {
      state.colorArmClick = presetColor;
      state.colorArmOptClick = darkenColor(presetColor, 34);
      setSelectedColorArmHover(true);

      setSelectedColorArm(selectedColor.name);
    }
    if (type === "collar") {
      state.colorCollarClick = presetColor;
      state.colorCollarOptClick = darkenColor(presetColor, 34);
      setSelectedColorCollarHover(true);
      setSelectedColorCollar(selectedColor.name);
    }
    if (type === "design") {
      state[`designColorClick${ind}`] = presetColor;
      state[`designColor${ind}OptClick`] = darkenColor(presetColor, 34);
      const setSelectedColorDesignHover = eval(
        `setSelectedColorDesignHover${ind}`
      );
      setSelectedColorDesignHover(selectedColor.name);
      const setSelectedColorDesign = eval(`setSelectedColorDesign${ind}`);
      setSelectedColorDesign(selectedColor.name);
    }
    if (type === "designPant") {
      state[`designColorPantClick${ind}`] = presetColor;
      state[`designColorPant${ind}OptClick`] = darkenColor(presetColor, 34);
      const setSelectedColorPantDesignHover = eval(
        `setSelectedColorPantDesignHover${ind}`
      );
      setSelectedColorPantDesignHover(selectedColor.name);
      const setSelectedColorPantDesign = eval(
        `setSelectedColorPantDesign${ind}`
      );
      setSelectedColorPantDesign(selectedColor.name);
    }
  };
  function darkenColor(color, darkness) {
    // Remove '#' if it's at the beginning
    color = color.replace("#", "");

    // Parse hexadecimal color into RGB components
    var r = parseInt(color.substring(0, 2), 16);
    var g = parseInt(color.substring(2, 4), 16);
    var b = parseInt(color.substring(4, 6), 16);

    // Reduce RGB values based on darkness level
    r = Math.max(0, r - darkness);
    g = Math.max(0, g - darkness);
    b = Math.max(0, b - darkness);

    // Convert RGB back to hexadecimal
    var result =
      "#" +
      (r < 16 ? "0" : "") +
      r.toString(16) +
      (g < 16 ? "0" : "") +
      g.toString(16) +
      (b < 16 ? "0" : "") +
      b.toString(16);

    return result;
  }

  const handleMouseEnter = (presetColor, type, ind) => {
    if (type === "body") {
      state.colorBase = presetColor;
      state.colorBaseOpt = darkenColor(presetColor, 34);
    }
    if (type === "cap") {
      state.colorCap = presetColor;
      state.colorCapOpt = darkenColor(presetColor, 34);
    }
    if (type === "pant") {
      state.colorPantBase = presetColor;
      state.colorPantBaseOpt = darkenColor(presetColor, 34);
    }
    if (type === "waist") {
      state.colorPantWaist = presetColor;
      state.colorPantWaistOpt = darkenColor(presetColor, 34);
    }
    if (type === "arm") {
      state.colorArm = presetColor;
      state.colorArmOpt = darkenColor(presetColor, 34);
    }
    if (type === "collar") {
      state.colorCollar = presetColor;
      state.colorCollarOpt = darkenColor(presetColor, 34);
    }
    if (type === "design") {
      state[`designColor${ind}`] = presetColor;
      state[`designColor${ind}Opt`] = darkenColor(presetColor, 34);
    }
    if (type === "designPant") {
      state[`designColorPant${ind}`] = presetColor;
      state[`designColorPant${ind}Opt`] = darkenColor(presetColor, 34);
    }
  };

  const handleMouseLeave = (type) => {
    // setSelectedColorCollarHover("black")
    
    // if (selectedColorCollarHover) {
      state.colorCollar = snap.colorCollarClick;
      state.colorCollarOpt = snap.colorCollarOptClick;
    // }
      state.colorBase = snap.colorBaseClick;
      state.colorBaseOpt = snap.colorBaseOptClick;
      state.colorCap = snap.colorCapClick;
      state.colorCapOpt = snap.colorCapOptClick;
      state.colorPantBase = snap.colorPantBaseClick;
      state.colorPantBaseOpt = snap.colorPantBaseOptClick;
      state.colorPantWaist = snap.colorPantWaistClick;
      state.colorPantWaistOpt = snap.colorPantWaistOptClick;
      state.colorArm = snap.colorArmClick;
      state.colorArmOpt = snap.colorArmOptClick;
      state.designColorPant1 = snap.designColorPantClick1;
      state.designColorPant1Opt = snap.designColorPant1OptClick;
      state.designColorPant2 = snap.designColorPantClick2;
      state.designColorPant2Opt = snap.designColorPant2OptClick;
      state.designColorPant3 = snap.designColorPantClick3;
      state.designColorPant3Opt = snap.designColorPant3OptClick;
      state.designColorPant4 = snap.designColorPantClick4;
      state.designColorPant4Opt = snap.designColorPant4OptClick;
      state.designColorPant5 = snap.designColorPantClick5;
      state.designColorPant5Opt = snap.designColorPant5OptClick;
      state.designColorPant6 = snap.designColorPantClick6;
      state.designColorPant6Opt = snap.designColorPant6OptClick;
      state.designColor1 = snap.designColorClick1;
      state.designColor1Opt = snap.designColor1OptClick;
      state.designColor2 = snap.designColorClick2;
      state.designColor2Opt = snap.designColor2OptClick;
      state.designColor3 = snap.designColorClick3;
      state.designColor3Opt = snap.designColor3OptClick;
      state.designColor4 = snap.designColorClick4;
      state.designColor4Opt = snap.designColor4OptClick;
      state.designColor5 = snap.designColorClick5;
      state.designColor5Opt = snap.designColor5OptClick;
      state.designColor6 = snap.designColorClick6;
      state.designColor6Opt = snap.designColor6OptClick;
      state.designColor7 = snap.designColorClick7;
      state.designColor7Opt = snap.designColor7OptClick;
      state.designColor8 = snap.designColorClick8;
      state.designColor8Opt = snap.designColor8OptClick;

    // Add similar logic for design if needed
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
  };
  const [expanded, setExpanded] = useState({
    collar: false,
    body: false,
    pant: false,
    waist: false,
    sleeve: false,
    cap: false,
    design: false,
    designPant: false,
  });

  const toggleExpanded = (type) => {
    setExpanded((prevState) => ({
      ...prevState,
      collar: type === "collar" ? !prevState.collar : false,
      body: type === "body" ? !prevState.body : false,
      pant: type === "pant" ? !prevState.pant : false,
      waist: type === "waist" ? !prevState.waist : false,
      cap: type === "cap" ? !prevState.cap : false,
      sleeve: type === "sleeve" ? !prevState.sleeve : false,
      design: type === "design" ? !prevState.design : false,
      designPant: type === "designPant" ? !prevState.designPant : false,
    }));
  };

  const handleCollarTypeChange = (type) => {
    setLoading(true);
    state.selectedCollarType = type;
    setLoading(false);
  };
  const toggleRotation = () => {
    setRotateEnabled(!rotateEnabled);
  };
  const goBackToDesigns = () => {
    state.intro = true;
    state.option = true;
  };
  const goBackToSelection = () => {
    state.option = true;
    state.type = null;
    state.intro = true;
  };
  const [viewMode, setViewMode] = useState(true);

  const toggleViewMode = () => {
    console.log("Current view mode:", viewMode);
    setViewMode((prevMode) => !prevMode); // Toggle the previous mode
  };

  const handleSubmit = async () => {
    await downloadCanvasToImage();
    setTimeout(() => {
      state.form = true;
      state.option = false;
      state.intro = true;
    }, [1000]);
  };

  return (
    <div className={styles.container} style={{ background: "lightblue" }}>
      {loading && <Loader />}
      {/* <img
        src={download}
        onClick={downloadCanvasToImage}
        alt="Download"
        className={styles.downloadButton}
      /> */}
      <button
        className={styles.submitButton}
        onClick={() => {
          handleSubmit();
        }}
      >
        Next
      </button>

      <div className={styles.canvas}>
        {!snap.intro && (
          <Canvas camera={{ fov: 25 }} gl={{ preserveDrawingBuffer: true }}>
            <CameraRig>
              <Center>
                {snap.type === "shirt" && (
                  <Shirt
                    logo={logo}
                    rotateEnabled={rotateEnabled}
                    viewMode={viewMode}
                  />
                )}
                {snap.type === "cap" && (
                  <Cap rotateEnabled={rotateEnabled} viewMode={viewMode} />
                )}
                {snap.type === "pant" && (
                  <Pant rotateEnabled={rotateEnabled} viewMode={viewMode} />
                )}
              </Center>
            </CameraRig>
          </Canvas>
        )}
      </div>
      {snap.type === "shirt" && (
        <div className={styles.sidebar}>
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={goBackToDesigns}>
            Go Back
            </button>
            <button className={styles.button} onClick={toggleRotation}>
              {rotateEnabled ? "Disable Rotation" : "Enable Rotation"}
            </button>
            <div className={`${styles.button}`} onClick={toggleViewMode}>
              Toggle View
            </div>
          </div>
          <p className={styles.intro}>
            Unleash your creativity! Our t-shirt is your canvas, ready for your
            artistic touch. Customize with vibrant colors at no extra charge.
            Your masterpiece won't proceed to production until you give the
            green light.
          </p>
          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("collar")}
            >
              <h3 className={styles.heading}>Collar</h3>
              <span className={styles.icon}>{expanded.collar ? "▼" : "▶"}</span>
            </div>
            {expanded.collar && (
              <>
                <h4 className={styles.subHeading}>Select Collar Type</h4>
                <div className={styles.collarTypes}>
                  {collarTypes.map((collar, index) => (
                    <img
                      key={index}
                      src={collar.image}
                      alt={collar.type}
                      className={
                        snap.selectedCollarType === collar.type
                          ? styles.selected
                          : ""
                      }
                      onClick={() => handleCollarTypeChange(collar.type)}
                    />
                  ))}
                </div>
                {snap.selectedCollarType && (
                  <p className={styles.intro1}>
                    Selected Collar Type:{" "}
                    {snap.selectedCollarType.toUpperCase()}
                  </p>
                )}

                <h4 className={styles.subHeading}>Select Collar Color</h4>
                <div className={styles.colorPicker}>
                  {combinedColors.map(({ color, name }, index) => (
                    <div
                      key={index}
                      className={`${styles.colorOption} ${
                        snap.colorCollarClick === color
                          ? styles.selectedColor
                          : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onMouseEnter={() => handleMouseEnter(color, "collar")}
                      onMouseLeave={() => handleMouseLeave("collar")}
                      onClick={() => handleClick(color, "collar")}
                    >
                      {snap.colorCollar === color && (
                        <span className={styles.colorName}>{name}</span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("body")}
            >
              <h3 className={styles.heading}>Base</h3>
              <span className={styles.icon}>{expanded.body ? "▼" : "▶"}</span>
            </div>

            {expanded.body && (
              <>
                <h4 className={styles.subHeading}>Select Base Color</h4>
                <div className={styles.colorPicker}>
                  {combinedColors.map(({ color, name }, index) => (
                    <div
                      key={index}
                      className={`${styles.colorOption} ${
                        snap.colorBaseClick === color
                          ? styles.selectedColor
                          : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onMouseEnter={() => handleMouseEnter(color, "body")}
                      onMouseLeave={() => handleMouseLeave("body")}
                      onClick={() => handleClick(color, "body")}
                    >
                      {snap.colorBase === color && (
                        <span className={styles.colorName}>{name}</span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("sleeve")}
            >
              <h3 className={styles.heading}>Sleeve</h3>
              <span className={styles.icon}>{expanded.sleeve ? "▼" : "▶"}</span>
            </div>

            {expanded.sleeve && (
              <>
                <h4 className={styles.subHeading}>Select Sleeve Color</h4>
                <div className={styles.colorPicker}>
                  {combinedColors.map(({ color, name }, index) => (
                    <div
                      key={index}
                      className={`${styles.colorOption} ${
                        snap.colorArmClick === color ? styles.selectedColor : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onMouseEnter={() => handleMouseEnter(color, "arm")}
                      onMouseLeave={() => handleMouseLeave("arm")}
                      onClick={() => handleClick(color, "arm")}
                    >
                      {snap.colorArm === color && (
                        <span className={styles.colorName}>{name}</span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("design")}
            >
              <h3 className={styles.heading}>Design</h3>
              <span className={styles.icon}>{expanded.design ? "▼" : "▶"}</span>
            </div>

            {expanded.design &&
              [...Array(snap.designColors)].map((_, index) => (
                <>
                  <h4 className={styles.subHeading}>
                    Select {snap[`designColor${index + 1}Name`]}
                  </h4>
                  <div className={styles.colorPicker}>
                    {combinedColors.map(({ color, name }) => (
                      <div
                        key={index + 1}
                        className={`${styles.colorOption} ${
                          snap[`designColorClick${index + 1}`] === color
                            ? styles.selectedColor
                            : ""
                        }`}
                        style={{ backgroundColor: color }}
                        onMouseEnter={() =>
                          handleMouseEnter(color, "design", index + 1)
                        }
                        onMouseLeave={() => handleMouseLeave("design")}
                        onClick={() => handleClick(color, "design", index + 1)}
                      >
                        {snap[`designColor${index + 1}`] === color && (
                          <span className={styles.colorName}>{name}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ))}
          </div>
        </div>
      )}
      {snap.type === "cap" && (
        <div className={styles.sidebar}>
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={goBackToSelection}>
              Go back to Selection
            </button>
            <button className={styles.button} onClick={toggleRotation}>
              {rotateEnabled ? "Disable Rotation" : "Enable Rotation"}
            </button>
            <div className={`${styles.button}`} onClick={toggleViewMode}>
              Toggle View
            </div>
          </div>
          <p className={styles.intro}>
            Unleash your creativity! Our t-shirt is your canvas, ready for your
            artistic touch. Customize with vibrant colors at no extra charge.
            Your masterpiece won't proceed to production until you give the
            green light.
          </p>

          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("cap")}
            >
              <h3 className={styles.heading}>Color</h3>
              <span className={styles.icon}>{expanded.cap ? "▼" : "▶"}</span>
            </div>

            {expanded.cap && (
              <>
                <h4 className={styles.subHeading}>Select Cap Color</h4>
                <div className={styles.colorPicker}>
                  {combinedColors.map(({ color, name }, index) => (
                    <div
                      key={index}
                      className={`${styles.colorOption} ${
                        snap.colorCapClick === color ? styles.selectedColor : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onMouseEnter={() => handleMouseEnter(color, "cap")}
                      onMouseLeave={() => handleMouseLeave("cap")}
                      onClick={() => handleClick(color, "cap")}
                    >
                      {snap.colorCapClick === color && (
                        <span className={styles.colorName}>
                          {selectedColorCap}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {snap.type === "pant" && (
        <div className={styles.sidebar}>
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={goBackToDesigns}>
              Go back to Designs
            </button>
            <button className={styles.button} onClick={toggleRotation}>
              {rotateEnabled ? "Disable Rotation" : "Enable Rotation"}
            </button>
            <div className={`${styles.button}`} onClick={toggleViewMode}>
              Toggle View
            </div>
          </div>
          <p className={styles.intro}>
            Unleash your creativity! Our t-shirt is your canvas, ready for your
            artistic touch. Customize with vibrant colors at no extra charge.
            Your masterpiece won't proceed to production until you give the
            green light.
          </p>

          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("pant")}
            >
              <h3 className={styles.heading}>Base</h3>
              <span className={styles.icon}>{expanded.pant ? "▼" : "▶"}</span>
            </div>

            {expanded.pant && (
              <>
                <h4 className={styles.subHeading}>Select Base Color</h4>
                <div className={styles.colorPicker}>
                  {combinedColors.map(({ color, name }, index) => (
                    <div
                      key={index}
                      className={`${styles.colorOption} ${
                        snap.colorPantBaseClick === color
                          ? styles.selectedColor
                          : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onMouseEnter={() => handleMouseEnter(color, "pant")}
                      onMouseLeave={() => handleMouseLeave("pant")}
                      onClick={() => handleClick(color, "pant")}
                    >
                      {snap.colorPantBaseClick === color && (
                        <span className={styles.colorName}>
                          {selectedColorPant}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("waist")}
            >
              <h3 className={styles.heading}>Waist Band</h3>
              <span className={styles.icon}>{expanded.waist ? "▼" : "▶"}</span>
            </div>

            {expanded.waist && (
              <>
                <h4 className={styles.subHeading}>Select Waist Band Color</h4>
                <div className={styles.colorPicker}>
                  {combinedColors.map(({ color, name }, index) => (
                    <div
                      key={index}
                      className={`${styles.colorOption} ${
                        snap.colorPantWaistClick === color
                          ? styles.selectedColor
                          : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onMouseEnter={() => handleMouseEnter(color, "waist")}
                      onMouseLeave={() => handleMouseLeave("waist")}
                      onClick={() => handleClick(color, "waist")}
                    >
                      {snap.colorPantWaistClick === color && (
                        <span className={styles.colorName}>
                          {selectedColorPantWaist}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div>
            <div
              className={styles.headingWrapper}
              onClick={() => toggleExpanded("designPant")}
            >
              <h3 className={styles.heading}>Design</h3>
              <span className={styles.icon}>
                {expanded.designPant ? "▼" : "▶"}
              </span>
            </div>

            {expanded.designPant &&
              [...Array(snap.designColorsPant)].map((_, index) => (
                <>
                  <h4 className={styles.subHeading}>
                    Select {snap[`designColorPant${index + 1}Name`]}
                  </h4>
                  <div className={styles.colorPicker}>
                    {combinedColors.map(({ color, name }) => (
                      <div
                        key={index + 1}
                        className={`${styles.colorOption} ${
                          snap[`designColorPantClick${index + 1}`] === color
                            ? styles.selectedColor
                            : ""
                        }`}
                        style={{ backgroundColor: color }}
                        onMouseEnter={() =>
                          handleMouseEnter(color, "designPant", index + 1)
                        }
                        onMouseLeave={() => handleMouseLeave("designPant")}
                        onClick={() =>
                          handleClick(color, "designPant", index + 1)
                        }
                      >
                        {snap[`designColorPantClick${index + 1}`] === color && (
                          <span className={styles.colorName}>
                            {eval(`selectedColorPantDesign${index + 1}`)}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CanvasModel;

import {toast} from "react-toastify"


const orderPlacedCustomerMail = async({
    order_id,order_date,order_amount,to_email,to_name,order_address,ordered_items,payment_mode})=>{

    try {
        const data = {
          service_id: "service_2vvxnro",
          template_id: "template_ne6u2wq",
          user_id: "2wR6mGxEdcjQnJZcM",
          template_params: {
            to_name: `${to_name}`,
order_id: `${order_id}`,
order_date: `${order_date}`,
order_amount: `${order_amount}`,
payment_mode: `${payment_mode}`,
to_email: `${to_email}`,
order_address: `${order_address}`,
ordered_items: `${ordered_items}`
           
          },
        };


        const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Failed to send email");
        }
        else{
            toast.success("Email Confirmation Sent")
        }



    
      } catch (error) {
        console.error("Error sending email:", error);
        toast.error("Failed to send email notification.");
      }

}

const orderPlacedAdminMail = async({order_id,
order_date,customer_name,shipping_address,ordered_items,payment_mode,order_amount})=>{

    try {
        const data = {
          service_id: "service_2vvxnro",
          template_id: "template_yyeklqh",
          user_id: "2wR6mGxEdcjQnJZcM",
          
          template_params: {
            order_id: `${order_id}`,
            order_date: `${order_date}`,
            order_amount: `${order_amount}`,
            customer_name: `${customer_name}`,
            shipping_address: `${shipping_address}`,
            ordered_items: `${ordered_items}`,
            payment_mode: `${payment_mode}`
            },
        };


        const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Failed to send email");
        }

      } catch (error) {
        console.error("Error sending email:", error);
        toast.error("Admin offline.");
      }




}

const returnCustomerMail = async({to_name, quantity, product_name, order_amount, to_email,order_id})=>{

    try {
        const data = {
          service_id: "service_2vvxnro",
          template_id: "template_6o4yg1j",
          user_id: "2wR6mGxEdcjQnJZcM",
          
          template_params: {
            to_name: `${to_name ?to_name: "Customer"}`,
            quantity: `${quantity}`,
            product_name: `${product_name}`,
            order_amount: `$${order_amount}`,
            to_email: `${to_email}`,
            order_id:`${order_id}`
        
           
          },
        };

        const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Failed to send email");
        }

      } catch (error) {
        console.error("Error sending email:", error);
        toast.error("Failed to send email notification.");
      }

}

const returnAdminMail = async({order_id, order_date, customer_name, return_reason})=>{

    try {
        const data = {
          service_id: "service_2vvxnro",
          template_id: "template_j5hizlf",
          user_id: "2wR6mGxEdcjQnJZcM",
          
          template_params: {
            order_id: `${order_id}`,
            order_date: `${order_date}`,
            customer_name: `${customer_name}`,
            return_reason: `${return_reason}`,
            },
        };


        const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Failed to send email");
        }

      } catch (error) {
        console.error("Error sending email:", error);
        toast.error("Admin offline.");
      }

}

const shippedMail = async({
    to_name,order_id,shipment_date,tracking_url,to_email
})=>{

    try {
        const data = {
          service_id: "service_2vvxnro",
          template_id: "template_qjzw3px",
          user_id: "2wR6mGxEdcjQnJZcM",
          
          template_params: {
            to_name: `${to_name}`,
            order_id: `${order_id}`,
            date: `${shipment_date}`,
            tracking_url: `${tracking_url}`,
            to_email: `${to_email}`,
            },
        };


        const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Failed to send email");
        }

      } catch (error) {
        console.error("Error sending email:", error);
        toast.error("Admin offline.");
      }



}

const teamKitMail = async({
  apparel_name,
  cust_name,
  team_name,
  cust_phone,
  email_address,
  pdf_url,
})=>{

  try {
      const data = {
        service_id: "service_2vvxnro",
        template_id: "template_0pn8bx5",
        user_id: "2wR6mGxEdcjQnJZcM",
        
        template_params: {
          apparel_name:  apparel_name,
          cust_name: cust_name,
          cust_phone: cust_phone,
          email_address: email_address,
          pdf_url: pdf_url,
          team_name:team_name
          },
      };


      const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Admin offline.");
    }



}

export {returnAdminMail,returnCustomerMail,teamKitMail,shippedMail,orderPlacedAdminMail,orderPlacedCustomerMail}
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  selectCartItems,
  selectCartTotalAmount,
  selectCartTotalAmountWithTaxes,
  selectCartTotalQuantity,
} from "../../../redux/slice/cartSlice";
import Card from "../../card/Card";
import styles from "./CheckoutSummary.module.scss";

const CheckoutSummary = ({ selectedState }) => {
  const cartItems = useSelector(selectCartItems);
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const discountedAmount2 = localStorage.getItem("discountedAmount2");
  const [cartTotalAmountWithTaxes, setCartTotalAmountWithTaxes] = useState(
    useSelector(selectCartTotalAmountWithTaxes)
  );
  const cartAmtwithTaxes = useSelector(selectCartTotalAmountWithTaxes);
  useEffect(() => {
    // Update cartTotalAmountWithTaxes if discountedAmount2 changes
    const updatedCartTotalAmountWithTaxes =
      discountedAmount2 && (parseFloat(discountedAmount2) / 100).toFixed(2);

    if (updatedCartTotalAmountWithTaxes !== undefined) {
      setCartTotalAmountWithTaxes(updatedCartTotalAmountWithTaxes);
    }
  }, [discountedAmount2]);
  const handleCancelDiscount = () => {
    // Clear discountedAmount2 from local storage
    localStorage.removeItem("discountedAmount2");
    setCartTotalAmountWithTaxes(cartAmtwithTaxes);

    // eslint-disable-next-line no-restricted-globals
    location.reload(true);
    // You might want to update the state accordingly if needed
  };

  return (
    <div>
      <h3>Checkout Summary</h3>
      <div>
        {cartItems.length === 0 ? (
          <>
            <p>No item in your cart.</p>
            <button className="--btn">
              <Link to="/">Back To Shop</Link>
            </button>
          </>
        ) : (
          <div>
            {cartItems.map((item, index) => {
              const {
                id,
                name,
                price,
                tax,
                sport,
                category,
                cartQuantity,
                selectedSize,
                knockingService,
                knockingServicePrice,
              } = item;

              const unitTotalPriceWithTax = price * (tax / 100) + price;
              const formattedUnitTotalPriceWithTax =
                typeof unitTotalPriceWithTax === "number"
                  ? unitTotalPriceWithTax.toFixed(2)
                  : "N/A";
              const amount = (
                (((price + knockingServicePrice) * tax) / 100) * cartQuantity +
                (price + knockingServicePrice) * cartQuantity
              ).toFixed(2);

              //   <Card cardClass={styles.card}>
              //   <h4>{name}</h4>
              //   {(sport==="Cricket"&& category ==="Bats")&&(<p>
              //   Knocking Service: {knockingService}
              // </p>)}
              //   <p>Quantity: {cartQuantity}</p>
              //   <p>Unit price: {price + knockingServicePrice}</p>
              //   <p>tax: {tax}%</p>
              //   <p>
              //     Amount:{" "}
              //     {(
              //       (((price+knockingServicePrice) * tax) / 100) * cartQuantity +
              //       price * cartQuantity
              //     ).toFixed(2)}
              //   </p>
              // </Card>

              return (
                <Card key={id} cardClass={styles.card}>
                  <h4>{name}</h4>
                  <p>Quantity: {cartQuantity}</p>
                  {sport === "Cricket" && category === "Bats" && (
                    <p>Knocking Service: {knockingService}</p>
                  )}
                  <p>Selected Size: {selectedSize}</p>
                  <p>Unit price: {price + knockingServicePrice}</p>
                  {selectedState === "New Jersey" ? (
                    <>
                      <p>Tax: {tax}% (${((tax/100)*price).toFixed(2)})</p>
                      <p>
                        Amount:{" "}
                        {(
                          (((price + knockingServicePrice) * tax) / 100) *
                            cartQuantity +
                          (price + knockingServicePrice) * cartQuantity
                        ).toFixed(2)}
                      </p>
                    </>
                  ) : (
                    <p>
                      Amount:{" "}
                      {((price + knockingServicePrice) * cartQuantity).toFixed(
                        2
                      )}
                    </p>
                  )}
                  {/* <p>
                    Discounted price:{" "}
                    {formattedDiscAmount < 0.01 ? "N/A" : formattedDiscAmount}
                  </p> */}
                </Card>
              );
            })}
          </div>
        )}
        <div className={styles.text}>
          <h3>{`Subtotal (${cartTotalQuantity} items):`}</h3>

          <span className={styles.price}>
            <span className={styles.priceSym1}>$</span>
            {selectedState === "New Jersey" ? (
              <span className={styles.priceData1}>
                {discountedAmount2
                  ? cartTotalAmountWithTaxes
                  : cartAmtwithTaxes.toFixed(2)}
              </span>
            ) : (
              <span className={styles.priceData1}>
                {discountedAmount2
                  ? cartTotalAmountWithTaxes
                  : cartTotalAmount.toFixed(2)}
              </span>
            )}
          </span>
          {discountedAmount2 && (
            <button onClick={handleCancelDiscount} className="--btn">
              Remove Discount
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutSummary;

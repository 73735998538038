import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../checkoutSuccess/CheckoutSuccess.module.scss";
import { useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useEffect } from "react";

const CheckoutSuccess = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectStatus = queryParams.get("redirect_status");

    if (redirectStatus === "failed") {
      // Redirect to checkout-failure page
      localStorage.setItem("status",true)
      navigate("/checkout");
    }
  }, [location.search, navigate]);


  return (
    <section>
      <div className={styles.container}>
        <h2>Checkout Successful</h2>
        <p>Thank you for your purchase</p>
        <br />

        <div className={styles.btn}>
          <button type="button" className={` --btn --btn-primary`}>
            <Link to="/order-history">View Order Status</Link>
          </button>
        </div>
      </div>
    </section>
  );
};

export default CheckoutSuccess;

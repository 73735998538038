import React from "react";
import styles from "./Terms.module.scss";

export default function Terms() {
  return (
    <div className={styles.privacyPolicy}>
      <h2>Terms and Conditions of Use</h2>
      <p>
        {" "}
        These terms and conditions outline the rules and regulations for the use
        of our website and services. By accessing or using our website, you
        agree to comply with these terms. Please take the time to read them
        carefully.
      </p>
      <h4>Privacy</h4>
      <p>
        We prioritize the protection of your privacy. Please review our detailed
        Privacy Policy, which governs how we handle your personal information
        and outlines our privacy practices.
      </p>
      <h4>Electronic Communications</h4>
      <p>
        When you interact with UltimateSportStore, you are engaging with us
        electronically. This includes receiving communications via email or
        notices posted on our site. By using our services, you consent to
        receiving electronic communications from us.
      </p>
      <h4>Copyright</h4>
      <p>
        All content on our site, such as text, graphics, logos, images, and
        software, is the property of UltimateSportStore or its content suppliers and
        is protected by copyright laws. Any unauthorized use of this content is
        strictly prohibited.
      </p>
      <h4>Trademarks</h4>
      <p>
        UltimateSportStore trademarks and trade dress are exclusive to our brand.
        They should not be used in a way that may cause confusion or discredit
        our company. Any trademarks not owned by UltimateSportStore are the property
        of their respective owners.
      </p>
      <h4>License and Site Access</h4>
      <p>
        UltimateSportStore grants you a limited license to access and use our site
        for personal purposes. However, any commercial use, reproduction, or
        unauthorized access is strictly prohibited without our express written
        consent.
      </p>
      <h4>Your Membership Account</h4>
      <p>
        By using our site, you are responsible for maintaining the
        confidentiality of your account information. If you are under 18, you
        may only use our website with the involvement of a parent or guardian.
        UltimateSportStore reserves the right to refuse service, terminate accounts,
        and edit or remove content at our discretion.
      </p>
      <h4>Reviews, Comments, Emails, and Other Content</h4>
      <p>
        You may submit comments, suggestions, or other content, provided it
        adheres to our guidelines. By submitting content, you grant
        UltimateSportStore a nonexclusive, royalty-free right to use, reproduce, and
        display that content.
      </p>
      <h4>Risk of Loss</h4>
      <p>
        We assume all risk until the package is delivered to you through our
        trusted shippers. If a package is lost in transit, we will replace the
        product for you.
      </p>
      <h4>Product Descriptions</h4>
      <p>
        UltimateSportStore strives for accurate product descriptions. If a product is
        not as described, your sole remedy is to return it in unused condition.
      </p>
      <h4>Disclaimer of Warranties and Limitation of Liability</h4>
      <p>
        Our site is provided "as is" and "as available." UltimateSportStore disclaims
        all warranties, express or implied, and will not be liable for any
        damages arising from the use of our site.
      </p>
      <h4>Disputes</h4>
      <p>
        We are committed to working closely with you to resolve any issues. Our
        customers are our focus, and we will do our best to address and rectify
        problems.
      </p>
      <h4>Site Policies, Modification, and Severability</h4>
      <p>
        Please review our other policies, such as Shipping and Returns. We
        reserve the right to make changes to our site, policies, and these Terms
        and Conditions at any time. If any provision is deemed invalid, it will
        not affect the validity of the remaining provisions.
      </p>
      <br></br>
      <p>
        Thank you for choosing UltimateSportStore! If you do not agree to these
        terms, please refrain from using our site
      </p>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import styles from "./ManageCuratedJersey.module.scss";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase/config";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

const ManageCuratedJersey = () => {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchCuratedJerseys = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "curatedJerseys"));
        const imageUrls = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          url: doc.data().imageUrl,
        }));
        setImages(imageUrls);
      } catch (error) {
        console.error("Error fetching curated jerseys:", error.message);
        toast.error("Error fetching curated jerseys");
      }
    };

    fetchCuratedJerseys();
  }, []);

  const handleImageUpload = async (files) => {
    if (files) {
      try {
        setIsUploading(true);
        const imageUrls = await Promise.all(Array.from(files).map(uploadImage));
        const newImageDocs = await Promise.all(imageUrls.map(addImageDoc));
        const updatedImages = [...images, ...newImageDocs];
        setImages(updatedImages);

        toast.success("Images added successfully");
      } catch (error) {
        console.error("Error uploading images:", error.message);
        toast.error("Error uploading images");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const uploadImage = async (file) => {
    const storageRef = ref(storage, `curatedJerseys/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const addImageDoc = async (imageUrl) => {
    const docRef = await addDoc(collection(db, "curatedJerseys"), {
      imageUrl,
    });
    return { id: docRef.id, url: imageUrl };
  };

  const handleImageDelete = async (image) => {
    try {
      setImages(images.filter((img) => img.id !== image.id));

      const docRef = doc(db, "curatedJerseys", image.id);
      await deleteDoc(docRef);

      // Delete image from storage
      const storageRef = ref(storage, image.url);
      await deleteObject(storageRef);

      toast.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error.message);
      toast.error("Error deleting image");
    }
  };

  return (
    <div className={styles.jerseyCategory}>
      <h2>Manage Curated Jersey</h2>

      <div className={styles.imageList}>
        {images.map((image) => (
          <div key={image.id} className={styles.imageItem}>
            <img src={image.url} alt={`Jersey`} />
            <button onClick={() => handleImageDelete(image)}>Delete</button>
          </div>
        ))}
      </div>

      <div className={styles.imageUpload}>
        <input
          type="file"
          multiple
          onChange={(e) => handleImageUpload(e.target.files)}
        />
        {isUploading && <p>Uploading...</p>}
      </div>
    </div>
  );
};

export default ManageCuratedJersey;

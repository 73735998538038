import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import styles from "./ManageCouponCode.module.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Loader from "../../loader/Loader";
import Notiflix from "notiflix";
import {
  onSnapshot,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  setDoc
} from "firebase/firestore";
import Modal from "react-modal";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import styled from "styled-components";

Modal.setAppElement("#root");

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto; /* Set width to auto */
  max-width: 90%; /* Set max-width to 90% of the viewport width */
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%; /* Set width to 100% */
  max-width: 600px; /* Set max-width for larger screens */
  height: auto;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Add a shadow */
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
`;

const StyledButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
`;

const CancelButton = styled(StyledButton)`
  background-color: #f44336;
`;

const ManageCouponCode = () => {
  const [coupons, setCoupons] = useState([]);
  const [couponName,setCouponName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [editCoupon, setEditCoupon] = useState(null);
  const [discountType, setDiscountType] = useState("percentage"); // State for discount type

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponsCollection = collection(db, "coupons");
        const snapshot = await getDocs(couponsCollection);
        const couponData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCoupons(couponData);
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    };
  
    fetchCoupons();
  }, [editCoupon]); // Add editCoupon to the dependency array
  

  const toggleCouponStatus = async (couponId, currentStatus) => {
    try {
      const couponRef = doc(db, "coupons", couponId);
      await updateDoc(couponRef, {
        status: !currentStatus,
      });
  
      // Update the status in the coupons state
      setCoupons(prevCoupons =>
        prevCoupons.map(coupon =>
          coupon.id === couponId ? { ...coupon, status: !currentStatus } : coupon
        )
      );
  
      toast.success("Coupon status updated successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };
  

  const confirmDelete = (couponId) => {
    Notiflix.Confirm.show(
      "Delete Coupon!!!",
      "You are about to delete this coupon",
      "Delete",
      "Cancel",
      function okCb() {
        deleteCoupon(couponId);
      },
      function cancelCb() {},
      {
        width: "320px",
        borderRadius: "3px",
        titleColor: "orangered",
        okButtonBackground: "orangered",
        cssAnimationStyle: "zoom",
      }
    );
  };

  const deleteCoupon = async (couponId) => {
    try {
      const couponRef = doc(db, "coupons", couponId);
      await deleteDoc(couponRef);
      toast.success("Coupon deleted successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const openEditModal = (coupon) => {
    setEditCoupon(coupon);
    setDiscountType(coupon.discountPercentage ? "percentage" : "amount");
  };

  const closeEditModal = () => {
    setEditCoupon(null);
  };

  const updateCoupon = async (updatedData) => {
    try {
      const couponRef = doc(db, "coupons", editCoupon.id);
      await setDoc(couponRef, updatedData); // Use setDoc instead of updateDoc
      toast.success("Coupon updated successfully.");
      closeEditModal();
    } catch (error) {
      toast.error(error.message);
    }
  };
  

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.table}>
        <h2>Manage Coupon Codes</h2>
        {coupons.length === 0 ? (
          <p>No coupons found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Coupon Code</th>
                <th>Valid From</th>
                <th>Valid Until</th>
                <th>Discount Value</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon) => (
                <tr key={coupon.id}>
                  <td>{coupon.code}</td>
                  <td>{coupon.validFromDate}</td>
                  <td>{coupon.validUntilDate}</td>
                  <td>{`${coupon.discountPercentage ? coupon.discountPercentage+"%":coupon.discountAmount+"$"}` }</td>
                  <td>
                    <Toggle
                      checked={coupon.status}
                      icons={false}
                      onChange={() =>
                        toggleCouponStatus(coupon.id, coupon.status)
                      }
                    />
                  </td>
                  <td className={styles.icons}>
                    <FaEdit
                      size={20}
                      color="green"
                      onClick={() => openEditModal(coupon)}
                    />
                    &nbsp;
                    <FaTrashAlt
                      size={18}
                      color="red"
                      onClick={() => confirmDelete(coupon.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Edit Coupon Modal */}
      <StyledModal
        isOpen={!!editCoupon}
        onRequestClose={closeEditModal}
        contentLabel="Edit Coupon Modal"
      >
        <ModalContent>
          <h2>Edit Coupon</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const updatedData = {
                validFromDate: e.target.validFromDate.value,
                code: editCoupon.code,
                validUntilDate: e.target.validUntilDate.value,
                [discountType === "percentage" ? "discountPercentage" : "discountAmount"]: Number(e.target.discountValue.value),
                singleUse: e.target.singleUse.checked,
                status:true
              };
              updateCoupon(updatedData);
            }}
          >
            <br />
            <h3>Valid From:</h3>
            <StyledInput
              type="date"
              name="validFromDate"
              defaultValue={editCoupon?.validFromDate}
              required
            /><br />
            <h3>Valid Until:</h3>
            <StyledInput
              type="date"
              name="validUntilDate"
              defaultValue={editCoupon?.validUntilDate}
              required
            /><br />
            <h3>Discount Type:</h3>
            <select
  id="discountType"
  required
  value={discountType}  // Make sure to bind the value of the select dropdown to the discountType state
  onChange={(e) => setDiscountType(e.target.value)} // Update the discountType state when the user changes the value
  className={styles.input}
>
  <option value="percentage">Percentage</option>
  <option value="amount">Amount</option>
</select><br />
            <h3>{`Discount ${discountType === "percentage" ? "Percentage" : "Amount"}:`}</h3>
            <StyledInput
              type="number"
              name="discountValue"
              defaultValue={editCoupon?.discountPercentage || editCoupon?.discountAmount}
              required
            /><br />
            <h3>Single Use:</h3>
            <Toggle
              id="singleUse"
              name="singleUse"
              defaultChecked={editCoupon?.singleUse}
              icons={false}
            /><br />
            <div>
              <StyledButton type="submit">Update Coupon</StyledButton>
              <CancelButton onClick={closeEditModal}>Cancel</CancelButton>
            </div>
          </form>
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default ManageCouponCode;

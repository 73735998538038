import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, addDoc, updateDoc, doc, arrayUnion, arrayRemove, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styles from './AdminTeamKit.module.scss';
import Modal from 'react-modal';

const storage = getStorage();

const AdminTeamKit = () => {
  const [sections, setSections] = useState([]);
  const [newSectionName, setNewSectionName] = useState('');
  const [showNewSectionInput, setShowNewSectionInput] = useState(false);
  const [newFieldLabels, setNewFieldLabels] = useState({});
  const [newFieldType, setNewFieldType] = useState('text');
  const [newFieldOptions, setNewFieldOptions] = useState([]);
  const [newFieldSizeLimit, setNewFieldSizeLimit] = useState(0);
  const [showNewFieldInput, setShowNewFieldInput] = useState({});
  const [editingField, setEditingField] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedFieldIndex, setEditedFieldIndex] = useState(null); // Added state to track the index of the edited field
  const [lastCreatedSectionId, setLastCreatedSectionId] = useState(null); 
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'teamkitsections'), (snapshot) => {
      const fetchedSections = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSections(fetchedSections);
    });

    return () => unsubscribe();
  }, []);

  const addSection = async () => {
    try {
      if (newSectionName.trim() !== '') {
        const newSectionRef = await addDoc(collection(db, 'teamkitsections'), { name: newSectionName, fields: [] });
        const newSection = { id: newSectionRef.id, name: newSectionName, fields: [] };
        // setSections(prevSections => [...prevSections, newSection]); // Add the new section to the sections array
        setLastCreatedSectionId(newSectionRef.id); // Update the ID of the last created section
        setNewSectionName('');
        setShowNewSectionInput(false);
      } else {
        console.error('Section name cannot be empty');
      }
    } catch (error) {
      console.error('Error adding section:', error);
    }
  };

  const addField = (sectionId) => {
    setShowNewFieldInput(prevState => ({ ...prevState, [sectionId]: true }));
  };

  const saveField = async (sectionId) => {
    if (newFieldLabels[sectionId]?.trim() !== '') {
      let imageUrls = [];
      if (newFieldType === 'radio-image') {
        const imageUploadPromises = Array.from(newFieldOptions).map(async image => {
          const imageRef = ref(storage, `radio_images/${sectionId}/${image.name}`);
          await uploadBytes(imageRef, image);
          const imageUrl = await getDownloadURL(imageRef);
          imageUrls.push(imageUrl);
        });
        await Promise.all(imageUploadPromises);
      }

      const newField = {
        label: newFieldLabels[sectionId],
        type: newFieldType,
        options: newFieldType === 'dropdown' ? newFieldOptions.split(',') : imageUrls,
        sizeLimit: newFieldType === 'file' || newFieldType === 'multiple-files' ? newFieldSizeLimit : null,
      };
      try {
        const sectionRef = doc(db, 'teamkitsections', sectionId);
        await updateDoc(sectionRef, {
          fields: arrayUnion(newField)
        });
        // setSections(prevSections => {
        //   return prevSections.map(section => {
        //     if (section.id === sectionId) {
        //       return {
        //         ...section,
        //         fields: section.fields ? [...section.fields, newField] : [newField],
        //       };
        //     }
        //     return section;
        //   });
        // });
        // setNewFieldLabels(prevLabels => ({ ...prevLabels, [sectionId]: '' }));
        setNewFieldOptions([]);
        setNewFieldSizeLimit(0);
        setShowNewFieldInput(prevState => ({ ...prevState, [sectionId]: false }));
      } catch (error) {
        console.error('Error saving field:', error);
      }
    }
  };

  const deleteField = async (sectionId, fieldIndex) => {
    try {
      const sectionRef = doc(db, 'teamkitsections', sectionId);
      await updateDoc(sectionRef, {
        fields: arrayRemove(sections.find(section => section.id === sectionId).fields[fieldIndex])
      });
      setSections(prevSections =>
        prevSections.map(section => {
          if (section.id === sectionId) {
            const updatedFields = section.fields.filter((_, index) => index !== fieldIndex);
            return { ...section, fields: updatedFields };
          }
          return section;
        })
      );
    } catch (error) {
      console.error('Error deleting field:', error);
    }
  };

  const deleteSection = async (sectionId) => {
    try {
      await deleteDoc(doc(db, 'teamkitsections', sectionId));
      setSections(prevSections =>
        prevSections.filter(section => section.id !== sectionId)
      );
    } catch (error) {
      console.error('Error deleting section:', error);
    }
  };

  const toggleSection = async (sectionId) => {
    try {
      const sectionRef = doc(db, 'teamkitsections', sectionId);
      const section = sections.find(section => section.id === sectionId);
      await updateDoc(sectionRef, { enabled: !section.enabled });
      setSections(prevSections =>
        prevSections.map(section => {
          if (section.id === sectionId) {
            return { ...section, enabled: !section.enabled };
          }
          return section;
        })
      );
    } catch (error) {
      console.error('Error toggling section:', error);
    }
  };

  const editField = (field, sectionId, fieldIndex) => { // Added fieldIndex parameter
    setEditingField({ ...field, sectionId });
    setIsEditModalOpen(true);
    setEditedFieldIndex(fieldIndex); // Set the index of the edited field
  };

  return (
    <div className={styles['admin-panel']}>
      <h2>Team Kit Form Fields</h2>
      <div>
        {editingField && (
          <EditFieldModal isOpen={isEditModalOpen} onClose={() => {setIsEditModalOpen(false)
          // eslint-disable-next-line no-restricted-globals
          location.reload()}} field={editingField} fieldIndex={editedFieldIndex} sections={sections} setSections = {setSections}/>
        )}
        {sections.map(section => (
          <div key={section.id}>
            <h3>{section.name}</h3>
            <button className={styles.delete} onClick={() => deleteSection(section.id)}>Delete Section</button>
            <button className={styles[section.enabled ? 'disable' : 'enable']} onClick={() => toggleSection(section.id)}>{section.enabled ? 'Disable' : 'Enable'} Section</button>
            <h4>Fields</h4>
            {section.fields && section.fields.map((field, index) => (
              <div key={index} className={styles.fieldContainer}>
                <div className={styles.fieldContent}>
                  <span className={styles.fieldName}>{field.label} ({field.type})</span>
                  <button className={styles.edit} onClick={() => editField(field, section.id, index)}>Edit</button>
                  <button className={styles.delete} onClick={() => deleteField(section.id, index)}>Delete</button>
                </div>
              </div>
            ))}
            {showNewFieldInput[section.id] && (
              <div>
                <input
                  type="text"
                  value={newFieldLabels[section.id] || ''}
                  onChange={(e) => setNewFieldLabels(prevLabels => ({ ...prevLabels, [section.id]: e.target.value }))}
                  placeholder="Enter new field label"
                />
                <select value={newFieldType} onChange={(e) => setNewFieldType(e.target.value)}>
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="color">Color Choice</option>
                  <option value="file">Single File</option>
                  <option value="multiple-files">Multiple Files</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="radio-image">Radio Image</option>
                  <option value="description">Description</option>
                </select>
                {newFieldType === 'dropdown' && (
                  <input
                    type="text"
                    value={newFieldOptions}
                    onChange={(e) => setNewFieldOptions(e.target.value)}
                    placeholder="Enter options (comma-separated)"
                  />
                )}
                
                {newFieldType === 'radio-image' && (
                  <div>
                    <label htmlFor={`imageInput-${section.id}`}>
                      Select multiple images:
                      <input
                        id={`imageInput-${section.id}`}
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={(e) => setNewFieldOptions(e.target.files)}
                      />
                    </label>
                  </div>
                )}
                <button className={styles.other} onClick={() => saveField(section.id)}>Save Field</button>
              </div>
            )}
            <button className={styles.add} onClick={() => addField(section.id)}>Add Field</button>
            <br /><br /><hr />
          </div>
        ))}
        <br /><br />
        {showNewSectionInput ? (
          <div>
            <input
              type="text"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
              placeholder="Enter new section name"
            />
            <button className={styles.other} onClick={addSection}>Add Section</button>
          </div>
        ) : (
          <button className={styles.addSec} onClick={() => setShowNewSectionInput(true)}>Add Section</button>
        )}
      </div>
    </div>
  );
};

const EditFieldModal = ({ isOpen, onClose, field, fieldIndex,sections,setSections }) => { // Added fieldIndex parameter
  const [editedField, setEditedField] = useState({ ...field });

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    setEditedField(prevField => ({
      ...prevField,
      options: [...prevField.options, ...files],
    }));
  };

  const handleDeleteImage = (index) => {
    const updatedOptions = editedField.options.filter((_, i) => i !== index);
    setEditedField(prevField => ({
      ...prevField,
      options: updatedOptions,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const sectionId = field.sectionId;
      const section = sections.find(sec => sec.id === sectionId);
      const updatedFields = [...section.fields]; // Copy the fields array to modify it
      updatedFields[fieldIndex] = editedField; // Update the edited field in the copied array
      const sectionRef = doc(db, 'teamkitsections', sectionId);
      await updateDoc(sectionRef, { fields: updatedFields }); // Update the fields array in the database
      setSections(prevSections =>
        prevSections.map(sec => {
          if (sec.id === sectionId) {
            return { ...sec, fields: updatedFields }; // Update the fields array in the local state
          }
          return sec;
        })
      );
      onClose(); // Close the modal after saving changes
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };
  

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.EditFieldModal}>
      <div className={styles.modalContent}>
        <h2>Edit Field</h2>
        <label htmlFor="label">Label:</label>
        <input type="text" id="label" name="label" value={editedField.label} onChange={() => {}} />
        <label htmlFor="type">Type:</label>
        <select id="type" name="type" value={editedField.type} onChange={() => {}}>
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="file">File</option>
          <option value="multiple-files">Multiple Files</option>
          <option value="dropdown">Dropdown</option>
          <option value="radio-image">Radio Image</option>
        </select>
        {editedField.type === 'radio-image' && (
          <div>
            {editedField.options.map((image, index) => (
              <div key={index} className={styles.imageContainer}>
                <img src={image} alt={`Image ${index}`} className={styles.image} height={"150px"} width={"150px"}/>
                <button onClick={() => handleDeleteImage(index)}>Delete</button>
              </div>
            ))}
            <input type="file" accept="image/*" multiple onChange={handleFileInputChange} />
          </div>
        )}
        <button className={styles.other} onClick={handleSaveChanges}>Save Changes</button>
        <button className={styles.cancel} onClick={onClose}>Cancel</button>
      </div>
    </Modal>
  );
};

export default AdminTeamKit;

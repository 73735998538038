import { motion, AnimatePresence } from "framer-motion";
import { useSnapshot } from "valtio";
import state from "../State";
import design1 from "../assets/designs/1.png";
import ShirtForm from "../form/ShirtForm";
import PantForm from "../form/PantForm";
import CapForm from "../form/CapForm";
import design2 from "../assets/designs/2.jpg";
import design3 from "../assets/designs/3.jpg";
import design4 from "../assets/designs/4.jpg";
import design5 from "../assets/designs/5.jpg";
import design6 from "../assets/designs/6.jpg";
import designPant1 from "../assets/pantDesigns/1.png";
import designPant2 from "../assets/pantDesigns/2.png";
import designPant3 from "../assets/pantDesigns/3.png";
import designPant4 from "../assets/pantDesigns/4.png";
import designPant5 from "../assets/pantDesigns/5.png";
import designPant6 from "../assets/pantDesigns/6.png";
import design7 from "../assets/designs/7.jpg";
import design8 from "../assets/designs/8.jpg";
import design9 from "../assets/designs/9.jpg";
import design10 from "../assets/designs/10.jpg";
import cap from "../assets/cap.gif";
import shirt from "../assets/shirt.gif";
import pant from "../assets/pant.gif";
import design11 from "../assets/designs/11.jpg";
import design12 from "../assets/designs/12.jpg";
import design13 from "../assets/designs/13.jpg";
import design14 from "../assets/designs/14.jpg";
import design15 from "../assets/designs/15.jpg";
import design16 from "../assets/designs/16.jpg";
import design17 from "../assets/designs/17.png";
import design18 from "../assets/designs/18.png";
import design19 from "../assets/designs/19.png";
import design20 from "../assets/designs/20.png";
import design21 from "../assets/designs/21.png";
import design22 from "../assets/designs/22.png";
import design23 from "../assets/designs/23.png";
import design24 from "../assets/designs/24.png";
import design25 from "../assets/designs/25.png";
import design26 from "../assets/designs/26.png";
import design27 from "../assets/designs/27.png";
import design28 from "../assets/designs/28.png";
import design29 from "../assets/designs/29.png";
import design32 from "../assets/designs/32.png";
import design30 from "../assets/designs/30.png";
import design31 from "../assets/designs/31.png";
import design33 from "../assets/designs/33.png";
import design34 from "../assets/designs/34.png";
import design35 from "../assets/designs/35.png";
import design36 from "../assets/designs/36.png";
import design37 from "../assets/designs/37.png";
import design38 from "../assets/designs/38.png";
import design39 from "../assets/designs/39.png";
import design40 from "../assets/designs/40.png";
import design41 from "../assets/designs/41.png";
import design42 from "../assets/designs/42.png";
import design43 from "../assets/designs/43.png";
import design44 from "../assets/designs/44.png";
import design45 from "../assets/designs/45.png";
import design46 from "../assets/designs/46.png";
import design47 from "../assets/designs/47.png";
import design48 from "../assets/designs/48.png";
import design49 from "../assets/designs/49.png";
import design50 from "../assets/designs/50.png";

import styles from "./Home.module.scss";
import { useEffect, useState } from "react";
import Loader from "../../loader/Loader";
import { useNavigate } from "react-router-dom";
import CuratedForm from "../form/CuratedForm";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
const designs = {
  shirt: [
    { id: 1, imageUrl: design1 },
    { id: 17, imageUrl: design17 },
    { id: 18, imageUrl: design18 },
    { id: 20, imageUrl: design20 },
    { id: 22, imageUrl: design22 },
    { id: 2, imageUrl: design2 },
    { id: 25, imageUrl: design25 },
    { id: 21, imageUrl: design21 },
    { id: 12, imageUrl: design12 },
    { id: 19, imageUrl: design19 },
    { id: 26, imageUrl: design26 },
    { id: 3, imageUrl: design3 },
    { id: 27, imageUrl: design27 },
    { id: 28, imageUrl: design28 },
    { id: 29, imageUrl: design29 },
    { id: 14, imageUrl: design14 },
    { id: 30, imageUrl: design30 },
    { id: 13, imageUrl: design13 },
    { id: 31, imageUrl: design31 },
    { id: 23, imageUrl: design23 },
    { id: 32, imageUrl: design32 },
    { id: 24, imageUrl: design24 },
    { id: 11, imageUrl: design11 },
    { id: 33, imageUrl: design33 },
    { id: 16, imageUrl: design16 },
    { id: 10, imageUrl: design10 },
    { id: 34, imageUrl: design34 },
    { id: 35, imageUrl: design35 },
    { id: 36, imageUrl: design36 },
    { id: 15, imageUrl: design15 },
    { id: 37, imageUrl: design37 },
    { id: 38, imageUrl: design38 },
    { id: 4, imageUrl: design4 },
    { id: 39, imageUrl: design39 },
    { id: 40, imageUrl: design40 },
    { id: 6, imageUrl: design6 },
    { id: 41, imageUrl: design41 },
    { id: 5, imageUrl: design5 },
    { id: 42, imageUrl: design42 },
    { id: 43, imageUrl: design43 },
    { id: 9, imageUrl: design9 },
    { id: 44, imageUrl: design44 },
    { id: 45, imageUrl: design45 },
    { id: 46, imageUrl: design46 },
    { id: 47, imageUrl: design47 },
    { id: 7, imageUrl: design7 },
    { id: 48, imageUrl: design48 },
    { id: 49, imageUrl: design49 },
    { id: 50, imageUrl: design50 },
    { id: 8, imageUrl: design8 },
  ],

  cap: [{ id: 1, imageUrl: design1 }],
  pant: [
    { id: 1, imageUrl: designPant1 },
    { id: 2, imageUrl: designPant2 },
    { id: 3, imageUrl: designPant3 },
    { id: 4, imageUrl: designPant4 },
    { id: 5, imageUrl: designPant5 },
    { id: 6, imageUrl: designPant6 },
  ],
  curated: [],
};

const options = [
  { id: "shirt", imageUrl: shirt },
  { id: "pant", imageUrl: pant },
  { id: "cap", imageUrl: cap },
  { id: "curated", imageUrl: shirt },
];
const Home = () => {
  const snap = useSnapshot(state);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to "auto" for instant scrolling
    });
  }, [snap.option, snap.type, snap.form, snap.intro]);
  useEffect(() => {
    if (!sessionStorage.getItem("reloaded")) {
      sessionStorage.setItem("reloaded", "true");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, []);
  function onOptionClick(option) {
    if (option === "cap") {
      state.option = false;
      state.type = option;
      onDesignClick(1);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setSelectedOption(option);
      state.option = false;
      state.type = option;
      console.log(state);
    }
  }
  const [curatedDesigns, setCuratedDesigns] = useState([]);

  useEffect(() => {
    const fetchCuratedDesigns = async () => {
      try {
        setLoading(true);

        const curatedCollection = collection(db, "curatedJerseys");
        const curatedSnapshot = await getDocs(curatedCollection);
        const curatedDesigns = curatedSnapshot.docs.map((doc) => ({
          id: doc.id,
          imageUrl: doc.data().imageUrl,
        }));
        designs.curated = curatedDesigns;
        await setCuratedDesigns(designs.curated);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching curated designs:", error);
      }
    };

    fetchCuratedDesigns();
  }, []);

  const convertToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };
  const navigate = useNavigate();

  const onDesignClick = async (id) => {
    if (snap.type === "curated") {
      state.id = id;
      setLoading(true);
      const design = designs[selectedOption].find((d) => d.id === id);
      if (design) {
        try {
          const base64DataUrl = await convertToBase64(design.imageUrl);
          localStorage.setItem("curatedJersey", base64DataUrl);
        } catch (error) {
          console.error("Error converting image to base64:", error);
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      state.form = true;
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      state.id = id;
      state.intro = false;
    }
  };
  function backToSelection() {
    state.option = true;
    state.type = null;
    setSelectedOption(null);
  }
  function curatedSelection() {
    state.type = "curated";
    state.option = false;
    state.intro = true;
    setSelectedOption("curated");
  }
  function shirtSelection() {
    state.type = "shirt";
    state.option = false;
    state.intro = true;
    setSelectedOption("shirt");
  }
  function backToHome() {
    navigate("/");
  }

  const [curatedImageIndex, setCuratedImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCuratedImageIndex(
        (prevIndex) => (prevIndex + 1) % curatedDesigns.length
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [curatedDesigns]);
  console.log(curatedDesigns);
  return (
    <div>
      <>
        {snap.option && (
          <>
            {loading && <Loader />}
            <div
              className={styles.padiv}
              style={{ paddingLeft: "70px", marginTop: "30px" }}
            >
              <h4 className={styles.linkstyle} onClick={() => backToHome()}>
                &larr; Back to Home
              </h4>
            </div>
            <div className={styles.optionsWrapper}>
              <div
                className={`${styles.section} ${styles["customize-section"]}`}
              >
                <h2 className={styles.sectionHeading}>Customize Your Own</h2>
                <div
                  className={`${styles["options-container"]} ${styles["design-grid"]} ${styles.gridc}`}
                >
                  {options.slice(0, 3).map((option) => (
                    <div
                      key={option.id}
                      className={styles.option}
                      onClick={() => onOptionClick(option.id)}
                    >
                      <img
                        src={option.imageUrl}
                        alt={`Option ${option.id}`}
                        className={styles["design-image"]}
                      />
                      <div className={styles.viewAllContainer}>
  <p onClick={() => onOptionClick(option.id)} className={styles.viewAllText}>CREATE {option.id.toUpperCase()} {">"}</p>
</div>
                     
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.divider}></div>
              <div className={`${styles.section} ${styles["curated-section"]}`}>
                <h2 className={styles.sectionHeading}>
                  Select from Curated and Trending Designs
                </h2>
                
                <div
                  className={`${styles["options-container"]} ${styles["design-grid"]}`}
                >
                  <div
                    key={options[3].id}
                    className={`${styles["option"]} ${styles["option2"]}`}
                    onClick={() => onOptionClick(options[3].id)}
                  >

                    {curatedDesigns.length > 0 && (
                      <>
                    
                      <img
                        src={curatedDesigns[curatedImageIndex]?.imageUrl}
                        alt={`Curated Design ${curatedImageIndex}`}
                        className={styles["curated-image"]}
                      />
                      </>
                      
                    )}
                   <br></br>
                    <div className={styles.viewAllContainer}>
  <p className={styles.viewAllText} onClick={() => onOptionClick(options[3].id)}>View All {">"}</p>
</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedOption && snap.intro && !snap.form && (
          <>
            {loading && <Loader />}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={styles.padiv}
                style={{ paddingLeft: "70px", marginTop: "30px" }}
              >
                <h4
                  className={styles.linkstyle}
                  onClick={() => backToSelection()}
                >
                  &larr; Back to Selection
                </h4>
              </div>{" "}
              {selectedOption === "shirt" && (
                <div
                  className={styles.padiv}
                  style={{
                    textAlign: "right",
                    paddingRight: "70px",
                    marginTop: "30px",
                  }}
                >
                  <h4
                    className={styles.linkstyle1}
                    onClick={() => curatedSelection()}
                  >
                    Select from our Curated Designs &rarr;
                  </h4>
                </div>
              )}{" "}
              {selectedOption === "curated" && (
                <div
                  className={styles.padiv}
                  style={{
                    textAlign: "right",
                    paddingRight: "70px",
                    marginTop: "30px",
                  }}
                >
                  <h4
                    className={styles.linkstyle1}
                    onClick={() => shirtSelection()}
                  >
                    Create your own Design &rarr;
                  </h4>
                </div>
              )}
            </div>
            {snap.type === "curated" ? (
              <h2
                className={styles.heading}
                style={{
                  margin: "0px 25px 25px 25px",
                }}
              >
                Select from other customer orders and trending designs
              </h2>
            ) : (
              <h2 className={styles.heading}>
                Select your {selectedOption} design
              </h2>
            )}
            <div className={styles["design-grid"]}>
              {designs[selectedOption].map((design) => (
                <div key={design.id} onClick={() => onDesignClick(design.id)}>
                  <img
                    src={design.imageUrl}
                    alt={`Design ${design.id}`}
                    className={styles["design-image"]}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        {snap.type === "shirt" && snap.form && <ShirtForm />}
        {snap.type === "pant" && snap.form && <PantForm />}
        {snap.type === "cap" && snap.form && <CapForm />}
        {snap.type === "curated" && snap.form && <CuratedForm />}
      </>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import styles from "./ProductList.module.scss";
import { BsFillGridFill } from "react-icons/bs";
import { FaListAlt } from "react-icons/fa";
import Search from "../../search/Search";
import ProductItem from "../productItem/ProductItem";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FILTER_BY_SEARCH,
  selectFilteredProducts,
  FILTER_BY_CATEGORY,
} from "../../../redux/slice/filterSlice";
import Pagination from "../../pagination/Pagination";

const ProductList = ({ products }) => {
  const [grid, setGrid] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("latest");
  const [category, setCategory] = useState("All");
  const filteredProducts = useSelector((state) =>
    selectFilteredProducts(state).filter((product) => product.enabled)
  );

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  // Get Current Products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({ products, search }));
  }, [dispatch, products, search]);
  useEffect(() => {
    dispatch(FILTER_BY_CATEGORY({ products, category }));
  }, [dispatch, products, category]);

  // Handle sorting after setting the category
  const handleSort = (sortedProducts) => {
    // Filter out products with no stock count
    const productsWithStock = sortedProducts.filter((product) =>
      product.sizeInfo.some((color) => color.stockCount > 0)
    );

    // Filter out products with no stock count
    const productsWithoutStock = sortedProducts.filter((product) =>
      product.sizeInfo.every((color) => color.stockCount === 0)
    );

    if (sort === "latest") {
      // No sorting needed for 'latest'
      return productsWithStock.concat(productsWithoutStock);
    } else if (sort === "lowest-price") {
      return productsWithStock
        .concat(productsWithoutStock)
        .sort((a, b) => a.price - b.price);
    } else if (sort === "highest-price") {
      return productsWithStock
        .concat(productsWithoutStock)
        .sort((a, b) => b.price - a.price);
    } else if (sort === "a-z") {
      return productsWithStock
        .concat(productsWithoutStock)
        .sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort === "z-a") {
      return productsWithStock
        .concat(productsWithoutStock)
        .sort((a, b) => b.name.localeCompare(a.name));
    }
    return productsWithStock.concat(productsWithoutStock);
  };

  // Sort the currentProducts after setting the category
  const sortedCurrentProducts = handleSort(
    filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
  );
  return (
    <div className={styles["product-list"]} id="product">
      <div className={styles.top}>
        <div className={styles.icons}>
          <p>
            <b className={styles.prodfound}>{filteredProducts.length}</b>{" "}
            Products found.
          </p>
        </div>

        <div className={styles.sort}>
          <label>Sort by:</label>
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="latest">Latest</option>
            <option value="lowest-price">Lowest Price</option>
            <option value="highest-price">Highest Price</option>
            <option value="a-z">A - Z</option>
            <option value="z-a">Z - A </option>
          </select>
        </div>
      </div>

      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {products.length === 0 ? (
            <p>No product found.</p>
          ) : (
            <>
              {sortedCurrentProducts.map((product) => (
                <div key={product.id}>
                  {product && product.enabled && (
                    <ProductItem {...product} grid={grid} product={product} />
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        productsPerPage={productsPerPage}
        totalProducts={filteredProducts.length}
      />
    </div>
  );
};

export default ProductList;

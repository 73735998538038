import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import Card from "../../card/Card";
import Loader from "../../loader/Loader";
import Toggle from 'react-toggle'; // Import Toggle component
import styles from "./CreateCouponCode.module.scss";

const initialState = {
  name: "",
  discountType: "percentage", // Added discountType to indicate percentage or amount
  discountValue: "", // Changed to empty string
  validFromDate: "",
  validUntilDate: "",
  singleUse:false
};

const CreateCouponCode = () => {
  const [coupon, setCoupon] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setCoupon({ ...coupon, [name]: value });
  };

  const createCouponCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { name, validFromDate, validUntilDate, discountType, discountValue,singleUse } = coupon;

      const couponData = {
        code: name.toUpperCase(),
        validFromDate,
        validUntilDate,
        status: true,
        createdAt: Timestamp.now().toDate(),
        singleUse: singleUse
      };

      if (discountType === "percentage") {
        couponData.discountPercentage = Number(discountValue);
      } else {
        couponData.discountAmount = Number(discountValue);
      }

      const docRef = await addDoc(collection(db, "coupons"), couponData);

      setIsLoading(false);
      setCoupon({ ...initialState });

      toast.success("Coupon code created successfully.");
      navigate("/admin/home");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.product}>
        <h2>Create New Coupon Code</h2>
        <Card cardClass={styles.card}>
          <form onSubmit={createCouponCode} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="name">Coupon Code Name:</label>
              <input
                type="text"
                id="name"
                placeholder="Coupon name"
                required
                name="name"
                value={coupon.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="discountType">Discount Type:</label>
              <select
                id="discountType"
                required
                value={coupon.discountType}
                onChange={(e) => handleInputChange("discountType", e.target.value)}
                className={styles.input}
              >
                <option value="percentage">Percentage</option>
                <option value="amount">Amount</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="discountValue">Discount Value:</label>
              <input
                type="number"
                id="discountValue"
                placeholder={`Discount ${coupon.discountType === "percentage" ? "Percentage" : "Amount"}`}
                required
                name="discountValue"
                value={coupon.discountValue}
                onChange={(e) => handleInputChange("discountValue", e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="validFromDate">Valid From Date:</label>
              <input
                id="validFromDate"
                type="date"
                required
                value={coupon.validFromDate}
                onChange={(e) => handleInputChange("validFromDate", e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="validUntilDate">Valid Until Date:</label>
              <input
                id="validUntilDate"
                type="date"
                required
                value={coupon.validUntilDate}
                onChange={(e) => handleInputChange("validUntilDate", e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="singleUse">Single Use:</label>
              <Toggle
                id="singleUse"
                name="singleUse"
                value = {coupon.singleUse}
                checked={coupon.singleUse}
                onChange={(e) => handleInputChange("singleUse", e.target.checked)}
                icons={false} // Disable default icons
                className={styles.toggle}
              />
            </div>

            <button className={`${styles.btn} ${styles.btnPrimary}`}>
              Create Coupon
            </button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateCouponCode;

import React, { useEffect } from "react";
import Product from "../../components/product/Product";
import Slider from "../../components/slider/Slider";
import TopCategories from "../../components/home/topCategories/TopCategories";
import LatestProducts from "../../components/home/latestProducts/LatestProducts";
import Banner from "../../components/home/banner/BannerPage";
import VideoReviews from "../../components/home/videoReviews/VideoReviews";
import SocialIcons from "../../components/home/socialIcons/SocialIcons";

const Home = () => {
  const url = window.location.href;

  useEffect(() => {
    const scrollToProducts = () => {
      if (url.includes("#products")) {
        const productElement = document.getElementById("products");
        if (productElement) {
          productElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };
    scrollToProducts();
  }, [url]);

  return (
    <div style={{ minHeight: "737px" }}>
      <Slider />
      <TopCategories />
      <LatestProducts />
      <Banner />
      <VideoReviews />
      <SocialIcons />
    </div>
  );
};

export default Home;

import { useState, useEffect } from "react";
import styles from "./auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import Card from "../../components/card/Card";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth, db } from "../../firebase/config";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { useSelector } from "react-redux";
import { selectPreviousURL } from "../../redux/slice/cartSlice";
import registerGif from "../../assets/registerGif.gif";
import { Timestamp, collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import glogo from "../../assets/gsignin.png";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const previousURL = useSelector(selectPreviousURL);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsEmailVerified(user.emailVerified);
        if (!user.emailVerified) {
          // If email is not verified, sign out the user
          signOut(auth);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const redirectUser = () => {
    if (previousURL.includes("cart")) {
      return navigate("/cart");
    }
    navigate("/");
  };

  const handleAuthError = (error) => {
    setIsLoading(false);
    console.log(error);
    console.log(error.code);

    switch (error.code) {
      case "auth/invalid-email":
        toast.error("Invalid email address.");
        break;
      case "auth/user-disabled":
        toast.error("User account has been disabled.");
        break;
      case "auth/user-not-found":
        toast.error("User not found. Please check your email or register.");
        break;
      case "auth/wrong-password":
        toast.error("Invalid password. Please try again.");
        break;
      default:
        toast.error("An error occurred. Please try again.");
        break;
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();
    const restrictedEmail = `${process.env.REACT_APP_ADMIN_MAIL}`;
    if (email.toLowerCase() === restrictedEmail.toLowerCase()) {
      toast.error("Please use Google Sign In");
      return;
    }
    setIsLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (user.emailVerified) {
        setIsLoading(false);
        toast.success("Login Successful...");
        redirectUser();
        await updateDoc(doc(db, "users", user.uid), {
          isVerified: true,
        });
      } else {
        handleAuthError(
          new Error("Please verify your email before logging in.")
        );
        await signOut(auth);
      }
    } catch (error) {
      handleAuthError(error);
    }
  };

  const provider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  setIsLoading(true);

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const additionalInfo = getAdditionalUserInfo(result);
    console.log("User signed in with Google:", user);
    console.log("Additional user info:", additionalInfo);

    // Extract first and last names, provide default values if they are missing
    const firstName = additionalInfo.profile?.given_name || "FirstName";
    const lastName = additionalInfo.profile?.family_name || "LastName";

    const userDocRef = doc(db, "users", user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      console.log("User already exists in Firestore");
      setIsLoading(false);
      toast.error("User with this email already exists.");
      return;
    }

    console.log("Creating new user document in Firestore");
    await setDoc(userDocRef, {
      firstName,
      lastName,
      phoneNumber: user.phoneNumber || "N/A",
      email: user.email,
      photoURL: additionalInfo.profile?.picture || "",
      signin: "google",
      dob: null,
      createdAt: Timestamp.now(),
      isVerified: false,
    });

    if (user.emailVerified) {
      setIsLoading(false);
      toast.success("Login Successful");
      redirectUser();
      await updateDoc(userDocRef, {
        isVerified: true,
      });
    } else {
      console.log("User email not verified");
      handleAuthError(new Error("Please verify your email before logging in."));
      await signOut(auth);
    }
  } catch (error) {
    console.log("Error during Google sign-in:", error);
    handleAuthError(error);
  }
};




  return (
    <>
      {isLoading && <Loader />}
      <section className={`container ${styles.auth}`}>
        <div className={styles.img}>
          <img src={registerGif} alt="Login" width="400" />
        </div>

        <Card>
          <div className={styles.form}>
            <h2>Login</h2>
            

            <form onSubmit={loginUser}>
              <input
                type="text"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="--btn --btn-primary --btn-block">
                Login
              </button>
              <div className={styles.links}>
                <Link to="/reset">Reset Password</Link>
              </div>
              <p>-- or --</p>
            </form>
            <div style={{ textAlign: "center" }}>
              <img
                onClick={signInWithGoogle}
                style={{ width: "240px", cursor: "pointer" }}
                src={glogo}
                alt="google signin"
              />
            </div>

            <span className={styles.register}>
              <p>Don't have an account?</p>
              <Link to="/register" className={styles.linkstyle}>
                Register
              </Link>
            </span>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select"; // Import react-select for dropdown menu
import { setDoc } from "firebase/firestore";
import {
  ADD_TO_CART,
  CALCULATE_TOTAL_QUANTITY,
  DECREASE_CART,
  selectCartItems,
  REMOVE_FROM_CART,
  SAVE_URL,
} from "../../../redux/slice/cartSlice";
import {
  ADD_TO_WISHLIST,
  CALCULATE_WISHLIST_QUANTITY,
  DECREASE_WISHLIST,
  REMOVE_FROM_WISHLIST,
  selectWishlistItems,
} from "../../../redux/slice/wishlistSlice";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import Card from "../../card/Card";
import StarsRating from "react-star-rate";
import { getDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { auth, db } from "../../../firebase/config";
import { FaArrowLeft, FaHeart, FaRegHeart, FaShare, FaShareAlt } from "react-icons/fa";
import { format } from "date-fns";
import styles from "./ProductDetails.module.scss";
import spinnerImg from "../../../assets/spinner.jpg";
import { toast } from "react-toastify";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ReactStars from "react-stars";
import ImageCarousel from "../../imageCarousel/ImageCarousel";
import {  onSnapshot } from "firebase/firestore";
import { selectIsLoggedIn } from "../../../redux/slice/authSlice";


const ProductDetails = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [showGotoCart,setShowGotoCart] = useState(false)
  const [knockingServicePrices, setKnockingServicePrices] = useState({});
  const [hasSoldOutColor, setHas] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(false);
  const [isIncrementButtonDisabled, setIsIncrementButtonDisabled] = useState(false);
  const [selectedKnockingService, setSelectedKnockingService] = useState("No Knocking"); // State for selected knocking service
  const [isInCart, setIsInCart] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const { id } = useParams();

  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const wishlistItems = useSelector(selectWishlistItems);
  const { document } = useFetchDocument("products", id);
  const { data } = useFetchCollection("reviews");
  const filteredReviews = data.filter((review) => review.productID === id);

  const cart = cartItems.find((cart) => cart.id === id);
  const isCartAdded = cartItems.findIndex((cart) => cart.id === id);
  const isKnockingServiceSame = cartItem && cartItem.knockingService === selectedKnockingService;
  const wishlist = wishlistItems.find((item) => item.id === id);
  const isWishlistAdded = wishlistItems.findIndex((item) => item.id === id);

  const [replyInputs, setReplyInputs] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const existingCartItem = cartItems.find(
      (item) => item.id === id && item.selectedSize === selectedSize
    );
    if (existingCartItem) {
      setIsInCart(true);
      setSelectedKnockingService(existingCartItem.knockingService);
      setCartItem(existingCartItem);
    }
  }, [cartItems, id, selectedSize]);

  useEffect(() => {
    if (product && product.sizeInfo.every((color) => color.stockCount === 0)) {
      setHas(true);
    }
  }, [product]);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "productcustomizations", "OY7inZspq51Tn2tTMRf2"), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        if (data && data.knockingPrices) {
          setKnockingServicePrices(data.knockingPrices);
          console.log(data.knockingPrices)
        } else {
          console.error("Knocking prices map not found in document");
        }
      } else {
        console.error("Document does not exist");
      }
    });
  
    return () => unsubscribe();
  }, []);
  

  useEffect(() => {
 const fetchProduct = async()=>{
  const docRef = await doc(db, "products", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const obj = {
      id: id,
      ...docSnap.data(),
    };
    setProduct(obj);
 }}
 fetchProduct()

    if (document && document.sizeInfo && document.sizeInfo.length > 0) {
      const firstAvailableSize = document.sizeInfo.find(
        (size) => size.stockCount > 0
      );

      if (firstAvailableSize) {
        setSelectedSize(firstAvailableSize.size);
        setAvailableQuantity(firstAvailableSize.stockCount);
      }
    }

  

   

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email === `${process.env.REACT_APP_ADMIN_MAIL}`) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, [document]);

  useEffect(() => {
    setIsIncrementButtonDisabled(
      availableQuantity === 0 || getQuantityForSize() >= availableQuantity
    );
  }, [availableQuantity, selectedSize, cartItems]);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [currentUserID, setCurrentUserID] = useState(null);
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentUserID(user.uid);
    } else {
      setCurrentUserID(null);
    }
  });
  const url = window.location.href;

  const notifyMe = async (event) => {
    if (isLoggedIn) {
      event.preventDefault();
      event.stopPropagation();

      const docRef = doc(db, "products", id);
      const notificationsRef = doc(db, "notifications", currentUserID);
      try {
        const docSnapshot = await getDoc(docRef);
        const productData = docSnapshot.data();

        if (productData.sizeInfo) {
          const updatedSizeInfo = productData.sizeInfo.map((size, index) => {
            if (index === 0) {
              if (!size.stockRequests) {
                size.stockRequests = [];
              }
              if (!size.stockRequests.includes(currentUserID)) {
                size.stockRequests.push(currentUserID);
              }
            }
            return size;
          });

          await updateDoc(docRef, { sizeInfo: updatedSizeInfo });

          const notificationsSnapshot = await getDoc(notificationsRef);

          const existingProducts = notificationsSnapshot.exists()
            ? notificationsSnapshot.data().products || []
            : [];

          const isNewProduct = !existingProducts.some(
            (newProd) => newProd.id === id
          );
          const name = product.name;

          if (isNewProduct) {
            existingProducts.push({
              id,
              name,
              stockStatus: false,
              seen: false,
            });
            await setDoc(notificationsRef, { products: existingProducts });
          }

          toast.success("You will be notified");
        } else {
          console.error("Invalid sizeInfo structure in Firestore document");
        }
      } catch (error) {
        console.error("Error processing Notify Me:", error);
      }
    } else {
      dispatch(SAVE_URL(url));
      window.scrollTo({ top: 0, behavior: "smooth" });

      navigate("/login");
    }
  };



  const addToCart = (product) => {

    // const totalPrice = Number(product.price) + Number(knockingServicePrices[selectedKnockingService])
    // Check if a size is selected
    if (selectedSize === null && product.sizeInfo.length === 1) {
      setSelectedSize(product.sizeInfo[0].size);
    }
  
    // Check if the selected size exists in the product size info
    const selectedSizeInfo = product.sizeInfo.find((info) => info.size === selectedSize);
  
    // Proceed if the selected size info is found
    if (selectedSizeInfo && selectedSizeInfo.stockCount !== undefined) {
      const stockCount = selectedSizeInfo.stockCount;
  
      // Calculate the total quantity of all cart items with the same product ID and selected size
      const totalQuantityForSize = cartItems
        .filter((item) => item.id === product.id && item.selectedSize === selectedSize)
        .reduce((acc, item) => acc + item.cartQuantity, 0);
  
      // Calculate the remaining available quantity for the selected size
      const remainingQuantity = Math.max(0, stockCount - totalQuantityForSize);
  
      // Check if there is remaining available quantity
      if (remainingQuantity > 0) {
        // Add the product to the cart up to the remaining available quantity
        const quantityToAdd = Math.min(remainingQuantity, product.minimumOrderQuantity);
        dispatch(
          ADD_TO_CART(
            {
              ...product,
              // price:totalPrice,
              selectedSize,
              knockingService: selectedKnockingService,
              knockingServicePrice: knockingServicePrices[selectedKnockingService],
            },
            true,
            quantityToAdd
          )
        );
        setIsAddToCartDisabled(false); // Enable the "ADD TO CART" button
      } else {
        setIsAddToCartDisabled(true); // Disable the "ADD TO CART" button
        console.error(
          "Cannot add to cart. No stock available for the selected size."
        );
      }
  
      dispatch(CALCULATE_TOTAL_QUANTITY());
    }
  };
  
  
  
  
  
  
  
  
  

  const decreaseCart = (cart) => {
    dispatch(DECREASE_CART({ ...cart, selectedSize: cart.selectedSize }));
 
  
      setIsAddToCartDisabled(false);
    
  };
  
  

  const shareProduct = async() => {
    const productUrl = window.location.href;
    const productTitle = `Check out this ${product ? product.name : "product"} from ULTIMATE SPORT STORE`;

    if (navigator.share) {
      await navigator.share({ title: productTitle, url: productUrl,text:productTitle})
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Share failed:', error));
    } else {
      toast.error("Unable to share product. Try copying the URL instead.");
    }
  };

  const toggleWishlist = (product) => {
    if (isWishlistAdded < 0) {
      dispatch(ADD_TO_WISHLIST(product));
    } else {
      dispatch(REMOVE_FROM_WISHLIST(product));
    }
    dispatch(CALCULATE_WISHLIST_QUANTITY());
  };

  const handleReplyInputChange = (reviewId, e) => {
    setReplyInputs((prevInputs) => ({
      ...prevInputs,
      [reviewId]: e.target.value,
    }));
  };

  const submitReply = async (reviewId) => {
    try {
      if (!isAdmin) {
        console.error("Only admins can submit replies.");
        return;
      }

      const replyContent = replyInputs[reviewId];

      if (reviewId && replyContent) {
        const reviewDocRef = doc(db, "reviews", reviewId);
        const reviewDocSnap = await getDoc(reviewDocRef);

        if (reviewDocSnap.exists()) {
          const currentReplies = reviewDocSnap.data().replies || [];
          const adminReplyIndex = currentReplies.findIndex(
            (reply) => reply.adminReply
          );

          if (adminReplyIndex !== -1) {
            currentReplies[adminReplyIndex] = {
              content: replyContent,
              timestamp: Timestamp.now().toDate(),
              adminReply: true,
            };
          } else {
            currentReplies.push({
              content: replyContent,
              timestamp: Timestamp.now().toDate(),
              adminReply: true,
            });
          }

          await updateDoc(reviewDocRef, {
            replies: currentReplies,
            hasReply: true,
          });

          setReplyInputs((prevInputs) => ({
            ...prevInputs,
            [reviewId]: "",
          }));

          toast.success("Reply sent Successfully");
        }
      }
    } catch (error) {
      toast.error("Error submitting reply:", error.message);
    }
  };

  const handleSizeChange = (newSize) => {
    setSelectedSize(newSize);
  };

  const handleKnockingServiceChange = (selectedOption) => {
    setSelectedKnockingService(selectedOption.value); // Extract value from selected option object
  };
  const getQuantityForSize = () => {
    const selectedSizeQuantity = cartItems
      .filter((item) => ((item.selectedSize === selectedSize) && (item.id === id)))
      .reduce((acc, item) => {
        return acc + item.cartQuantity;
      }, 0);

    return selectedSizeQuantity;
  };

  const formatDescription = (description) => {
    // Use regex to find words between ** **
    const boldRegex = /\*\*(.*?)\*\*/g;
    // Replace ** ** with <strong> </strong>
    return description.replace(boldRegex, "<strong>$1</strong>");
  };
  const formattedDescription = product ? formatDescription(product.desc) : "";

  const knockingServiceOptions = [
  "none","10k","20k"
  ];

  const handleUpdateCart = () => {
    if (cartItem) {
    
      dispatch(REMOVE_FROM_CART({ id, selectedSize, knockingService: cartItem.knockingService }));
      dispatch(ADD_TO_CART({ ...product, selectedSize, knockingService: selectedKnockingService, minimumOrderQuantity: 1, knockingServicePrice: knockingServicePrices[selectedKnockingService] }));
      toast.success(`${product.name} updated with new knocking service`, {
        position: "top-left",
      });
    }
  };

  return (
    <section style={{ paddingTop: "23px" }}>
      <div className={`container ${styles.product}`}>
        <div className={styles.centerSpinner}>
          {product === null ? (
            <img src={spinnerImg} alt="Loading" style={{ width: "50px" }} />
          ) : (
            <>
              <div className={styles.padiv} style={{ paddingLeft: "23px" }}>
                <Link className={styles.linkstyle} to="/">
                  &larr; Back to shopping
                </Link>
              </div>
              <div className={styles.details}>
                <div className={styles.img}>
                  <div
                    className={styles.inside}
                    onClick={() => toggleWishlist(product)}
                  >
                    <div className={styles.icon}>
                      {isWishlistAdded < 0 ? (
                        <FaRegHeart className={styles.wishlistIcon} />
                      ) : (
                        <FaHeart className={styles.wishlistIcon} />
                      )}
                    </div>
                  </div>
                  <ImageCarousel
                    className={styles.carousel}
                    imageURLs={product.imageURLs}
                    videoURL={product.videoURL}
                  />
                </div>
                <div className={styles.content}>
                  <h1 className={styles.prodname}>
                    {product.name}   {<FaShareAlt onClick={()=>shareProduct()}/>}
                  </h1>
                  {product.actualPrice > product.price ? (
                    <div>
                      <span className={styles.price}>
                        <span
                          className={styles.priceSym}
                          style={{ color: "crimson" }}
                        >
                          $
                        </span>
                        {product.actualPrice &&
                          product.actualPrice > (product.price) && (
                            <span className={styles.priceData}>
                              <s
                                style={{ fontSize: 20, color: "crimson" }}
                              >{`${product.actualPrice}`}</s>
                            </span>
                          )}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <span className={styles.price}>
                    <span
                      className={styles.priceSym}
                      style={{ color: "green" }}
                    >
                      $
                    </span>
                    <span
                      className={styles.priceData}
                      style={{ color: "green", fontSize: 30 }}
                    >{`${product.price}`}</span>
                  </span>
                  <br></br>
                  {product.minimumOrderQuantity > 1 && (
                    <p>Minimum Order Quantity: <b>{product.minimumOrderQuantity}</b></p>
                  )}
                {hasSoldOutColor ? (
                    <>
                      <div className={styles.soldOutText}>
                        This item has been sold.
                      </div>
                      <button className={styles.addToCart} onClick={notifyMe}>
                        Notify Me
                      </button>
                    </>
                  ) : (
                    <>
                  {
  product.sport === 'Cricket' && product.category === 'Bats' && product.sizeInfo && product.sizeInfo.find((info) => info.size === selectedSize) && (product.sizeInfo.find((info) => info.size === selectedSize)).stockCount === 1 && isInCart && (
    isKnockingServiceSame && (
      <p>Already in cart. You can update your knocking preferences if required.</p>
    )
  )
}

{product.sport === 'Cricket' && product.category === 'Bats' && product.sizeInfo && product.sizeInfo.find((info) => info.size === selectedSize) && (
  product.sizeInfo.find((info) => info.size === selectedSize)).stockCount === 1 && isInCart ? (
    !isKnockingServiceSame && (
      <button className={styles.addToCart} onClick={()=>{handleUpdateCart()
            setShowGotoCart(true)
      }}>Update Cart</button>
    )
  ) : (
    <button className={styles.addToCart} onClick={()=>{addToCart(product)
      setShowGotoCart(true)
    }}>Add to Cart</button>
  )
}

      

      
                    </>
                    
                  )}
                  <div className={styles.cart}>
                    <div className={styles.count}>
                      {isCartAdded < 0 ? null : (
                        <>
                          <button
                            className={styles.incredecre}
                            onClick={() => decreaseCart(cart)}
                          >
                            -
                          </button>
                          <p style={{ fontSize: "14px" }}>
                            {getQuantityForSize()}
                          </p>
                          {
                            ((product.sizeInfo.find((info) => info.size === selectedSize)).stockCount > 1)&&(
                              <button
                            className={styles.incredecre}
                            onClick={() => {addToCart(product)
                              setShowGotoCart(true)
                            }}
                          >
                            +
                          </button>
                            )
                          }
                          
                          {showGotoCart &&(
                            <button
                            className={styles.addToCart2}
                            onClick={() => {
                              navigate("/cart");
                            }}
                          >
                            GO TO CART
                          </button>

                          )}
                          
                        </>
                      )}
                    </div>
                  </div>
                  <table className={styles.detailsTable}>
                    <tbody>
                      <tr>
                        <td className={styles.detailsLabel}>
                          <b>Category</b>
                        </td>
                        <td className={styles.detailsValue}>
                          {product.category}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.detailsLabel}>
                          <b>Colour</b>
                        </td>
                        <td className={styles.detailsValue}>
                          {product.colour}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.detailsLabel}>
                          <b>Brand</b>
                        </td>
                        <td className={styles.detailsValue}>{product.brand}</td>
                      </tr>
                      <tr>
                        <td className={styles.detailsLabel}>
                          <b>Available Sizes / Weight</b>
                        </td>
                        <td className={styles.detailsValue}>
                          {product.sizeInfo.length > 1 ? (
                            <div className={styles.dropdownContainer}>
                              <div className={styles.selectedSize}>
                                <span>{selectedSize || "Select Size"}</span>
                                <span className={styles.dropdownIcon}>
                                  &#9662;
                                </span>
                              </div>
                              <ul className={styles.sizeOptions}>
                                {product.sizeInfo.map((size, index) => (
                                  <li
                                    key={index}
                                    onClick={() => handleSizeChange(size.size)}
                                  >
                                    {size.size}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : (
                            <span>{product.sizeInfo[0].size}</span>
                          )}
                        </td>
                      </tr>
                      {(product.sport==="Cricket" && product.category === "Bats")&&(
   <tr>
   <td className={styles.detailsLabel}>
     <b>Select Knocking Service</b>
   </td>
   <td className={styles.detailsValue}>
   <Select
  value={selectedKnockingService ? { value: selectedKnockingService, label: `${selectedKnockingService}` } : null}
  onChange={handleKnockingServiceChange}
  options={[
    { value: "No Knocking", label: `No Knocking` },
    { value: "10k Machine Knocks", label: `10k Machine Knocks + $${knockingServicePrices["10k Machine Knocks"]}` },
    { value: "20k Machine Knocks", label: `20k Machine Knocks + $${knockingServicePrices["20k Machine Knocks"]}` }
  ]}
  styles={{
    control: (provided) => ({
      ...provided,
      width: 220, // Adjust width as needed
    }),
    menu: (provided) => ({
      ...provided,
      width: 300, // Adjust width as needed
    }),
    option: (provided) => ({
      ...provided,
      height: 40, // Adjust height as needed
    }),
  }}
/>

   </td>
 </tr>
                      )}
                   
                    </tbody>
                  </table>
                  <h1 className={styles.desc}>About this item</h1>
                  <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: formattedDescription }}
                  />
                </div>
              </div>
              <div className={styles.reviews}>
                <h3
                  style={{
                    fontSize: "16px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    marginBottom: "20px",
                    marginRight: "30px",
                  }}
                >
                  Product Reviews
                </h3>
                <div>
                  {filteredReviews.length === 0 ? (
                    <p>There are no reviews for this product yet.</p>
                  ) : (
                    <>
                      {filteredReviews.map((item, index) => {
                        const {
                          id: reviewId,
                          rate,
                          review,
                          reviewDate,
                          userName,
                          replies,
                          imageURLs,
                        } = item;

                        const isEdited = review.startsWith("(edited)");

                        return (
                          <Card cardClass={styles.card} key={index}>
                            <div className={styles.review}>
                              <div className={styles.row}>
                                <div className={styles.col1}>
                                  <ReactStars
                                    size={20}
                                    value={rate}
                                    edit={false}
                                  />
                                  <span>
                                    {isEdited ? "Edited on " : "Reviewed on "}
                                    <span>
                                      {format(
                                        new Date(reviewDate),
                                        "MMMM dd, yyyy"
                                      )}
                                    </span>
                                  </span>
                                </div>
                                <div className={styles.col2}>
                                  <div>
                                    <span className={styles.userName}>
                                      <b>{userName}</b>
                                    </span>
                                  </div>
                                  <div className={styles.revdiv}>
                                    <span className={styles.rev}>
                                      {isEdited ? review.slice(8) : review}
                                    </span>
                                  </div>
                                  {imageURLs && imageURLs.length > 0 && (
                                    <div className={styles.reviewImages}>
                                      {imageURLs.map(
                                        (imageUrl, index) =>
                                          imageUrl !== null && (
                                            <img
                                              width={
                                                imageURLs.length === 2
                                                  ? "200"
                                                  : 300
                                              }
                                              height={
                                                imageURLs.length === 2
                                                  ? "200"
                                                  : 300
                                              }
                                              key={index}
                                              src={imageUrl}
                                              alt={`Review Image ${index + 1}`}
                                              className={styles.reviewImage}
                                              style={{
                                                marginRight:
                                                  imageURLs.length === 2 &&
                                                  index === 0
                                                    ? "4%"
                                                    : 0,
                                              }}
                                            />
                                          )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {replies && replies.length > 0 && (
                                <div className={styles.repliesSection}>
                                  {replies.map((reply, replyIndex) => (
                                    <div
                                      className={styles.row}
                                      key={replyIndex}
                                    >
                                      <div className={styles.col1}>
                                        <div className={styles.reply}>
                                          <span className={styles.replyDate}>
                                            Replied on{" "}
                                            {format(
                                              new Date(
                                                reply.timestamp.toDate()
                                              ),
                                              "MMMM dd, yyyy"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={styles.col2}>
                                        <span className={styles.userName}>
                                          Owner
                                        </span>
                                        <div className={styles.revdiv}>
                                          <span className={styles.rev}>
                                            {reply.content}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {isAdmin && (
                                <div className={styles.replySection}>
                                  <button
                                    className="--btn"
                                    onClick={() =>
                                      setReplyInputs((prevInputs) => ({
                                        ...prevInputs,
                                        [reviewId]: "",
                                      }))
                                    }
                                  >
                                    Reply
                                  </button>
                                  {replyInputs[reviewId] !== undefined && (
                                    <div className={styles.replyInput}>
                                      <textarea
                                        value={replyInputs[reviewId]}
                                        onChange={(e) =>
                                          handleReplyInputChange(reviewId, e)
                                        }
                                        placeholder="Type your reply..."
                                        cols="30"
                                        rows="3"
                                      ></textarea>
                                      <button
                                        className="--btn --btn-primary"
                                        onClick={() => submitReply(reviewId)}
                                      >
                                        Submit Reply
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Card>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;

import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_BY_CATEGORY,
  FILTER_BY_BRAND,
  FILTER_BY_PRICE,
  FILTER_BY_SUB_CATEGORY,
} from "../../../redux/slice/filterSlice";
import { useNavigate, useLocation } from "react-router-dom";
import {
  selectProducts,
  selectMinPrice,
  selectMaxPrice,
} from "../../../redux/slice/productSlice";
import styles from "./ProductFilter.module.scss";

const ProductFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [category, setCategory] = useState("All");
  const [brand, setBrand] = useState("All");
  const [subcategories, setSubcategories] = useState([]);
  const [subcategory, setSubcategory] = useState("All");
  // Add this state variable
  const [selectedSubcategory, setSelectedSubcategory] = useState("All");

  const products = useSelector(selectProducts);
  const minPrice = useSelector(selectMinPrice);
  const maxPrice = useSelector(selectMaxPrice);
  const [price, setPrice] = useState(maxPrice);
  const [maxPriceInSubcategory, setMaxPriceInSubcategory] = useState(maxPrice);
  const [filteredBrands, setFilteredBrands] = useState(["All"]);
  const dispatch = useDispatch();
  const [sideEffectTriggered, setSideEffectTriggered] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    if (category !== "All") {
      dispatch(FILTER_BY_CATEGORY({ products, category }));
    }
    const timeoutId = setTimeout(() => {
      filterProducts(category);
    }, 1);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [dispatch, products, category]);

  useEffect(() => {
    if (brand !== "All") {
      // dispatch(FILTER_BY_BRAND({ products, brand }));
    }
  }, [dispatch, products, brand]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get("category") || "All";
    const brandParam = searchParams.get("brand") || "All";
    const subcategoryParam = searchParams.get("subcategory") || "All";

    // Update local state with the category, brand, and price from the URL
    setCategory(categoryParam);
    setBrand(brandParam);
    setSubcategory(subcategoryParam);

    // Example: If you want to filter products based on the category from the URL
    filterProducts(categoryParam);

    // Example: If you want to filter brands based on the category from the URL
    filterBrands(brandParam);

    // Example: If you want to filter subcategories based on the category and subcategory from the URL
    filterSubcategories(subcategoryParam);

    // Update max prices when a specific category is provided in the URL
    const updatedMaxPriceInCategory =
      calculateMaxPriceForCategory(categoryParam);
    const updatedMaxPriceInBrand = calculateMaxPriceForBrand(
      brandParam,
      updatedMaxPriceInCategory
    );

    setPrice(updatedMaxPriceInCategory);
    setMaxPriceInSubcategory(calculateMaxPriceForSubcategory(subcategoryParam));
    // setMaxPriceInBrand(updatedMaxPriceInBrand);
  }, [location.search]);

  const filterSubcategories = (selectedSubcategory) => {
    // Update the subcategory state
    setSubcategory(selectedSubcategory);
    setSelectedSubcategory(selectedSubcategory);

    // Filter products based on the selected subcategory
    const updatedFilteredProducts = products.filter(
      (product) => product.subCategory === selectedSubcategory
    );

    // Update the max price for the selected subcategory
    const updatedMaxPriceInSubcategory =
      updatedFilteredProducts.length > 0
        ? Math.max(...updatedFilteredProducts.map((product) => product.price))
        : maxPrice; // Use the global maxPrice if there are no filtered products

    setMaxPriceInSubcategory(updatedMaxPriceInSubcategory);

    // Calculate the max price for the selected brand and subcategory
    const updatedMaxPriceInBrand = calculateMaxPriceForBrand(
      brand,
      selectedSubcategory // Pass the selected subcategory here
    );
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("subcategory", selectedSubcategory);
    navigate(`?${searchParams.toString()}`);

    setPrice(updatedMaxPriceInSubcategory);

    // Update filtered brands based on the selected subcategory
    const filteredBrandsInSubcategory =
      selectedSubcategory === "All"
        ? ["All"]
        : [
            "All",
            ...new Set(updatedFilteredProducts.map((product) => product.brand)),
          ];

    setFilteredBrands(filteredBrandsInSubcategory);

    // Dispatch action to update products based on the selected subcategory
    dispatch(
      FILTER_BY_SUB_CATEGORY({
        products: updatedFilteredProducts,
        subCategory: selectedSubcategory,
      })
    );

    // Update the max value of the slider using updatedMaxPriceInBrand
    const sliderInput = document.querySelector(".slider input");
    if (sliderInput) {
      sliderInput.max = Math.ceil(updatedMaxPriceInBrand);
    }

    // Update the filteredProducts state
    setFilteredProducts(updatedFilteredProducts);
  };

  const calculateMaxPriceForSubcategory = (selectedSubcategory) => {
    let maxPriceForSubcategory = maxPrice; // default to global maxPrice

    if (selectedSubcategory !== "All") {
      const subcategoryPrices = products
        .filter((product) => product.subCategory === selectedSubcategory)
        .map((product) => product.price);

      maxPriceForSubcategory =
        subcategoryPrices.length > 0 ? Math.max(...subcategoryPrices) : 0;
    }

    return maxPriceForSubcategory;
  };

  const calculateMaxPriceForCategory = (cat) => {
    let maxPriceForCategory = maxPrice; // default to global maxPrice

    if (cat !== "All") {
      const categoryPrices = products
        .filter((product) => product.category === cat)
        .map((product) => product.price);

      maxPriceForCategory =
        categoryPrices.length > 0 ? Math.max(...categoryPrices) : 0;
    }

    return maxPriceForCategory;
  };

  const calculateMaxPriceForBrand = (selectedBrand, selectedSubcategory) => {
    let maxPriceForBrand = maxPriceInSubcategory; // default to maxPriceInSubcategory

    if (selectedBrand !== "All") {
      const brandPrices = products
        .filter(
          (product) =>
            product.brand === selectedBrand &&
            product.subCategory === selectedSubcategory
        )
        .map((product) => product.price);

      maxPriceForBrand = brandPrices.length > 0 ? Math.max(...brandPrices) : 0;
    }

    return maxPriceForBrand;
  };

  const updateTrackBackground = (value) => {
    const percentage = ((value - 0) / (maxPriceInSubcategory - 0)) * 100;
    setTrackBackground(
      `linear-gradient(to right, #10d5c2 0%, #10d5c2 ${percentage}%, #ddd ${percentage}%, #ddd 100%)`
    );
  };

  const buttonRef = useRef(null);

  const filterProducts = (cat) => {
    let updatedFilteredProducts = products;
    let maxPriceInCategory = maxPrice; // default to global maxPrice

    if (cat !== "All") {
      updatedFilteredProducts = products.filter(
        (product) => product.category === cat
      );

      const categoryPrices = updatedFilteredProducts.map(
        (product) => product.price
      );
      maxPriceInCategory =
        categoryPrices.length > 0 ? Math.max(...categoryPrices) : 0;
    }

    // Update filtered brands
    const filteredBrands =
      cat === "All"
        ? ["All"]
        : [
            "All",
            ...new Set(updatedFilteredProducts.map((product) => product.brand)),
          ];

    setFilteredBrands(filteredBrands);

    // Update the max price
    setPrice(maxPriceInCategory);
    setCategory(cat);
    setBrand("All"); // Reset brand filter to "All" when category changes

    // Set the flag to trigger the side effects after state is updated
    setSideEffectTriggered(true);

    // Update the filteredProducts state
    setFilteredProducts(updatedFilteredProducts);

    // Update track background when category changes
    updateTrackBackground(maxPriceInCategory);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("category", cat);
    navigate(`?${searchParams.toString()}`);

    const selectedCategory = updatedFilteredProducts.filter(
      (product) => product.category === cat
    );
    const uniqueSubcategories = [
      ...new Set(selectedCategory.map((product) => product.subCategory)),
    ];
    setSubcategories(uniqueSubcategories);

    // Update the max value of the slider using maxPriceInCategory
    // Check if the max price in the brand also needs to be updated
    const updatedMaxPriceInBrand = calculateMaxPriceForBrand(
      brand,
      subcategory !== "All" ? subcategory : null
    );

    // Update the max value of the slider using maxPriceInCategory
    const sliderInput = document.querySelector(".slider input");
    if (sliderInput) {
      sliderInput.max = Math.ceil(maxPriceInCategory);
      sliderInput.value = Math.min(sliderInput.value, sliderInput.max);
    }

    // Dispatch actions
    dispatch(
      FILTER_BY_CATEGORY({
        products: updatedFilteredProducts,
        category: cat,
      })
    );

    dispatch(
      FILTER_BY_SUB_CATEGORY({
        products: updatedFilteredProducts,
        subCategory: subcategory,
      })
    );

    dispatch(
      FILTER_BY_BRAND({
        products: updatedFilteredProducts,
        brand: "All",
      })
    );

    dispatch(
      FILTER_BY_PRICE({
        products: updatedFilteredProducts,
        price: maxPriceInCategory,
      })
    );
    if (buttonRef.current) {
      buttonRef.current.click();
    }
    // Reset the flag to avoid triggering the side effects in subsequent renders
    setSideEffectTriggered(false);
  };

  // useEffect(() => {
  //   dispatch(
  //     FILTER_BY_CATEGORY({
  //       products: products,
  //       category,
  //     })
  //   );

  // }, [category]);

  const filteredCategories =
    category !== "All"
      ? filteredProducts.filter((product) => product.category === category)
      : filteredProducts;

const allCategories = [
  "All",
  ...new Set(
    products.reduce((categories, product) => {
      if (product.enabled) {
        categories.push(product.category);
      }
      return categories;
    }, [])
  ),
];


  const maxPriceInCategory = useMemo(() => {
    if (category === "All") {
      return maxPrice;
    }

    const categoryPrices = filteredCategories.map((product) => product.price);

    return categoryPrices.length > 0 ? Math.max(...categoryPrices) : 0;
  }, [category, filteredCategories, maxPrice]);

  const maxPriceInBrand = useMemo(() => {
    if (brand === "All") {
      return maxPriceInCategory; // Use maxPriceInSubcategory if a subcategory is selected
    }

    const brandPrices = filteredCategories
      .filter((product) => product.brand === brand)
      .map((product) => product.price);

    return brandPrices.length > 0 ? Math.max(...brandPrices) : 0;
  }, [brand, filteredCategories, maxPriceInCategory]); // Include maxPriceInSubcategory in dependencies

  // Side effects to be executed after state is updated
  useEffect(() => {
    if (sideEffectTriggered) {
      // Dispatch actions or perform other side effects if needed
      dispatch(FILTER_BY_CATEGORY({ products: filteredProducts, category }));
      dispatch(FILTER_BY_BRAND({ products: filteredProducts, brand: "All" }));
      dispatch(
        FILTER_BY_PRICE({
          products: filteredProducts,
          price: maxPriceInCategory,
        })
      );

      // Reset the flag to avoid triggering the side effects in subsequent renders
      setSideEffectTriggered(false);
    }
  }, [
    sideEffectTriggered,
    category,
    filteredProducts,
    dispatch,
    maxPriceInCategory,
  ]);

  const filterBrands = (selectedBrand) => {
    setBrand(selectedBrand);
    let filteredProducts = filteredCategories;
    let maxPriceInBrand = maxPriceInCategory; // default to maxPrice of the category

    if (selectedBrand !== "All") {
      filteredProducts = filteredProducts.filter(
        (product) => product.brand === selectedBrand
      );

      const brandPrices = filteredProducts.map((product) => product.price);
      maxPriceInBrand = brandPrices.length > 0 ? Math.max(...brandPrices) : 0;
    } else {
      maxPriceInBrand = maxPriceInCategory;
    }

    setPrice(maxPriceInBrand);

    dispatch(
      FILTER_BY_BRAND({ products: filteredProducts, brand: selectedBrand })
    );
    dispatch(
      FILTER_BY_PRICE({ products: filteredProducts, price: maxPriceInBrand })
    );
    updateTrackBackground(maxPriceInBrand);
  };
  const filterPrices = (selectedPrice) => {
    const floatValue = parseFloat(selectedPrice);

    setPrice(floatValue);
    updateTrackBackground(floatValue);

    let filteredProducts = products;

    // Apply brand filter if not "All"
    if (brand !== "All") {
      filteredProducts = filteredProducts.filter(
        (product) => product.brand === brand
      );
    }

    // Apply category filter if not "All"
    if (category !== "All") {
      filteredProducts = filteredProducts.filter(
        (product) => product.category === category
      );
    }

    // Apply subcategory filter if not "All"
    if (subcategory !== "All") {
      filteredProducts = filteredProducts.filter(
        (product) => product.subCategory === subcategory
      );
    }

    // Filter products based on the selected price
    filteredProducts = filteredProducts.filter((product) => {
      const productPrice = parseFloat(product.price);

      // Ensure productPrice is a valid number
      if (!isNaN(productPrice)) {
        return productPrice <= floatValue;
      }

      return false;
    });

    const updatedMaxPriceInBrand = calculateMaxPriceForBrand(
      brand,
      subcategory !== "All" ? subcategory : null
    );

    dispatch(
      FILTER_BY_PRICE({ products: filteredProducts, price: floatValue })
    );

    // Update the max value of the slider
    const sliderInput = document.querySelector(".slider input");
    if (sliderInput) {
      sliderInput.max = Math.ceil(updatedMaxPriceInBrand);
    }
  };

  const [trackBackground, setTrackBackground] = useState(
    `linear-gradient(to right, #10d5c2 0%, #10d5c2 0%, #ddd 100%)`
  );

  useEffect(() => {
    // Update the background dynamically when the price changes
    const percentage = ((price - 0) / (maxPriceInBrand - 0)) * 100;
    setTrackBackground(
      `linear-gradient(to right, #10d5c2 0%, #10d5c2 ${percentage}%, #ddd ${percentage}%, #ddd 100%)`
    );
  }, [price, maxPriceInBrand]);

  const clearFilters = () => {
    setCategory("All");
    setBrand("All");

    // Calculate the max price from the entire list of products
    const allPrices = products.map((product) => product.price);
    const updatedMaxPrice = allPrices.length > 0 ? Math.max(...allPrices) : 0;

    setPrice(updatedMaxPrice);

    dispatch(FILTER_BY_CATEGORY({ products, category: "All" }));
    dispatch(FILTER_BY_BRAND({ products, brand: "All" }));
    dispatch(FILTER_BY_PRICE({ products, price: updatedMaxPrice }));
  };
  return (
    <div className={styles.filter}>
      <h4 className={styles.cat}>Categories</h4>
      <div className={styles.category}>
        {allCategories.map((cat, index) => (
          <>
            {cat && (
              <div key={index} className={styles.categoryItem}>
                <button
                  key={index}
                  type="button"
                  className={`${category}` === cat ? `${styles.active}` : null}
                  onClick={() => filterProducts(cat)}
                >
                  &#8250; {cat}
                </button>
                {cat === category &&
                  !location.pathname.includes("/all-products") && (
                    <ul className={styles.subcategoryList}>
                      {subcategories.map((subcat, subIndex) => (
                        <button
                          key={subIndex}
                          onClick={() => filterSubcategories(subcat)}
                          className={
                            selectedSubcategory === subcat ? styles.active : ""
                          }
                        >
                          {subcat}
                        </button>
                      ))}
                    </ul>
                  )}
              </div>
            )}
          </>
        ))}
      </div>
      <h4>Brand</h4>
      <div className={styles.brand}>
        <select value={brand} onChange={(e) => filterBrands(e.target.value)}>
          {filteredBrands.map((brand, index) => (
            <option key={index} value={brand}>
              {brand}
            </option>
          ))}
        </select>
      </div>
      <h4>Price</h4>
      <p>{`$${price}`}</p>
      <div className={styles.slider}>
        <input
          type="range"
          value={price}
          onChange={(e) => filterPrices(e.target.value)}
          min={0}
          max={
            maxPriceInBrand && Number.isInteger(maxPriceInBrand)
              ? maxPriceInBrand
              : maxPriceInBrand + 1
          }
          style={{ background: trackBackground }}
        />
      </div>
      <br />
      <button className={styles.clearButton} onClick={clearFilters}>
        Clear Filter
      </button>
    </div>
  );
};

export default ProductFilter;

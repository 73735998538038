// PantForm.jsx
import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

import styles from "./ShirtForm.module.scss";
import Loader from "../../loader/Loader";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import state from "../State";
import { storage } from "../../../firebase/config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase Storage
import { CompressedArrayTexture } from "three";
import { teamKitMail } from "../../../emailjs/EmailTemplates";
const ShirtForm = () => {
  const [teamName, setTeamName] = useState("");
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [frontImageUrl, setFrontImageUrl] = useState(null);
  const [backImageUrl, setBackImageUrl] = useState(null);
  const [logoLeftThigh, setLogoLeftThigh] = useState(null);
  const [logoRightChest, setLogoRightChest] = useState(null);
  const [logoRightSleeve, setLogoRightSleeve] = useState(null);
  const [logoLeftSleeve, setLogoLeftSleeve] = useState(null);
  const [logoStomach, setLogoStomach] = useState(null);
  const [logoStomachPreview, setLogoStomachPreview] = useState(null);
  const [logoRightThigh, setLogoRightThigh] = useState(null);
  const [memberDetailsFile, setMemberDetailsFile] = useState(null);
  const [logoLeftThighPreview, setLogoLeftThighPreview] = useState(null); // State for left Chest logo preview
  const [logoRightChestPreview, setLogoRightChestPreview] = useState(null); // State for left Chest logo preview
  const [logoRightSleevePreview, setLogoRightSleevePreview] = useState(null); // State for right Chest logo preview
  const [logoLeftSleevePreview, setLogoLeftSleevePreview] = useState(null); // State for right Chest logo preview
  const [phoneNumberError, setPhoneNumberError] = useState(false); // State for phone number validation error
  const [emailError, setEmailError] = useState(false); // State for email validation error
  const snap = useSnapshot(state);
  useEffect(() => {
    setBackImageUrl(localStorage.getItem("DesignView_back"));
    setFrontImageUrl(localStorage.getItem("DesignView_front"));
  }, []);

  const handleFileChange = (event, setImage, setPreview) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file); // Set file blob for form submission
        setPreview(reader.result); // Set preview image
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle phone number input change
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    if (!/^\d{10}$/.test(value)) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  // Function to handle email input change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!/^\S+@\S+\.\S+$/.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleDataURL = async (dataURL, filePath) => {
    const contentType = dataURL.split(";")[0].split(":")[1];
    const base64Data = dataURL.split(",")[1];
    const byteArray = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
    const blob = new Blob([byteArray], { type: contentType });
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, blob);
    return getDownloadURL(storageRef);
  };

  // Function to handle Blob
  const handleBlob = async (blob, filePath) => {
    try {
      const storageRef = ref(storage, filePath);
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error handling blob:", error);
      throw error; // Rethrow the error to handle it upstream
    }
  };

  // Function to handle File object
  const handleFileObject = async (file, filePath) => {
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    // Validate form data
    if (
      !teamName ||
      !customerName ||
      !phoneNumber ||
      !email ||
      !frontImageUrl ||
      !backImageUrl ||
     
     
      phoneNumberError ||
      emailError
    ) {
      alert("Please fill in all fields correctly");
      setShowLoader(false);
      return;
    }

    try {
      // Function to handle uploading various types of files
      const [
        frontImageURL,
        backImageURL,
        leftThighURL,
        rightChestURL,
        leftSleeveURL,
        rightSleeveURL,
        // rightThighURL,
        memberDetailsURL,
        stomachURL
      ] = await Promise.all([
        handleDataURL(frontImageUrl, `front_images_tsirt/${frontImageUrl.name}${Date()}${teamName}`),
        handleDataURL(backImageUrl, `back_images_tshirt/${backImageUrl.name}${Date()}${teamName}`),
        logoLeftThigh?handleFileObject(
          logoLeftThigh,
          `left_thigh_images/${logoLeftThigh.name}${Date()}${teamName}`
        ):null,
        logoLeftSleeve?handleFileObject(
          logoLeftSleeve,
          `left_thigh_images/${logoLeftSleeve.name}${Date()}${teamName}`
        ):null,
        logoRightSleeve?handleFileObject(
          logoRightSleeve,
          `left_thigh_images/${logoRightSleeve.name}${Date()}${teamName}`
        ):null,
        logoRightChest?handleFileObject(
          logoRightChest,
          `left_thigh_images/${logoRightChest.name}${Date()}${teamName}`
        ):null,
        memberDetailsFile?handleFileObject(
          memberDetailsFile,
          `member_details_files/${memberDetailsFile.name}${Date()}${teamName}`
        ):null,
       logoStomach?handleFileObject(
          logoStomach,
          `logo_abdomen/${logoStomach.name}${Date()}${teamName}`
        ):null,
      ]);
      console.log(memberDetailsFile);
      // Prepare data for PDF
      const pdfData = {
        "Team Name": teamName,
   
        "Front Image URL": backImageURL,
        "Back Image URL": frontImageURL,
        "Left Chest Image URL": leftThighURL,
        "Right Chest Image URL": rightChestURL,
        "Left Sleeve Image URL": leftSleeveURL,
        "Right Sleeve Image URL": rightSleeveURL,
        "Abdomen Image URL": stomachURL,
        "Member Details File": memberDetailsURL,

        "Collar Color": snap.colorCollar,
        "Sleeve Color": snap.colorArm,
        "Base Color": snap.colorBase,
      };

      const designColors2 = [
        { name: snap.designColor1Name, value: snap.designColor1 },
        { name: snap.designColor2Name, value: snap.designColor2 },
        { name: snap.designColor3Name, value: snap.designColor3 },
        { name: snap.designColor4Name, value: snap.designColor4 },
        { name: snap.designColor5Name, value: snap.designColor5 },
        { name: snap.designColor6Name, value: snap.designColor6 },
        { name: snap.designColor7Name, value: snap.designColor7 },
        { name: snap.designColor8Name, value: snap.designColor8 },
        { name: snap.designColor10Name, value: snap.designColor10 },
        { name: snap.designColor9Name, value: snap.designColor9 },
      ];

      designColors2.forEach((color) => {
        if (color.name !== null && color.name !== undefined) {
          pdfData[color.name + " color"] = color.value;
        }
      });

      

      // Create new jsPDF document
      const doc = new jsPDF();

      // Set default font size and spacing
      doc.setFontSize(12);
      const lineHeight = 8;
      let yPos = 20;

      // Add content to PDF
      for (const key in pdfData) {
        const value = pdfData[key];
        if (value && value!==null) {
          // Add key-value pair to PDF
          if (value.startsWith("https")) {
            doc.text(20, yPos, `${key}:`);
            doc.setTextColor(0,0,139);
            doc.textWithLink("Click here to see original File", 70, yPos, {
              url: value,
            });
            yPos += lineHeight;
            doc.setTextColor(0,0,0);
          } else {
            doc.text(20, yPos, `${key}: ${value}`);
            yPos += lineHeight;
          }

          // Add image if URL key
          if (key.includes("URL")) {
            // Load image
            const response = await fetch(value);
            const blob = await response.blob();
            const imageDataUrl = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(blob);
            });

            // Add image to PDF
            doc.addImage(imageDataUrl, "JPEG", 20, yPos, 80, 50);
            yPos += lineHeight + 50; // Adjust vertical position after image
          }

          // Check if content exceeds page height
          if (yPos >= doc.internal.pageSize.height - 20) {
            // Add new page
            doc.addPage();
            // Reset yPos
            yPos = 20;
          }
        }
      }

      // Save or download the PDF
      const pdfBlob = doc.output("blob");
      const storageRef = ref(
        storage,
        `tshirt_${teamName}_${Date().toString()}.pdf`
      );
      await uploadBytes(storageRef, pdfBlob);
      const downloadURL = await getDownloadURL(storageRef);
      console.log("PDF download URL:", downloadURL);
      await teamKitMail({apparel_name:"T-Shirt",team_name:teamName,cust_name:customerName,email_address:email,pdf_url:downloadURL,cust_phone:phoneNumber})
      navigate("/thank-you", { replace: true });
      setShowLoader(false);
    } catch (error) {
      console.error("Error:", error);
      setShowLoader(false);
      // Handle error
    }
  };
  function backToSelection() {
    state.form = false;
    state.intro = false;
  }
  return (
    <div className={styles["pant-form"]}>
      {showLoader && <Loader />}
      <div className={styles.padiv}>
        <h4 className={styles.linkstyle} onClick={() => backToSelection()}>
          &larr; Back to Customizer
        </h4>
      </div>
      <h1>Just a little more information</h1>
      <form onSubmit={handleSubmit}>
        <div className={styles["desktop-layout"]}>
          <div className={styles["column"]}>
            <label>
              Team Name:
              <input
                type="text"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                required
              />
            </label>
            <label>
              Customer Name:
              <input
                type="text"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                required
              />
            </label>
            <label>
              Phone Number:
              <input
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
              {phoneNumberError && (
                <p className={styles["error-message"]}>
                  Please enter a valid phone number (10 digits)
                </p>
              )}
            </label>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              {emailError && (
                <p className={styles["error-message"]}>
                  Please enter a valid email address
                </p>
              )}
            </label>
          </div>
          <div className={styles["column"]}>
            <label>
              Logo Left Chest:
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(e, setLogoLeftThigh, setLogoLeftThighPreview)
                }
              />
              {logoLeftThighPreview && (
                <img
                  src={logoLeftThighPreview}
                  alt="Left Chest Logo Preview"
                  width={200}
                />
              )}
              <br></br>
            </label>
            <label>
              Logo Right Chest:
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setLogoRightChest,
                    setLogoRightChestPreview
                  )
                }
              />
              {logoRightChestPreview && (
                <img
                  src={logoRightChestPreview}
                  alt="Right Chest Logo Preview"
                  width={200}
                />
              )}
              <br></br>
            </label>
            <label>
              Logo Left Sleeve:
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setLogoLeftSleeve,
                    setLogoLeftSleevePreview
                  )
                }
              />
              {logoLeftSleevePreview && (
                <img
                  src={logoLeftSleevePreview}
                  alt="Left Sleeve Logo Preview"
                  width={200}
                />
              )}
              <br></br>
            </label>{" "}
            <label>
              Logo Right Sleeve:
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setLogoRightSleeve,
                    setLogoRightSleevePreview
                  )
                }
              />
              {logoRightSleevePreview && (
                <img
                  src={logoRightSleevePreview}
                  alt="Left Chest Logo Preview"
                  width={200}
                />
              )}
              <br></br>
            </label>
            <label>
              Logo Abdomen:
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleFileChange(e, setLogoStomach, setLogoStomachPreview)
                }
              />
              {logoStomachPreview && (
                <img
                  src={logoStomachPreview}
                  alt="Right Chest Logo Preview"
                  width={200}
                />
              )}
              <br></br>
            </label>
            <label>
              Team Member Details File (Excel or PDF):
              <input
                type="file"
                accept=".xls,.xlsx,.pdf"
                onChange={(e) => setMemberDetailsFile(e.target.files[0])}
              />
            </label>
          </div>
          <div className={styles["column"]}>
            <label>Shirt Preview Images (Scroll):</label>
            <div className={styles["image-previews"]}>
              {backImageUrl && (
                <img
                  src={backImageUrl}
                  alt="Back View"
                  className={`${styles["preview-image"]} `}
                />
              )}
              {frontImageUrl && (
                <img
                  src={frontImageUrl}
                  alt="Front View"
                  className={`${styles["preview-image"]} `}
                />
              )}
            </div>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ShirtForm;

import React, { useEffect } from 'react'
import Product from '../Product';

export default function AllProducts() {
  const url = window.location.href;

  useEffect(() => {
    const scrollToProducts = () => {
      if (url.includes("#products")) {
        const productElement = document.getElementById("products");
        if (productElement) {
          productElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };
    scrollToProducts();
  }, [url]);

  return (
    <div style={{ minHeight: "737px" }}>
      <Product id="products" />
    </div>
  );

}

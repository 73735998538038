// Import necessary Firebase and React dependencies
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProducts } from "../../../redux/slice/productSlice";
import styles from "./SoldOut.module.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Loader from "../../loader/Loader";
import Notiflix from "notiflix";
import Search from "../../search/Search";
import Pagination from "../../pagination/Pagination";
import useFetchCollection from "../../../customHooks/useFetchCollection";

const SoldOut = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  // Redux state
  const products = useSelector(selectProducts);
  const { data, isLoading } = useFetchCollection("products");

  // Filter products where all stockCount values are 0
  const soldOutProducts = products.filter((product) =>
    product.sizeInfo.every((color) => color.stockCount === 0)
  );

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.table}>
        <h2>Sold Out Products</h2>

        <div className={styles.search}>
          <p>
            <b>{soldOutProducts.length}</b> sold out products found
          </p>
          <Search value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>

        {soldOutProducts.length === 0 ? (
          <p>No sold out products found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Image</th>
                <th>Name</th>
                <th>Category</th>
                <th>Price</th>
                <th>Tax</th>
                <th>Restock</th>
              </tr>
            </thead>
            <tbody>
              {soldOutProducts
                .slice(
                  (currentPage - 1) * productsPerPage,
                  currentPage * productsPerPage
                )
                .map((product, index) => (
                  <tr key={product.id}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={product.imageURLs[0]} // Using the first image URL
                        alt={product.name}
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>{product.name}</td>
                    <td>{product.category}</td>
                    <td>
                      {/* Check if sizeInfo is defined before mapping over it */}
                      {product.sizeInfo &&
                        product.sizeInfo.map((size, sizeIndex) => (
                          <div key={sizeIndex}>
                            <p>
                              <strong>{`Size ${sizeIndex + 1}:`}</strong>{" "}
                              {size.size} -{" "}
                              {product.price !== undefined
                                ? `$${product.price}`
                                : "N/A"}
                            </p>
                          </div>
                        ))}
                    </td>
                    <td>{`${product.tax}%`}</td>
                    <td className={styles.icons}>
                      <Link to={`/admin/edit-product/${product.id}`}>
                        <FaEdit size={20} color="green" />
                      </Link>
                      &nbsp;
                      
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          productsPerPage={productsPerPage}
          totalProducts={soldOutProducts.length}
        />
      </div>
    </>
  );
};

export default SoldOut;

import { proxy } from "valtio";

const state = proxy({
  intro: true,
  designColor1Name: null,
  designColor2Name: null,
  designColor3Name: null,
  designColor4Name: null,
  designColor5Name: null,
  designColor6Name: null,
  designColor7Name: null,
  designColor8Name: null,
  designColor9Name: null,
  designColor10Name: null,
  designColor1PantName: null,
  designColor2PantName: null,
  designColor3PantName: null,
  designColor4PantName: null,
  designColor5PantName: null,
  colorCap: "darkred",
  option: true,
  type: null,
  selectedCollarType: "polo",
  id: null,
  designColors: null,
  designColorsPant: null,
  designColor: [],
  designColor1: "darkred",
  designColorPant1: "darkred",
  designColorPant2: "darkred",
  designColorPant3: "darkred",
  designColorPant4: "darkred",
  designColorPant5: "darkred",
  designColorPant6: "darkred",
  form: false,
  designColor2: "darkred",
  designColor3: "darkred",
  designColor4: "darkred",
  designColor5: "darkred",
  designColor6: "darkred",
  designColor7: "darkred",
  designColor8: "darkred",
  designColor10: "darkred",
  designColor9: "darkred",
  designColorClick1: "darkred",
  designColorPantClick2: "darkred",
  designColorPantClick1: "darkred",
  designColorPantClick3: "darkred",
  designColorPantClick4: "darkred",
  designColorPantClick5: "darkred",
  designColorPantClick6: "darkred",
  designColorClick2: "darkred",
  designColorClick3: "darkred",
  designColorClick4: "darkred",
  designColorClick5: "darkred",
  designColorClick6: "darkred",
  designColorClick7: "darkred",
  designColorClick8: "darkred",
  designColorClick9: "darkred",
  designColorClick10: "darkred",
  color: "brown",
  test: "yellow",
  colorArm: "darkred",
  colorBase: "black",
  colorPantBase: "black",
  colorPantWaist: "black",
  colorPantBaseClick: "black",
  colorPantWaistClick: "black",
  colorBack: "yellow",
  colorLogo: "white",
  colorCollar: "black",
  colorArmClick: "darkred",
  colorCapClick: "#BA9BC9",
  colorBaseClick: "black",
  colorBackClick: "yellow",
  colorLogoClick: "white",
  colorCollarClick: "black",
  //color optimizations:
  colorCollarOpt: "black",
  colorArmOpt: "darkred",
  colorBaseOpt: "black",
  colorPantBaseOpt: "black",
  colorCapOpt: "darkred",
  colorPantWaistOpt: "black",
  designColor1Opt: "darkred",
  designColorPant1Opt: "darkred",
  designColorPant2Opt: "darkred",
  designColorPant3Opt: "darkred",
  designColorPant4Opt: "darkred",
  designColorPant5Opt: "darkred",
  designColorPant6Opt: "darkred",
  designColor2Opt: "darkred",
  designColor3Opt: "darkred",
  designColor4Opt: "darkred",
  designColor5Opt: "darkred",
  designColor6Opt: "darkred",
  designColor7Opt: "darkred",
  designColor8Opt: "darkred",
  designColor10Opt: "darkred",
  designColor9Opt: "darkred",
  colorCollarOptClick: "black",
  colorArmOptClick: "darkred",
  colorBaseOptClick: "black",
  colorPantBaseOptClick: "black",
  colorCapOptClick: "darkred",
  colorPantWaistOptClick: "black",
  designColor1OptClick: "darkred",
  designColorPant1OptClick: "darkred",
  designColorPant2OptClick: "darkred",
  designColorPant3OptClick: "darkred",
  designColorPant4OptClick: "darkred",
  designColorPant5OptClick: "darkred",
  designColorPant6OptClick: "darkred",
  designColor2OptClick: "darkred",
  designColor3OptClick: "darkred",
  designColor4OptClick: "darkred",
  designColor5OptClick: "darkred",
  designColor6OptClick: "darkred",
  designColor7OptClick: "darkred",
  designColor8OptClick: "darkred",
  designColor10OptClick: "darkred",
  designColor9OptClick: "darkred",
});

export default state;

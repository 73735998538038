import React from "react";
import CanvasModel from "../../components/customizer/canvas/CanvasModel";
import Home from "../../components/customizer/home/Home";
import { Suspense } from "react";
import Loader from "../../components/loader/Loader";
import { useSnapshot } from "valtio";
import state from "../../components/customizer/State";

const Customizer = () => {
  const snap = useSnapshot(state);
  return (
    <Suspense fallback={<Loader />}>
      <Home />
      {!snap.intro && <CanvasModel />}
      {/* <Customize /> */}
    </Suspense>
  );
};

export default Customizer;

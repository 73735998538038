import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useFetchCollection from "../../customHooks/useFetchCollection";
import styles from "./Profile.module.scss";
import { auth, db, storage } from "../../firebase/config";
import Modal from "react-modal";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";

const Profile = () => {
  const navigate = useNavigate();
  const { data: userData, isLoading } = useFetchCollection("users");
  const user = auth.currentUser;
  const [currentUser, setCurrentUser] = useState(null);
  const [userPhotoURL, setUserPhotoURL] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false); // State for modal visibility

  useEffect(() => {
    const findUserByEmail = () => {
      if (Array.isArray(userData) && userData.length > 0 && user) {
        const foundUser = userData.find(
          (userData) => userData.email === user.email
        );
        if (foundUser) {
          setCurrentUser(foundUser);
          setUserPhotoURL(foundUser.photoURL);
        }
      }
    };

    findUserByEmail();
  }, [userData, user]);
  useEffect(() => {
    // Alert if currentUser is missing dob or phoneNumber
    if (currentUser && (!currentUser.dob || !currentUser.phoneNumber)) {
      alert("Please complete your profile by editing it.");
    }
  }, [currentUser]);
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const EditProfileModal = ({ isOpen, onClose, currentUser }) => {
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [updatedUser, setUpdatedUser] = useState(currentUser);
    const initialDob = currentUser?.dob
      ? currentUser.dob.toDate().toISOString().slice(0, 10)
      : ""; // Fallback to empty string if currentUser or currentUser.dob is null
    const [dob, setDob] = useState(initialDob);
    const handleProfilePicChange = async (e) => {
      const file = e.target.files[0]; // Get the selected file from input
      const storageRef = ref(storage, `profile/${Date.now()}${file.name}`); // Create a reference to the storage location with a unique name using current timestamp + filename
      const uploadTask = uploadBytesResumable(storageRef, file); // Start uploading the file

      try {
        const snapshot = await uploadTask; // Wait for the upload task to complete
        const downloadURL = await getDownloadURL(snapshot.ref); // Get the download URL of the uploaded file

        // Update the user's profile with the new profile picture URL
        const userDocRef = doc(db, "users", currentUser.id);
        await updateDoc(userDocRef, { photoURL: downloadURL });

        // Update the state with the new profile picture URL
        setUserPhotoURL(downloadURL);

        toast.success("Profile picture uploaded successfully.");
      } catch (error) {
        toast.error(error.message); // Display error message if upload fails
      }
    };
    const isValidDate = (dateString) => {
      // Regular expression to match the date format YYYY-MM-DD
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
    };
    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!isValidDate(dob)) {
        alert("Please enter a valid date in the format YYYY-MM-DD.");
        return;
      }

      // Convert the entered DOB string to a Date object
      const enteredDate = new Date(dob);

      // Get today's date without the time component
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

      // Compare the entered DOB with today's date
      if (enteredDate >= today) {
        alert("Date of birth should be before today's date.");
        return;
      }
      const userDocRef = doc(db, "users", currentUser.id);

      try {
        // Update user data
        await setDoc(userDocRef, updatedUser);
        await setDoc(userDocRef, { ...updatedUser, dob: new Date(dob) });
        // Upload profile picture if available
        if (profilePicFile) {
          const storageRef = storage.ref();
          const profilePicRef = storageRef.child(`profile/${currentUser.id}`);
          await profilePicRef.put(profilePicFile);
          const photoURL = await profilePicRef.getDownloadURL();
          setUpdatedUser({ ...updatedUser, photoURL });
        }

        console.log("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile:", error);
      }

      onClose(); // Close the modal after updating
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={{
          content: {
            margin: "auto",
            height: "100vh",
            // Center the modal horizontally
            display: "flex", // Use flexbox for layout
            flexDirection: "column", // Arrange children vertically
            alignItems: "center", // Center children horizontally
            justifyContent: "center", // Center children vertically
          },
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "1002",
            overflow: "hidden", // Change overlay color and transparency
          },
        }}
      >
        <div className={styles.modalContent}>
          <button
            style={{
              position: "absolute", // Position the close button
              top: "12px", // Align the button to the top
              right: "0px", // Align the button to the right
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "20px",
              color: "black",
            }}
            onClick={onClose}
          >
            X
          </button>
          <h2>Edit Profile</h2>
          <form onSubmit={handleSubmit}>
            {currentUser &&
              Object.entries(currentUser)
                .filter(
                  ([key]) =>
                    key !== "id" &&
                    key !== "isVerified" &&
                    key !== "createdAt" &&
                    key !== "shippingAddress" &&
                    key !== "photoURL" &&
                    key !== "dob" &&
                    key !== "email"
                )
                .map(([key, value]) => (
                  <div key={key} className={styles.inputWrapper}>
                    <label htmlFor={key} className={styles.label}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}:
                    </label>
                    <input
                      id={key}
                      type="text"
                      value={updatedUser[key]}
                      onChange={(e) =>
                        setUpdatedUser({
                          ...updatedUser,
                          [key]: e.target.value,
                        })
                      }
                      placeholder={key} // Use the field key as placeholder
                      className={styles.input}
                    />
                  </div>
                ))}

            <div className={styles.inputWrapper}>
              <label className={styles.label2} htmlFor="dob">
                Date of Birth:
              </label>
              <input
                id="dob"
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </div>
            <div className={styles.inputWrapper}>
              <label htmlFor="profilePic" className={styles.label}>
                Profile Picture:
              </label>
              <input
                id="profilePic"
                type="file"
                onChange={handleProfilePicChange}
                className={styles.input}
              />
            </div>
            <button type="submit">Save Changes</button>
          </form>
        </div>
      </Modal>
    );
  };

  // Function to format shipping address
  const formatShippingAddress = (address) => {
    if (!address) return "";
    const { name, line1, line2, city, state, postal_code, country } = address;
    return `${name}\n${line1}${
      line2 ? `, ${line2}` : ""
    }\n${city}, ${state} ${postal_code}\n${country}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <ul>
          <li>
            <a href="/order-history">Orders</a>
          </li>
          <li>
            <a href="/return-history">Returns</a>
          </li>
          {/* Add more options as needed */}
        </ul>
      </div>
      <div className={styles.profile}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className={styles.userInfo}>
            {/* First Row */}
            <div className={styles.row}>
              {/* First Column - Profile Pic */}
              <div className={styles.column}>
                {user && userPhotoURL ? (
                  <img
                    className={styles.userAvatar}
                    src={userPhotoURL}
                    alt="User"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faUser}
                    className={styles.userAvatar}
                  />
                )}
              </div>
              {/* Second Column - User Details (except address) */}
              <div className={styles.column2}>
                <div className={styles.details}>
                  {currentUser && (
                    <>
                      <div className={styles.labels}>
                        <p className={styles.detailLabel}>Name:</p>
                        <p className={styles.detailLabel}>Email:</p>
                        {currentUser.dob && (
                          <p className={styles.detailLabel}>Date of Birth:</p>
                        )}

                        {currentUser.phoneNumber && (
                          <p className={styles.detailLabel}>Phone Number:</p>
                        )}
                      </div>
                      <div className={styles.values}>
                        <p className={styles.detailValue}>
                          {currentUser.firstName} {currentUser.lastName}
                        </p>
                        <p className={styles.detailValue}>
                          {currentUser.email}
                        </p>
                        {currentUser.dob && (
                          <p className={styles.detailValue}>
                            {currentUser.dob
                              .toDate()
                              .toLocaleDateString(undefined, {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              })}
                          </p>
                        )}

                        {currentUser.phoneNumber && (
                          <p className={styles.detailValue}>
                            {currentUser.phoneNumber}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Second Row */}
            {/* Second Row */}
            <div className={styles.row1}>
              {currentUser && currentUser.shippingAddress && (
                <>
                  {/* Label for the Address */}
                  <label htmlFor="address" className={styles.label}>
                    Address:
                  </label>
                  {/* Single Column - Address */}
                  <div className={styles.column}>
                    {/* Display shipping address */}
                    <div className={styles.addressTextarea}>
                      <textarea
                        id="address" // Add id for accessibility and associating label
                        value={formatShippingAddress(
                          currentUser.shippingAddress
                        )}
                        readOnly
                        rows={6} // Adjust the number of rows as needed
                        className={styles.addressTextarea}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <button
              className={styles.editButton}
              onClick={() => setShowEditModal(true)}
            >
              Edit Profile
            </button>
            <button className={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
      <EditProfileModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        currentUser={currentUser}
      />
    </div>
  );
};

export default Profile;

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { db } from "../../firebase/config";
import { doc, getDoc, onSnapshot } from "firebase/firestore"; // Import onSnapshot

const initialState = {
  cartItems:JSON.parse(localStorage.getItem("cartItems"))||[],
  cartTotalQuantity: 0,
  
  cartTotalAmount: 0,
  cartTotalAmountWithTaxes: 0,
  previousURL: "",
 
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {

    UPDATE_CART_ITEMS(state, action) {
      // Update cart items with new prices
      state.cartItems = action.payload;
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.info("Cart items updated with new prices", {
        position: "top-left",
      });
    },
    ADD_TO_CART(state, action) {
      const { id, selectedSize, minimumOrderQuantity, knockingService, knockingServicePrice } = action.payload;

      const existingProductIndex = state.cartItems.findIndex(
        (item) => item.id === id && item.selectedSize === selectedSize && item.knockingService === knockingService
      );
          
      if (existingProductIndex >= 0) {
        const existingCartItem = state.cartItems[existingProductIndex];
        if (existingCartItem.cartQuantity >= minimumOrderQuantity) {
          existingCartItem.cartQuantity += 1;
          existingCartItem.totalAmount += existingCartItem.price + knockingServicePrice;
          toast.info(`${existingCartItem.name} (${selectedSize}) increased by 1`, {
            position: "top-left",
          });
        } else {
          existingCartItem.totalAmount += existingCartItem.price + knockingServicePrice;
          existingCartItem.cartQuantity += minimumOrderQuantity;
          toast.info(`${existingCartItem.name} (${selectedSize}) increased by ${minimumOrderQuantity}`, {
            position: "top-left",
          });
        }
      } else {
        const tempProduct = { ...action.payload, cartQuantity: minimumOrderQuantity };
        state.cartItems.push(tempProduct);
        toast.success(`${action.payload.name} added to cart`, {
          position: "top-left",
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    DECREASE_CART(state, action) {
      const { id, selectedSize, knockingService, knockingServicePrice, minimumOrderQuantity } = action.payload;

      const existingCartItemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === id &&
          item.selectedSize === selectedSize &&
          item.knockingService === knockingService
      );

      if (existingCartItemIndex !== -1) {
        const existingCartItem = state.cartItems[existingCartItemIndex];

        if (existingCartItem.cartQuantity > minimumOrderQuantity) {
          existingCartItem.cartQuantity -= 1;
          existingCartItem.knockingServiceQuantity -= 1;
          existingCartItem.totalAmount -= existingCartItem.price + knockingServicePrice; // Subtract knocking service price from total amount
          toast.info(`${existingCartItem.name} (${selectedSize}) decreased by 1`, {
            position: "top-left",
          });
        } else {
          state.cartItems.splice(existingCartItemIndex, 1);
          toast.success(`${existingCartItem.name} (${selectedSize}) removed from cart`, {
            position: "top-left",
          });
        }

        state.cartTotalQuantity -= 1;

        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
    REMOVE_FROM_CART(state, action) {
      const { id, selectedSize, knockingService } = action.payload;
    
      // Filter out the item to be removed based on id, selectedSize, and knockingService
      state.cartItems = state.cartItems.filter(
        (item) => !(item.id === id && item.selectedSize === selectedSize && item.knockingService === knockingService)
      );
    
      // Calculate the total quantity in the cart after removing the item
      state.cartTotalQuantity = state.cartItems.reduce((acc, item) => acc + item.cartQuantity, 0);
    
      // Update local storage with the modified cart items
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    
    CLEAR_CART(state, action) {
      state.cartItems = [];
      toast.info(`Cart cleared`, {
        position: "top-left",
      });

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    
    CALCULATE_SUBTOTAL(state) {
      const totalAmount = state.cartItems.reduce(
        (acc, cartItem) =>
          acc + (cartItem.price+ cartItem.knockingServicePrice) * cartItem.cartQuantity, // Add knocking service price to subtotal calculation
        0
      );

      const totalAmountWithTaxes = state.cartItems.reduce(
        (acc, cartItem) => {
          const unitTotalPriceWithTax =
            (cartItem.price+
            cartItem.knockingServicePrice) * (1+cartItem.tax / 100)  ; // Add knocking service price to total amount with taxes calculation
          return acc + unitTotalPriceWithTax * cartItem.cartQuantity;
        },
        0
      );

      return {
        ...state,
        cartTotalAmount: totalAmount,
        cartTotalAmountWithTaxes: totalAmountWithTaxes,
      };
    },
    
    CALCULATE_TOTAL_QUANTITY(state, action) {
      const array = [];
      state.cartItems.forEach((item) => {
        const { cartQuantity } = item;
        array.push(cartQuantity);
      });
      const totalQuantity = array.reduce((a, b) => a + b, 0);
      state.cartTotalQuantity = totalQuantity;
    },
    SAVE_URL(state, action) {
      state.previousURL = action.payload;
    },

  },
});




export const {
  ADD_TO_CART,
  DECREASE_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEMS,
  CLEAR_CART,
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  SAVE_URL,
} = cartSlice.actions;

export const selectCartItems = (state) => state.cart.cartItems;
export const selectCartTotalQuantity = (state) => state.cart.cartTotalQuantity;
export const selectCartTotalAmount = (state) => state.cart.cartTotalAmount;
export const selectCartTotalAmountWithTaxes = (state) => state.cart.cartTotalAmountWithTaxes;
export const selectPreviousURL = (state) => state.cart.previousURL;

export default cartSlice.reducer;

import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/config";
import { collection, getDocs, getDoc, updateDoc, doc } from "firebase/firestore";
import styles from "./Returns.module.scss";
import { toast } from "react-toastify";

const StatusDropdown = ({ returnId, currentStatus, handleStatusChange }) => {
  const statusOptions = [
    "Applied For Return",
    "Return Request Rejected",
    "Returned Product Under Review",
    "Returned And Exchanged",
    "Returned And Refunded",
  ];

  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false);

  const handleChange = (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    setIsUpdateAllowed(true);
  };

  const handleUpdateClick = () => {
    if (isUpdateAllowed) {
      handleStatusChange(returnId, selectedStatus);
      setIsUpdateAllowed(false);
    }
  };

  return (
    <div>
      <select
        value={selectedStatus}
        onChange={handleChange}
        className={styles.statusDropdown}
      >
        {statusOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <button onClick={handleUpdateClick} disabled={!isUpdateAllowed}>
        Update
      </button>
    </div>
  );
};

const Returns = () => {
  const [returns, setReturns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReturns = async () => {
      try {
        const returnsCollection = collection(db, "returns");
        const returnsSnapshot = await getDocs(returnsCollection);
        const returnsData = returnsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        returnsData.sort((a, b) => b.applyDate.toDate() - a.applyDate.toDate());
        setReturns(returnsData);
      } catch (error) {
        toast.error("Error fetching returns", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReturns();
  }, []);

  const handleStatusChange = async (returnId, newStatus) => {
    try {
      const returnsCollection = collection(db, "returns");
      const returnDocRef = doc(returnsCollection, returnId);

      await updateDoc(returnDocRef, {
        status: newStatus,
      });


      const returnData = await getDoc(returnDocRef);
      const orderId = returnData.data().orderId;
      const ordersCollection = collection(db, "orders");
      const orderDocRef = doc(ordersCollection, orderId);

      if (newStatus === "Return Request Rejected" || newStatus === "Returned And Exchanged" || newStatus === "Returned And Refunded") {
       
        const productId = returnData.data().productId;
        const quantityReturned = returnData.data().quantity;
    
        const productsCollection = collection(db, "products");
        const productDocRef = doc(productsCollection, productId);
    
        const productSnapshot = await getDoc(productDocRef);
        const productData = productSnapshot.data();
    
        // Find the size object corresponding to the returned product
        const sizeIndex = productData.sizeInfo.findIndex(size => size.size === returnData.data().productSize);
    
        if (sizeIndex !== -1) {
            const currentStock = productData.sizeInfo[sizeIndex].stockCount;
            const newStock = currentStock + quantityReturned;
    
            // Update the stock count of the specific size
            productData.sizeInfo[sizeIndex].stockCount = newStock;
    
            // Update the product document with the modified sizeInfo
            await updateDoc(productDocRef, {
                sizeInfo: productData.sizeInfo,
            });
    
        } else {
        }
    }

      setReturns((prevReturns) =>
        prevReturns.map((returnItem) =>
          returnItem.id === returnId
            ? { ...returnItem, status: newStatus }
            : returnItem
        )
        .sort((a, b) => b.applyDate.toDate() - a.applyDate.toDate())
      );
      toast.success("Return Status updated")
    } catch (error) {
      toast.error("Error updating return status", error);
    }
  };

  return (
    <div className={styles.returns}>
      <h2>Returns History</h2>
      <>
        {isLoading ? (
          <p>Loading...</p>
        ) : returns.length === 0 ? (
          <p>No returns found</p>
        ) : (
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Customer Name</th>
                  <th>Customer Phone</th>
                  <th>Customer Email</th>
                  <th>Product Name</th>
                  <th>Size</th>
                  <th>Return Quantity</th>
                  <th>Date Applied</th>
                  <th>Reason for Return</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {returns.map((returnItem) => (
                  <tr key={returnItem.id} className={styles.tableRow}>
                    <td>{returnItem.orderId}</td>
                    <td>{returnItem.customerName}</td>
                    <td>{returnItem.customerPhoneNumber}</td>
                    <td>{returnItem.customerEmail}</td>
                    <td>{returnItem.productName}</td>
                    <td>{returnItem.productSize}</td>
                    <td>{returnItem.quantity}</td>
                    <td>
                      {returnItem.applyDate.toDate().toLocaleDateString()}
                    </td>
                    <td>{returnItem.reasonForReturn}</td>
                    <td>
                      <StatusDropdown
                        returnId={returnItem.id}
                        currentStatus={returnItem.status}
                        handleStatusChange={handleStatusChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </div>
  );
};

export default Returns;

import { useState, useEffect } from "react";
import styles from "./auth.module.scss";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { auth, db } from "../../firebase/config";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import Card from "../../components/card/Card";
import { Link, useNavigate } from "react-router-dom";
import registerGif from "../../assets/registerGif.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  addDoc,
  collection,
  updateDoc,
  doc,
  getFirestore,
  serverTimestamp,
  Timestamp,
  setDoc,
} from "@firebase/firestore";
// import { Timestamp } from "@google-cloud/firestore";

const Register = () => {
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // ... (existing code)
  const isValidDOB = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // Check if the entered date is a valid date
    if (isNaN(dobDate.getTime())) {
      return false;
    }
    console.log(currentDate);
    console.log(dobDate);
    // Check if the entered date is in the past
    if (dobDate >= currentDate) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsEmailVerified(user.emailVerified);
      }
    });

    return () => unsubscribe();
  }, []);

  const registerUser = async (e) => {
    e.preventDefault();
    if (password !== cPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (!isValidDOB(dob)) {
      toast.error("Please enter a valid date of birth.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // const db = getFirestore();
      // await addDoc(collection(db, "users"), {
      //   firstName,
      //   lastName,
      //   email,
      //   phoneNumber,
      //   dob,
      //   createdAt: serverTimestamp(),
      //   isVerified: false,
      // });
      const userDocRef = doc(db, "users", user.uid);
      const dobDate = new Date(dob);

      // Convert to Firebase Timestamp
      const dobTimestamp = Timestamp.fromDate(dobDate);
      await setDoc(userDocRef, {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email,
        photoURL: null,
        signIn: "email",
        dob: dobTimestamp,
        createdAt: Timestamp.now().toDate(),
        isVerified: false,
      });

      setIsLoading(false);
      toast.success(
        "Registration Successful. Please check your email for verification."
      );
      await signOut(auth);

      // Check if the email is verified before allowing login
      if (!user.emailVerified) {
        await signOut(auth);
        toast.error("Please verify your email before logging in.");
        navigate("/login");
        setIsLoading(false);
        return;
      }
      // Redirect to login screen after successful registration and email verification
    } catch (error) {
      setIsLoading(false);
      // Extract the code property from the error object
      const errorCode = error?.code;
      console.log(error);

      // Handle Firebase Auth errors
      switch (errorCode) {
        case "auth/invalid-email":
          toast.error("Invalid email address.");
          break;
        case "auth/email-already-in-use":
          toast.error("Email is already in use.");
          break;
        case "auth/weak-password":
          toast.error("Password is too weak.");
          break;
        default:
          toast.error("An error occurred. Please try again.");
          console.log(errorCode);
          break;
      }
    }
  };
  const applyButton = () => {
    setFormSubmitted(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className={`container ${styles.auth}`}>
        <Card>
          <div className={styles.form}>
            <h2>Register</h2>
            <li className={styles.nav__item} style={{ display: "none" }}>
              <h2 className={styles.nav__title}> Contact Information</h2>
              <ul>
                <li>Cricket bats Newark</li>
                <li>Cricket balls USA</li>
                <li>Free shipping cricket gear</li>
                <li>Cricket gear Newark</li>
                <li>Cricket accessories New Jersey</li>
                <li>Cricket equipment free shipping</li>
                <li>Cricket clothing Newark</li>
                <li>Cricket shoes USA</li>
                <li>Cricket gloves Newark</li>
                <li>Cricket pads free shipping</li>
                <li>Cricket helmets Newark</li>
                <li>Cricket bags USA</li>
                <li>Cricket jerseys Newark</li>
                <li>Cricket trousers free shipping</li>
                <li>Cricket caps Newark</li>
                <li>Cricket sunglasses USA</li>
                <li>Cricket protection Newark</li>
                <li>Free shipping cricket training equipment</li>
                <li>Cricket nets Newark</li>
                <li>Cricket stumps USA</li>
                <li>Cricket wickets Newark</li>
                <li>Cricket umpire equipment free shipping</li>
                <li>Custom cricket gear Newark</li>
                <li>Junior cricket equipment USA</li>
                <li>Senior cricket equipment Newark</li>
                <li>Professional cricket gear free shipping</li>
                <li>Discount cricket equipment Newark</li>
                <li>Online cricket store New Jersey</li>
                <li>Cricket shop USA</li>
                <li>Best cricket gear Newark</li>
                <li>Top-rated cricket equipment free shipping</li>
                <li>Cricket gift ideas Newark</li>
                <li>Cricket memorabilia USA</li>
                <li>International cricket gear Newark</li>
                <li>Affordable cricket gear free shipping</li>
                <li>Premium cricket gear Newark</li>
                <li>Cricket training aids USA</li>
                <li>Cricket coaching equipment Newark</li>
                <li>Cricket fielding equipment free shipping</li>
                <li>Cricket coaching accessories Newark</li>
                <li>Cricket fitness gear USA</li>
                <li>Cricket technology Newark</li>
                <li>Cricket scorebooks free shipping</li>
                <li>Cricket team uniforms Newark</li>
                <li>Cricket match clothing USA</li>
                <li>Cricket tournament gear Newark</li>
                <li>Cricket fan merchandise free shipping</li>
                <li>Cricket gifts Newark</li>
                <li>Cricket presents USA</li>
                <li>Cricket souvenirs Newark</li>
                <li>Official cricket merchandise free shipping</li>
                <li>Youth cricket gear Newark</li>
                <li>Professional cricket bats USA</li>
                <li>Customized cricket bats Newark</li>
                <li>Cricket ball packs free shipping</li>
                <li>Cricket gear sets Newark</li>
                <li>Cricket training gear USA</li>
                <li>Cricket protective gear Newark</li>
                <li>Cricket ground equipment free shipping</li>
                <li>Cricket pitch equipment Newark</li>
                <li>Cricket field maintenance USA</li>
                <li>Cricket tournament uniforms Newark</li>
                <li>Cricket event merchandise free shipping</li>
                <li>Cricket coaching resources Newark</li>
                <li>Cricket technique tools USA</li>
                <li>Cricket strategy aids Newark</li>
                <li>Cricket match essentials free shipping</li>
                <li>Cricket gear for beginners Newark</li>
                <li>Cricket gear for professionals USA</li>
                <li>Cricket gear for kids Newark</li>
                <li>Cricket gear for women free shipping</li>
                <li>Cricket gear for men Newark</li>
                <li>Cricket gear for all ages USA</li>
                <li>Cricket gear for all skill levels Newark</li>
                <li>Cricket gear for all budgets free shipping</li>
                <li>Cricket equipment reviews Newark</li>
                <li>Latest cricket gear USA</li>
                <li>Cricket gear trends Newark</li>
                <li>Cricket gear innovations free shipping</li>
                <li>Cricket gear sale Newark</li>
                <li>Cricket gear clearance USA</li>
                <li>Cricket gear discounts Newark</li>
                <li>Cricket gear promotions free shipping</li>
                <li>Cricket gear bundles Newark</li>
                <li>Cricket gear packages USA</li>
                <li>Cricket gear combo deals Newark</li>
                <li>Cricket gear value packs free shipping</li>
                <li>Cricket gear bulk discounts Newark</li>
                <li>Cricket Store New Jersey</li>
                <li>Cricket Store Newark</li>
                <li>Cricket Store NJ</li>
                <li>Cricket Store USA</li>
                <li>Top Quality Cricket Store USA</li>
                <li>Best Cricket Store USA</li>
                <li>Original Cricket Brands</li>
                <li>Genuine Cricket Brands</li>
                <li>Free Shipping for all cricket products across USA</li>
                <li>Best Price</li>
                <li>Low Price Cricket Bats</li>
                <li>Quality Cricket Bats</li>
                <li>Affordable Cricket Bats</li>
                <li>Cheap Cricket Bats</li>
                <li>Latest Cricket Bats</li>
                <li>Cricket Bats buy online</li>
                <li>Best in class Cricket Bats</li>
                <li>Top Performant Cricket Bats</li>
                <li>Long Handle Cricket Bats</li>
                <li>Short Handle Cricket Bats</li>
                <li>Lightweight Cricket Bats</li>
                <li>Lightweight Cricket Pads</li>
                <li>Power Hitting Cricket Bats</li>
                <li>Cricket bats</li>
                <li>Cricket balls</li>
                <li>Cricket gear</li>
                <li>Cricket accessories</li>
                <li>Cricket equipment</li>
                <li>Cricket clothing</li>
                <li>Cricket shoes</li>
                <li>Cricket gloves</li>
                <li>Cricket pads</li>
                <li>Cricket helmets</li>
                <li>Cricket bags</li>
                <li>Cricket jerseys</li>
                <li>Cricket trousers</li>
                <li>Cricket caps</li>
                <li>Cricket sunglasses</li>
                <li>Cricket protection</li>
                <li>Cricket training equipment</li>
                <li>Cricket nets</li>
                <li>Cricket stumps</li>
                <li>Cricket wickets</li>
                <li>Cricket umpire equipment</li>
                <li>Custom cricket gear</li>
                <li>Junior cricket equipment</li>
                <li>Senior cricket equipment</li>
                <li>Professional cricket gear</li>
                <li>Discount cricket equipment</li>
                <li>Online cricket store</li>
                <li>Cricket shop</li>
                <li>Best cricket gear</li>
                <li>Top-rated cricket equipment</li>
                <li>Cricket gift ideas</li>
                <li>Cricket memorabilia</li>
                <li>International cricket gear</li>
                <li>Affordable cricket gear</li>
                <li>Premium cricket gear</li>
                <li>Cricket training aids</li>
                <li>Cricket coaching equipment</li>
                <li>Cricket fielding equipment</li>
                <li>Cricket coaching accessories</li>
                <li>Cricket fitness gear</li>
                <li>Cricket technology</li>
                <li>Cricket scorebooks</li>
                <li>Cricket team uniforms</li>
                <li>Cricket match clothing</li>
                <li>Cricket tournament gear</li>
                <li>Cricket fan merchandise</li>
                <li>Cricket gifts</li>
                <li>Cricket presents</li>
                <li>Cricket souvenirs</li>
                <li>Official cricket merchandise</li>
                <li>Youth cricket gear</li>
                <li>Professional cricket bats</li>
                <li>Customized cricket bats</li>
                <li>Cricket ball packs</li>
                <li>Cricket gear sets</li>
                <li>Cricket training gear</li>
                <li>Cricket protective gear</li>
                <li>Cricket ground equipment</li>
                <li>Cricket pitch equipment</li>
                <li>Cricket field maintenance</li>
                <li>Cricket tournament uniforms</li>
                <li>Cricket event merchandise</li>
                <li>Cricket coaching resources</li>
                <li>Cricket technique tools</li>
                <li>Cricket strategy aids</li>
                <li>Cricket match essentials</li>
                <li>Cricket gear for beginners</li>
                <li>Cricket gear for professionals</li>
                <li>Cricket gear for kids</li>
                <li>Cricket gear for women</li>
                <li>Cricket gear for men</li>
                <li>Cricket gear for all ages</li>
                <li>Cricket gear for all skill levels</li>
                <li>Cricket gear for all budgets</li>
                <li>Cricket equipment reviews</li>
                <li>Latest cricket gear</li>
                <li>Cricket gear trends</li>
                <li>Cricket gear innovations</li>
                <li>Cricket gear sale</li>
                <li>Cricket gear clearance</li>
                <li>Cricket gear discounts</li>
                <li>Cricket gear promotions</li>
                <li>Cricket gear bundles</li>
                <li>Cricket gear packages</li>
                <li>Cricket gear combo deals</li>
                <li>Cricket gear value packs</li>
                <li>Cricket gear bulk discounts</li>
                <li>Online Cricket Store New Jersey</li>
                <li>Online Cricket Store Newark</li>
                <li>Online Cricket Store NJ</li>
                <li>Online Cricket Store USA</li>
                <li>Cricket bats MB Malik Newark</li>
                <li>Cricket balls MBS USA</li>
                <li>Free shipping SG cricket gear</li>
                <li>Cricket gear SS Newark</li>
                <li>Cricket accessories Gray-Nicolls New Jersey</li>
                <li>Cricket equipment MB Malik free shipping</li>
                <li>Cricket clothing MBS Newark</li>
                <li>Cricket shoes SG USA</li>
                <li>Cricket gloves SS Newark</li>
                <li>Cricket pads Gray-Nicolls free shipping</li>
                <li>Cricket helmets MB Malik Newark</li>
                <li>Cricket bags SS USA</li>
                <li>Cricket jerseys Gray-Nicolls Newark</li>
                <li>Cricket trousers free shipping</li>
                <li>Cricket caps MB Malik Newark</li>
                <li>Cricket sunglasses SG USA</li>
                <li>Cricket protection SS Newark</li>
                <li>Free shipping Gray-Nicolls cricket training equipment</li>
                <li>Cricket nets MB Malik Newark</li>
                <li>Cricket stumps SS USA</li>
                <li>Cricket wickets Gray-Nicolls Newark</li>
                <li>Cricket umpire equipment free shipping</li>
                <li>Custom MB Malik cricket gear Newark</li>
                <li>Junior cricket equipment SS USA</li>
                <li>Senior cricket equipment Gray-Nicolls Newark</li>
                <li>Professional SG cricket gear free shipping</li>
                <li>Discount cricket equipment MB Malik Newark</li>
                <li>Online cricket store New Jersey</li>
                <li>Cricket shop USA</li>
                <li>Best Gray-Nicolls cricket gear Newark</li>
                <li>Top-rated cricket equipment free shipping</li>
                <li>Cricket gift ideas MB Malik Newark</li>
                <li>Cricket memorabilia SS USA</li>
                <li>International cricket gear Gray-Nicolls Newark</li>
                <li>Affordable cricket gear free shipping</li>
                <li>Premium SG cricket gear Newark</li>
                <li>Cricket training aids USA</li>
                <li>Cricket coaching equipment Gray-Nicolls Newark</li>
                <li>Cricket fielding equipment free shipping</li>
                <li>Cricket coaching accessories MB Malik Newark</li>
                <li>Cricket fitness gear SS USA</li>
                <li>Cricket technology Gray-Nicolls Newark</li>
                <li>Cricket scorebooks free shipping</li>
                <li>Cricket team uniforms MB Malik Newark</li>
                <li>Cricket match clothing SS USA</li>
                <li>Cricket tournament gear Gray-Nicolls Newark</li>
                <li>Cricket fan merchandise free shipping</li>
                <li>Cricket gifts MB Malik Newark</li>
                <li>Cricket presents SS USA</li>
                <li>Cricket souvenirs Gray-Nicolls Newark</li>
                <li>Official cricket merchandise free shipping</li>
                <li>Youth cricket gear MB Malik Newark</li>
                <li>Professional cricket bats SG USA</li>
                <li>Customized cricket bats Gray-Nicolls Newark</li>
                <li>Cricket ball packs free shipping</li>
                <li>Cricket gear sets MB Malik Newark</li>
                <li>Cricket training gear SS USA</li>
                <li>Cricket protective gear Gray-Nicolls Newark</li>
                <li>Cricket ground equipment free shipping</li>
                <li>Cricket pitch equipment MB Malik Newark</li>
                <li>Cricket field maintenance SS USA</li>
                <li>Cricket tournament uniforms Gray-Nicolls Newark</li>
                <li>Cricket event merchandise free shipping</li>
                <li>Cricket coaching resources MB Malik Newark</li>
                <li>Cricket technique tools SS USA</li>
                <li>Cricket strategy aids Gray-Nicolls Newark</li>
                <li>Cricket match essentials free shipping</li>
                <li>Cricket gear for beginners MB Malik Newark</li>
                <li>Cricket gear for professionals SG USA</li>
                <li>Cricket gear for kids SS Newark</li>
                <li>Cricket gear for women free shipping</li>
                <li>Cricket gear for men Gray-Nicolls Newark</li>
                <li>Cricket gear for all ages USA</li>
                <li>Cricket gear for all skill levels Gray-Nicolls Newark</li>
                <li>Cricket gear for all budgets free shipping</li>
                <li>Cricket equipment reviews MB Malik Newark</li>
                <li>Latest SG cricket gear USA</li>
                <li>Cricket gear trends SS Newark</li>
                <li>Cricket gear innovations Gray-Nicolls free shipping</li>
                <li>Cricket gear sale MB Malik Newark</li>
                <li>Cricket gear clearance SS USA</li>
                <li>Cricket gear discounts Gray-Nicolls Newark</li>
                <li>Cricket gear promotions free shipping</li>
                <li>Cricket gear bundles MB Malik Newark</li>
                <li>Cricket gear packages SS USA</li>
                <li>Cricket gear</li>
              </ul>
            </li>
            <li className={styles.nav__item} style={{ display: "none" }}>
              <h2 className={styles.nav__title}>Our Products</h2>

              <ul className={styles.nav__ul} id="products">
                <li>CA Cricket Bats</li>
                <li>SS Cricket Bats</li>
                <li>SG Cricket Bats</li>
                <li>Graynicolls Cricket Bats</li>
                <li>Plain Cricket Bats</li>
                <li>MBS Cricket Bats</li>
                <li>MB Malik Cricket Bats</li>
                <li>Cricket Balls</li>
                <li>Cricket Gloves</li>
                <li>Cricket Kit Bags</li>
                <li>Cricket Bat Grips</li>
                <li>Cricket Pads</li>
                <li>Cricket Shoes</li>
                <li>CA Cricket Shoes</li>
                <li>CA JR20 Cricket Shoes</li>
                <li>Cricket Safety Gear</li>
                <li>Cricket Accessories</li>
                <li>Double Willow Cricket Bats</li>
                <li>English Willow Cricket Bats</li>
                <li>Cricket Helmets</li>

                <li>Genuine CA Cricket Bats</li>
                <li>Genuine SS Cricket Bats</li>
                <li>Genuine SG Cricket Bats</li>
                <li>Genuine Graynicolls Cricket Bats</li>
                <li>Genuine Plain Cricket Bats</li>
                <li>Genuine MBS Cricket Bats</li>
                <li>Genuine MB Malik Cricket Bats</li>
                <li>Genuine Cricket Balls</li>
                <li>Genuine Cricket Gloves</li>
                <li>Genuine Cricket Kit Bags</li>
                <li>Genuine Cricket Bat Grips</li>
                <li>Genuine Cricket Pads</li>
                <li>Genuine Cricket Shoes</li>
                <li>Genuine CA Cricket Shoes</li>
                <li>Genuine CA JR20 Cricket Shoes</li>
                <li>Genuine Cricket Safety Gear</li>
                <li>Genuine Cricket Accessories</li>
                <li>Genuine Double Willow Cricket Bats</li>
                <li>Genuine English Willow Cricket Bats</li>
                <li>Genuine Cricket Helmets</li>

                <li>Premium Double Willow Cricket Bats</li>
                <li>Premium English Willow Cricket Bats</li>
                <li>Premium Cricket Helmets</li>
                <li>Premium CA Cricket Bats</li>
                <li>Premium SS Cricket Bats</li>
                <li>Premium SG Cricket Bats</li>
                <li>Premium Graynicolls Cricket Bats</li>
                <li>Premium Plain Cricket Bats</li>
                <li>Premium MBS Cricket Bats</li>
                <li>Premium MB Malik Cricket Bats</li>
                <li>Premium Cricket Balls</li>
                <li>Premium Cricket Gloves</li>
                <li>Premium Cricket Kit Bags</li>
                <li>Premium Cricket Bat Grips</li>
                <li>Premium Cricket Pads</li>
                <li>Premium Cricket Shoes</li>
                <li>Premium CA Cricket Shoes</li>
                <li>Premium CA JR20 Cricket Shoes</li>
                <li>Premium Cricket Safety Gear</li>
                <li>Premium Cricket Accessories</li>
                <li>Premium Cricket Accessories</li>
              </ul>
            </li>
            <form onSubmit={registerUser}>
              <div className={styles.twoInputs}>
                <div className={styles.inputGroup}>
                  <label htmlFor="firstName">
                    First Name <span className={styles.required}>*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={`input ${
                      formSubmitted && !firstName ? styles.errorBorder : ""
                    }`}
                  />
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="lastName">
                    Last Name <span className={styles.required}>*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={`input ${
                      formSubmitted && !lastName ? styles.errorBorder : ""
                    }`}
                  />
                </div>
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="phoneNumber">
                  Phone Number <span className={styles.required}>*</span>
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  pattern="[0-9]{10}"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className={`input ${
                    formSubmitted && !phoneNumber ? styles.errorBorder : ""
                  }`}
                />
                {phoneNumber !== "" && !/^[0-9]{10}$/.test(phoneNumber) && (
                  <span className={styles.error}>
                    Invalid 10 digit phone number format
                  </span>
                )}
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="dob">
                  Date of Birth <span className={styles.required}>*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  placeholder="Enter your date of birth"
                  required
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  className={`input ${
                    formSubmitted && !dob ? styles.errorBorder : ""
                  }`}
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="email">
                  Email <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`input ${
                    formSubmitted && !email ? styles.errorBorder : ""
                  }`}
                />
              </div>
              <label htmlFor="dob">
                Password <span className={styles.required}>*</span>
              </label>
              <div className={styles.twoInputs}>
                <div className={styles.inputGroup}>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`input ${
                      formSubmitted && !password ? styles.errorBorder : ""
                    }`}
                  />
                  <span
                    className={`eye-icon ${
                      showPassword ? "visible" : "hidden"
                    }`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </span>
                </div>

                <div className={styles.inputGroup}>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    required
                    value={cPassword}
                    onChange={(e) => setCPassword(e.target.value)}
                    className={`input ${
                      formSubmitted && !cPassword ? styles.errorBorder : ""
                    }`}
                  />
                  <span
                    className={`eye-icon ${
                      showPassword ? "visible" : "hidden"
                    }`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </span>
                </div>
              </div>

              <button
                type="submit"
                onClick={applyButton}
                className="--btn --btn-primary --btn-block"
              >
                Register
              </button>
            </form>

            <span className={styles.register}>
              <p>Already have an account?</p>
              <Link to="/login" className={styles.linkstyle}>
                Login
              </Link>
            </span>
          </div>
        </Card>
        <div className={styles.img}>
          <img src={registerGif} alt="Register" width="400" />
        </div>
      </section>
    </>
  );
};

export default Register;

import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_CART,
  selectCartItems,
  selectCartTotalAmount,
  selectCartTotalAmountWithTaxes,
} from "../../../../redux/slice/cartSlice";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { selectEmail, selectUserID } from "../../../../redux/slice/authSlice";
import {
  selectBillingAddress,
  selectShippingAddress,
} from "../../../../redux/slice/checkoutSlice";
import {
  orderPlacedAdminMail,
  orderPlacedCustomerMail,
} from "../../../../emailjs/EmailTemplates";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";
import spinnerImg from "../../../../assets/spinner.jpg";
import styles from "../CheckoutForm.module.scss";

export default function CardPay(clientSecret2) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const cartItems = useSelector(selectCartItems);
  const userID = useSelector(selectUserID);
  const userEmail = useSelector(selectEmail);
  const cartTotalAmountWithTaxes = useSelector(selectCartTotalAmountWithTaxes);
  const shippingAddress = useSelector(selectShippingAddress);
  const billingAddress = useSelector(selectBillingAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const [paymentMeth, setPaymentMeth] = useState("Card");
  useEffect(() => {
    setIsFormLoaded(!!elements);
  }, [elements]);

  const couponCode2 = localStorage.getItem("couponcode");

  const addUsertoCoupon = async () => {
    try {
      const response = await fetch(
        // "http://127.0.0.1:4242/add-user-to-coupon",
        `${process.env.REACT_APP_BASE_URL}/add-user-to-coupon`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ couponCode: couponCode2, userId: userID }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const data = await response.json();
      console.log("User added to coupon successfully:", data);
    } catch (error) {
      console.error("Error adding user to coupon:", error.message);
      // Handle the error as needed (e.g., show a message to the user)
    }
  };
  const saveOrder = async () => {
    setShowLoader(true);
    try {
      var finalAmount =
        parseFloat(localStorage.getItem("discountedAmount2")) / 100;

      for (const cartItem of cartItems) {
        const productID = cartItem.id;
        const productDocRef = doc(db, "products", productID);
        const productDoc = await getDoc(productDocRef);
        const productData = productDoc.data();

        const selectedSize = cartItem.selectedSize;
        const purchasedQuantity = cartItem.cartQuantity;
        const updatedStock = productData.sizeInfo.map((size) =>
          size.size === selectedSize
            ? { ...size, stockCount: size.stockCount - purchasedQuantity }
            : size
        );

        const updatedProductData = {
          ...productData,
          sizeInfo: updatedStock,
        };

        await setDoc(productDocRef, updatedProductData);
      }

      const today = new Date();
      const orderDate = today.toDateString();
      const orderTime = today.toLocaleTimeString();

      const orderConfig = {
        userID,
        userEmail,
        orderDate,
        orderTime,
        orderAmount: (
          Number(
            finalAmount
              ? finalAmount
              : shippingAddress.state === "New Jersey"
              ? cartTotalAmountWithTaxes
              : cartTotalAmount
          ) +
          (2.99 / 100) *
            Number(
              finalAmount
                ? finalAmount
                : shippingAddress.state === "New Jersey"
                ? cartTotalAmountWithTaxes
                : cartTotalAmount
            ) +
          0.30
        ).toFixed(2),
        orderStatus: "Order Placed",
        cartItems: cartItems.map((item) => ({
          ...item,
          selectedSize: item.selectedSize,
        })),
        shippingAddress,
        createdAt: Timestamp.now().toDate(),
        paymentMethod: paymentMeth,
      };

      const formattedShippingAddress = `Shipping Address:
        Name: ${shippingAddress.name}
        Line 1: ${shippingAddress.line1}
        Line 2: ${shippingAddress.line2}
        City: ${shippingAddress.city}
        State: ${shippingAddress.state}
        Postal Code: ${shippingAddress.postal_code}
        Country: ${shippingAddress.country}
        Phone: ${shippingAddress.phone}`;

        const cartItemsString = cartItems
      
        .map(
          (item) =>
            `Product: ${item.name}\nSize: ${item.selectedSize}\nQuantity: ${item.cartQuantity} ${(item.sport==="Cricket" && item.category==="Bats")?`Knocking Service: ${item.knockingService}`:""}`
        )
        .join("\n\n");
      await addUsertoCoupon();

      const orderRef = await addDoc(collection(db, "orders"), orderConfig);

      dispatch(CLEAR_CART());
      await orderPlacedAdminMail({
        order_id: orderRef._key.path.segments[1],
        order_date: orderDate,
        order_amount:(Number(finalAmount
          ? finalAmount
          : (shippingAddress.state === "New Jersey"
          ? cartTotalAmountWithTaxes
          : cartTotalAmount))+((2.99/100)*Number(finalAmount
            ? finalAmount
            : (shippingAddress.state === "New Jersey"
            ? cartTotalAmountWithTaxes
            : cartTotalAmount)) + 0.30)).toFixed(2),
        payment_mode: orderConfig.paymentMethod,
        customer_name: shippingAddress.name,
        shipping_address: formattedShippingAddress,
        ordered_items: cartItemsString,
      });
      await orderPlacedCustomerMail({
        to_name: shippingAddress.name,
        order_id: orderRef._key.path.segments[1],
        order_date: orderDate,
        order_amount: (Number(finalAmount
          ? finalAmount
          : (shippingAddress.state === "New Jersey"
          ? cartTotalAmountWithTaxes
          : cartTotalAmount))+((3.99/100)*Number(finalAmount
            ? finalAmount
            : (shippingAddress.state === "New Jersey"
            ? cartTotalAmountWithTaxes
            : cartTotalAmount)))).toFixed(2),
        payment_mode: orderConfig.paymentMethod,

        to_email: userEmail,
        order_address: formattedShippingAddress,
        ordered_items: cartItemsString,
      });

      toast.success("Order saved");
      setShowLoader(false);
      navigate("/checkout-success");
    } catch (error) {
      setShowLoader(false);
      toast.error(error.message);
      navigate("/cart");
    }
  };

  const handleStripePaymentResult = async (result) => {
    setShowLoader(true);
    localStorage.removeItem("discountedAmount");

    try {
      if (result.error) {
        toast.error(result.error.message);
        setMessage(result.error.message);
        return;
      }

      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        toast.success("Payment successful");
        await saveOrder();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowLoader(false);
    }
  };
  console.log(clientSecret2);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (!stripe || !elements || !isFormLoaded) {
      return;
    }

    setIsLoading(true);
    setShowLoader(true);

    try {
      const confirmPayment = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://ultimatesportstore.com/checkout-success",
        },
        redirect: "if_required",
      });
      await stripe
        .confirmCashappPayment(clientSecret2.clientSecret2)
        .then(function ({ error, paymentIntent }) {
          if (error) {
            localStorage.setItem("status", true);
            // Inform the customer that there was an error.
          } else if (paymentIntent.status === "succeeded") {
            // Inform the customer that the payment was successful
          } else if (paymentIntent.status === "requires_action") {
            // Inform the customer that the payment did not go through
            localStorage.setItem("status", true);
          }
        });

      await handleStripePaymentResult(confirmPayment);
    } catch (error) {
      console.error("Error handling payment:", error);
      toast.error("Error processing payment");
    } finally {
      setIsLoading(false);
      setShowLoader(false);
    }
  };
  const paymentMethod = (e) => {
    const paymentType = e.value.type;
    const paymode = paymentType[0].toUpperCase() + paymentType.slice(1);
    setPaymentMeth(paymode);
    if (paymentType === "cashapp") {
      localStorage.setItem("status", true);
    } else {
      localStorage.setItem("status", false);
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <PaymentElement
        onChange={(e) => paymentMethod(e)}
        id={styles["payment-element"]}
      />
      <button
        disabled={isLoading || !isFormLoaded}
        className={styles.button}
        onClick={handleSubmit}
      >
        {console.log(couponCode2)}
        <span id="button-text">
          {isLoading ? (
            <img src={spinnerImg} alt="Loading..." style={{ width: "20px" }} />
          ) : (
            "Pay now"
          )}
        </span>
      </button>
      {message && <div id={styles["payment-message"]}>{message}</div>}
    </>
  );
}

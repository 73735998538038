import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const convertToEmbedUrl = (youtubeURL) => {
  const videoId = youtubeURL.split("v=")[1];
  return `https://www.youtube.com/embed/${videoId}`;
};

const ImageCarousel = ({ imageURLs, videoURL }) => {
  const youtubeEmbedURL = videoURL ? convertToEmbedUrl(videoURL) : null;

  // If there are no image URLs and no video URL, return null
  if (imageURLs.length === 0 && !videoURL) {
    return null; // Return null if there's nothing to render
  }

  let carouselItems = imageURLs.map((image, index) => (
    <div key={index}>
      <img src={image} alt={`Slide ${index + 1}`} />
    </div>
  ));

  // If there's no video URL and more than one image, remove the last slide
  if (!videoURL && imageURLs.length > 1) {
    carouselItems.pop();
  }

  // Render the Carousel component only if there are images or a video to display
  return (
    <>
      {carouselItems.length > 0 && (
        <Carousel showArrows={true} showThumbs={false} infiniteLoop={true}>
          {carouselItems}
        </Carousel>
      )}
      {youtubeEmbedURL && (
        <div>
          <h3>Featured Video:</h3>
          <iframe
            title="YouTube Video"
            width="100%"
            height="200"
            src={youtubeEmbedURL}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </>
  );
};

export default ImageCarousel;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { doc, setDoc, Timestamp, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";
import Card from "../../card/Card";
import Loader from "../../loader/Loader";
import styles from "./ChangeOrderStatus.module.scss";
import { shippedMail } from "../../../emailjs/EmailTemplates";

const ChangeOrderStatus = ({ order, id }) => {
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (order) {
      setStatus(order.orderStatus || "");
    }
  }, [order]);

  const editOrder = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);

    const orderConfig = {
      userID: order.userID,
      userEmail: order.userEmail,
      orderDate: order.orderDate,
      orderTime: order.orderTime,
      orderAmount: order.orderAmount,
      orderStatus: status,
      cartItems: order.cartItems.map((item) => ({
        ...item,
        status: status === "Delivered" ? "Delivered" : status,
      })),
      shippingAddress: order.shippingAddress,
      createdAt: order.createdAt,
      editedAt: Timestamp.now().toDate(),
      paymentMethod: order.paymentMethod,
      delivered: status === "Delivered" ? true : order.delivered || false,
      trackingUrl: order.trackingUrl ? order.trackingUrl : "",
    };

    try {
      if (status === "Cancelled") {
        for (const cartItem of order.cartItems) {
          const productID = cartItem.id;

          const productDocRef = doc(db, "products", productID);
          const productDoc = await getDoc(productDocRef);
          const productData = productDoc.data();

          const selectedSize = cartItem.selectedSize;
          const purchasedQuantity = cartItem.cartQuantity;
          const updatedStock = productData.sizeInfo.map((size) =>
            size.size === selectedSize
              ? { ...size, stockCount: size.stockCount + purchasedQuantity }
              : size
          );

          const updatedProductData = {
            ...productData,
            sizeInfo: updatedStock,
          };

          await setDoc(productDocRef, updatedProductData);
        }
      }

   

      if (status === "Shipped") {
        var trackingUrl = window.prompt("Enter the tracking URL:");

        if (trackingUrl !== null && trackingUrl.trim() !== "") {
          var data = {
            service_id: "your_service_id",
            template_id: "your_template_id",
            user_id: "your_user_id",
            template_params: {
              from_name: "Ecommerce App",
              to_name: "User",
              to_email: `${order.userEmail}`,
              message: "helloworld",
              reply_to: "tonavisinbox@gmail.com",
              track_url: trackingUrl,
            },
          };

          await shippedMail({to_name:order.shippingAddress.name,
          order_id: order.id,
        to_email:order.userEmail,shipment_date:new Date(),tracking_url:trackingUrl})
          orderConfig.trackingUrl = trackingUrl;

          await setDoc(doc(db, "orders", id), orderConfig);
        } else {
          alert("Please enter a valid tracking URL.");
          setIsLoading(false);
          return;
        }
      }

      await setDoc(doc(db, "orders", id), orderConfig);
      setIsLoading(false);
      toast.success("Order status changed successfully");
      navigate("/admin/orders");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.error(error.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className={styles.status}>
        <Card cardClass={styles.card}>
          <h4>Update Status</h4>
          <form onSubmit={(e) => editOrder(e, id)}>
            <span>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" disabled>
                  -- Choose one --
                </option>
                <option value="Payment Verification Pending">
                  Payment Verification Pending
                </option>
                <option value="Order Placed">Order Placed</option>
                <option value="Processing">Processing</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </span>
            <span>
              <button type="submit" className="--btn --btn-primary">
                Update Status
              </button>
            </span>
          </form>
        </Card>
      </div>
    </>
  );
};

export default ChangeOrderStatus;

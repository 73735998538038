import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFetchCollection from "../../../customHooks/useFetchCollection";

import {
  selectOrderHistory,
  STORE_ORDERS,
} from "../../../redux/slice/orderSlice";
import Loader from "../../loader/Loader";
import styles from "./Orders.module.scss";

const Orders = () => {
  const { data, isLoading } = useFetchCollection("orders");
  const orders = useSelector(selectOrderHistory);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(STORE_ORDERS(data));
  }, [dispatch, data]);

  const handleClick = (id) => {
    navigate(`/admin/order-details/${id}`);
  };

 


   

  return (
    <>
      <div className={styles.order}>
        <h2>Your Order History</h2>
        <p>
          Open an order to <b>Change order status</b>
        </p>
        <br />
        <>
          {isLoading && <Loader />}
          <div className={styles.table}>
            {orders.length === 0 ? (
              <p>No order found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Date</th>
                    <th>Order ID</th>
                    <th>Items Ordered</th>
                    <th>Customer Details</th>
                    <th>Order Amount</th>
                    
                    
                    <th>Order Status</th>
                    <th>Payment Method</th> 
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => {
                    const {
                      id,
                      orderDate,
                      orderTime,
                      orderAmount,
                      shippingAddress,
                      cartItems,
                      orderStatus,
                      paymentMethod, // New field
                    } = order;
                    var total;
                    order.cartItems.map((cart, index) => {
                      const price = parseFloat(cart.price)+parseFloat(cart.knockingServicePrice);
                      const tax = (order.shippingAddress.state==="New Jersey"?cart.tax:0);
                      const withTax = (price + (price * tax) / 100).toFixed(2);
                      total = (withTax * cart.cartQuantity).toFixed(2);
                  
                      });
                      const itemsOrdered = cartItems.map(
                        (item) => item.name
                      ).join(", ");

                    return (
                      <tr key={id} onClick={() => handleClick(id)}>
                        <td>{index + 1}</td>
                        <td>
                          {orderDate} at {orderTime}
                        </td>
                        <td>{id}</td>
                        <td>
                        {itemsOrdered}
                        </td>
                        <td>
                        <strong>{shippingAddress.name}</strong><br></br><br></br>
                        {
                            shippingAddress.line1+", "+
                            shippingAddress.line2+", "+
                            shippingAddress.city+`-${shippingAddress.postal_code}, `+
                            shippingAddress.state+", "+
                          shippingAddress.country
                        }
                        </td>
                        <td>
                          {"$"}
                          {parseFloat(orderAmount).toFixed(2)}
                        </td>
                        {/* <td>
                          {"$"}
                          {total}
                        </td> */}
                        
                             
                        <td>
                          <p
                            className={
                              orderStatus !== "Delivered"
                                ? `${styles.pending}`
                                : `${styles.delivered}`
                            }
                          >
                            {orderStatus}
                          </p>
                        </td>
                        <td>{paymentMethod}</td> {/* New column */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Orders;

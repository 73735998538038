import { addDoc, collection, Timestamp,query,where ,doc,updateDoc} from "firebase/firestore";
import { useEffect } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase/config";
import { getDocs } from "firebase/firestore";
import Card from "../../card/Card";
import Loader from "../../loader/Loader";
import styles from "./AddProduct.module.scss";
import { selectProducts } from "../../../redux/slice/productSlice";



const initialState = {
  name: "",
  imageInputs: Array(5).fill(""),
  imageURLs: [],
  price: "",
  actualPrice: "",
  tax: "",
  category: "",
  subCategory: "", // Updated to subCategory
  brand: "",
  desc: "",
  videoURL: "",
  sizeInfo: [],
  sport: "",
  colour: "",
  minimumOrderQuantity: "1", // New field
};

const AddProduct = () => {
  const products = useSelector(selectProducts);
const navigate = useNavigate();
  useEffect(() => {
    addSizeInput();
  }, []);
  
  const [otherSportValue, setOtherSportValue] = useState("");
  const [otherCategoryValue, setOtherCategoryValue] = useState("");
  const [otherSubcategoryValue, setOtherSubcategoryValue] = useState("");
  const [sports, setSports] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [product, setProduct] = useState({
    name: "",
    imageInputs: Array(5).fill(""),
    imageURLs: [],
    price: "",
    actualPrice: "",
    tax: "",
    category: "",
    subCategory: "",
    brand: "",
    desc: "",
    videoURL: "",
    sizeInfo: [],
    sport: "",
    colour: "",
    minimumOrderQuantity: "1",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0); // Added uploadProgress state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sportsSnapshot = await getDocs(collection(db, "sports"));
        const sportsData = sportsSnapshot.docs.map((doc) => doc.data());
        setSports(sportsData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sports:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

 

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setSelectedCategory(value);
  
    if (value === "Other") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        category: otherCategoryValue,
      }));
      setSubcategories([]); // Reset subcategories when "Other" is selected
    } else {
      const selectedSportData = sports.find((sport) => sport.name === selectedSport);
      if (selectedSportData) {
        const selectedCategoryData = selectedSportData.categories.find(
          (category) => category.name === value
        );
        if (selectedCategoryData) {
          setSubcategories(selectedCategoryData.subcategories || []);
        } else {
          setSubcategories([]); // Reset subcategories if selectedCategoryData is not found
        }
      } else {
        setSubcategories([]); // Reset subcategories if selectedSportData is not found
      }
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "sport") {
      setSelectedSport(value);
      setSelectedCategory("");
      setCategories([]);
      setSubcategories([]);
      const sport = sports.find((sport) => sport.name === value);
      setCategories(sport.categories || []);
    } else if (name === "category") {
      setSelectedCategory(value);
      const sport = sports.find((sport) => sport.name === selectedSport);
      const category = sport.categories.find(
        (category) => category.name === value
      );
      setSubcategories(category.subcategories || []);
    }
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleFloatInputChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d*$/.test(value) || value === '' || value === '.') {
      if (name === "actualPrice") {
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: value,
          price: value // Also update "Your Price"
        }));
      } else {
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: value
        }));
      }
    }
  };
  const handleSportChange = (e) => {
    const { value } = e.target;
    setSelectedSport(value);
    setSelectedCategory("");
    setCategories([]);
    setSubcategories([]);
  
    if (value === "Other") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        sport: otherSportValue,
      }));
      setCategories([]); // Reset categories when "Other" is selected
    } else {
      const selectedSportData = sports.find((sport) => sport.name === value);
      if (selectedSportData) {
        setCategories(selectedSportData.categories || []);
      } else {
        setCategories([]); // Reset categories if selectedSportData is not found
      }
    }
  };
  

  const handleImageChange = async (e, index) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `eshop/${Date.now()}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      const snapshot = await uploadTask;
      const downloadURL = await getDownloadURL(snapshot.ref);

      setProduct((prevProduct) => {
        const updatedImageURLs = [...prevProduct.imageURLs];
        updatedImageURLs[index] = downloadURL;

        return {
          ...prevProduct,
          imageURLs: updatedImageURLs.filter((url) => url !== ""),
        };
      });

      toast.success("Image uploaded successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSizeChange = (index, value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      sizeInfo: prevProduct.sizeInfo.map((sizeObj, i) =>
        i === index ? { ...sizeObj, size: value } : sizeObj
      ),
    }));
  };

  const handleStockCountChange = (index, value) => {
    if (/^\d+$/.test(value) || value === '') {
      setProduct((prevProduct) => ({
        ...prevProduct,
        sizeInfo: prevProduct.sizeInfo.map((sizeObj, i) =>
          i === index ? { ...sizeObj, stockCount: value === '' ? 0 : Number(value) } : sizeObj
        ),
      }));
    }
  };

  const addSizeInput = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      sizeInfo: [
        ...prevProduct.sizeInfo,
        {
          size: "",
          stockCount: 0,
        },
      ],
    }));
  };

  const addProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);



    try {
      const formattedSizeInfo = product.sizeInfo.map((sizeData) => ({
        size: sizeData.size,
        stockCount: Number(sizeData.stockCount),
      }));

      const sportValue = selectedSport === "Other" ? otherSportValue : selectedSport;
      const categoryValue = selectedCategory === "Other" ? otherCategoryValue : selectedCategory;
      const subcategoryValue = product.subCategory === "Other" ? otherSubcategoryValue : product.subCategory;
  
    // Update sports collection with new sport if "Other" option selected
if (selectedSport === "Other") {
  await addDoc(collection(db, "sports"), { 
    name: otherSportValue,
    categories: [], // Initialize categories array for the new sport
  });
}

// Update categories array within the selected sport if "Other" option selected
if (selectedCategory === "Other") {
  const sportDoc = await getDocs(query(collection(db, "sports"), where("name", "==", sportValue)));
  if (!sportDoc.empty) {
    const sportId = sportDoc.docs[0].id;
    const sportData = sportDoc.docs[0].data();
    const updatedCategories = [
      ...sportData.categories,
      { 
        name: otherCategoryValue,
        subcategories: [] // Initialize subcategories array for the new category
      }
    ];
    await updateDoc(doc(db, "sports", sportId), { categories: updatedCategories });
  }
}

// Update subcategories array within the selected category if "Other" option selected
if (product.subCategory === "Other") {
  const sportDoc = await getDocs(query(collection(db, "sports"), where("name", "==", sportValue)));
  if (!sportDoc.empty) {
    const sportId = sportDoc.docs[0].id;
    const sportData = sportDoc.docs[0].data();
    const category = sportData.categories.find(category => category.name === categoryValue);
    if (category) {
      const updatedSubcategories = [
        ...category.subcategories,
        otherSubcategoryValue
      ];
      const updatedCategories = sportData.categories.map(cat =>
        cat.name === categoryValue ? { ...cat, subcategories: updatedSubcategories } : cat
      );
      await updateDoc(doc(db, "sports", sportId), { categories: updatedCategories });
    }
  }
}

      await addDoc(collection(db, "products"), {
        name: product.name,
        imageURLs: product.imageURLs,
        tax: product.tax,
        category: otherCategoryValue?otherCategoryValue:selectedCategory,
        subCategory: otherSubcategoryValue?otherSubcategoryValue:product.subCategory, // Include subCategory
        brand: product.brand,
        desc: product.desc,
        videoURL: product.videoURL,
        sizeInfo: formattedSizeInfo,
        sport: otherSportValue?otherSportValue:selectedSport,
        createdAt: Timestamp.now().toDate(),
        price: Number(product.price),
        actualPrice: Number(product.actualPrice),
        colour: product.colour,
        enabled: true,
        minimumOrderQuantity: Number(product.minimumOrderQuantity), // Include minimumOrderQuantity
      });

      setIsLoading(false);
      setUploadProgress(0);
      setProduct(initialState);

      toast.success("Product uploaded successfully.");
      navigate("/admin/all-products");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const removeImage = (index) => {
    setProduct((prevProduct) => {
      const updatedImageInputs = [...prevProduct.imageInputs];
      updatedImageInputs.splice(index, 1);

      return {
        ...prevProduct,
        imageURLs: updatedImageInputs.filter((url) => url !== ""),
      };
    });
  };

  const removeSizeInput = (index) => {
    setProduct((prevProduct) => {
      const updatedSizeInfo = [...prevProduct.sizeInfo];
      updatedSizeInfo.splice(index, 1);
      return {
        ...prevProduct,
        sizeInfo: updatedSizeInfo,
      };
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      
      <div className={styles.product}>
        <h2>Add New Product</h2>
        <Card cardClass={styles.card}>
          <div>
          <form onSubmit={addProduct}>
            <label htmlFor="productName">Product Name:</label>
            <input
              type="text"
              id="productName"
              placeholder="Enter product name"
              required
              name="name"
              value={product.name}
              onChange={handleInputChange}
            />

            <label htmlFor="colour">Product Colour:</label>
            <input
              type="text"
              id="colour"
              placeholder="Enter product colour"
              required
              name="colour"
              value={product.colour}
              onChange={handleInputChange}
            />

            <label htmlFor="productTax">Tax Percentage (%):</label>
            <input
              type="text"
              id="productTax"
              placeholder="Enter tax percentage"
              required
              name="tax"
              value={product.tax}
              onChange={handleFloatInputChange}
              onWheel={(e) => e.preventDefault()}
              pattern="^\d*\.?\d*$"
            />

<label htmlFor="minimumOrderQuantity">Minimum Order Quantity:</label>
            <input
              type="text"
              id="minimumOrderQuantity"
              placeholder="Enter minimum order quantity"
              required
              name="minimumOrderQuantity"
              value={product.minimumOrderQuantity}
              onChange={handleFloatInputChange}
              pattern="^\d*$"
            />

            <label htmlFor="actualPrice">Actual Price (USD) </label>
            <input
              type="text"
              required
              id="actualPrice"
              placeholder="Enter actual product price"
              name="actualPrice"
              value={product.actualPrice}
              onChange={handleFloatInputChange}
              onWheel={(e) => e.preventDefault()}
              pattern="^\d*\.?\d*$"
            />

            <label htmlFor="price">Your Price (USD):</label>
            <input
              type="text"
              id="price"
              placeholder="Enter product selling price"
              required
              name="price"
              value={product.price}
              onChange={handleFloatInputChange}
              onWheel={(e) => e.preventDefault()}
              pattern="^\d*\.?\d*$"
            />

<label htmlFor="productSport">Sport:</label>
              <select
                id="productSport"
                required
                value={selectedSport}
                onChange={handleSportChange}
              >
                <option value="" disabled>
                  -- Choose sport --
                </option>
                {sports.map((sport) => (
                  <option key={sport.name} value={sport.name}>
                    {sport.name}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {selectedSport === "Other" && (
                <input
                  type="text"
                  value={otherSportValue}
                  onChange={(e) => setOtherSportValue(e.target.value)}
                />
              )}

              <label htmlFor="productCategory">Product Category:</label>
              <select
                id="productCategory"
                required
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="" disabled>
                  -- Choose product category --
                </option>
                {categories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {selectedCategory === "Other" && (
                <input
                  type="text"
                  value={otherCategoryValue}
                  onChange={(e) => setOtherCategoryValue(e.target.value)}
                />
              )}

              <label htmlFor="productSubcategory">Subcategory:</label>
              <select
                id="productSubcategory"
                required
                value={product.subCategory}
                onChange={handleInputChange}
                name="subCategory"
              >
                <option value="" disabled>
                  -- Choose subcategory --
                </option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {product.subCategory === "Other" && (
                <input
                  type="text"
                  value={otherSubcategoryValue}
                  onChange={(e) => setOtherSubcategoryValue(e.target.value)}
                />
              )}

            <label htmlFor="productBrand">Product Brand:</label>
            <input
              type="text"
              id="productBrand"
              placeholder="Enter product brand"
              required
              name="brand"
              value={product.brand}
              onChange={handleInputChange}
            />

            <label htmlFor="productDescription">Product Description:</label>
            <textarea
              id="productDescription"
              name="desc"
              required
              placeholder="Enter product description"
              value={product.desc}
              onChange={handleInputChange}
              cols="30"
              rows="10"
            ></textarea>

            <label htmlFor="productVideoURL">Product Video URL:</label>
            <input
              type="text"
              id="productVideoURL"
              placeholder="Enter product video URL"
              name="videoURL"
              value={product.videoURL}
              onChange={handleInputChange}
            />

            <label>Product images:</label>
            <Card cardClass={styles.group}>
              {uploadProgress === 0 ? null : (
                <div className={styles.progress}>
                  <div
                    className={styles["progress-bar"]}
                    style={{ width: `${uploadProgress}%` }}
                  >
                    {uploadProgress < 100
                      ? `Uploading ${uploadProgress}`
                      : `Upload Complete ${uploadProgress}%`}
                  </div>
                </div>
              )}

              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className={styles.imagePreviewContainer}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, index)}
                  />

                  {product.imageURLs[index] && (
                    <>
                      <div className={styles.imagePreview}>
                        <img src={product.imageURLs[index]} alt={`Image ${index + 1}`} />
                      </div>
                      <button
                        type="button"
                        className={styles.removeImageButton}
                        onClick={() => removeImage(index)}
                      >
                        X
                      </button>
                    </>
                  )}
                </div>
              ))}
            </Card>

            <label htmlFor="productSizes">Product Sizes:</label>
            <div>
              {product.sizeInfo.map((size, index) => (
                <div key={index}>
                  <label>{`Size ${index + 1}:`}</label>
                  <input
                    type="text"
                    placeholder={`Size ${index + 1}`}
                    required
                    value={size.size || ""}
                    onChange={(e) => handleSizeChange(index, e.target.value)}
                  />

                  <label>{`Stock Count for Size ${index + 1}:`}</label>
                  <input
                    type="text"
                    required
                    placeholder={`Stock count for Size ${index + 1}`}
                    value={size.stockCount || ""}
                    onChange={(e) => handleStockCountChange(index, e.target.value)}
                  />

                  <button
                    type="button"
                    className={styles.removeSizeButton}
                    onClick={() => removeSizeInput(index)}
                  >
                    Remove Size
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => addSizeInput()} className={styles.addSizeButton}>
                Add a size
              </button>
            </div><br></br>

            <button className="--btn --btn-primary">
              Save Product
            </button>
          </form>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddProduct;

import React, { useEffect } from 'react';
import styles from './ThankYouPage.module.scss'; // SCSS module for ThankYouPage
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import state from '../../components/customizer/State';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const snap = useSnapshot(state)
useEffect(()=>{
  window.scrollTo({ top: 0, behavior: "smooth" });
},[])
  const handleOkayButtonClick = () => {
    state.form = false
    state.option = true
    navigate('/customizer', { replace: true }); // Replace current entry in history stack
  };

  return (
    <div className={styles.thankYouContainer}>
      <div className={styles.thankYouMessage}>
        <h1 className={styles.animateLeft}>Thank you for reaching out!</h1>
        <h3 className={styles.animateRight}>Our representative will contact you shortly.</h3>
      </div>
      <button className={styles.okayButton} onClick={handleOkayButtonClick}>
        Okay
      </button>
    </div>
  );
};

export default ThankYouPage;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderHistory: [],
  totalOrderAmount: null,
  totalOrderAmountWithTaxes: null, // Added property for total order amount including taxes
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    STORE_ORDERS(state, action) {
      state.orderHistory = action.payload;
    },
    CALC_TOTAL_ORDER_AMOUNT(state, action) {
      const array = [];
      state.orderHistory.forEach((item) => {
        const { orderAmount,order_amount, tax } = item;
        if(orderAmount){
          const orderAmountWithTaxes = (orderAmount + (orderAmount * tax) / 100);
        array.push({ orderAmount, orderAmountWithTaxes });
        }
        else if(order_amount){
          const orderAmountWithTaxes = (order_amount + (order_amount * tax) / 100);
        array.push({ order_amount, orderAmountWithTaxes });

        }
        
      });
    
      const totalAmount = array.reduce((a, b) => a + (b.orderAmount?b.orderAmount:b.order_amount), 0);
      const totalAmountWithTaxes = array.reduce((a, b) => a + b.orderAmountWithTaxes, 0);

      state.totalOrderAmount = totalAmount;
      state.totalOrderAmountWithTaxes = totalAmountWithTaxes;
    },
  },
});

export const { STORE_ORDERS, CALC_TOTAL_ORDER_AMOUNT } = orderSlice.actions;

export const selectOrderHistory = (state) => state.orders.orderHistory;
export const selectTotalOrderAmount = (state) => state.orders.totalOrderAmount;
export const selectTotalOrderAmountWithTaxes = (state) => state.orders.totalOrderAmountWithTaxes;

export default orderSlice.reducer;

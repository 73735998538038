import React, { useState } from "react";
import styles from "./LatestProducts.module.scss";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import ProductItem from "../../product/productItem/ProductItem";

export default function LatestProducts() {
  const { data: products, isLoading } = useFetchCollection("products");
  const [grid, setGrid] = useState(true);

  // Sort products based on createdAt in descending order
  const sortedProducts = products
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Take the latest 5 products
  const latestProducts = sortedProducts.slice(0, 4);

  return (
    <div className={styles.latprod}>
      <div className={styles.topSection}>
        <div className={styles.toptext}>
          <h4> LATEST ARRIVALS</h4>
        </div>
        <div className={styles.viewAllLink}>
          <a href="/all-products">View All</a>
        </div>
      </div>
      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {latestProducts.length === 0 ? (
            <p>No product found.</p>
          ) : (
            <>
              {latestProducts.map((product) => (
                <div key={product.id}>
                  {product && product.enabled && (
                    <ProductItem {...product} grid={grid} product={product} />
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

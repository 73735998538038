import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetchCollection from "../../customHooks/useFetchCollection";
import {
  GET_PRICE_RANGE,
  selectProducts,
  STORE_PRODUCTS,
} from "../../redux/slice/productSlice";
import styles from "./Product.module.scss";
import ProductFilter from "./productFilter/ProductFilter";
import ProductList from "./productList/ProductList";
import spinnerImg from "../../assets/spinner.jpg";
import { FaCogs, FaWhatsapp, FaInstagram } from "react-icons/fa";
import {
  FILTER_BY_SEARCH,
  selectFilteredProducts,
  FILTER_BY_CATEGORY,
} from "../../redux/slice/filterSlice";
import Search from "../search/Search";
import { useLocation } from "react-router-dom";

const Product = () => {
  const { data, isLoading } = useFetchCollection("products");
  const [initialFilterApplied, setInitialFilterApplied] = useState(false); // Track if initial filter is applied
  const [showFilter, setShowFilter] = useState(false);
  const products = useSelector(selectProducts);
  const filteredProducts = useSelector(selectFilteredProducts);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const whatsappLink = "https://wa.me/19736877960";
  const instagramLink =
    "https://www.instagram.com/ultimatecricketstore?igsh=MThuYWg3eTR6ajA0Zw%3D%3D&utm_source=qr"; // Replace with your In

  const filterRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("search");
    setSearch(searchQuery || "");
    dispatch(FILTER_BY_SEARCH({ products, searchQuery }));

    if (!initialFilterApplied) {
      // If initial filter is not applied
      setInitialFilterApplied(true); // Set initial filter applied
      // Remove search parameter from URL if it exists
      if (searchParams.has("search")) {
        searchParams.delete("search");
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({}, "", newUrl);
      }
    }
  }, [filteredProducts]);

  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({ products, search }));
  }, [search]);

  useEffect(() => {
    dispatch(
      STORE_PRODUCTS({
        products: data,
      })
    );

    dispatch(
      GET_PRICE_RANGE({
        products: data,
      })
    );
  }, [dispatch, data]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Clear search parameter after filtering
  useEffect(() => {
    if (filteredProducts.length > 0) {
      setSearch("");
    }
  }, [filteredProducts]);

  return (
    <div id="products">
      <section>
        <div className={`container ${styles.product}`}>
          <h2 style={{ display: "none" }}>
            Welcome to UltimateCricketStore, your premier destination for all
            your cricket needs in USA!: 🏏 Specialized Bats,👟 Top-notch
            Shoes,🛡️Accessories
          </h2>

          <aside
            ref={filterRef}
            className={
              showFilter
                ? `${styles.filter} ${styles.show}`
                : `${styles.filter}`
            }
          >
            {isLoading ? null : <ProductFilter />}
          </aside>
          <div className={styles.content}>
            <div></div>
            {isLoading ? (
              <img
                src={spinnerImg}
                alt="Loading.."
                style={{ width: "50px" }}
                className="--center-all"
              />
            ) : (
              <ProductList products={products} />
            )}
            <div className={styles.icon} onClick={toggleFilter}>
              <FaCogs size={20} color="orangered" />
              <p>
                <b>{showFilter ? "Filters" : "Filters"}</b>
              </p>
            </div>
            <a href={whatsappLink} className={styles.whatsappButton}>
              <FaWhatsapp size={30} color="#fff" />
            </a>
            <a href={instagramLink} className={styles.instagramButton}>
              <FaInstagram size={30} color="#fff" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Product;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ADD_TO_CART,
  CALCULATE_TOTAL_QUANTITY,
  SAVE_URL,
} from "../../../redux/slice/cartSlice";
import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  CALCULATE_WISHLIST_QUANTITY,
  selectWishlistItems,
} from "../../../redux/slice/wishlistSlice";
import Card from "../../card/Card";
import styles from "./ProductItem.module.scss";
import { FaHeart } from "react-icons/fa";
import {
  doc,
  updateDoc,
  increment,
  arrayUnion,
  getDoc,
  setDoc,
  arrayRemove,
} from "firebase/firestore";

import { db } from "../../../firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { selectIsLoggedIn } from "../../../redux/slice/authSlice";

const ProductItem = ({
  product,
  onClick,
  grid,
  id,
  name,
  desc,
  imageURLs,
  sizeInfo,
}) => {
  const dispatch = useDispatch();
  const wishlistItems = useSelector(selectWishlistItems);
  const [currentUserID, setCurrentUserID] = useState(null);
  const [isInWishlist, setIsInWishlist] = useState(
    wishlistItems.some((item) => item.id === id)
  );
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentUserID(user.uid);
    } else {
      setCurrentUserID(null);
    }
  });

  const shortenText = (text, n) => {
    if (text.length > n) {
      return text.substring(0, n).concat("...");
    }
    return text;
  };

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const url = window.location.href;
  const navigate = useNavigate();

  const reloadPage = () => {
    window.location.reload();
  };
  const notifyMe = async (event) => {
    if (isLoggedIn) {
      event.preventDefault();
      event.stopPropagation();

      const docRef = doc(db, "products", id);
      const notificationsRef = doc(db, "notifications", currentUserID);
      try {
        const docSnapshot = await getDoc(docRef);
        const productData = docSnapshot.data();

        if (productData.sizeInfo) {
          const updatedSizeInfo = productData.sizeInfo.map((size, index) => {
            if (index === 0) {
              if (!size.stockRequests) {
                size.stockRequests = [];
              }
              if (!size.stockRequests.includes(currentUserID)) {
                size.stockRequests.push(currentUserID);
              }
            }
            return size;
          });

          await updateDoc(docRef, { sizeInfo: updatedSizeInfo });

          const notificationsSnapshot = await getDoc(notificationsRef);

          const existingProducts = notificationsSnapshot.exists()
            ? notificationsSnapshot.data().products || []
            : [];

          const isNewProduct = !existingProducts.some(
            (product) => product.id === id
          );

          if (isNewProduct) {
            existingProducts.push({
              id,
              name,
              imageURL: imageURLs.length > 0 ? imageURLs[0] : "",
              stockStatus: false,
              seen: false,
            });
            await setDoc(notificationsRef, { products: existingProducts });
          }

          toast.success("You will be notified");
        } else {
          console.error("Invalid sizeInfo structure in Firestore document");
        }
      } catch (error) {
        console.error("Error processing Notify Me:", error);
      }
    } else {
      dispatch(SAVE_URL(url));
      window.scrollTo({ top: 0, behavior: "smooth" });

      navigate("/login");
    }
  };

  const addToCart = (product) => {
    dispatch(ADD_TO_CART(product));
    dispatch(CALCULATE_TOTAL_QUANTITY());
  };

  const toggleWishlist = async (product, event) => {
    event.preventDefault();
    event.stopPropagation();

    const wishlistedDocRef = doc(db, "wishlisted", id);

    try {
      const wishlistedDocSnapshot = await getDoc(wishlistedDocRef);

      if (isInWishlist) {
        // Remove from wishlist
        dispatch(REMOVE_FROM_WISHLIST(product));
        setIsInWishlist(false);
        dispatch(CALCULATE_WISHLIST_QUANTITY());

        if (wishlistedDocSnapshot.exists()) {
          const currentWishlist = wishlistedDocSnapshot.data();
          const updatedWishlist = {
            ...currentWishlist,
            inWishlist: arrayUnion(currentUserID),
          };

          await setDoc(wishlistedDocRef, updatedWishlist);
        }
      } else {
        // Add to wishlist
        setIsInWishlist(true);

        const currentWishlist = wishlistedDocSnapshot.exists()
          ? wishlistedDocSnapshot.data()
          : {};

        const updatedWishlist = {
          ...currentWishlist,
          inWishlist: arrayUnion(currentUserID),
        };

        await setDoc(wishlistedDocRef, updatedWishlist);

        dispatch(ADD_TO_WISHLIST(product));
        dispatch(CALCULATE_WISHLIST_QUANTITY());
      }
    } catch (error) {
      console.error("Error toggling wishlist:", error);
    }
  };

  if (!sizeInfo || sizeInfo.length === 0 || !sizeInfo[0] === 0) {
    return null;
  }

  const price = product.price;
  const discountPercentage =
    product.actualPrice &&
    !isNaN(product.actualPrice) &&
    product.actualPrice > price
      ? (((product.actualPrice - price) / product.actualPrice) * 100).toFixed(0)
      : null;

  const hasSoldOutColor = sizeInfo.every((color) => color.stockCount === 0);
  const isEnabled = product.enabled;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setTimeout(() => {
      reloadPage(); // Invoke the callback function
    }, 300);
    // Add any other functionality you want to perform when the product is clicked
  };
  return (
    <div
      className={
        `${styles.wrapper} `
        //  ${hasSoldOutColor || !isEnabled ? styles["sold-out"] : ""}
      }
    >
      <div className={styles.container}>
        <Link to={`/product-details/${id}`} onClick={handleClick}>
          <div className={styles.top}>
            <img src={imageURLs.length > 0 ? imageURLs[0] : ""} alt={name} />
            {hasSoldOutColor && (
              <div className={styles.soldOutTag}>Sold Out</div>
            )}
          </div>
        </Link>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <div className={styles.details}>
              <h4 className={styles.prodname}>{shortenText(name, 20)}</h4>
              <p style={{ fontSize: 15, padding: "5px", paddingLeft: "0px" }}>
                {discountPercentage && (
                  <span
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: "orangered",
                      padding: "3px",
                      fontSize: 10,
                    }}
                  >
                    {`${discountPercentage}% OFF`}
                  </span>
                )}
              </p>
              {!hasSoldOutColor ? (
                <span
                  style={{ fontSize: 15, padding: "5px", paddingLeft: "0px" }}
                >
                  <span style={{ paddingRight: "5px" }}>
                    {product.actualPrice && product.actualPrice > price ? (
                      <s style={{ color: "red" }}>${product.actualPrice}</s>
                    ) : (
                      <></>
                    )}
                  </span>
                  <span style={{color:"black"}}>

                  {`$${price} `}
                  </span>
                </span>
              ) : (
                <span
                  style={{
                    color: "orangered",
                    padding: "3px",
                    fontSize: 15,
                  }}
                >
                  Sold Out
                </span>
              )}

              <div style={{ height: "33px" }}></div>

              {sizeInfo &&
                sizeInfo.length > 0 &&
                sizeInfo[0] &&
                sizeInfo[0].length > 0 && (
                  <p style={{ color: "red" }}>
                    {hasSoldOutColor
                      ? "This item has been sold."
                      : sizeInfo[0].stockCount < 4
                      ? `Only ${sizeInfo[0].stockCount} left!!!`
                      : "In Stock"}
                  </p>
                )}
            </div>
            <div className={styles.buy}>
              <div
                onClick={(event) => toggleWishlist(product, event)}
                className={styles.icon}
              >
                <i
                  className="material-icons"
                  style={{ color: isInWishlist ? "red" : "white" }}
                >
                  favorite
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {(hasSoldOutColor || !isEnabled) && (
        <div className={styles.overlay}>
          <div className={styles.soldOutText}>This item has been sold.</div>
          <button className={styles.notifyButton} onClick={notifyMe}>
            Notify Me
          </button>
        </div>
      )} */}
    </div>
  );
};

export default ProductItem;

import { useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import Select, { components, IndicatorSeparatorProps } from "react-select";
import { PiBellSimple } from "react-icons/pi";

import {
  FaAngleDown,
  FaBell,
  FaHeart,
  FaRegBell,
  FaRegHeart,
  FaShoppingCart,
  FaTimes,
  FaUserCircle,
} from "react-icons/fa";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { auth, db } from "../../firebase/config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBand from "./topBand/TopBand";
import {
  REMOVE_ACTIVE_USER,
  SET_ACTIVE_USER,
} from "../../redux/slice/authSlice";
import { FiShoppingCart, FiBell } from "react-icons/fi";

import { useLocation } from "react-router-dom";
import ShowOnLogin, { ShowOnLogout } from "../hiddenLink/hiddenLink";
import { AdminOnlyLink } from "../adminOnlyRoute/AdminOnlyRoute";
import {
  CALCULATE_TOTAL_QUANTITY,
  selectCartTotalQuantity,
} from "../../redux/slice/cartSlice";
import logoImg from "../../assets/logo.png";
import {
  FILTER_BY_SEARCH,
  selectFilteredProducts,
  FILTER_BY_CATEGORY,
  FILTER_BY_SPORT,
} from "../../redux/slice/filterSlice";
import {
  GET_PRICE_RANGE,
  selectProducts,
  STORE_PRODUCTS,
} from "../../redux/slice/productSlice";
import {
  CALCULATE_WISHLIST_QUANTITY,
  selectWishlistTotalQuantity,
} from "../../redux/slice/wishlistSlice";
import Search from "../search/Search";
import useFetchCollection from "../../customHooks/useFetchCollection";
import { getAuth } from "firebase/auth";
import { BiSearch, BiX } from "react-icons/bi";
import {
  doc,
  getDoc,
  writeBatch,
  updateDoc,
  arrayUnion,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { BsPersonCircle } from "react-icons/bs";
// Added `updateDoc` and `arrayUnion`
import ProductItem from "../product/productItem/ProductItem";
const logo = (
  <div className={styles.logo}>
    <Link to="/">
      <img
        id="ultimate sport store image"
        src={logoImg}
        alt="ultimate sport store"
      />
    </Link>
  </div>
);

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const SearchOverlay = ({ onClose, onSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: products, isLoading } = useFetchCollection("products");
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (!isLoading && products.length > 0 && searchValue.length > 1) {
      filterProducts();
    }
  }, [isLoading, products, searchValue]);

  const filterProducts = () => {
    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.category.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProducts(filtered.slice(0, 5)); // Limit to 4 products initially
  };

  const handleSearch = () => {
    onSearch(searchValue);
    filterProducts();
  };

  const clearSearch = () => {
    setSearchValue("");
    onSearch("");
  };

 const filteredEnabledProducts = filteredProducts.filter(
   (product) => product.enabled
 );
  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={styles.overlayContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.searchContainer}>
          <BiSearch style={{ marginRight: "20px" }} size={20} color="white" />
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search by name"
          />
        </div>{" "}
        {filteredEnabledProducts.length > 0 && (
          <div className={styles.productsLine}>
            <p>Products:</p>
            <Link to={`/all-products?search=${searchValue}`} onClick={onClose}>
              <p>View All</p>
            </Link>
          </div>
        )}
        <div className={styles.filteredProducts}>
          {searchValue.length > 1 && filteredEnabledProducts.length === 0 ? (
            <p>No products found.</p>
          ) : (
            filteredEnabledProducts.map((product) => (
              <ProductItem
                key={product.id}
                {...product}
                grid={true}
                product={product}
                onClick={onClose}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [displayName, setdisplayName] = useState("");
  const [scrollPage, setScrollPage] = useState(false);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const wishlistTotalQuantity = useSelector(selectWishlistTotalQuantity);
  const [search, setSearch] = useState("");
  const [sports, setSports] = useState([]);
  const products = useSelector(selectProducts);
  const { data: prods, isLoading } = useFetchCollection("products");
  const categoriesPerRow = 5;
  const [userNotifications, setUserNotifications] = useState([]);
  const auth = getAuth();
  const [currentUserID, setCurrentUserID] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  const handleSearch = (value) => {
    // Handle the search functionality here
    setSearch(value);
  };
  const [showSearchBar, setShowSearchBar] = useState(false); // State variable to track search bar visibility
  const handleProfileChange = (selectedOption) => {
    // Implement actions based on the selected option
    if (selectedOption.value === "logout") {
      logoutUser();
    } else if (selectedOption.value === "login") {
      navigate("/login");
    } else if (selectedOption.value === "profile") {
      navigate("/profile");
    } else if (selectedOption.value === "wishlist") {
      navigate("/wishlist");
    }
  };

  const profileOption = {
    value: currentUserID ? "logout" : "login",
    label: currentUserID ? "Logout" : "Login",
  };
  const [widestOptionWidth, setWidestOptionWidth] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef.current) {
      // Measure the width of each option in the menu
      const options = menuRef.current.querySelectorAll(".react-select__option");
      let maxWidth = 0;
      options.forEach((option) => {
        maxWidth = Math.max(maxWidth, option.offsetWidth);
      });
      // Set the menu width to the widest option width
      setWidestOptionWidth(`${maxWidth}px`);
    }
  }, [widestOptionWidth]);
  // Function to toggle the visibility of the search bar
  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };


useEffect(() => {
  if (!isLoading && prods.length > 0) {
    const uniqueCategories = [];

    prods.forEach((product) => {
      const productCategory = product.category;
      const productSubcategory = product.subCategory;
      console.log(product);
      if (productCategory && productSubcategory) {
        const existingCategory = uniqueCategories.find(
          (cat) => cat.label === productCategory
        );

        if (existingCategory) {
          existingCategory.options.push(productSubcategory);
        } else {
          uniqueCategories.push({
            label: productCategory,
            options: [productSubcategory],
          });
        }
      }
    });

    // Filter out categories where all products have enabled = false
    const filteredCategories = uniqueCategories.filter((category) =>
      category.options.some((subcategory) => {
        const productsInCategory = prods.filter(
          (product) =>
            product.category === category.label &&
            product.subCategory === subcategory
        );
        return productsInCategory.some((product) => product.enabled);
      })
    );

    setCategories(filteredCategories);
  }
}, [prods, isLoading]);

  const findSportByCategory = (category) => {
    const productWithCategory = prods.find(
      (product) => product.category.toLowerCase() === category.toLowerCase()
    );
    return productWithCategory ? productWithCategory.sport : "All";
  };
  const findSportBySubcategory = (category, subcategory) => {
    const lowerCaseCategory = category.label.toLowerCase();
    const lowerCaseSubcategory = subcategory.value.toLowerCase();

    const productWithSubcategory = prods.find(
      (product) =>
        product.category.toLowerCase() === lowerCaseCategory &&
        product.subCategory.toLowerCase() === lowerCaseSubcategory
    );

    console.log("Original Category (lowercase):", lowerCaseCategory);
    console.log("Original Subcategory (lowercase):", lowerCaseSubcategory);
    console.log("Found Product:", productWithSubcategory);

    return productWithSubcategory ? productWithSubcategory.sport : "All";
  };

  const handleCategoryClick = (category) => {
    let cat = category.toLowerCase().trim();
    const categoryValues = Object.values(categories);
    let foundCat = categories.find((cat) => {
      const formattedCategory = category.toLowerCase().trim(); // Convert category to lowercase
      const formattedLabel = cat.label.toLowerCase().trim(); // Convert cat.label to lowercase
      console.log("Formatted Category:", formattedCategory);
      console.log("Formatted Label:", formattedLabel);
      console.log("Comparison result:", formattedLabel === formattedCategory);
      if (formattedLabel === formattedCategory) {
        console.log("Match found:", cat);
        return cat;
      }
    });

    const formattedCategory = cat
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    navigate(
      `/sport/${findSportByCategory(cat)}?category=${
        foundCat.label
      }&subcategory=All`
    );
  };

  const handleSubcategoryClick = (category, subcategory) => {
    console.log(category);
    console.log(subcategory);

    const formattedCategory = category.label
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    const formattedSubcategory = subcategory.value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    const sport = findSportBySubcategory(category, subcategory);

    const targetURL = `/sport/${sport}?category=${category.label}&subcategory=${subcategory.value}`;

    navigate(targetURL);

    // Set the selected subcategory to null to show the placeholder
    setSelectedSubcategory(null);
  };

  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const [hoveredCategories, setHoveredCategories] = useState({});

  const handleCategoryHover = (category) => {
    setHoveredCategories((prevHoveredCategories) => ({
      ...prevHoveredCategories,
      [category]: !prevHoveredCategories[category],
    }));
  };
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  useEffect(() => {
    setSelectedCategoryValue(null);
  }, []);

  useEffect(() => {
    return () => {
      setHoveredCategory(null);
    };
  }, []);
  const [notificationVisible, setNotificationVisible] = useState(false);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentUserID(user.uid);
    } else {
      setCurrentUserID(null);
    }
  });
  const toggleNotificationDropdown = async () => {
    setNotificationVisible(!notificationVisible);

    // Add logic to update the 'seen' property in the user's notifications
    if (!notificationVisible) {
      await markNotificationsAsSeen();
    }
  };

  const headerRef = useRef(null);
  const notificationIconRef = useRef(null);
  const notificationDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      const header = headerRef.current;
      const icon = notificationIconRef.current;
      const dropdown = notificationDropdownRef.current;

      if (
        header &&
        icon &&
        dropdown &&
        !header.contains(event.target) &&
        !icon.contains(event.target) &&
        !dropdown.contains(event.target) &&
        notificationVisible
      ) {
        setNotificationVisible(false);
      }
    };

    window.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      window.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, [notificationVisible]);

  const markNotificationsAsSeen = async () => {
    try {
      const notificationsRef = doc(db, "notifications", currentUserID);
      const notificationsSnapshot = await getDoc(notificationsRef);

      if (notificationsSnapshot.exists()) {
        const userProducts = notificationsSnapshot.data().products || [];

        // Convert the products array into an array of objects with 'id' and 'seen' properties
        const productsToUpdate = userProducts
          .filter((product) => product.stockStatus)
          .map((product) => ({
            id: product.id,
            seen: product.seen || false,
          }));

        const batch = writeBatch(db);
        productsToUpdate.forEach((product) => {
          const productRef = doc(notificationsRef, "products", product.id);
          batch.update(productRef, { seen: true });
        });

        // Commit the batch update
        await batch.commit();
      }
    } catch (error) {
      console.error("Error marking notifications as seen:", error);
    }
  };

  const fetchUserNotifications = async () => {
    try {
      const notificationsRef = doc(db, "notifications", currentUserID);
      const notificationsSnapshot = await getDoc(notificationsRef);

      if (notificationsSnapshot.exists()) {
        const userProducts = notificationsSnapshot.data().products || [];

        // Filter out products with stockStatus set to false
        const filteredProducts = userProducts.filter(
          (product) => product.stockStatus
        );

        const notifications = filteredProducts.map((product) => ({
          id: product.id,
          message: `${product.name} has been restocked`,
          bold: false,
        }));

        // Fetch bold notifications from the "bold" subcollection
        const boldRef = collection(notificationsRef, "bold");
        const boldSnapshot = await getDocs(boldRef);
        boldSnapshot.forEach((doc) => {
          const index = notifications.findIndex((notif) => notif.id === doc.id);
          if (index !== -1) {
            notifications[index].bold = doc.data().bold || false;
          }
        });

        setUserNotifications(notifications);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Fetch notifications when the component mounts or when currentUserID changes
  useEffect(() => {
    if (currentUserID) {
      fetchUserNotifications();
    }
  }, [currentUserID]);

  const [dropdownWidth, setDropdownWidth] = useState(null);

  useEffect(() => {
    if (!isLoading && prods.length > 0) {
      const uniqueSports = [...new Set(prods.map((product) => product.sport))];
      setSports(uniqueSports);
    }
  }, [prods, isLoading]);
  const isMobile = window.innerWidth <= 800;

  useEffect(() => {
    dispatch(CALCULATE_TOTAL_QUANTITY());
    dispatch(CALCULATE_WISHLIST_QUANTITY());
  }, []);
  const handleNotificationClick = async (productId) => {
    try {
      const notificationsRef = doc(db, "notifications", currentUserID);

      // Explicitly create a document within the "bold" subcollection
      const boldDocRef = doc(notificationsRef, "bold", productId);

      // Set initial data for the "bold" document
      await setDoc(boldDocRef, { bold: false });

      // Handle other actions related to notification click
      // ...

      // Update the userNotifications state to mark the notification as bold
      setUserNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === productId
            ? { ...notification, bold: false }
            : notification
        )
      );
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const fixNavbar = () => {
    if (window.scrollY > 50) {
      setScrollPage(true);
    } else {
      setScrollPage(false);
    }
  };
  window.addEventListener("scroll", fixNavbar);
  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({ products, search }));
  }, [dispatch, products, search]);
  // Monitor currently sign in user
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.displayName == null) {
          const u1 = user.email.slice(0, -10);
          const uName = u1.charAt(0).toUpperCase() + u1.slice(1);
          setdisplayName(uName);
        } else {
          setdisplayName(user.displayName);
        }

        dispatch(
          SET_ACTIVE_USER({
            email: user.email,
            userName: user.displayName ? user.displayName : displayName,
            userID: user.uid,
          })
        );
      } else {
        setdisplayName("");
        dispatch(REMOVE_ACTIVE_USER());
      }
    });
  }, [dispatch, displayName]);
  const handleSportFilter = (sport) => {
    dispatch(FILTER_BY_SPORT({ products, sport }));

    // Scroll to the corresponding section
  };

  const handleSportFilterall = (sport) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    dispatch(FILTER_BY_SPORT({ products, sport }));

    // Scroll to the corresponding section
    const element = document.getElementById("products");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    document.body.style.overflow = showMenu ? "auto" : "hidden";
  };
  useEffect(() => {
    // Set the overflow property when the component mounts
    document.body.style.overflow = showMenu ? "hidden" : "auto";

    // Cleanup function to set the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showMenu]);

  const hideMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = "auto";
  };
  const customize = () => {
    navigate("/customizer");
  };
  // Add an effect to toggle body scrolling after the state change is complete
  useEffect(() => {
    const handleBodyOverflow = () => {
      requestAnimationFrame(() => {
        document.body.style.overflow = showMenu ? "hidden" : "auto";
      });
    };

    // Set a timeout to ensure the state change is complete
    const timeoutId = setTimeout(() => {
      handleBodyOverflow();
    }, 300); // Adjust the timeout duration if needed

    // Clear the timeout in case the component unmounts before the timeout completes
    return () => clearTimeout(timeoutId);
  }, [showMenu]);

  const logoutUser = () => {
    signOut(auth)
      .then(() => {
        toast.success("Logout successfully.");
        navigate("/");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const clearAllNotifications = async () => {
    try {
      const notificationsRef = doc(db, "notifications", currentUserID);

      // Delete the entire document in the "notifications" collection
      await deleteDoc(notificationsRef);

      // After deleting the document, close the dropdown
      setNotificationVisible(false);

      // Real-time listener for notifications
      const unsubscribe = onSnapshot(notificationsRef, (doc) => {
        if (doc.exists()) {
          const userProducts = doc.data().products || [];
          const notifications = userProducts.map((product) => ({
            id: product.id,
            message: `${product.name} has been restocked`,
            bold: false,
          }));

          // Fetch bold notifications from the "bold" subcollection
          const boldRef = collection(notificationsRef, "bold");
          const boldSnapshot = getDocs(boldRef).then((snapshot) => {
            snapshot.forEach((doc) => {
              const index = notifications.findIndex(
                (notif) => notif.id === doc.id
              );
              if (index !== -1) {
                notifications[index].bold = doc.data().bold || false;
              }
            });

            setUserNotifications(notifications);
          });
        } else {
          // No notifications after deletion
          setUserNotifications([]);
        }
      });

      // Unsubscribe the real-time listener when the component unmounts or is not needed
      return () => unsubscribe();
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  const wishlist = (
    <>
      <span className={styles.wishlist}>
        <Link to="/wishlist">
          {/* <FaRegHeart color="black" size={20} />
          <p>{wishlistTotalQuantity}</p> */}
          Wishlist
        </Link>
      </span>
    </>
  );
  const notifications = (
    <span className={styles.notificationContainer} ref={notificationIconRef}>
      <span
        className={styles.notifications}
        onClick={() => {
          toggleNotificationDropdown();
        }}
      >
        <FiBell color="white" size={20} />
        <p>{userNotifications.length > 0 ? userNotifications.length : 0}</p>
      </span>
      {notificationVisible && (
        <div
          className={styles.notificationDropdown}
          ref={notificationDropdownRef}
        >
          {userNotifications.length > 0 && (
            <div
              className={styles.clearNotifications}
              onClick={() => {
                clearAllNotifications();
                setNotificationVisible(false);
              }}
            >
              Clear Notifications
            </div>
          )}

          <div className={styles.notificationScrollArea}>
            {userNotifications && userNotifications.length > 0 ? (
              userNotifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`${styles.notificationCard} ${
                    notification.bold ? styles.boldNotification : ""
                  }`}
                  onClick={() => handleNotificationClick(notification.id)}
                >
                  <Link to={`/product-details/${notification.id}`}>
                    {notification.message}
                  </Link>
                </div>
              ))
            ) : (
              <p>No new notifications</p>
            )}
          </div>
        </div>
      )}
    </span>
  );

  const cart = (
    <span className={styles.cart}>
      <Link to="/cart">
        <FiShoppingCart color="white" size={20} />
        <p>{cartTotalQuantity}</p>
      </Link>
    </span>
  );
  // const profile = {};
  const dropdownOptions = [
    { label: "My Profile", value: "profile" },
    { label: "Wishlist", value: "wishlist" },
    profileOption,
    // { label: notifications, value: "notifications" },
  ];
  const handlePlaceholderClick = (props) => {
    handleCategoryClick(props.children);
  };

  const Placeholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <div onClick={() => handlePlaceholderClick(props)}>
          {props.children}
          <FaAngleDown style={{ marginLeft: "5px" }} />
        </div>
      </components.Placeholder>
    );
  };
  const indicatorSeparatorStyle = {
    alignSelf: "stretch",
    marginBottom: 8,
    marginTop: 8,
    width: 1,
    display: "none",
  };

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };
  const isRootPage = location.pathname === "/";
  const isSportsProductsPage = location.pathname.startsWith("/sport");
  return (
    <>
      <header
        className={`${styles.fixed} ${scrollPage ? styles.hideHeader : ""}`}
        ref={headerRef} // Assign the ref to the entire header
      >
        {isRootPage && <TopBand />}
        <div className={styles.header}>
          {logo}
          {/* <div className={styles.search}>
            {location.pathname === "/" && (
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}
          </div> */}

          {!isMobile && (
            <div className={styles.categories}>
              {categories.map((category, index) => {
                const rowIndex = Math.floor(index / categoriesPerRow);
                const columnIndex = index % categoriesPerRow;
                return (
                  <div
                    key={category.label}
                    className={`${styles.category} ${
                      rowIndex > 0 ? styles.startNewRow : ""
                    } ${columnIndex > 0 ? styles.columnGap : ""}`}
                    onMouseEnter={() => handleCategoryHover(category.label)}
                    onMouseLeave={() => handleCategoryHover(category.label)}
                  >
                    <div
                      className={styles.category}
                      // onClick={() => handleCategoryClick(category)}
                    >
                      <div></div>
                      <div className={styles.subcategories}>
                        <Select
                          options={category.options
                            .filter(
                              (option, index, self) =>
                                self.indexOf(option) === index
                            ) // Filter out duplicates
                            .map((option) => ({
                              value: option,
                              label: option.toUpperCase(),
                            }))}
                          components={{ Placeholder, IndicatorSeparator }}
                          isSearchable={false}
                          placeholder={`${category.label.toUpperCase()}  `}
                          value={null}
                          className={styles.subcategorySelect}
                          styles={{
                            placeholder: (styles, state) => ({
                              fontSize: "1.4rem",
                              ...styles,
                              color: "white",
                              "&:hover": {
                                color: "orangered",
                              },
                            }),
                            dropdownIndicator: () => ({ display: "none" }),
                            option: (styles, state) => ({
                              ...styles,
                              color: "black",
                              backgroundColor: "#f4f4f4",
                              fontSize: "1.2rem",
                              "&:hover": {
                                ...styles,
                                fontSize: "1.2rem",
                                backgroundColor: "",
                                color: "orangered",
                              },
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "none",
                              border: "none",
                              backgroundColor: "#0a1930",
                              width: state.selectProps.dropdownWidth || "100%",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              minWidth: "130px",
                              backgroundColor: "#f4f4f4",
                              width: dropdownWidth,
                              position: "absolute",
                              marginTop: "-5px",
                            }),
                          }}
                          menuIsOpen={hoveredCategories[category.label]}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          onMenuOpen={() => {
                            const placeholderElement = document.querySelector(
                              `.${styles.subcategorySelect} .${styles.placeholder}`
                            );
                            if (placeholderElement) {
                              const placeholderWidth =
                                placeholderElement.offsetWidth;
                              setDropdownWidth(`${placeholderWidth}px`);
                            }
                          }}
                          onChange={(selectedOption) =>
                            handleSubcategoryClick(category, selectedOption)
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <nav
            className={
              showMenu ? `${styles["show-nav"]}` : `${styles["hide-nav"]}`
            }
          >
            <ul onClick={hideMenu}>
              <li className={styles["logo-mobile"]}>
                <div className={styles.logomenu}>{logo}</div>
                <FaTimes size={17} color="#fff" onClick={hideMenu} />
              </li>
            </ul>
            <div className={styles["header-right"]} onClick={hideMenu}>
              <div className={styles.iconsWrapper}>
                <button onClick={customize} className={styles.gradientButton}>
                  Create your own T-shirt
                </button>

                {!currentUserID && (
                  <span className={styles.links}>
                    <NavLink to="/login" className={activeLink}>
                      Login
                    </NavLink>
                  </span>
                )}
                {!isMobile && currentUserID && notifications}
                {/* {wishlist} */}
                {cart}
                {!isMobile && (
                  <BiSearch
                    size={20}
                    color="white"
                    style={{
                      marginLeft: "10px",
                      width: "22px",
                      cursor: "pointer",
                    }}
                    onClick={toggleOverlay}
                  />
                )}
                {currentUserID && (
                  <Select
                    className={` ${styles.profileDropdown}`}
                    options={dropdownOptions}
                    isSearchable={false}
                    onChange={handleProfileChange}
                    placeholder={
                      <BsPersonCircle
                        color="white"
                        size={35}
                        style={{ marginLeft: "5px", width: "22px" }}
                      />
                    }
                    value={null}
                    styles={{
                      indicatorSeparator: () => ({ display: "none" }),
                      placeholder: (styles, state) => ({
                        // Placeholder styles
                        fontSize: "1.4rem",
                        cursor: "pointer",
                        ...styles,
                        // New styles for hovered placeholder
                        "&:hover": {
                          color: "orangered", // Change this to your desired text color
                        },
                      }),
                      dropdownIndicator: () => ({ display: "none" }),
                      option: (styles, state) => ({
                        // Option styles
                        ...styles,
                        color: "black",
                        backgroundColor: "#f4f4f4",
                        fontSize: "1.2rem",
                        "&:hover": {
                          // Hover styles
                          ...styles,
                          backgroundColor: "",
                          fontSize: "1.2rem",
                          color: "orangered",
                        },
                      }),

                      control: (provided, state) => ({
                        // Control styles
                        ...provided,
                        boxShadow: "none",
                        border: "none",
                        backgroundColor: "#0a1930",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "85px",
                        marginTop: "-5px",
                        marginLeft: "-35px",
                        position: "absolute",
                        backgroundColor: "#f4f4f4",
                      }),
                    }}
                    // menuPortalTarget={document.body}
                    menuShouldScrollIntoView={false}
                    menuRef={menuRef}
                  />
                )}
              </div>

              {isMobile && (
                <>
                  {categories.map((category) => (
                    <li
                      style={{
                        fontSize: "15px",
                        marginLeft: "12px",
                        marginTop: "15px",
                        listStyle: "none",
                        borderBottom: "1px solid #ccc",
                        // color:"white"
                      }}
                      key={category.label}
                    >
                      <div
                        style={{ color: "white" }}
                        onClick={() => handleCategoryClick(category.label)}
                      >
                        {category.label.toUpperCase()}
                      </div>
                      <ul style={{ fontSize: "15px" }}>
                        {category.options
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          ) // Filter out duplicates
                          .map((option) => (
                            <li
                              style={{
                                color: "white",
                                margin: "5px",
                                borderBottom: "none",
                              }}
                              key={option}
                              onClick={() =>
                                handleSubcategoryClick(category, {
                                  value: option,
                                  label: option.toUpperCase(),
                                })
                              }
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  marginRight: "10px",
                                }}
                              >
                                ›
                              </span>
                              {option.toUpperCase()}
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </>
              )}
            </div>
          </nav>
          {/* <div
            className={
              showMenu
                ? `${styles["nav-wrapper"]} ${styles["show-nav-wrapper"]}`
                : `${styles["nav-wrapper"]}`
            }
            onClick={hideMenu}
          ></div> */}
          {isMobile && showMenu && (
            <div className={styles.overlay} onClick={hideMenu}></div>
          )}
          {showOverlay && (
            <SearchOverlay onClose={closeOverlay} onSearch={handleSearch} />
          )}
          <AdminOnlyLink>
            <Link to="/admin/home">
              <button className="--btn --btn-primary">Admin Panel</button>
            </Link>
          </AdminOnlyLink>

          <div className={styles["menu-icon"]}>
            <BiSearch
              size={20}
              color="white"
              style={{
                marginLeft: "10px",
                width: "22px",
                cursor: "pointer",
                marginTop: "5px",
              }}
              onClick={toggleOverlay}
            />
            <HiOutlineMenuAlt3 color="white" size={28} onClick={toggleMenu} />
          </div>
        </div>
        {/* <div className={styles.searchdown}>
          <div className={styles.searchbar}>
            {location.pathname === "/" && (
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}
          </div>
        </div> */}

        {/* {(isRootPage || isSportsProductsPage) && (
          <div className={styles.sportsBand}>
            <ul>
              <li>
                <Link to="/" onClick={() => handleSportFilterall("All")}>
                  All Sports
                </Link>
              </li>
              {sports.map((sport) => (
                <li key={sport}>
                  <Link
                    to={`/sport/${sport}`}
                    onClick={() => handleSportFilter(sport)}
                  >
                    {sport}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )} */}
      </header>
    </>
  );
};

export default Header;

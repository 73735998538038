import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectProducts } from "../../../redux/slice/productSlice";
import styles from "./StockRequests.module.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Loader from "../../loader/Loader";
import Notiflix from "notiflix";
import Search from "../../search/Search";
import Pagination from "../../pagination/Pagination";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import { Link } from "react-router-dom";

const StockRequests = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  // Redux state
  const products = useSelector(selectProducts);
  const { data, isLoading } = useFetchCollection("products");

  // Filter products with stockRequests in sizeInfo
  const stockRequestsProducts = products.filter((product) =>
    product.sizeInfo?.some((size) => size.stockRequests?.length > 0)
  );

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.table}>
        <h2>Products with Stock Requests</h2>

        <div className={styles.search}>
          <p>
            <b>{stockRequestsProducts.length}</b> products with stock requests
            found
          </p>
          <Search value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>

        {stockRequestsProducts.length === 0 ? (
          <p>No products with stock requests found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Image</th>
                <th>Name</th>
                <th>Category</th>
                <th>Requests</th>
                <th>Restock</th>
              </tr>
            </thead>
            <tbody>
              {stockRequestsProducts
                .slice(
                  (currentPage - 1) * productsPerPage,
                  currentPage * productsPerPage
                )
                .map((product, index) => (
                  <tr key={product.id}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={product.imageURLs[0]} // Using the first image URL
                        alt={product.name}
                        style={{ width: "100px" }}
                      />
                    </td>
                    <td>{product.name}</td>
                    <td>{product.category}</td>
                    
                    <td>{product.sizeInfo[0].stockRequests.length}</td>
                    <td className={styles.icons}>
                      <Link to={`/admin/edit-product/${product.id}`}>
                        <FaEdit size={20} color="green" />
                      </Link>
                      &nbsp;
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          productsPerPage={productsPerPage}
          totalProducts={stockRequestsProducts.length}
        />
      </div>
    </>
  );
};

export default StockRequests;

import React from "react";
import styles from "./TopBand.module.scss"; // Update the import path based on your project structure
import useFetchCollection from "../../../customHooks/useFetchCollection";

const TopBand = () => {
  const { data, isLoading } = useFetchCollection("taglines");

  if (isLoading || !data) {
    return <p>Loading...</p>;
  }

  // Assuming data is an array and there's only one tagline document
  const taglineData = data[0];

if (taglineData && taglineData.status) {
  return (
    <div className={styles.topBand}>
      <p className={styles.scrollingText}>{taglineData.text}</p>
    </div>
  );
} else {
  return null; // Don't render anything if status is false
}
};

export default TopBand;

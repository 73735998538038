import React, { useState, useEffect } from "react";
import styles from "./JerseyCategory.module.scss";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { toast } from "react-toastify";
import { db, storage } from "../../../../firebase/config";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

const JerseyCategory = () => {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchJerseyCategory = async () => {
      try {
        const docRef = doc(db, "topcategories", "jersey");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setImages(docSnap.data().imageUrls || []);
        }
      } catch (error) {
        console.error("Error fetching jersey category:", error.message);
        toast.error("Error fetching jersey category");
      }
    };

    fetchJerseyCategory();
  }, []);

  const handleImageUpload = async (files) => {
    if (files) {
      try {
        setIsUploading(true);
        const imageUrls = await Promise.all(Array.from(files).map(uploadImage));
        const updatedImages = [...images, ...imageUrls];
        setImages(updatedImages);

        const docRef = doc(db, "topcategories", "jersey");
        await updateDoc(docRef, { imageUrls: updatedImages });

        toast.success("Images added successfully");
      } catch (error) {
        console.error("Error uploading images:", error.message);
        toast.error("Error uploading images");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const uploadImage = async (file) => {
    const storageRef = ref(storage, `jersey/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleImageDelete = async (imageUrl) => {
    try {
      const updatedImages = images.filter((url) => url !== imageUrl);
      setImages(updatedImages);

      const docRef = doc(db, "topcategories", "jersey");
      await updateDoc(docRef, { imageUrls: updatedImages });

      // Delete image from storage
      const storageRef = ref(storage, imageUrl);
      await deleteObject(storageRef);

      toast.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error.message);
      toast.error("Error deleting image");
    }
  };

  return (
    <div className={styles.jerseyCategory}>
      <h2>Manage Jersey Category</h2>

      <div className={styles.imageList}>
        {images.map((image, index) => (
          <div key={index} className={styles.imageItem}>
            <img src={image} alt={`Jersey ${index}`} />
            <button onClick={() => handleImageDelete(image)}>Delete</button>
          </div>
        ))}
      </div>

      <div className={styles.imageUpload}>
        <input
          type="file"
          multiple
          onChange={(e) => handleImageUpload(e.target.files)}
        />
        {isUploading && <p>Uploading...</p>}
      </div>
    </div>
  );
};

export default JerseyCategory;

import React, { useRef, useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { useGLTF, OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import state from "../State";
import Loader from "../../loader/Loader";
import { useThree } from "@react-three/fiber";

const Cap = ({ logo, rotateEnabled, viewMode }) => {
  const snap = useSnapshot(state);
  const { nodes } = useGLTF("/cap.glb");
  const meshRef = useRef();
  const [shirtTexture, setShirtTexture] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const designs = [
    {
      id: 1,
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:inkscape="http://www.inkscape.org/namespace/inkscape/extension" x="0px" y="0px" width="4960" height="4960" viewBox="0 0 4960 4960"><defs class="kb-defs"><g class=""></g>
</defs><g class="kb-view kb-view-0"><path id="Base" fill="${snap.colorCapOptOpt}" d="M4960,3139.077c-40.455,4.144-80.298,14.208-117.869,29.772
		c-36.001,14.914-71.234,36.128-91.861,69.189c-38.977,62.471-14.719,143.883-26.105,216.63
		c-13.721,11.444-27.443,22.888-41.164,34.332c-482.885,0.447-925.694-44.406-1362-52c-684.589-11.915-1350.326,11.299-2108,12
		c-199.44,0.185-650.56,43.815-850,44l-33.972-38.332c-11.386-72.747,12.871-154.159-26.106-216.63
		c-20.628-33.061-55.86-54.275-91.861-69.189c-37.57-15.564-77.414-25.628-117.869-29.772
		c2.451-168.502,35.117-342.553,94.397-500.24c118.705-315.755,331.313-663.497,387.04-669.069
		c39.02-3.902,234.867,353.146,363.036,732.073c3.024,8.939,5.915,17.805,8.694,26.608c9.167-30.343,19.275-60.262,30.309-89.612
		c118.705-315.755,331.313-663.497,387.04-669.069c39.02-3.902,234.867,353.146,363.036,732.073
		c2.101,6.211,4.12,12.377,6.102,18.522c8.504-27.572,17.771-54.789,27.822-81.526c118.705-315.755,331.313-663.497,387.04-669.069
		c39.02-3.902,234.867,353.146,363.036,732.073c5.112,15.113,9.85,30.019,14.27,44.745c10.536-36.567,22.428-72.567,35.654-107.749
		c118.705-315.755,331.313-663.497,387.04-669.069c38.403-3.84,228.708,341.938,356.902,714.104
		c5.115-15.145,10.467-30.163,16.058-45.035c118.705-315.755,331.313-663.497,387.04-669.069
		c39.02-3.902,234.867,353.146,363.036,732.073c8.401,24.838,15.819,49.131,22.392,72.938c6.572-23.808,13.99-48.101,22.392-72.938
		c128.169-378.927,324.016-735.975,363.036-732.073c55.727,5.573,268.335,353.314,387.04,669.069
		C4924.883,2796.524,4957.549,2970.575,4960,3139.077z M861,4906.876c0.026-0.027,390.194-35.906,548-129.876
		c64.318-38.3,94.811-128.052,136-250c62.403-184.758,110.854-470.955,34-982c-81.645,165.867-188.56,351.306-344,474
		c-126.929,100.189-293.634,154.428-444,156c-304.8,3.186-506-272-712-590c-30,168-43.257,283.365-44,426
		c-1.667,320.067,57.1,603.197,278,760C515.296,4914.598,745.846,4915.711,861,4906.876L861,4906.876z M2507.356,3719.114h-647.87
		v413.917h647.87V3719.114z M2927.272,3947.068c0-56.322-47.001-101.979-104.979-101.979s-104.979,45.658-104.979,101.979
		s47.001,101.98,104.979,101.98S2927.272,4003.39,2927.272,3947.068z" style="fill: ${snap.colorCapOpt};"></path><g class="field-id-98863448 field-type-custom" field-key="brand"><path id="ira_x5F_symbol_x5F_left" class="st11" d="M174.2,3047.1c0.5,1.2,0.4,2.5-0.3,3.8c-0.8,1.2-1.9,1.8-3.3,1.8H94.2
	c-1.5,0-2.6-0.6-3.3-1.8c-0.8-1.2-1-2.5-0.3-3.8l3.6-8.4c0.3-0.8,0.8-1.4,1.4-1.8c0.6-0.5,1.4-0.7,2.2-0.7h53.4l-3.4-8.3l-15.4-36.7
	l-15.4,36.7c-0.3,0.8-0.8,1.4-1.4,1.8c-0.7,0.4-1.4,0.6-2.2,0.6h-9.6c-1.5,0-2.6-0.6-3.4-1.8c-0.8-1.1-0.9-2.4-0.3-3.8l23.3-54.6
	c0.3-0.8,0.8-1.5,1.4-1.9s1.4-0.6,2.2-0.6h11c1.8,0,3,0.8,3.7,2.5L174.2,3047.1z"></path>

<path id="ira_x5F_symbol_x5F_left_1_" class="st11" d="M4960.3,3047.1c0.5,1.2,0.4,2.5-0.3,3.8c-0.8,1.2-1.9,1.8-3.3,1.8h-76.3
	c-1.5,0-2.6-0.6-3.3-1.8c-0.8-1.2-1-2.5-0.3-3.8l3.6-8.4c0.3-0.8,0.8-1.4,1.4-1.8c0.6-0.5,1.4-0.7,2.2-0.7h53.4l-3.4-8.3l-15.4-36.7
	l-15.4,36.7c-0.3,0.8-0.8,1.4-1.4,1.8c-0.7,0.4-1.4,0.6-2.2,0.6h-9.6c-1.5,0-2.6-0.6-3.4-1.8c-0.8-1.1-0.9-2.4-0.3-3.8l23.3-54.6
	c0.3-0.8,0.8-1.5,1.4-1.9s1.4-0.6,2.2-0.6h11c1.8,0,3,0.8,3.7,2.5L4960.3,3047.1z"></path></g><g class="field-id-98833953 field-type-editor" field-key="editor"><image placement-key="add-club-logo" name="Upload your Club Logo" placed-text="Placed Logos" x="2450" y="3200" width="250" height="250" view="Front"></image></g></g></svg>`,
    },
  ];
  const stateString = JSON.stringify(snap);
  const svgStr = designs.find((design) => design.id === snap.id);
  const svgString = svgStr.svg;
  state.designColors = svgStr.colors;
  const svgDataURL = `data:image/svg+xml;base64,${btoa(svgString)}`;
  useEffect(() => {
    // Load SVG texture
    const shirtTextureLoader = new THREE.TextureLoader();
    shirtTextureLoader.load(svgDataURL, (texture) => {
      texture.flipY = false;
      setShirtTexture(texture);
    });
  }, [svgDataURL]);

  useEffect(() => {
    if (svgStr && svgStr.colorNames && svgStr.colorNames.length > 0) {
      svgStr.colorNames.forEach((colorName, index) => {
        state[`designColor${index + 1}Name`] = colorName;
      });
    }
  }, [svgStr]);

  // Define materials to apply texture
  const [logoTexture, setLogoTexture] = useState(null);

  useEffect(() => {
    const materialsToApplyTexture = [
      "FABRIC_11_FRONT_3933",
      "FABRIC_11_BACK_3933",
    ];
    if (!shirtTexture) return;

    // Apply texture to materials
    Object.values(nodes).forEach((mesh) => {
      if (
        mesh.material &&
        materialsToApplyTexture.includes(mesh.material.name)
      ) {
        console.log(mesh);

        mesh.material.map = shirtTexture;

        mesh.material.needsUpdate = true; // Ensure material updates
        mesh.material.toneMapping = THREE.LinearToneMapping; // Better tone mapping for realistic lighting
        // Adjust material properties if needed
        // mesh.material.roughness = 0.5;
        mesh.material.metalness = 0.5;
        mesh.castShadow = true;
        mesh.receiveShadow = true;
      }
    });
    setLoading(false);
  }, [shirtTexture, nodes]);
  const controls = useRef();

  const { camera } = useThree();

  useEffect(() => {
    if (viewMode) {
      // Adjust camera position for back view
      camera.position.set(0, 0, 2); // Example position for the back view
      camera.lookAt(0, 0, 0); // Look at the center of the scene
      camera.position.applyQuaternion(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          Math.PI
        )
      );
    } else {
      // Adjust camera position for front view
      camera.position.set(0, 0, 2); // Example position for the front view
      camera.lookAt(0, 0, 1); // Look slightly ahead
    }
  }, [camera, viewMode]);

  return (
    <>
      <OrbitControls
        ref={controls}
        rotateSpeed={0.5}
        enablePan={false}
        autoRotate={rotateEnabled} // Enable auto rotation
        autoRotateSpeed={0.5} // Adjust auto rotation speed
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2.5}
      />
      <ambientLight intensity={0.5} />{" "}
      {/* Ambient light to provide general illumination */}
      <directionalLight position={[5, 5, 5]} intensity={0.8} castShadow />{" "}
      {/* Directional light to simulate sunlight */}
      <directionalLight position={[-5, -5, -5]} intensity={0.5} />{" "}
      {/* Additional light from behind */}
      <group key={stateString} ref={meshRef} scale={[1.75, 1.75, 1.75]}>
        {Object.values(nodes).map((mesh, index) => (
          <mesh
            key={index}
            geometry={mesh.geometry}
            material={mesh.material}
            visible={mesh.visible}
            dispose={null}
            castShadow
            receiveShadow
          />
        ))}
      </group>
    </>
  );
};

export default Cap;

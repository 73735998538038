import React from "react";
import styles from "./PrivacyPolicy.module.scss";

export default function PrivacyPolicy() {
  return (
<div className={styles.privacyPolicy}>
<h2>Privacy Policy</h2>

      <h4>Information Collection:</h4>
      <p>
        We collect information when you register, place an order, enter contests,
        respond to surveys, or engage in site features. The collected data may include
        your name, email address, mailing address, phone number, and credit card
        information. Gift recipients' information is only used for order fulfillment
        and not for marketing.
      </p>

      <h4>Use of Information:</h4>
      <p>
        We use collected information to personalize your site experience, process
        transactions, respond to customer service requests, administer contests or
        promotions, and send periodic emails if you opt-in for newsletters. You have
        the choice to opt-out from promotional emails.
      </p>

      <h4>Information Security:</h4>
      <p>
        We employ security measures to protect your personal information. This
        includes secured networks, limited access by authorized personnel, and
        encrypted transmission of sensitive information via SSL technology.
      </p>

      <h4>Use of Cookies:</h4>
      <p>
        Yes, we use cookies to improve user experience, remember shopping cart items,
        understand preferences, and compile aggregate data on site traffic and
        interaction. Third-party service providers may assist in analyzing site visitor
        information but are restricted from using it for other purposes.
      </p>

      <p>
        You can manage cookie settings in your browser, but disabling cookies may affect
        site features and service functionality.
      </p>

      <p>
        For further details on how your information is handled, please refer to our
        comprehensive Privacy Policy.
      </p>

      <h4>Questions and Feedback:</h4>
      <p>
        Your questions, comments, and concerns about privacy are highly valued. Please
        feel free to share any feedback regarding privacy or any other matter with us.
      </p>

      <h4>Online Policy Only:</h4>
      <p>
        This privacy policy pertains specifically to information collected through our
        website and does not extend to information collected offline.
      </p>
    </div>
  );
}

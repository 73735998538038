import { useState, useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../../card/Card.js";
import {
  SAVE_BILLING_ADDRESS,
  SAVE_SHIPPING_ADDRESS,
} from "../../../redux/slice/checkoutSlice.js";
import styles from "./CheckoutDetails.module.scss";
import CheckoutSummary from "../checkoutSummary/CheckoutSummary.js";

const initialAddressState = {
  name: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "United States",
  phone: "",
};

const CheckoutDetails = () => {
  const [shippingAddress, setShippingAddress] = useState({
    ...initialAddressState,
  });
  const [billingAddress, setBillingAddress] = useState({
    ...initialAddressState,
  });
  const [copyAddress, setCopyAddress] = useState(true);
  const [usStates, setUsStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [shippingErrors, setShippingErrors] = useState({});
  const [billingErrors, setBillingErrors] = useState({});
  const [postalCodeError, setPostalCodeError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Perform the initial copy when the component mounts
    if (copyAddress) {
      setBillingAddress({ ...shippingAddress });
    }
  }, [copyAddress, shippingAddress]);
  useEffect(() => {
    const statesData = [
      { value: "AL", label: "Alabama" },
      { value: "AK", label: "Alaska" },
      { value: "AZ", label: "Arizona" },
      { value: "AR", label: "Arkansas" },
      { value: "CA", label: "California" },
      { value: "CO", label: "Colorado" },
      { value: "CT", label: "Connecticut" },
      { value: "DE", label: "Delaware" },
      { value: "FL", label: "Florida" },
      { value: "GA", label: "Georgia" },
      { value: "HI", label: "Hawaii" },
      { value: "ID", label: "Idaho" },
      { value: "IL", label: "Illinois" },
      { value: "IN", label: "Indiana" },
      { value: "IA", label: "Iowa" },
      { value: "KS", label: "Kansas" },
      { value: "KY", label: "Kentucky" },
      { value: "LA", label: "Louisiana" },
      { value: "ME", label: "Maine" },
      { value: "MD", label: "Maryland" },
      { value: "MA", label: "Massachusetts" },
      { value: "MI", label: "Michigan" },
      { value: "MN", label: "Minnesota" },
      { value: "MS", label: "Mississippi" },
      { value: "MO", label: "Missouri" },
      { value: "MT", label: "Montana" },
      { value: "NE", label: "Nebraska" },
      { value: "NV", label: "Nevada" },
      { value: "NH", label: "New Hampshire" },
      { value: "NJ", label: "New Jersey" },
      { value: "NM", label: "New Mexico" },
      { value: "NY", label: "New York" },
      { value: "NC", label: "North Carolina" },
      { value: "ND", label: "North Dakota" },
      { value: "OH", label: "Ohio" },
      { value: "OK", label: "Oklahoma" },
      { value: "OR", label: "Oregon" },
      { value: "PA", label: "Pennsylvania" },
      { value: "RI", label: "Rhode Island" },
      { value: "SC", label: "South Carolina" },
      { value: "SD", label: "South Dakota" },
      { value: "TN", label: "Tennessee" },
      { value: "TX", label: "Texas" },
      { value: "UT", label: "Utah" },
      { value: "VT", label: "Vermont" },
      { value: "VA", label: "Virginia" },
      { value: "WA", label: "Washington" },
      { value: "WV", label: "West Virginia" },
      { value: "WI", label: "Wisconsin" },
      { value: "WY", label: "Wyoming" },
    ];

    setUsStates(statesData);
  }, []);
  const validateBillingField = (name, value) => {
    let errors = { ...billingErrors };

    switch (name) {
      case "name":
        errors.name = value.trim() === "" ? "Name is required" : "";
        break;
      case "line1":
        errors.line1 = value.trim() === "" ? "Address line 1 is required" : "";
        break;
      case "city":
        errors.city = value.trim() === "" ? "City is required" : "";
        break;
      case "state":
        errors.state = value.trim() === "" ? "State is required" : "";
        break;
      case "postal_code":
        errors.postal_code =
          value.trim() === "" ? "Postal code is required" : "";
        break;
      case "country":
        errors.country = value.trim() === "" ? "Country is required" : "";
        break;
      case "phone":
        errors.phone = value.trim() === "" ? "Phone is required" : "";
        break;
      // Add more cases for other fields as needed

      default:
        break;
    }

    setBillingErrors(errors);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    // Use a regular expression to define the valid format for phone numbers
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleShipping = async (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const isValidPhoneNumber = /^\d{10}$/.test(value.replace(/[-()\s]/g, ""));

      setShippingErrors({
        ...shippingErrors,
        phone: isValidPhoneNumber ? "" : "Invalid phone number format(10 digit)",
      });
    }
    // Special handling for state
    if (name === "state") {
      setShippingAddress({
        ...shippingAddress,
        state: value,
      });
    } else {
      // For postal_code, handle separately to preserve the last digit
      if (name === "postal_code") {
        const truncatedValue = value.slice(0, 5);

        // Update state with the truncated postal code
        setShippingAddress({
          ...shippingAddress,
          [name]: truncatedValue,
        });

        // Validate and fetch state information based on the truncated postal code
        if (isValidPostalCode(truncatedValue)) {
          try {
            const response = await axios.get(
              `https://api.zippopotam.us/us/${truncatedValue}`
            );
            const stateCode = response.data.places[0]?.state || "";
            setSelectedState(stateCode);

            // Update state with the full postal code after the asynchronous fetch
            setShippingAddress({
              ...shippingAddress,
              postal_code: value,
              state: stateCode,
            });
          } catch (error) {
            console.error("Error fetching state from postal code:", error);
            setSelectedState("");
            setPostalCodeError("This Postal Code doesn't exist");
          }
        }
      } else {
        // For other fields, handle normally
        setShippingAddress({
          ...shippingAddress,
          [name]: value,
        });
      }

      // Copy to billing if needed
      if (copyAddress) {
        setBillingAddress({
          ...billingAddress,
          [name]: value,
        });
      }
    }
  };

  const handleBilling = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const isValidPhoneNumber = /^\d{10}$/.test(value.replace(/[-()\s]/g, ""));

      setBillingErrors({
        ...billingErrors,
        phone: isValidPhoneNumber ? "" : "Invalid phone number format",
      });
    }
    setBillingAddress({
      ...billingAddress,
      [name]: value,
    });
  };

  const handleCopyShippingToBilling = () => {
    setCopyAddress(!copyAddress);

    if (!copyAddress) {
      setBillingAddress({ ...shippingAddress });
    }
  };

  const handleCountryChange = (val) => {
    setShippingAddress({
      ...shippingAddress,
      country: val,
    });

    if (val === "United States") {
      setSelectedState("CA");
    } else {
      setSelectedState("");
    }
  };

  const isValidPostalCode = (postalCode) => {
    // Use a regular expression to define the valid format for postal codes
    const postalCodeRegex = /^\d{5}$/;
    return postalCodeRegex.test(postalCode);
  };

  const handlePostalCodeChange = (e) => {
    const postalCode = e.target.value;

    // Clear previous postal code error
    setPostalCodeError("");

    // Validate the postal code
    if (!isValidPostalCode(postalCode)) {
      setPostalCodeError(
        "Invalid postal code format. Please enter a valid postal code."
      );
      setSelectedState("");
    } else {
      // Fetch state information based on the valid postal code
      axios
        .get(`https://api.zippopotam.us/us/${postalCode}`)
        .then((response) => {
          const stateCode = response.data.places[0]?.state || "";
          setSelectedState(stateCode);
        })
        .catch((error) => {
          console.error("Error fetching state from postal code:", error);
          setSelectedState("");
          setPostalCodeError("This Postal Code doesn't exist");
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));
    dispatch(SAVE_BILLING_ADDRESS(billingAddress));
    navigate("/checkout");
  };

  return (
    <section>
      <div className={`container ${styles.checkout}`}>
        <h2>Checkout Details</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formSection}>
            <Card cardClass={styles.card}>
              <h3>Shipping Address</h3>
              <label>Recipient Name</label>
              <input
                type="text"
                placeholder="John Doe"
                required
                name="name"
                value={shippingAddress.name}
                onChange={(e) => handleShipping(e)}
              />
              {shippingErrors.name && (
                <span className={styles.error}>{shippingErrors.name}</span>
              )}
              <label>Address line 1</label>
              <input
                type="text"
                placeholder="123 Main St"
                required
                name="line1"
                value={shippingAddress.line1}
                onChange={(e) => handleShipping(e)}
              />
              <label>Address line 2</label>
              <input
                type="text"
                placeholder="Apt 45"
                name="line2"
                value={shippingAddress.line2}
                onChange={(e) => handleShipping(e)}
              />
              <label>City</label>
              <input
                type="text"
                placeholder="Anytown"
                required
                name="city"
                value={shippingAddress.city}
                onChange={(e) => handleShipping(e)}
              />
              <label>Postal code</label>
              <input
                type="text"
                placeholder="12345"
                required
                name="postal_code"
                value={shippingAddress.postal_code}
                onChange={(e) => {
                  // Limit input to max 5 characters
                  handleShipping({
                    target: {
                      name: "postal_code",
                      value: e.target.value.slice(0, 5),
                    },
                  });
                  handlePostalCodeChange(e);
                }}
                maxLength={5}
              />
              {postalCodeError && (
                <span className={styles.error}>{postalCodeError}</span>
              )}
              <label>State</label>
              <input
                type="text"
                placeholder="CA"
                required
                name="state"
                disabled
                value={selectedState}
                onChange={(e) => handleShipping(e)}
              />
              {/* <CountryDropdown
                className={styles.select}
                valueType="short"
                value={shippingAddress.country}
                onChange={(val) => handleCountryChange(val)}
              /> */}
              <label>Country</label>
              <input
                type="text"
                disabled
                name="country"
                value="United States"
                onChange={(e) => handleShipping(e)}
              />
              <label>Phone</label>
              <input
                type="text"
                placeholder="555-1234"
                required
                name="phone"
                value={shippingAddress.phone}
                onChange={(e) => handleShipping(e)}
              />
              {shippingErrors.phone && (
                <span className={styles.error}>{shippingErrors.phone}</span>
              )}
            </Card>
            <div className={styles.checkbox}>
              <label>
                <input
                  type="checkbox"
                  checked={copyAddress}
                  onChange={handleCopyShippingToBilling}
                />
                Copy Shipping to Billing
              </label>
            </div>
          </div>
          {!copyAddress && (
            <div className={styles.formSection}>
              <Card cardClass={styles.card}>
                <h3>Billing Address</h3>
                <label>Recipient Name</label>
                <input
                  type="text"
                  placeholder="John Doe"
                  required
                  name="name"
                  value={billingAddress.name}
                  onChange={(e) => handleBilling(e)}
                />
                <label>Address line 1</label>
                <input
                  type="text"
                  placeholder="123 Main St"
                  required
                  name="line1"
                  value={billingAddress.line1}
                  onChange={(e) => handleBilling(e)}
                />
                <label>Address line 2</label>
                <input
                  type="text"
                  placeholder="Apt 45"
                  name="line2"
                  value={billingAddress.line2}
                  onChange={(e) => handleBilling(e)}
                />
                <label>City</label>
                <input
                  type="text"
                  placeholder="Anytown"
                  required
                  name="city"
                  value={billingAddress.city}
                  onChange={(e) => handleBilling(e)}
                />
                <label>State</label>
                <RegionDropdown
                  className={styles.select}
                  country={billingAddress.country}
                  value={billingAddress.state}
                  onChange={(val) =>
                    setBillingAddress({ ...billingAddress, state: val })
                  }
                />
                <label>Postal code</label>
                <input
                  type="text"
                  placeholder="12345"
                  required
                  name="postal_code"
                  value={billingAddress.postal_code}
                  onChange={(e) => handleBilling(e)}
                />
                <CountryDropdown
                  className={styles.select}
                  valueType="short"
                  value={billingAddress.country}
                  onChange={(val) =>
                    handleBilling({ target: { name: "country", value: val } })
                  }
                />
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="555-1234"
                  required
                  name="phone"
                  value={billingAddress.phone}
                  onChange={(e) => handleBilling(e)}
                />
                {billingErrors.phone && (
                  <span className={styles.error}>{billingErrors.phone}</span>
                )}
              </Card>
            </div>
          )}
          <div className={styles.formSection}>
            <Card cardClass={styles.card}>
              <CheckoutSummary />
              <div className={styles.btn}>
                <button type="submit" className="--btn --btn-primary">
                  Proceed To Checkout
                </button>
              </div>
            </Card>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CheckoutDetails;

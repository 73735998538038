import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetchCollection from "../../customHooks/useFetchCollection";
import {
  GET_PRICE_RANGE,
  selectProducts,
  STORE_PRODUCTS,
} from "../../redux/slice/productSlice";
import styles from "./SportsProducts.module.scss";
import ProductFilter from "../../components/product/productFilter/ProductFilter";
import ProductList from "../../components/product/productList/ProductList";
import spinnerImg from "../../assets/spinner.jpg";
import { FaCogs, FaWhatsapp , FaInstagram} from "react-icons/fa";
import {
  FILTER_BY_SEARCH,
  selectFilteredProducts,
  FILTER_BY_CATEGORY,
} from "../../redux/slice/filterSlice";
import Search from "../search/Search";
import { useParams } from "react-router-dom";

const SportProducts = () => {
  const { sportsname } = useParams(); // Access the sport parameter from the route
  const { data, isLoading } = useFetchCollection("products");
  const [showFilter, setShowFilter] = useState(false);
  const products = useSelector(selectProducts);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const whatsappLink = "https://wa.me/your-phonenumber";
  const instagramLink = "https://www.instagram.com/your-instagram-handle"; // Replace with your Instagram profile link

  const filterRef = useRef(null);
useEffect(() => {
  // Filter products based on the sport, only if the sport is not "all"
  const filteredProducts =
    sportsname !== "All"
      ? data.filter((product) => product.sport === sportsname)
      : data;

  dispatch(STORE_PRODUCTS({ products: filteredProducts }));
  dispatch(GET_PRICE_RANGE({ products: filteredProducts }));

  // Apply search filter
  dispatch(FILTER_BY_SEARCH({ products: filteredProducts, search }));
}, [dispatch, data, sportsname, search]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div id="products">
      <section>
        <div className={`container ${styles.product}`}>
          <aside
            ref={filterRef}
            className={
              showFilter
                ? `${styles.filter} ${styles.show}`
                : `${styles.filter}`
            }
          >
            {isLoading ? null : <ProductFilter />}
          </aside>
          <div className={styles.content}>
            <div></div>
            {isLoading ? (
              <img
                src={spinnerImg}
                alt="Loading.."
                style={{ width: "50px" }}
                className="--center-all"
              />
            ) : // Conditionally render ProductList based on sportsname
            sportsname !== "All" ? (
              <ProductList products={products} />
            ) : (
              <ProductList products={data} />
            )}
            <div className={styles.icon} onClick={toggleFilter}>
              <FaCogs size={20} color="orangered" />
              <p>
                <b>{showFilter ? "Filters" : "Filters"}</b>
              </p>
            </div>
            <a href={whatsappLink} className={styles.whatsappButton}>
              <FaWhatsapp size={30} color="#fff" />
            </a>
            <a href={instagramLink} className={styles.instagramButton}>
              <FaInstagram size={30} color="#fff" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SportProducts;

import React from "react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import styles from "./SocialIcons.module.scss";

export default function SocialIcons() {
  const whatsappLink = "https://wa.me/19736877960";
  const instagramLink =
    "https://www.instagram.com/ultimate_sport_store/?utm_source=ig_web_button_share_sheet"; // Replace with your In

  return (
    <div className={styles.social}>
      <a href={whatsappLink} className={styles.whatsappButton}>
        <FaWhatsapp size={30} color="#fff" />
      </a>
      <a href={instagramLink} className={styles.instagramButton}>
        <FaInstagram size={30} color="#fff" />
      </a>
    </div>
  );
}

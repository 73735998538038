import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_TO_CART,
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  CLEAR_CART,
  DECREASE_CART,
  UPDATE_CART_ITEMS,
  REMOVE_FROM_CART,
  SAVE_URL,
  selectCartItems,
  selectCartTotalAmount,
  selectCartTotalQuantity,
  SET_CART_ITEMS,
} from "../../redux/slice/cartSlice";
import { selectIsLoggedIn } from "../../redux/slice/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import Card from "../../components/card/Card";
import LineSeparator from "../../components/lineSeparator/LineSeparator";
import styles from "./Cart.module.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../firebase/config";
import { doc, getDoc, onSnapshot, collection } from "firebase/firestore";

const Cart = () => {
  const cartItems = useSelector(selectCartItems);
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  const [productPrices, setProductPrices] = useState({});
  const [knockingServicePrices, setKnockingServicePrices] = useState({});

  useEffect(() => {
    const productUnsubscribe = onSnapshot(collection(db, "products"), (snapshot) => {
      const updatedPrices = {};
      snapshot.forEach((doc) => {
        updatedPrices[doc.id] = doc.data().price;
      });
      setProductPrices(updatedPrices);
    });

    const knockingServiceUnsubscribe = onSnapshot(doc(db, "productcustomizations", "OY7inZspq51Tn2tTMRf2"), (docSnapshot) => {
      if (docSnapshot.exists()) {
        const knockingPrices = docSnapshot.data().knockingPrices || {};
        setKnockingServicePrices(knockingPrices);
      }
    });

    return () => {
      productUnsubscribe();
      knockingServiceUnsubscribe();
    };
  }, []);

  const increaseCart = (cart) => {
    const { id, selectedSize } = cart;
    const existingCartItem = cartItems.find(
      (item) => item.id === id && item.selectedSize === selectedSize
    );

    if (existingCartItem) {
      const { sizeInfo } = existingCartItem;

      if (sizeInfo && sizeInfo.length > 0) {
        const selectedSizeInfo = sizeInfo.find(
          (info) => info.size === selectedSize
        );

        if (selectedSizeInfo && selectedSizeInfo.stockCount !== undefined) {
          const { stockCount } = selectedSizeInfo;
          const maxQuantity = stockCount - existingCartItem.cartQuantity;

          if (maxQuantity > 0) {
            if (maxQuantity >= cart.cartQuantity) {
              dispatch(ADD_TO_CART(cart));
            } else {
              dispatch(ADD_TO_CART({ ...cart, cartQuantity: maxQuantity }));
              toast.error(`Only ${maxQuantity} units available for this item`);
            }
          } else {
            toast.error(`No more stock available for this item`);
          }
        }
      }
    } else {
      dispatch(ADD_TO_CART(cart));
    }
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      return text.substring(0, n).concat("...");
    }
    return text;
  };

  const decreaseCart = (cart) => {
    dispatch(DECREASE_CART({ ...cart, selectedSize: cart.selectedSize }));
  };

  const removeFromCart = (cart) => {
    dispatch(REMOVE_FROM_CART(cart));
  };

  const clearCart = () => {
    dispatch(CLEAR_CART());
  };

  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL());
    dispatch(CALCULATE_TOTAL_QUANTITY());
    dispatch(SAVE_URL(""));
  }, [cartItems, dispatch]);

  const url = window.location.href;
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkout = async () => {
    const dummyRequestUrl = `${process.env.REACT_APP_BASE_URL}/dummy-call`;

    fetch(dummyRequestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dummy: "data" }),
    }).catch((error) => {
      console.error("Error making dummy call:", error);
    });

    if (isLoggedIn) {
      const unavailableProducts = [];
      const promises = cartItems.map(async (cart) => {
        const { id } = cart;
        const productDocRef = doc(db, "products", id);
        const productDocSnapshot = await getDoc(productDocRef);
        const productData = productDocSnapshot.data();
        if (productData && productData.enabled) {
          const { sizeInfo } = productData;
          if (sizeInfo && sizeInfo.length > 0) {
            const selectedSizeInfo = sizeInfo.find(
              (info) => info.size === cart.selectedSize
            );
            if (
              selectedSizeInfo &&
              selectedSizeInfo.stockCount >= cart.cartQuantity
            ) {
              return true;
            }
          }
        }
        unavailableProducts.push(productData.name);
        return false;
      });

      Promise.all(promises).then((results) => {
        const canProceedToCheckout = results.every((result) => result === true);
        if (canProceedToCheckout) {
          const currentScrollPos =
            window.scrollY || document.documentElement.scrollTop;
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate("/checkout");
        } else {
          const message = `The following products are not available to buy: ${unavailableProducts.join(
            ", "
          )}`;
          toast.error(message);
        }
      });
    } else {
      dispatch(SAVE_URL(url));
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/login");
    }
  };

  const isMobile = window.innerWidth <= 800;

  return (
    <section>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.leftContent}>
          <h2>Shopping Cart</h2>
          <>
            <p> {cartItems.length === 0 ? "Your cart is Empty" : ""}</p>
            <br />
            <div>
              <Link className={styles.linkstyle} to="/">
                &larr; Continue shopping
              </Link>
            </div>
            <br></br>
          </>
          {cartItems.length !== 0 && (
            <>
              {isMobile ? (
                <div className={styles.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Total</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((cart, index) => {
                        const {
                          id,
                          name,
                          sport,
                          category,
                          imageURLs,
                          cartQuantity,
                          selectedSize,
                          knockingService,
                          knockingServicePrice
                        } = cart;
                        return (
                          <tr key={id}>
                            <td className={styles.icons}>
                              <FaTrashAlt
                                size={19}
                                color="red"
                                onClick={() => removeFromCart(cart)}
                              />
                            </td>
                            <td>
                              <div className={styles.itemDetails}>
                                <Link to={`/product-details/${id}`} target="_blank">
                                  <img
                                    src={imageURLs[0]}
                                    alt={name}
                                    className={styles.prodImage}
                                  />
                                </Link>
                                <div className={styles.itemInfo}>
                                  <h1 className={styles.prodname}>
                                    {shortenText(name, 20)} - {selectedSize}
                                    <br></br><br></br>
                                    <h1 className={styles.prodname}>
                                      {sport === "Cricket" && category === "Bats" ? `${knockingService} (+$${knockingServicePrices[knockingService]})` : ""}
                                    </h1>
                                  </h1>
                                  <span className={styles.price}>
                                    <span className={styles.priceSym}>$</span>
                                    <span className={styles.priceData}>
                                      {Number(productPrices[id]) }
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className={styles.count}>
                                <button className="--btn" onClick={() => decreaseCart(cart)}>
                                  -
                                </button>
                                <p>{cartQuantity}</p>
                                <button className="--btn" onClick={() => increaseCart(cart)}>
                                  +
                                </button>
                              </div>
                            </td>
                            <td>
                              <span className={styles.price}>
                                <span className={styles.priceSym}>$</span>
                                <span className={styles.priceData}>
                                  {(Number(productPrices[id]) + Number(knockingServicePrices[knockingService]) * cartQuantity).toFixed(2)}
                                </span>
                              </span>
                              <p className={styles.tax}>+tax</p>
                            </td>
                           
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Customizations</th>
                        <th>Size</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((cart, index) => {
                        const {
                          id,
                          name,
                          sport,
                          category,
                          imageURLs,
                          cartQuantity,
                          selectedSize,
                          knockingService,
                          knockingServicePrice,
                        } = cart;
                        return (
                          <tr key={id}>
                            <td className={styles.icons}>
                              <FaTrashAlt
                                size={19}
                                color="red"
                                onClick={() => removeFromCart(cart)}
                              />
                            </td>
                            <td>
                              <div className={styles.itemDetails}>
                                <Link to={`/product-details/${id}`} target="_blank">
                                  <img
                                    src={imageURLs[0]}
                                    alt={name}
                                    className={styles.prodImage}
                                  />
                                </Link>
                                <div className={styles.itemInfo}>
                                  <h1 className={styles.prodname}>{name}{sport === "Cricket" && category === "Bats" ? <><br></br>{knockingService}</> : ""}</h1>
                                  <p style={{ fontSize: "13px" }}>Product # {id}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className={styles.price}>
                                <span className={styles.priceSym}>$</span>
                                <span className={styles.priceData}>
                                  {Number(productPrices[id]) }
                                </span>
                              </span>
                            </td>
                            <td>
                              {knockingService !== "No Knocking" ? (
                                <h4>
                                  {knockingService} (${knockingServicePrices[knockingService]})
                                </h4>
                              ) : (
                                <h4 >N/A</h4>
                              )}
                            </td>
                            <td>
                              <span className={styles.price}>
                                <span className={styles.priceSym}></span>
                                <span className={styles.size}>{selectedSize}</span>
                              </span>
                            </td>
                            <td>
                              <div className={styles.count}>
                                <button className="--btn" onClick={() => decreaseCart(cart)}>
                                  -
                                </button>
                                <p>{cartQuantity}</p>
                                {}
                                <button className="--btn" onClick={() => increaseCart(cart)}>
                                  +
                                </button>
                              </div>
                            </td>
                            <td>
                              <span className={styles.price}>
                                <span className={styles.priceSym}>$</span>
                                <span className={styles.priceData}>
                                  {(Number(productPrices[id]) + Number(knockingServicePrices[knockingService]) * cartQuantity).toFixed(2)}
                                </span>
                              </span>
                            </td>
                            
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className={styles.footer}>
                <button className="--btn --btn-danger" onClick={clearCart}>
                  Clear Cart
                </button>
              </div>
            </>
          )}
        </div>
        <LineSeparator />
        {cartItems.length !== 0 && (
          <div className={styles.rightContent}>
            <Card cardClass={styles.card}>
              <div className={styles.text}>
                <h3>{`Subtotal (${cartTotalQuantity} items):`}</h3>
                <div style={{ marginTop: "20px" }}>
                  <span className={styles.price}>
                    <span className={styles.priceSym1}>$</span>
                    <span className={styles.priceData1}>
                      {cartTotalAmount.toFixed(2)}
                    </span>
                  </span>
                </div>
              </div>
              <div className={styles.btn}>
                <button
                  onClick={() => {
                    checkout();
                    scrollToTop();
                  }}
                  className="--btn --btn-primary"
                >
                  Proceed to Buy
                </button>
              </div>
            </Card>
          </div>
        )}
      </div>
    </section>
  );
};

export default Cart;

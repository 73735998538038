// CheckoutForm.js

import React, { useEffect, useState } from "react";
import Loader from "../../loader/Loader";

import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import styles from "./CheckoutForm.module.scss";
import Card from "../../card/Card";
import spinnerImg from "../../../assets/spinner.jpg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectUserID } from "../../../redux/slice/authSlice";
import qr from "../../../assets/qrcode.png";
import zelle from "../../../assets/zelle.jpeg";

import {
  CLEAR_CART,
  selectCartItems,
  selectCartTotalAmount,
  selectCartTotalAmountWithTaxes,
} from "../../../redux/slice/cartSlice";
import {
  selectShippingAddress,
  selectBillingAddress,
} from "../../../redux/slice/checkoutSlice";
import {
  addDoc,
  collection,
  Timestamp,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/config";
import { useNavigate } from "react-router-dom";
import CheckoutSummaryDiscount from "../checkoutSummaryDiscount/CheckoutSummaryDiscount";
import { IoIosClose } from "react-icons/io";
import {
  orderPlacedAdminMail,
  orderPlacedCustomerMail,
} from "../../../emailjs/EmailTemplates";
import CardPay from "./cardPay/CardPay";
import { loadStripe } from "@stripe/stripe-js";
const CheckoutForm = ({ description, couponCode, selectedState }) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const elements = useElements();
  var finalAmount = parseFloat(localStorage.getItem("discountedAmount2")) / 100;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userID = useSelector(selectUserID);
  const userEmail = useSelector(selectEmail);
  const cartItems = useSelector(selectCartItems);
  const cartTotalAmountWithTaxes = useSelector(selectCartTotalAmountWithTaxes);
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const shippingAddress = useSelector(selectShippingAddress);
  const billingAddress = useSelector(selectBillingAddress);
  const [showPayWithCard, setShowPayWithCard] = useState(false);
  const [showPayWithZelleVenmo, setShowPayWithZelleVenmo] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showPayment, setShowPayment] = useState("Venmo");
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);



 

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);

    // Toggle the 'no-scroll' class on the body element
    document.body.style.overflow = showOverlay ? "auto" : "hidden";
  };
  useEffect(() => {
    // Set the overflow property when the component mounts
    document.body.style.overflow = showOverlay ? "hidden" : "auto";

    // Cleanup function to set the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showOverlay]);
  const handlePayWithCardClick = async (e) => {
    setIsLoading(true);
    await createPaymentIntent();
    // Close overlay when Pay with Card is clicked
    setShowPayWithCard(true);

    setShowPayWithZelleVenmo(false);
    setShowOverlay(false);
    setIsLoading(false);
  };
  const handlePayWithZelleVenmoClickArr = () => {
    setShowPayWithZelleVenmo(true);
    setShowPayWithCard(false);
    setShowOverlay(false); // Open overlay when Pay with Zelle/Venmo is clicked
  };

  const handlePayWithZelleVenmoClick = () => {
    setShowPayWithZelleVenmo(true);
    setShowPayWithCard(false);
    toggleOverlay(); // Open overlay when Pay with Zelle/Venmo is clicked
  };

  const closePopup = () => {
    setShowOverlay(false);
    setShowPayWithCard(false);
  };
  const closeOverlay = () => {
    // Close the overlay only if the X icon is clicked
    setShowOverlay(false);
    setShowPayWithCard(false);
    // Set overflow to 'auto' on the body element
    document.body.style.overflow = "auto";
  };
  const handleshowPaymentVenmo = () => {
    setShowPayment("Venmo");
  };
  const handleshowPaymentZelle = () => {
    setShowPayment("Zelle");
  };

  
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

  const saveOrderVenmo = async () => {
    setShowLoader(true);
    await addUsertoCoupon();
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();

    try {
      // Iterate through each item in the cart to update the stock
      for (const cartItem of cartItems) {
        const productID = cartItem.id;
        const productDocRef = doc(db, "products", productID);
        const productDoc = await getDoc(productDocRef);
        const productData = productDoc.data();

        // Find the selected size for the current item
        const selectedSize = cartItem.selectedSize;

        // Update the stock based on the purchased quantity for the selected size
        const purchasedQuantity = cartItem.cartQuantity;
        const updatedStock = productData.sizeInfo.map((size) =>
          size.size === selectedSize
            ? { ...size, stockCount: size.stockCount - purchasedQuantity }
            : size
        );

        // Save the updated product details back to Firebase
        const updatedProductData = {
          ...productData,
          sizeInfo: updatedStock,
        };

        await setDoc(productDocRef, updatedProductData);
      }

      // Continue with saving the order
      const orderConfig = {
        userID,
        userEmail,
        orderDate: date,
        orderTime: time,
        orderAmount: finalAmount
        ? finalAmount
        : (shippingAddress.state === "New Jersey"
        ? cartTotalAmountWithTaxes
        : cartTotalAmount),
        orderStatus: "Payment Verification Pending",
        cartItems: cartItems.map((item) => ({
          ...item,
          selectedSize: item.selectedSize, // Store selected size for each item
        })),
        shippingAddress,
        createdAt: Timestamp.now().toDate(),
        paymentMethod: "Venmo",
      };

      const orderRef = await addDoc(collection(db, "orders"), orderConfig);
      const formattedShippingAddress = `
Shipping Address:
Name: ${shippingAddress.name}
Line 1: ${shippingAddress.line1}
Line 2: ${shippingAddress.line2}
City: ${shippingAddress.city}
State: ${shippingAddress.state}
Postal Code: ${shippingAddress.postal_code}
Country: ${shippingAddress.country}
Phone: ${shippingAddress.phone}
`;
      const cartItemsString = cartItems
      
        .map(
          (item) =>
            `Product: ${item.name}\nSize: ${item.selectedSize}\nQuantity: ${item.cartQuantity} ${(item.sport==="Cricket" && item.category==="Bats")?`Knocking Service: ${item.knockingService}`:""}`
        )
        .join("\n\n");

      dispatch(CLEAR_CART());
      await orderPlacedAdminMail({
        order_id: orderRef._key.path.segments[1],
        order_amount:(Number(finalAmount
          ? finalAmount
          : (shippingAddress.state === "New Jersey"
          ? cartTotalAmountWithTaxes
          : cartTotalAmount))+((0/100)*Number(finalAmount
            ? finalAmount
            : (shippingAddress.state === "New Jersey"
            ? cartTotalAmountWithTaxes
            : cartTotalAmount)))).toFixed(2),
        payment_mode:orderConfig.paymentMethod,
        order_date: date,
        customer_name: shippingAddress.name,
        shipping_address: formattedShippingAddress,
        ordered_items: cartItemsString,
      });
      await orderPlacedCustomerMail({
        to_name: shippingAddress.name,
      
        payment_mode:orderConfig.paymentMethod,
        order_id: orderRef._key.path.segments[1],
        order_date: date,
        order_amount:(Number(finalAmount
          ? finalAmount
          : (shippingAddress.state === "New Jersey"
          ? cartTotalAmountWithTaxes
          : cartTotalAmount))+((0/100)*Number(finalAmount
            ? finalAmount
            : (shippingAddress.state === "New Jersey"
            ? cartTotalAmountWithTaxes
            : cartTotalAmount)))).toFixed(2),
        to_email: userEmail,
        order_address: formattedShippingAddress,
        ordered_items: cartItemsString,
      });
      setShowLoader(false);
      toast.success("Order saved");
      navigate("/checkout-success");
    } catch (error) {
      setShowLoader(false);
      toast.error(error.message);
      navigate("/cart");
    }
  };
  const addUsertoCoupon = async () => {
    try {
      const response = await fetch(
        // "http://127.0.0.1:4242/add-user-to-coupon",
        `${process.env.REACT_APP_BASE_URL}/add-user-to-coupon`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ couponCode: couponCode, userId: userID }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const data = await response.json();
      console.log("User added to coupon successfully:", data);
    } catch (error) {
      console.error("Error adding user to coupon:", error.message);
      // Handle the error as needed (e.g., show a message to the user)
    }
  };

  const saveOrderZelle = async () => {
    setShowLoader(true);

    await addUsertoCoupon();
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();

    try {
      // Iterate through each item in the cart to update the stock
      for (const cartItem of cartItems) {
        const productID = cartItem.id;
        const productDocRef = doc(db, "products", productID);
        const productDoc = await getDoc(productDocRef);
        const productData = productDoc.data();

        // Find the selected size for the current item
        const selectedSize = cartItem.selectedSize;

        // Update the stock based on the purchased quantity for the selected size
        const purchasedQuantity = cartItem.cartQuantity;
        const updatedStock = productData.sizeInfo.map((size) =>
          size.size === selectedSize
            ? { ...size, stockCount: size.stockCount - purchasedQuantity }
            : size
        );

        // Save the updated product details back to Firebase
        const updatedProductData = {
          ...productData,
          sizeInfo: updatedStock,
        };

        await setDoc(productDocRef, updatedProductData);
      }

      // Continue with saving the order
      const orderConfig = {
        userID,
        userEmail,
        orderDate: date,
        orderTime: time,
        orderAmount: finalAmount
        ? finalAmount
        : (shippingAddress.state === "New Jersey"
        ? cartTotalAmountWithTaxes
        : cartTotalAmount),
        orderStatus: "Payment Verification Pending",
        cartItems: cartItems.map((item) => ({
          ...item,
          selectedSize: item.selectedSize, // Store selected size for each item
        })),
        shippingAddress,
        createdAt: Timestamp.now().toDate(),
        paymentMethod: "Zelle",
      };

      const orderRef = await addDoc(collection(db, "orders"), orderConfig);
      const formattedShippingAddress = `
Shipping Address:
Name: ${shippingAddress.name}
Line 1: ${shippingAddress.line1}
Line 2: ${shippingAddress.line2}
City: ${shippingAddress.city}
State: ${shippingAddress.state}
Postal Code: ${shippingAddress.postal_code}
Country: ${shippingAddress.country}
Phone: ${shippingAddress.phone}
`;
const cartItemsString = cartItems
      
.map(
  (item) =>
    `Product: ${item.name}\nSize: ${item.selectedSize}\nQuantity: ${item.cartQuantity} ${(item.sport==="Cricket" && item.category==="Bats")?`Knocking Service: ${item.knockingService}`:""}`
)
.join("\n\n");

      dispatch(CLEAR_CART());
      console.log("About to mail");
      await orderPlacedAdminMail({
        order_id: orderRef._key.path.segments[1],
        order_date: date,
        order_amount:(Number(finalAmount
          ? finalAmount
          : (shippingAddress.state === "New Jersey"
          ? cartTotalAmountWithTaxes
          : cartTotalAmount))+((0/100)*Number(finalAmount
            ? finalAmount
            : (shippingAddress.state === "New Jersey"
            ? cartTotalAmountWithTaxes
            : cartTotalAmount)))).toFixed(2),
        payment_mode:orderConfig.paymentMethod,
        customer_name: shippingAddress.name,
        shipping_address: formattedShippingAddress,
        ordered_items: cartItemsString,
      });
      await orderPlacedCustomerMail({
        to_name: shippingAddress.name,
        order_id: orderRef._key.path.segments[1],
        
        payment_mode:orderConfig.paymentMethod,
        order_date: date,
        order_amount: (Number(finalAmount
          ? finalAmount
          : (shippingAddress.state === "New Jersey"
          ? cartTotalAmountWithTaxes
          : cartTotalAmount))+((0/100)*Number(finalAmount
            ? finalAmount
            : (shippingAddress.state === "New Jersey"
            ? cartTotalAmountWithTaxes
            : cartTotalAmount)))).toFixed(2),
        to_email: userEmail,
        order_address: formattedShippingAddress,
        ordered_items: cartItemsString,
      });
      console.log("After Mail");
      setShowLoader(false);
      toast.success("Order saved");
      navigate("/checkout-success");
    } catch (error) {
      setShowLoader(false);
      toast.error(error.message);
      navigate("/cart");
    }
  };
  const totalAmountWithTaxes = useSelector(selectCartTotalAmountWithTaxes);
  const totalAmount = useSelector(selectCartTotalAmount)
  const [clientSecret, setClientSecret] = useState("");

  const createPaymentIntent = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create-payment-intent`,
        //  `http://127.0.0.1:4242/create-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            items: cartItems,
            userEmail: shippingAddress.mail,
            shipping: shippingAddress,
            billing: billingAddress,
            couponCode: couponCode,
            description: description,
            userId: userID,
            selectedState: shippingAddress.state
          }),
        }
      );
      console.log(response);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const data = await response.json();
      if(shippingAddress.state === "New Jersey"){
        if (data.discountedAmount !== totalAmountWithTaxes * 100) {
          localStorage.setItem("discountedAmount2", data.discountedAmount);
          
        }

      }else{
        if (data.discountedAmount !== totalAmount * 100) {
          localStorage.setItem("discountedAmount2", data.discountedAmount);
          
        }

      }
      
      setClientSecret(data.clientSecret);
    } catch (error) {
      setMessage("");
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    // if (!stripe || !elements) {
    //   return;
    // }
    // await fetch(
    //   "https://thebestapi.com/coupon-code",
    //   {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       couponCode: couponCode,
    //       userId: userID,
    //     }),
    //   }
    // );
    setIsLoading(true);

    // Check the selected payment method
    // if (showPayWithCard) {
    //   // If paying with a card, use the standard Stripe confirmation process
    //   const confirmPayment = await stripe.confirmPayment({
    //     elements,
    //     confirmParams: {
    //       return_url: "https://ultimatesportstore.com/checkout-success",
    //     },
    //     redirect: "if_required",
    //   });

    //   handleStripePaymentResult(confirmPayment);
    // } else
    if (showPayWithZelleVenmo) {
      // If paying with Zelle
      if (showPayment === "Zelle") {
        saveOrderZelle();
      }
      // If paying with Venmo
      else if (showPayment === "Venmo") {
        saveOrderVenmo();
      }
    }

    setIsLoading(false);
  };

  // const handleStripePaymentResult = (result) => {
  //   localStorage.removeItem("discountedAmount");

  //   if (result.error) {
  //     toast.error(result.error.message);
  //     setMessage(result.error.message);
  //     return;
  //   }

  //   if (result.paymentIntent) {
  //     if (result.paymentIntent.status === "succeeded") {
  //       setIsLoading(false);
  //       toast.success("Payment successful");
  //       saveOrder();
  //     }
  //   }
  // };

  return showLoader || isLoading ? (
    <Loader />
  ) : (
    <section>
      <div className={`container ${styles.checkout}`}>
        <h2>Checkout</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <Card cardClass={styles.card}>
              <CheckoutSummaryDiscount />
            </Card>
          </div>
          <div>
            <Card cardClass={`${styles.card} ${styles.pay}`}>
              <div className={styles.payWithCardToggle}>
                <h3>
                  Pay with Card: ${(
  Number(
    finalAmount
      ? finalAmount
      : shippingAddress.state === "New Jersey"
      ? cartTotalAmountWithTaxes
      : cartTotalAmount
  ) +
  (2.99 / 100) *
    Number(
      finalAmount
        ? finalAmount
        : selectedState === "New Jersey"
        ? cartTotalAmountWithTaxes
        : cartTotalAmount
    ) +
  0.30
).toFixed(2)}

                  <span
                    onClick={handlePayWithCardClick}
                    className={`${styles.arrow} arrow`}
                  >
                    &#9660;
                  </span>
                </h3>
                <p><b>{"("}2.99% + ¢30{")"}</b> CONVENIENCE FEE.
                </p>
                {showPayWithCard && (
                  // <>
                  //   <PaymentElement id={styles["payment-element"]} />
                  //   <button
                  //     disabled={isLoading || !stripe || !elements}
                  //     id="submit"
                  //     className={styles.button}
                  //   >
                  //     <span id="button-text">
                  //       {isLoading ? (
                  //         <img
                  //           src={spinnerImg}
                  //           alt="Loading..."
                  //           style={{ width: "20px" }}
                  //         />
                  //       ) : (
                  //         "Pay now"
                  //       )}
                  //     </span>
                  //   </button>
                  //   {message !== null && (
                  //     <div id={styles["payment-message"]}>{message}</div>
                  //   )}
                  // </>
                  <Elements options={{ clientSecret }} stripe={stripePromise}>
                    <CardPay
                      clientSecret2={clientSecret}
                    />
                  </Elements>
                )}
              </div>
              <div className={styles.payWithCardToggle}>
                <h3>
                  Pay with Zelle/Venmo: ${Number(finalAmount?finalAmount:(shippingAddress.state==="New Jersey"?cartTotalAmountWithTaxes:cartTotalAmount)).toFixed(2)}
                  <span
                    onClick={() => {
                      handlePayWithZelleVenmoClickArr();
                    }}
                    className={`${styles.arrow} arrow`}
                  >
                    &#9660;
                  </span>
                </h3>
                <p><b>NO</b> CONVENIENCE FEE.
                </p>
                {showPayWithZelleVenmo && (
                  <div className={styles.zelleVenmoButtons}>
                    <div
                      className={styles.button}
                      onClick={() => {
                        handlePayWithZelleVenmoClick();
                        setShowPayment("Zelle");
                        console.log(showPayment);
                      }}
                    >
                      <img
                        src="https://download.logo.wine/logo/Zelle_(payment_service)/Zelle_(payment_service)-Logo.wine.png"
                        alt="Zelle"
                        className={styles.buttonIcon}
                      />
                    </div>
                    <div
                      className={styles.button}
                      onClick={() => {
                        handlePayWithZelleVenmoClick();
                        setShowPayment("Venmo");

                        console.log(showPayment);
                      }}
                    >
                      <img
                        src="https://logos-world.net/wp-content/uploads/2021/12/Venmo-Logo.png"
                        alt="Venmo"
                        className={styles.buttonIcon}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </form>
        {showOverlay && (
          <div className={styles.overlay}>
            <span className={styles.closeButton} onClick={closeOverlay}>
              {" "}
              <IoIosClose />
            </span>
            <div>
              {showPayment === "Zelle" ? (
                <div className={styles.qrCodeContainer}>
                  <div className={styles.qrCodeBox}>
                    <div className={styles.qrCodeSquareFrame}>
                      <div className={styles.centered}>
                        <div className={styles.qrcode}>
                          <img src={zelle} style={{ width: 260 }} />
                        </div>
                      </div>
                      <div className={styles.text}>
                        <br></br>
                        <p className={styles.one}>Scan to pay using Zelle</p>
                        <p className={styles.two}>
                          You can also pay to <b>ddroyal0429@gmail.com</b>
                          <br />
                          <br />
                        </p>
                      </div>
                      <div className={styles.button} onClick={saveOrderZelle}>
                        Complete Order
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.qrCodeContainer}>
                  <div className={styles.qrCodeBox}>
                    <div className={styles.qrCodeSquareFrame}>
                      <div className={styles.centered}>
                        <div className={styles.qrcode}>
                          <img src={qr} />
                        </div>
                      </div>
                      <div className={styles.text}>
                        <p className={styles.one}>Scan to pay using Venmo</p>
                        <p className={styles.two}>
                          You can also pay through <b>@ddroyal</b>
                          <br />
                          <br />
                        </p>
                      </div>
                      <div className={styles.button} onClick={saveOrderVenmo}>
                        Complete Order
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showPayWithCard && (
          <div className={showOverlay ? styles.popup : ""}>
            {/* Your Pay with Card content goes here */}
          </div>
        )}
        {showPayWithZelleVenmo && (
          <div className={showOverlay ? styles.popup : ""}>
            {/* Your Pay with Zelle/Venmo content goes here */}
          </div>
        )}
      </div>
    </section>
  );
};

export default CheckoutForm;

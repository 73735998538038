import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectUserName } from "../../../redux/slice/authSlice";
import styles from "./Navbar.module.scss";
import { FaUserCircle } from "react-icons/fa";

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const Navbar = () => {
  const userName = useSelector(selectUserName);

  return (
    <div className={styles.navbar}>
      <div className={styles.user}>
        <FaUserCircle size={40} color="#fff" />
        <h4>{userName}</h4>
      </div>
      <nav>
        <ul>
          <li>
            <NavLink to="/admin/home" className={activeLink}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/all-products" className={activeLink}>
              All Products
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/add-product/" className={activeLink}>
              Add Product
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/orders" className={activeLink}>
              Orders
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/reviews" className={activeLink}>
              Reviews
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/create-coupon-code" className={activeLink}>
              Create Coupon Code
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/manage-coupon-code" className={activeLink}>
              Manage Coupon Codes
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/manage-tagline" className={activeLink}>
              Manage Tagline
            </NavLink>
          </li>{" "}
          <li>
            <NavLink to="/admin/manage-curated-jerseys" className={activeLink}>
              Manage Curated Jerseys
            </NavLink>
          </li>
          <li>
            <NavLink to="manage-featured-categories" className={activeLink}>
              Manage Featured Categories
            </NavLink>
          </li>
          <li>
            <NavLink to="manage-jersey" className={activeLink}>
              Manage Jersey Previews
            </NavLink>
          </li>
          <li>
            <NavLink to="manage-video-reviews" className={activeLink}>
              Manage Video Reviews
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/stock-requests" className={activeLink}>
              Stock Requests
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/sold-out" className={activeLink}>
              Sold Out
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/returns" className={activeLink}>
              Returns
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/admin-teamkit" className={activeLink}>
              Team Kit Form
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;

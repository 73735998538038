import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Card from "../../components/card/Card";
import { toast } from "react-toastify";
import Modal from "react-modal";
import {
  CALCULATE_SUBTOTAL,
  CALCULATE_WISHLIST_QUANTITY,
  REMOVE_FROM_WISHLIST,
  SAVE_URL,
  selectWishlistItems,
} from "../../redux/slice/wishlistSlice";
import {
  ADD_TO_CART,
  CALCULATE_TOTAL_QUANTITY,
} from "../../redux/slice/cartSlice";
import styles from "./Wishlist.module.scss";

const Wishlist = () => {
  const wishlistItems = useSelector(selectWishlistItems);
  const dispatch = useDispatch();

  const [selectedSize, setSelectedSize] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSoldOutColor, setHas] = useState(false);

  const addToCartFromWishlist = (product) => {
    if (!product.enabled) {
      // Product is not enabled
      toast.error("Product is not enabled");
      return;
    }
    if (product && product.sizeInfo.every((color) => color.stockCount === 0)) {
      toast.error("Product has sold out")
      return
    }
    if (product.sizeInfo && product.sizeInfo.length > 0) {
      setSelectedProduct(product);
      setSelectedSize(product.sizeInfo[0].size); // Set selected size to the first available size
      setIsModalOpen(true);
    } else {
      // Product has no size options, add directly to the cart
      dispatch(ADD_TO_CART(product));
      dispatch(REMOVE_FROM_WISHLIST(product));
    }
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };
  const removeFromWishlist = (product) => {
    dispatch(REMOVE_FROM_WISHLIST(product));
  };
  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL());
    dispatch(CALCULATE_WISHLIST_QUANTITY());
    dispatch(SAVE_URL(""));
  }, [wishlistItems, dispatch]);
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedSize("");
    setSelectedProduct(null);
  };

  const handleAddToCart = () => {
    if (selectedProduct) {
      const selectedSizeInfo = selectedProduct.sizeInfo.find(
        (info) => info.size === selectedSize
      );

      if (selectedSizeInfo && selectedSizeInfo.stockCount > 0) {
        const productToAdd = { ...selectedProduct, selectedSize };
        dispatch(ADD_TO_CART(productToAdd));
        dispatch(CALCULATE_TOTAL_QUANTITY());
        dispatch(REMOVE_FROM_WISHLIST(selectedProduct));
        setIsModalOpen(false);
        setSelectedSize("");
        setSelectedProduct(null);
      } else {
        // No size with stock is selected
        // You can show a notification or handle it in a way that suits your UI/UX
        toast.error("No size with stock is selected");
      }
    }
  };

  useEffect(() => {
    if (
      selectedProduct &&
      selectedProduct.sizeInfo &&
      selectedProduct.sizeInfo.length === 1
    ) {
      setSelectedSize(selectedProduct.sizeInfo[0].size);
    }
  }, [selectedProduct]);

  return (
    <section>
      <div className={`container ${styles.table}`}>
        <div className={styles.heading}>
          <h2>Your Wishlist</h2>
          <h2 style={{ display: "none" }}>
            Welcome to UltimateCricketStore, your premier destination for all
            your cricket needs in USA!: 🏏 Specialized Bats,👟 Top-notch
            Shoes,🛡️Accessories
          </h2>
          <h2 style={{ display: "none" }}>
            Welcome to Ultimate Sport Store, your premier destination for
            top-notch cricket gear! At Ultimate Sport Store, we understand that
            cricket is more than a sport - it's a passion, a way of life.
            Whether you're a seasoned professional or just starting, our
            carefully curated selection ensures you have access to the best
            equipment from renowned brands like MB Malik, MBS, SG, SS, and
            Gray-Nicolls. What sets us apart? Not only do we offer an
            unparalleled selection of cricket bats, balls, gear, and apparel,
            but we also provide free shipping across the USA. Yes, you heard it
            right - enjoy the convenience of doorstep delivery without any extra
            cost. Our team is here to assist you in making the right choices.
            With expert advice tailored to your needs, finding the perfect
            cricket gear has never been easier. We believe in making quality
            cricket equipment accessible to all, offering competitive prices and
            special promotions. At Ultimate Sport Store, we share your
            enthusiasm for the game. Join us on this exciting journey, explore
            our online store, and experience cricket excellence like never
            before. Thank you for choosing Ultimate Sport Store - where your
            cricket journey begins!
          </h2>
          <li className={styles.nav__item} style={{ display: "none" }}>
            <h2 className={styles.nav__title}> Contact Information</h2>
            <ul>
              <li>Cricket bats Newark</li>
              <li>Cricket balls USA</li>
              <li>Free shipping cricket gear</li>
              <li>Cricket gear Newark</li>
              <li>Cricket accessories New Jersey</li>
              <li>Cricket equipment free shipping</li>
              <li>Cricket clothing Newark</li>
              <li>Cricket shoes USA</li>
              <li>Cricket gloves Newark</li>
              <li>Cricket pads free shipping</li>
              <li>Cricket helmets Newark</li>
              <li>Cricket bags USA</li>
              <li>Cricket jerseys Newark</li>
              <li>Cricket trousers free shipping</li>
              <li>Cricket caps Newark</li>
              <li>Cricket sunglasses USA</li>
              <li>Cricket protection Newark</li>
              <li>Free shipping cricket training equipment</li>
              <li>Cricket nets Newark</li>
              <li>Cricket stumps USA</li>
              <li>Cricket wickets Newark</li>
              <li>Cricket umpire equipment free shipping</li>
              <li>Custom cricket gear Newark</li>
              <li>Junior cricket equipment USA</li>
              <li>Senior cricket equipment Newark</li>
              <li>Professional cricket gear free shipping</li>
              <li>Discount cricket equipment Newark</li>
              <li>Online cricket store New Jersey</li>
              <li>Cricket shop USA</li>
              <li>Best cricket gear Newark</li>
              <li>Top-rated cricket equipment free shipping</li>
              <li>Cricket gift ideas Newark</li>
              <li>Cricket memorabilia USA</li>
              <li>International cricket gear Newark</li>
              <li>Affordable cricket gear free shipping</li>
              <li>Premium cricket gear Newark</li>
              <li>Cricket training aids USA</li>
              <li>Cricket coaching equipment Newark</li>
              <li>Cricket fielding equipment free shipping</li>
              <li>Cricket coaching accessories Newark</li>
              <li>Cricket fitness gear USA</li>
              <li>Cricket technology Newark</li>
              <li>Cricket scorebooks free shipping</li>
              <li>Cricket team uniforms Newark</li>
              <li>Cricket match clothing USA</li>
              <li>Cricket tournament gear Newark</li>
              <li>Cricket fan merchandise free shipping</li>
              <li>Cricket gifts Newark</li>
              <li>Cricket presents USA</li>
              <li>Cricket souvenirs Newark</li>
              <li>Official cricket merchandise free shipping</li>
              <li>Youth cricket gear Newark</li>
              <li>Professional cricket bats USA</li>
              <li>Customized cricket bats Newark</li>
              <li>Cricket ball packs free shipping</li>
              <li>Cricket gear sets Newark</li>
              <li>Cricket training gear USA</li>
              <li>Cricket protective gear Newark</li>
              <li>Cricket ground equipment free shipping</li>
              <li>Cricket pitch equipment Newark</li>
              <li>Cricket field maintenance USA</li>
              <li>Cricket tournament uniforms Newark</li>
              <li>Cricket event merchandise free shipping</li>
              <li>Cricket coaching resources Newark</li>
              <li>Cricket technique tools USA</li>
              <li>Cricket strategy aids Newark</li>
              <li>Cricket match essentials free shipping</li>
              <li>Cricket gear for beginners Newark</li>
              <li>Cricket gear for professionals USA</li>
              <li>Cricket gear for kids Newark</li>
              <li>Cricket gear for women free shipping</li>
              <li>Cricket gear for men Newark</li>
              <li>Cricket gear for all ages USA</li>
              <li>Cricket gear for all skill levels Newark</li>
              <li>Cricket gear for all budgets free shipping</li>
              <li>Cricket equipment reviews Newark</li>
              <li>Latest cricket gear USA</li>
              <li>Cricket gear trends Newark</li>
              <li>Cricket gear innovations free shipping</li>
              <li>Cricket gear sale Newark</li>
              <li>Cricket gear clearance USA</li>
              <li>Cricket gear discounts Newark</li>
              <li>Cricket gear promotions free shipping</li>
              <li>Cricket gear bundles Newark</li>
              <li>Cricket gear packages USA</li>
              <li>Cricket gear combo deals Newark</li>
              <li>Cricket gear value packs free shipping</li>
              <li>Cricket gear bulk discounts Newark</li>
              <li>Cricket Store New Jersey</li>
              <li>Cricket Store Newark</li>
              <li>Cricket Store NJ</li>
              <li>Cricket Store USA</li>
              <li>Top Quality Cricket Store USA</li>
              <li>Best Cricket Store USA</li>
              <li>Original Cricket Brands</li>
              <li>Genuine Cricket Brands</li>
              <li>Free Shipping for all cricket products across USA</li>
              <li>Best Price</li>
              <li>Low Price Cricket Bats</li>
              <li>Quality Cricket Bats</li>
              <li>Affordable Cricket Bats</li>
              <li>Cheap Cricket Bats</li>
              <li>Latest Cricket Bats</li>
              <li>Cricket Bats buy online</li>
              <li>Best in class Cricket Bats</li>
              <li>Top Performant Cricket Bats</li>
              <li>Long Handle Cricket Bats</li>
              <li>Short Handle Cricket Bats</li>
              <li>Lightweight Cricket Bats</li>
              <li>Lightweight Cricket Pads</li>
              <li>Power Hitting Cricket Bats</li>
              <li>Cricket bats</li>
              <li>Cricket balls</li>
              <li>Cricket gear</li>
              <li>Cricket accessories</li>
              <li>Cricket equipment</li>
              <li>Cricket clothing</li>
              <li>Cricket shoes</li>
              <li>Cricket gloves</li>
              <li>Cricket pads</li>
              <li>Cricket helmets</li>
              <li>Cricket bags</li>
              <li>Cricket jerseys</li>
              <li>Cricket trousers</li>
              <li>Cricket caps</li>
              <li>Cricket sunglasses</li>
              <li>Cricket protection</li>
              <li>Cricket training equipment</li>
              <li>Cricket nets</li>
              <li>Cricket stumps</li>
              <li>Cricket wickets</li>
              <li>Cricket umpire equipment</li>
              <li>Custom cricket gear</li>
              <li>Junior cricket equipment</li>
              <li>Senior cricket equipment</li>
              <li>Professional cricket gear</li>
              <li>Discount cricket equipment</li>
              <li>Online cricket store</li>
              <li>Cricket shop</li>
              <li>Best cricket gear</li>
              <li>Top-rated cricket equipment</li>
              <li>Cricket gift ideas</li>
              <li>Cricket memorabilia</li>
              <li>International cricket gear</li>
              <li>Affordable cricket gear</li>
              <li>Premium cricket gear</li>
              <li>Cricket training aids</li>
              <li>Cricket coaching equipment</li>
              <li>Cricket fielding equipment</li>
              <li>Cricket coaching accessories</li>
              <li>Cricket fitness gear</li>
              <li>Cricket technology</li>
              <li>Cricket scorebooks</li>
              <li>Cricket team uniforms</li>
              <li>Cricket match clothing</li>
              <li>Cricket tournament gear</li>
              <li>Cricket fan merchandise</li>
              <li>Cricket gifts</li>
              <li>Cricket presents</li>
              <li>Cricket souvenirs</li>
              <li>Official cricket merchandise</li>
              <li>Youth cricket gear</li>
              <li>Professional cricket bats</li>
              <li>Customized cricket bats</li>
              <li>Cricket ball packs</li>
              <li>Cricket gear sets</li>
              <li>Cricket training gear</li>
              <li>Cricket protective gear</li>
              <li>Cricket ground equipment</li>
              <li>Cricket pitch equipment</li>
              <li>Cricket field maintenance</li>
              <li>Cricket tournament uniforms</li>
              <li>Cricket event merchandise</li>
              <li>Cricket coaching resources</li>
              <li>Cricket technique tools</li>
              <li>Cricket strategy aids</li>
              <li>Cricket match essentials</li>
              <li>Cricket gear for beginners</li>
              <li>Cricket gear for professionals</li>
              <li>Cricket gear for kids</li>
              <li>Cricket gear for women</li>
              <li>Cricket gear for men</li>
              <li>Cricket gear for all ages</li>
              <li>Cricket gear for all skill levels</li>
              <li>Cricket gear for all budgets</li>
              <li>Cricket equipment reviews</li>
              <li>Latest cricket gear</li>
              <li>Cricket gear trends</li>
              <li>Cricket gear innovations</li>
              <li>Cricket gear sale</li>
              <li>Cricket gear clearance</li>
              <li>Cricket gear discounts</li>
              <li>Cricket gear promotions</li>
              <li>Cricket gear bundles</li>
              <li>Cricket gear packages</li>
              <li>Cricket gear combo deals</li>
              <li>Cricket gear value packs</li>
              <li>Cricket gear bulk discounts</li>
              <li>Online Cricket Store New Jersey</li>
              <li>Online Cricket Store Newark</li>
              <li>Online Cricket Store NJ</li>
              <li>Online Cricket Store USA</li>
              <li>Cricket bats MB Malik Newark</li>
              <li>Cricket balls MBS USA</li>
              <li>Free shipping SG cricket gear</li>
              <li>Cricket gear SS Newark</li>
              <li>Cricket accessories Gray-Nicolls New Jersey</li>
              <li>Cricket equipment MB Malik free shipping</li>
              <li>Cricket clothing MBS Newark</li>
              <li>Cricket shoes SG USA</li>
              <li>Cricket gloves SS Newark</li>
              <li>Cricket pads Gray-Nicolls free shipping</li>
              <li>Cricket helmets MB Malik Newark</li>
              <li>Cricket bags SS USA</li>
              <li>Cricket jerseys Gray-Nicolls Newark</li>
              <li>Cricket trousers free shipping</li>
              <li>Cricket caps MB Malik Newark</li>
              <li>Cricket sunglasses SG USA</li>
              <li>Cricket protection SS Newark</li>
              <li>Free shipping Gray-Nicolls cricket training equipment</li>
              <li>Cricket nets MB Malik Newark</li>
              <li>Cricket stumps SS USA</li>
              <li>Cricket wickets Gray-Nicolls Newark</li>
              <li>Cricket umpire equipment free shipping</li>
              <li>Custom MB Malik cricket gear Newark</li>
              <li>Junior cricket equipment SS USA</li>
              <li>Senior cricket equipment Gray-Nicolls Newark</li>
              <li>Professional SG cricket gear free shipping</li>
              <li>Discount cricket equipment MB Malik Newark</li>
              <li>Online cricket store New Jersey</li>
              <li>Cricket shop USA</li>
              <li>Best Gray-Nicolls cricket gear Newark</li>
              <li>Top-rated cricket equipment free shipping</li>
              <li>Cricket gift ideas MB Malik Newark</li>
              <li>Cricket memorabilia SS USA</li>
              <li>International cricket gear Gray-Nicolls Newark</li>
              <li>Affordable cricket gear free shipping</li>
              <li>Premium SG cricket gear Newark</li>
              <li>Cricket training aids USA</li>
              <li>Cricket coaching equipment Gray-Nicolls Newark</li>
              <li>Cricket fielding equipment free shipping</li>
              <li>Cricket coaching accessories MB Malik Newark</li>
              <li>Cricket fitness gear SS USA</li>
              <li>Cricket technology Gray-Nicolls Newark</li>
              <li>Cricket scorebooks free shipping</li>
              <li>Cricket team uniforms MB Malik Newark</li>
              <li>Cricket match clothing SS USA</li>
              <li>Cricket tournament gear Gray-Nicolls Newark</li>
              <li>Cricket fan merchandise free shipping</li>
              <li>Cricket gifts MB Malik Newark</li>
              <li>Cricket presents SS USA</li>
              <li>Cricket souvenirs Gray-Nicolls Newark</li>
              <li>Official cricket merchandise free shipping</li>
              <li>Youth cricket gear MB Malik Newark</li>
              <li>Professional cricket bats SG USA</li>
              <li>Customized cricket bats Gray-Nicolls Newark</li>
              <li>Cricket ball packs free shipping</li>
              <li>Cricket gear sets MB Malik Newark</li>
              <li>Cricket training gear SS USA</li>
              <li>Cricket protective gear Gray-Nicolls Newark</li>
              <li>Cricket ground equipment free shipping</li>
              <li>Cricket pitch equipment MB Malik Newark</li>
              <li>Cricket field maintenance SS USA</li>
              <li>Cricket tournament uniforms Gray-Nicolls Newark</li>
              <li>Cricket event merchandise free shipping</li>
              <li>Cricket coaching resources MB Malik Newark</li>
              <li>Cricket technique tools SS USA</li>
              <li>Cricket strategy aids Gray-Nicolls Newark</li>
              <li>Cricket match essentials free shipping</li>
              <li>Cricket gear for beginners MB Malik Newark</li>
              <li>Cricket gear for professionals SG USA</li>
              <li>Cricket gear for kids SS Newark</li>
              <li>Cricket gear for women free shipping</li>
              <li>Cricket gear for men Gray-Nicolls Newark</li>
              <li>Cricket gear for all ages USA</li>
              <li>Cricket gear for all skill levels Gray-Nicolls Newark</li>
              <li>Cricket gear for all budgets free shipping</li>
              <li>Cricket equipment reviews MB Malik Newark</li>
              <li>Latest SG cricket gear USA</li>
              <li>Cricket gear trends SS Newark</li>
              <li>Cricket gear innovations Gray-Nicolls free shipping</li>
              <li>Cricket gear sale MB Malik Newark</li>
              <li>Cricket gear clearance SS USA</li>
              <li>Cricket gear discounts Gray-Nicolls Newark</li>
              <li>Cricket gear promotions free shipping</li>
              <li>Cricket gear bundles MB Malik Newark</li>
              <li>Cricket gear packages SS USA</li>
              <li>Cricket gear</li>
            </ul>
          </li>
          <li className={styles.nav__item} style={{ display: "none" }}>
            <h2 className={styles.nav__title}>Our Products</h2>

            <ul className={styles.nav__ul} id="products">
              <li>CA Cricket Bats</li>
              <li>SS Cricket Bats</li>
              <li>SG Cricket Bats</li>
              <li>Graynicolls Cricket Bats</li>
              <li>Plain Cricket Bats</li>
              <li>MBS Cricket Bats</li>
              <li>MB Malik Cricket Bats</li>
              <li>Cricket Balls</li>
              <li>Cricket Gloves</li>
              <li>Cricket Kit Bags</li>
              <li>Cricket Bat Grips</li>
              <li>Cricket Pads</li>
              <li>Cricket Shoes</li>
              <li>CA Cricket Shoes</li>
              <li>CA JR20 Cricket Shoes</li>
              <li>Cricket Safety Gear</li>
              <li>Cricket Accessories</li>
              <li>Double Willow Cricket Bats</li>
              <li>English Willow Cricket Bats</li>
              <li>Cricket Helmets</li>

              <li>Genuine CA Cricket Bats</li>
              <li>Genuine SS Cricket Bats</li>
              <li>Genuine SG Cricket Bats</li>
              <li>Genuine Graynicolls Cricket Bats</li>
              <li>Genuine Plain Cricket Bats</li>
              <li>Genuine MBS Cricket Bats</li>
              <li>Genuine MB Malik Cricket Bats</li>
              <li>Genuine Cricket Balls</li>
              <li>Genuine Cricket Gloves</li>
              <li>Genuine Cricket Kit Bags</li>
              <li>Genuine Cricket Bat Grips</li>
              <li>Genuine Cricket Pads</li>
              <li>Genuine Cricket Shoes</li>
              <li>Genuine CA Cricket Shoes</li>
              <li>Genuine CA JR20 Cricket Shoes</li>
              <li>Genuine Cricket Safety Gear</li>
              <li>Genuine Cricket Accessories</li>
              <li>Genuine Double Willow Cricket Bats</li>
              <li>Genuine English Willow Cricket Bats</li>
              <li>Genuine Cricket Helmets</li>

              <li>Premium Double Willow Cricket Bats</li>
              <li>Premium English Willow Cricket Bats</li>
              <li>Premium Cricket Helmets</li>
              <li>Premium CA Cricket Bats</li>
              <li>Premium SS Cricket Bats</li>
              <li>Premium SG Cricket Bats</li>
              <li>Premium Graynicolls Cricket Bats</li>
              <li>Premium Plain Cricket Bats</li>
              <li>Premium MBS Cricket Bats</li>
              <li>Premium MB Malik Cricket Bats</li>
              <li>Premium Cricket Balls</li>
              <li>Premium Cricket Gloves</li>
              <li>Premium Cricket Kit Bags</li>
              <li>Premium Cricket Bat Grips</li>
              <li>Premium Cricket Pads</li>
              <li>Premium Cricket Shoes</li>
              <li>Premium CA Cricket Shoes</li>
              <li>Premium CA JR20 Cricket Shoes</li>
              <li>Premium Cricket Safety Gear</li>
              <li>Premium Cricket Accessories</li>
              <li>Premium Cricket Accessories</li>
            </ul>
          </li>
        </div>
        {wishlistItems.length === 0 ? (
          <div className={styles.empty}>
            <p>Your wishlist is currently empty.</p>
            <div>
              <Link className={styles.linkstyle} to="/">
                &larr; Continue shopping
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <br />
            <div className={styles.wishlistGrid}>
              {wishlistItems.map((product, index) => (
                <div key={index}>
                  <Card cardClass={styles.productInfo2}>
                    <div className={styles.productInfo}>
                      <FaTrashAlt
                        size={19}
                        color="red"
                        style={{ cursor: "pointer", marginRight: "15px" }}
                        onClick={() => removeFromWishlist(product)}
                      />
                      <div className={styles.temp}>
                        <Link
                          to={`/product-details/${product.id}`}
                          target="_blank"
                        >
                          <img
                            src={product.imageURLs[0]}
                            alt={product.name}
                            className={styles.productImage}
                          />
                        </Link>
                      </div>
                      <div className={styles.details}>
                        <h1 className={styles.prodname}>
                          {shortenText(product.name, 20)}
                        </h1>
                        <span className={styles.price}>
                          <span className={styles.priceSym}>$</span>
                          <span
                            className={styles.priceData}
                          >{`${product.price}`}</span>
                        </span>
                        <p className={styles.tax}>+tax</p>
                      </div>
                      <div className={styles.actions}>
                        <button
                          className={`--btn --btn-primary ${
                            !product.enabled ? styles.disabled : ""
                          }`}
                          onClick={() => {
                            if (product.enabled) {
                              addToCartFromWishlist(product);
                            } else {
                              // Log a message when the button is clicked and the product is not enabled
                              toast.error("Product is not enabled");
                            }
                          }}
                          disabled={!product.enabled}
                        >
                          {product.enabled
                            ? "Move to Cart"
                            : "Product Unavailable"}
                          {console.log(product)}
                        </button>
                      </div>
                    </div>
                  </Card>
                  <div style={{ height: "20px" }}></div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Select Size Modal"
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        shouldCloseOnOverlayClick={true}
      >
        <h2>Select Size</h2>
        <select
          value={selectedSize}
          onChange={(e) => setSelectedSize(e.target.value)}
          className={styles.select}
        >
          {selectedProduct &&
            selectedProduct.sizeInfo.map((sizeInfo) => (
              // Disable the option if stock count is 0
              <option
                key={sizeInfo.size}
                value={sizeInfo.size}
                disabled={sizeInfo.stockCount === 0}
              >
                {sizeInfo.size}
              </option>
            ))}
        </select>
        <div className={styles.modalActions}>
          <button onClick={handleAddToCart} className={styles.addToCartBtn}>
            Add to Cart
          </button>
          <button onClick={handleModalClose} className={styles.cancelBtn}>
            Cancel
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default Wishlist;

import { addDoc, collection, getDocs, Timestamp, updateDoc, doc, getDoc,query,where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase/config";
import Card from "../../card/Card";
import Loader from "../../loader/Loader";
import styles from "./EditProduct.module.scss";
import { selectProducts } from "../../../redux/slice/productSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth";



const initialState = {
  name: "",
  imageInputs: Array(5).fill(""),
  imageURLs: [],
  price: 0,
  tax: 0,
  category: "",
  subCategory: "", // Changed to subCategory
  brand: "",
  desc: "",
  videoURL: "",
  sizeInfo: [],
  sport: "",
  colour: "",
  minimumOrderQuantity: 1, // Added minimumOrderQuantity
};

const EditProduct = () => {
  const { id } = useParams();
  const [otherSportValue, setOtherSportValue] = useState("");
  const [otherCategoryValue, setOtherCategoryValue] = useState("");
  const [otherSubcategoryValue, setOtherSubcategoryValue] = useState("");
  const products = useSelector(selectProducts);
  const [product, setProduct] = useState(initialState);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [sports, setSports] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const navigate = useNavigate();
  const [currentUserID, setCurrentUserID] = useState(null);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentUserID(user.uid);
    } else {
      setCurrentUserID(null);
    }
  });

  useEffect(() => {
    const selectedSportData = sports.find((sport) => sport.name === selectedSport);
    console.log("Selected Sport Data:", selectedSportData);
    if (selectedSportData) {
      setCategories(selectedSportData.categories || []);
      if (selectedSportData.categories && selectedSportData.categories.length > 0) {
        const selectedCategoryData = selectedSportData.categories.find((category) => category.name === selectedCategory);
        console.log("Selected Category Data:", selectedCategoryData);
        setSubcategories(selectedCategoryData ? selectedCategoryData.subcategories || [] : []);
      }
    }
  }, [selectedSport, selectedCategory, sports]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sportsSnapshot = await getDocs(collection(db, "sports"));
        const sportsData = sportsSnapshot.docs.map((doc) => doc.data());
        setSports(sportsData);
        setIsLoading(false);

        
      } catch (error) {
        console.error("Error fetching sports:", error);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    const selectedSportData = sports.find((sport) => sport.name === selectedSport);
    if (selectedSportData) {
      setCategories(selectedSportData.categories || []);
      if (selectedSportData.categories && selectedSportData.categories.length > 0) {
        const selectedCategoryData = selectedSportData.categories.find((category) => category.name === selectedCategory);
        setSubcategories(selectedCategoryData ? selectedCategoryData.subcategories || [] : []);
      }
    }
    console.log(subcategories)

  }, [selectedSport, selectedCategory, sports]);
  

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const existingProduct = querySnapshot.docs.find((doc) => doc.id === id);

        if (existingProduct) {
          const data = existingProduct.data();
          setProduct({
            name: data.name,
            imageURLs: data.imageURLs,
            tax: data.tax,
            price: data.price || 0,
            category: data.category,
            subCategory: data.subCategory , // Changed to subCategory
            brand: data.brand,
            desc: data.desc,
            colour: data.colour,
            videoURL: data.videoURL,
            sizeInfo: data.sizeInfo.map((size) => ({
              size: size.size,
              stockCount: size.stockCount,
            })),
            sport: data.sport,
            actualPrice: data.actualPrice !== undefined && data.actualPrice !== '' ? parseFloat(data.actualPrice) : null,
            enabled: data.enabled ? data.enabled : true,
            minimumOrderQuantity: data.minimumOrderQuantity || 1, // Added minimumOrderQuantity
          });
          setSelectedSport(data.sport)
          setSelectedCategory(data.category)
        

        } else {
          toast.error("Product not found");
          navigate("/admin/all-products");
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
  }, [id, navigate]);

 

  const handleSportChange = (e) => {
    const { value } = e.target;
    setSelectedSport(value);
    setSelectedCategory("");
    setCategories([]);
    setSubcategories([]);
  
    if (value === "Other") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        sport: otherSportValue,
      }));
      setCategories([]); // Reset categories when "Other" is selected
    } else {
      const selectedSportData = sports.find((sport) => sport.name === value);
      if (selectedSportData) {
        setCategories(selectedSportData.categories || []);
      } else {
        setCategories([]); // Reset categories if selectedSportData is not found
      }
    }
  };
  
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setSelectedCategory(value);
  
    if (value === "Other") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        category: otherCategoryValue,
      }));
      setSubcategories([]); // Reset subcategories when "Other" is selected
    } else {
      const selectedSportData = sports.find((sport) => sport.name === selectedSport);
      if (selectedSportData) {
        const selectedCategoryData = selectedSportData.categories.find(
          (category) => category.name === value
        );
        if (selectedCategoryData) {
          setSubcategories(selectedCategoryData.subcategories || []);
        } else {
          setSubcategories([]); // Reset subcategories if selectedCategoryData is not found
        }
      } else {
        setSubcategories([]); // Reset subcategories if selectedSportData is not found
      }
    }
  };
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "sport") {
      setSelectedSport(value);
      setSelectedCategory("");
      setCategories([]);
      setSubcategories([]);
      const sport = sports.find((sport) => sport.name === value);
      setCategories(sport.categories || []);
    } else if (name === "category") {
      setSelectedCategory(value);
      const sport = sports.find((sport) => sport.name === selectedSport);
      const category = sport.categories.find(
        (category) => category.name === value
      );
      setSubcategories(category.subcategories || []);
    }
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleWordInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleNumberInputChange = (e) => {
    const { name, value } = e.target;
    if (value === '' || !isNaN(parseFloat(value))) {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleFloatInputChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d*$/.test(value) || value === '' || value === '.') {
       
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: value
        }));
      
    }
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `eshop/${Date.now()}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProduct((prevProduct) => {
            const updatedImageInputs = prevProduct.imageURLs
              ? [...prevProduct.imageURLs]
              : Array(5).fill("");
            updatedImageInputs[index] = downloadURL;
            return {
              ...prevProduct,
              imageURLs: updatedImageInputs,
            };
          });
          toast.success("Image uploaded successfully.");
        });
      }
    );
  };

  const handleSizeChange = (index, value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      sizeInfo: prevProduct.sizeInfo.map((sizeObj, i) =>
        i === index ? { ...sizeObj, size: value } : sizeObj
      ),
    }));
  };

  const handleStockCountChange = (index, value) => {
    if (value === '' || (!isNaN(parseFloat(value)) && value >= 0)) {
        setProduct((prevProduct) => ({
            ...prevProduct,
            sizeInfo: prevProduct.sizeInfo.map((sizeObj, i) =>
                i === index ? { ...sizeObj, stockCount: Number(value) } : sizeObj
            ),
        }));
    }
};


  const addSizeInput = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      sizeInfo: [
        ...prevProduct.sizeInfo,
        {
          size: "",
          stockCount: 0,
        },
      ],
    }));
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formattedSizeInfo = product.sizeInfo.map((sizeData) => ({
        size: sizeData.size,
        stockCount: Number(sizeData.stockCount),
      }));

      const sportValue = selectedSport === "Other" ? otherSportValue : selectedSport;
      const categoryValue = selectedCategory === "Other" ? otherCategoryValue : selectedCategory;
      const subcategoryValue = product.subCategory === "Other" ? otherSubcategoryValue : product.subCategory;
  
    // Update sports collection with new sport if "Other" option selected
if (selectedSport === "Other") {
  await addDoc(collection(db, "sports"), { 
    name: otherSportValue,
    categories: [], // Initialize categories array for the new sport
  });
}

// Update categories array within the selected sport if "Other" option selected
if (selectedCategory === "Other") {
  const sportDoc = await getDocs(query(collection(db, "sports"), where("name", "==", sportValue)));
  if (!sportDoc.empty) {
    const sportId = sportDoc.docs[0].id;
    const sportData = sportDoc.docs[0].data();
    const updatedSubcategories = subcategories.includes(otherSubcategoryValue) ? subcategories : [...subcategories, otherSubcategoryValue];
    const updatedCategories = [
      ...sportData.categories,
      { 
        name: otherCategoryValue,
     
        subcategories: updatedSubcategories // Initialize subcategories array for the new category
      }
    ];
    await updateDoc(doc(db, "sports", sportId), { categories: updatedCategories });
  }
}

// Update subcategories array within the selected category if "Other" option selected
if (product.subCategory === "Other") {
  const sportDoc = await getDocs(query(collection(db, "sports"), where("name", "==", sportValue)));
  if (!sportDoc.empty) {
    const sportId = sportDoc.docs[0].id;
    const sportData = sportDoc.docs[0].data();
    const category = sportData.categories.find(category => category.name === categoryValue);
    if (category) {
      const updatedSubcategories = [
        ...category.subcategories,
        otherSubcategoryValue
      ];
      const updatedCategories = sportData.categories.map(cat =>
        cat.name === categoryValue ? { ...cat, subcategories: updatedSubcategories } : cat
      );
      await updateDoc(doc(db, "sports", sportId), { categories: updatedCategories });
    }
  }
}

console.log("subcat val",otherSubcategoryValue)

      const productRef = doc(db, "products", id);

      await updateDoc(productRef, {
        name: product.name,
        imageURLs: product.imageURLs,
        tax: Number(product.tax),
        category: categoryValue,
        subCategory: otherSubcategoryValue?otherSubcategoryValue:product.subCategory,
        brand: product.brand,
        desc: product.desc,
        videoURL: product.videoURL,
        sizeInfo: formattedSizeInfo,
        sport: sportValue,
        colour: product.colour,
        price: product.price,
        actualPrice: product.actualPrice,
        enabled: product.enabled,
        minimumOrderQuantity: Number(product.minimumOrderQuantity), // Added minimumOrderQuantity
      });

      const notificationsRef = doc(db, "notifications", currentUserID);
      const notificationsSnapshot = await getDoc(notificationsRef);

      if (notificationsSnapshot.exists()) {
        const userProducts = notificationsSnapshot.data().products || [];

        if (Array.isArray(userProducts)) {
          const isProductInNotifications = userProducts.some(
            (userProduct) => userProduct.id === id
          );

          if (isProductInNotifications) {
            const updatedProducts = userProducts.map((userProduct) => {
              if (userProduct.id === id) {
                userProduct.stockStatus = true;
              }
              return userProduct;
            });

            await updateDoc(notificationsRef, { products: updatedProducts });
          }
        }
      }
      setIsLoading(false);
      setUploadProgress(0);

      toast.success("Product updated successfully.");
      navigate("/admin/all-products");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const removeImage = (index) => {
    setProduct((prevProduct) => {
      const updatedImageURLs = [...prevProduct.imageURLs];
      updatedImageURLs.splice(index, 1);
      return {
        ...prevProduct,
        imageURLs: updatedImageURLs,
      };
    });
  };

  const removeSizeInput = (index) => {
    setProduct((prevProduct) => {
      const updatedSizeInfo = [...prevProduct.sizeInfo];
      updatedSizeInfo.splice(index, 1);
      return {
        ...prevProduct,
        sizeInfo: updatedSizeInfo,
      };
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.product}>
        <h2>{product.subCategory}</h2>
        <Card cardClass={styles.card}>
          <form onSubmit={saveChanges}>
            <label htmlFor="productName">Product Name:</label>
            <input
              type="text"
              id="productName"
              placeholder="Enter product name"
              required
              name="name"
              value={product.name}
              onChange={(e) => handleWordInputChange(e)}
            />

            <label htmlFor="productTax">Tax Percentage (%):</label>
            <input
              type="text"
              id="productTax"
              placeholder="Enter tax percentage"
              required
              pattern="^\d*\.?\d*$"
              name="tax"
              value={product.tax}
              onChange={(e) => handleFloatInputChange(e)}
            />

<label htmlFor="minimumOrderQuantity">Minimum Order Quantity:</label>
            <input
              type="text"
              id="minimumOrderQuantity"
              placeholder="Enter Minimum Order Quantity"
              pattern="^\d*\.?\d*$"
              required
              name="minimumOrderQuantity"
              value={product.minimumOrderQuantity}
              onChange={(e) => handleFloatInputChange(e)}
            />

            <label htmlFor="actualPrice">Actual Price (USD)</label>
            <input
              type="text"
              id="actualPrice"
              required
              placeholder="Enter product selling price"
              pattern="^\d*\.?\d*$"
              name="actualPrice"
              value={product.actualPrice}
              onChange={(e) => handleFloatInputChange(e)}
            />

            <label htmlFor="price">Your Price (USD):</label>
            <input
              type="text"
              id="price"
              placeholder="Enter product selling price"
              pattern="^\d*\.?\d*$"
              required
              name="price"
              value={product.price}
              onChange={(e) => handleFloatInputChange(e)}
            />

<label htmlFor="productSport">Sport:</label>
              <select
                id="productSport"
                required
                value={selectedSport}
                onChange={handleSportChange}
              >
                <option value="" disabled>
                  -- Choose sport --
                </option>
                {sports.map((sport) => (
                  <option key={sport.name} value={sport.name}>
                    {sport.name}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {selectedSport === "Other" && (
                <input
                  type="text"
                  value={otherSportValue}
                  onChange={(e) => setOtherSportValue(e.target.value)}
                />
              )}

              <label htmlFor="productCategory">Product Category:</label>
              <select
                id="productCategory"
                required
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="" disabled>
                  -- Choose product category --
                </option>
                {categories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {selectedCategory === "Other" && (
                <input
                  type="text"
                  value={otherCategoryValue}
                  onChange={(e) => setOtherCategoryValue(e.target.value)}
                />
              )}

              <label htmlFor="productSubcategory">Subcategory:</label>
              <select
                id="productSubcategory"
                required
                value={product.subCategory}
                onChange={handleInputChange}
                name="subCategory"
              >
                <option value="" disabled>
                  -- Choose subcategory --
                </option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
                <option value="Other">Other</option>
              </select>
              {(product.subCategory === "Other" || selectedCategory === "Other") && (
                <input
                  type="text"
                  value={otherSubcategoryValue}
                  onChange={(e) => setOtherSubcategoryValue(e.target.value)}
                />
              )}

            <label htmlFor="productBrand">Product Brand:</label>
            <input
              type="text"
              id="productBrand"
              placeholder="Enter product brand"
              required
              name="brand"
              value={product.brand}
              onChange={(e) => handleWordInputChange(e)}
            />

            <label htmlFor="colour">Product Colour:</label>
            <input
              type="text"
              id="colour"
              placeholder="Enter product colour"
              required
              name="colour"
              value={product.colour}
              onChange={(e) => handleWordInputChange(e)}
            />

           

            <label htmlFor="productDescription">Product Description:</label>
            <textarea
              id="productDescription"
              name="desc"
              required
              placeholder="Enter product description"
              value={product.desc}
              onChange={(e) => handleWordInputChange(e)}
              cols="30"
              rows="10"
            ></textarea>

            <label htmlFor="productVideoURL">Product Video URL:</label>
            <input
              type="text"
              id="productVideoURL"
              placeholder="Enter product video URL"
              name="videoURL"
              value={product.videoURL}
              onChange={(e) => setProduct({ ...product, videoURL: e.target.value })}
            />

            <label htmlFor="minimumOrderQuantity">Minimum Order Quantity:</label>
            <input
              type="number"
              id="minimumOrderQuantity"
              placeholder="Enter minimum order quantity"
              name="minimumOrderQuantity"
              value={product.minimumOrderQuantity}
              onChange={(e) => handleNumberInputChange(e)}
              min="1"
              onWheel={(e) => e.preventDefault()}
            />

            <label>Product images:</label>
            <Card cardClass={styles.group}>
              {uploadProgress === 0 ? null : (
                <div className={styles.progress}>
                  <div
                    className={styles["progress-bar"]}
                    style={{ width: `${uploadProgress}%` }}
                  >
                    {uploadProgress < 100
                      ? `Uploading ${uploadProgress}`
                      : `Upload Complete ${uploadProgress}%`}
                  </div>
                </div>
              )}

              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className={styles.imagePreviewContainer}>
                  <input
                    type="file"
                    accept="image/*"
                    placeholder={`Product Image ${index + 1}`}
                    name={`image${index}`}
                    onChange={(e) => handleImageChange(e, index)}
                  />

                  {product.imageURLs[index] && (
                    <div className={styles.imagePreview}>
                      <img src={product.imageURLs[index]} alt={`Image ${index + 1}`} />
                    </div>
                  )}

                  {product.imageURLs[index] && (
                    <button
                      type="button"
                      className={styles.removeImageButton}
                      onClick={() => removeImage(index)}
                    >
                      X
                    </button>
                  )}
                </div>
              ))}
            </Card>

            <label htmlFor="productSizes">Product Sizes:</label>
            <div>
              {product.sizeInfo.map((size, index) => (
                <div key={index}>
                  <label>{`Size ${index + 1}:`}</label>
                  <input
                    type="text"
                    required
                    placeholder={`Size ${index + 1}`}
                    value={size.size || ""}
                    onChange={(e) => handleSizeChange(index, e.target.value)}
                  />

                  <label>{`Stock Count for Size ${index + 1}:`}</label>
                  <input
                    type="number"
                    required
                    placeholder={`Stock count for Size ${index + 1}`}
                    value={size.stockCount}
                    onChange={(e) => handleStockCountChange(index, e.target.value)}
                   
                    onWheel={(e) => e.preventDefault()}
                  />

                  <button
                    type="button"
                    className={styles.removeSizeButton}
                    onClick={() => removeSizeInput(index)}
                  >
                    Remove Size
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => addSizeInput()}>
                Add a size
              </button>
            </div>

            <button className="--btn --btn-primary">
              Save Changes
            </button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default EditProduct;
